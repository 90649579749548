import React from 'react';
import moment from 'moment';
import { Absence } from '@/types/absences.type';
import { FormatDate } from '@/constants/date.constants';
import { Typography } from '@mui/material';
import { Spacer } from '../Spacer';

interface QuestionnaireSuiviHeaderProperties {
  absence?: Absence;
}

export function QuestionnaireSuiviHeader({ absence }: QuestionnaireSuiviHeaderProperties) {
  return (
    <>
      {absence?.date_end && (
        <Typography fontSize={16} lineHeight={1.5}>
          {` Bonjour, vous avez déclaré une absence jusqu'au`}{' '}
          {absence ? moment(absence.date_end).format(FormatDate.FRENCH_DATE) : ''}.
        </Typography>
      )}
      {!absence?.date_end && absence?.date_end_hospi_theory && (
        <Typography fontSize={16} lineHeight={1.5}>
          Vous avez déclaré une hospitalisation sans durée connue. Nous avons appliqué une date théorique de fin le{' '}
          {absence ? moment(absence.date_end_hospi_theory).format(FormatDate.FRENCH_DATE) : ''}.
        </Typography>
      )}

      <Spacer spacing={4} />

      <Typography fontSize={14} lineHeight={1.4} fontWeight={600}>
        Quel est le statut de cette absence ?
      </Typography>
    </>
  );
}
