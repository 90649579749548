import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';

export interface KerijLoadingOCRProps {
  close?: boolean;
  onClose?: () => void;
}

export const KerijLoadingOCR = (parameters: KerijLoadingOCRProps) => {
  const { close, onClose } = parameters;

  // Max 7s
  const [intervalDownload, setIntervalDownload] = useState<NodeJS.Timer>();
  const [progressValueDownload, setProgressValueDownload] = useState<number>(0);

  // Max 7s
  const [intervalRead, setIntervalRead] = useState<NodeJS.Timer>();
  const [progressValueRead, setProgressValueRead] = useState<number>(0);

  // Max 7s
  const [intervalDate, setIntervalDate] = useState<NodeJS.Timer>();
  const [progressValueDate, setProgressValueDate] = useState<number>(0);

  // Max 35s
  const [intervalAnalyze, setIntervalAnalyze] = useState<NodeJS.Timer>();
  const [progressValueAnalyze, setProgressValueAnalyze] = useState<number>(0);

  const closeAllLoaders = () => {
    const intervalTime = 1000;

    if (intervalDownload != null) {
      clearInterval(intervalDownload);
      setIntervalDownload(
        setInterval(() => {
          if (progressValueDownload < 100) setProgressValueDownload((value) => value + 1);
        }, intervalTime / 100),
      );
    }

    if (intervalRead != null) {
      clearInterval(intervalRead);
      setIntervalRead(
        setInterval(() => {
          if (progressValueRead < 100) setProgressValueRead((value) => value + 1);
        }, intervalTime / 100),
      );
    }

    if (intervalDate != null) {
      clearInterval(intervalDate);
      setIntervalDate(
        setInterval(() => {
          if (progressValueDate < 100) setProgressValueDate((value) => value + 1);
        }, intervalTime / 100),
      );
    }

    if (intervalAnalyze != null) {
      clearInterval(intervalAnalyze);
      setIntervalAnalyze(
        setInterval(() => {
          if (progressValueAnalyze < 100) setProgressValueAnalyze((value) => value + 1);
        }, intervalTime / 100),
      );
    }
  };

  useEffect(() => {
    setIntervalDownload(setIntervalLoader(progressValueDownload, setProgressValueDownload, close ? 1000 : 7000));
  }, []);

  useEffect(() => {
    if (progressValueDownload >= 100) {
      clearInterval(intervalDownload);
      setIntervalDownload(undefined);
      setIntervalRead(setIntervalLoader(progressValueRead, setProgressValueRead, close ? 1000 : 7000));
    }
  }, [progressValueDownload]);

  useEffect(() => {
    if (progressValueRead >= 100) {
      clearInterval(intervalRead);
      setIntervalRead(undefined);
      setIntervalDate(setIntervalLoader(progressValueDate, setProgressValueDate, close ? 1000 : 7000));
    }
  }, [progressValueRead]);

  useEffect(() => {
    if (progressValueDate >= 100) {
      clearInterval(intervalDate);
      setIntervalDate(undefined);
      setIntervalAnalyze(setIntervalLoader(progressValueAnalyze, setProgressValueAnalyze, close ? 1000 : 35_000));
    }
  }, [progressValueDate]);

  useEffect(() => {
    if (progressValueAnalyze >= 100) {
      clearInterval(intervalAnalyze);
      setIntervalAnalyze(undefined);
      setTimeout(() => onClose?.(), 500);
    }
  }, [progressValueAnalyze]);

  useEffect(() => {
    if (close === true) closeAllLoaders();
  }, [close]);

  return (
    <Box>
      <Typography fontSize={16} fontWeight={600} lineHeight={1.5}>
        L’analyse de votre justificatif peut prendre un peu de temps, tout dépend de l’écriture du médecin !
      </Typography>

      <Spacer spacing={4} />

      <LoaderLine text="Conformité du document" value={progressValueDownload} />
      <LoaderLine text="Contrôle de la nature de l’absence" value={progressValueRead} />
      <LoaderLine text="Conformité des dates de début et de fin" value={progressValueDate} />
      <LoaderLine text="Cohérence avec vos informations saisies" value={progressValueAnalyze} />
    </Box>
  );
};

const setIntervalLoader = (
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  intervalTime: number,
): NodeJS.Timer => {
  return setInterval(() => {
    if (value < 100) setValue((data) => data + 1);
  }, intervalTime / 100);
};

interface LoaderLineParameters {
  text: string;
  value: number;
}

const LoaderLine = (parameters: LoaderLineParameters) => {
  const { value, text } = parameters;

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} sx={(theme) => ({ maxWidth: theme.spacing(50) })}>
        <Typography flexGrow={1} fontSize={12} lineHeight={'normal'}>
          {text}
        </Typography>
        <Typography fontSize={12} lineHeight={'normal'}>
          {value}%
        </Typography>
      </Box>

      <Box pt={0.5} pb={1} sx={(theme) => ({ maxWidth: theme.spacing(50) })}>
        <Box display={'flex'}>
          <LinearProgress variant="determinate" value={value} sx={{ width: '100%' }} />
        </Box>
      </Box>
    </Box>
  );
};
