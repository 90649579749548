// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ------ */
/* CUSTOM CSS KERIJ */
/* ------ */
/*
.btn-kerij {
  box-sizing: border-box;
  background: #342F6C;
  border-radius: 63px;
  font-weight: bolder;
  color:#0E0653;
  padding:1.5rem;
}

.btn-kerij:hover {
  color:#FFFFFF;
  filter: brightness(120%);
}

.btn-kerij:focus {
  box-shadow: none;
}

.btn-kerij-outline {
  box-sizing: border-box;
  background: #FFFFFF;
  border-color:#342F6C;
  border-radius: 63px;
  color:#342F6C;
  padding:1.5rem;
}

.btn-kerij-outline:hover {
  filter: brightness(120%);
}

.btn-kerij-outline:focus {
  box-shadow: none;
}

.btn-kerij-sos {
  box-sizing: border-box;
  background: #FCCC69;
  border-radius: 63px;
  color:#342F6C;
  padding:1.5rem;
}

.btn-kerij-sos:hover {
  filter: brightness(95%);
}

.btn-kerij-sos:focus {
  box-shadow: none;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,gDAAgD;EAChD,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;;GAGG;;AAEH,WAAW;AACX,qBAAqB;AACrB,WAAW;AACX;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAkDG","sourcesContent":["html,\nbody {\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Montserrat', sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n/* code {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n\n/* ------ */\n/* CUSTOM CSS KERIJ */\n/* ------ */\n/*\n.btn-kerij {\n  box-sizing: border-box;\n  background: #342F6C;\n  border-radius: 63px;\n  font-weight: bolder;\n  color:#0E0653;\n  padding:1.5rem;\n}\n\n.btn-kerij:hover {\n  color:#FFFFFF;\n  filter: brightness(120%);\n}\n\n.btn-kerij:focus {\n  box-shadow: none;\n}\n\n.btn-kerij-outline {\n  box-sizing: border-box;\n  background: #FFFFFF;\n  border-color:#342F6C;\n  border-radius: 63px;\n  color:#342F6C;\n  padding:1.5rem;\n}\n\n.btn-kerij-outline:hover {\n  filter: brightness(120%);\n}\n\n.btn-kerij-outline:focus {\n  box-shadow: none;\n}\n\n.btn-kerij-sos {\n  box-sizing: border-box;\n  background: #FCCC69;\n  border-radius: 63px;\n  color:#342F6C;\n  padding:1.5rem;\n}\n\n.btn-kerij-sos:hover {\n  filter: brightness(95%);\n}\n\n.btn-kerij-sos:focus {\n  box-shadow: none;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
