import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { Menu as MenuIcon } from '@/components/AppIcon';
import { Button, type ButtonProps } from '@/components/Button';
import { AccountContext, useAppSelector } from '@/store';
import { UserAvatar } from '@/components/UserAvatar';
import { useAuthentication } from '@/hooks/useAuthentication';

export interface NavbarMenuButtonProps extends ButtonProps {
  isAuthenticated: boolean;
}

export function NavbarMenuButton(props: NavbarMenuButtonProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { clear } = useAuthentication();

  const { isAuthenticated, onClick, ...otherProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>();
  const user = useAppSelector(AccountContext.getUser);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onClick?.(event);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const handleMenuItemClick = (callback: (event: React.MouseEvent<HTMLElement>) => void) => {
    return (event: React.MouseEvent<HTMLElement>): void => {
      handleClose();
      callback(event);
    };
  };

  const itemStyle = {
    m: 0,
    p: 0,
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  };

  return (
    <>
      <Button
        color="secondary"
        startIcon={<MenuIcon sx={{ fontSize: (theme) => theme.spacing(3) }} />}
        endIcon={<UserAvatar user={isAuthenticated ? user : null} AvatarProps={{ size: 'medium' }} />}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="large"
        {...otherProps}
      />
      <Menu
        sx={{ marginTop: 0.375 }}
        slotProps={{
          paper: {
            sx(theme) {
              return {
                '& .MuiList-root': {
                  p: 0,
                  minWidth: theme.spacing(29.5),
                },
              };
            },
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isAuthenticated && (
          <MenuItem
            divider
            sx={itemStyle}
            component={Link}
            to="/profil"
            onClick={handleMenuItemClick((_event) => {
              // do nothing
            })}
          >
            <Typography mt={2} mb={2}>
              Profil
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          divider
          sx={itemStyle}
          component={Link}
          to="/report"
          onClick={handleMenuItemClick((_event) => {
            // do nothing
          })}
        >
          <Typography mt={2} mb={2}>
            Nous contacter
          </Typography>
        </MenuItem>

        <MenuItem
          divider
          sx={itemStyle}
          component={Link}
          to="/mentions-legales"
          onClick={handleMenuItemClick((_event) => {
            // do nothing
          })}
        >
          <Typography mt={2} mb={2}>
            Mention légales
          </Typography>
        </MenuItem>

        {isAuthenticated && (
          <MenuItem
            sx={itemStyle}
            onClick={handleMenuItemClick((_event) => {
              clear();
            })}
          >
            <Typography mt={2} mb={2}>
              Déconnexion
            </Typography>
          </MenuItem>
        )}

        {!isAuthenticated && (
          <MenuItem onClick={() => navigate('/login')} sx={itemStyle}>
            <Typography mt={2} mb={2}>
              Se connecter
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
