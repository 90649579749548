import React from 'react';
import classNames from 'clsx';
import { Box, Typography, BoxProps, LinearProgress } from '@mui/material';
import { Spacer } from '../Spacer';

const componentName = 'EmployeeStepper';

export interface EmployeeStepperProps extends BoxProps {
  value: number;
  length: number;
  lastStepLabel?: string;
}

export function EmployeeStepper(props: EmployeeStepperProps) {
  const { value, length, lastStepLabel, className, ...boxProps } = props;

  const isLast = value === length;
  const step = lastStepLabel && isLast ? value - 1 : value;
  const stepsLength = lastStepLabel ? length - 1 : length;

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      <Typography fontSize={16} fontWeight={600} lineHeight={1.25}>
        {isLast && lastStepLabel ? lastStepLabel : `Étape ${String(step)}/${String(stepsLength)}`}
      </Typography>
      <Spacer spacing={5} />
      <LinearProgress variant="determinate" value={(step * 100) / stepsLength} />
      <Spacer spacing={2} />
    </Box>
  );
}
