import { parcoursStateFindAll } from '@/services/rh/parcours/parcoursStateFindAll';
import { useQuery } from 'react-query';

export const ParcoursStateQuery = (() => {
  const useIndex = (parameters: parcoursStateFindAll.Parameters) =>
    useQuery(
      ['parcoursStateIndex', parameters],
      async () => {
        return parcoursStateFindAll(parameters);
      },
      { keepPreviousData: true },
    );

  return {
    useIndex,
  };
})();
