import React from 'react';
import { AppLayout } from '@/components/AppLayout';
import { ProfilFormUI } from './ProfilFormUI';

export const ProfilFormPage: React.FC = () => {
  return (
    <AppLayout mobileBackButton>
      <ProfilFormUI />
    </AppLayout>
  );
};
