import React, { useState, useEffect } from 'react';
import './QuestionSalarieVisite.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../Logotype-Simple-Fondclair.svg';
import moment from 'moment';
import { StatusCodes } from 'http-status-codes';
import { encodeToken, verifyToken } from '../../services/auth';
import { validateResponseVisitEmployee } from '../../services/rh/employees';
import { useToast } from '../Toast';
import { rdvVisiteMedicale } from '@/components/AppPicto';

function QuestionSalarieVisite() {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [decode, setDecode] = useState({
    idRhNotification: '',
    idBusiness: '',
    type: '',
    date: '',
    from: '',
    urlVisiteMedicale: '',
    address: '',
  });
  const [expire, setExpire] = useState('');
  const [isPresent, setIsPresent] = useState('1');
  const [reason, setReason] = useState('0');

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === StatusCodes.OK) {
            setDecode(val.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  return (
    <div>
      {expire ? (
        <div className="container-fluid mt-5">
          <h1 className="question-form mb-5">{expire}</h1>
        </div>
      ) : (
        <div>
          <div className="px-4 my-3">
            <Link
              className="text-link mt-4 pt-5"
              to="/demarches"
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
              }}
            >
              <img src={logo} className="App-logo" alt="logo" width="73" />
            </Link>
          </div>
          <div className="container-fluid col-12 col-xl-6 mt-5">
            <div className="text-center">
              <img alt="kerij" className="mb-3 img-rdv" src={rdvVisiteMedicale} />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title card-title-rdv">{decode.type}</h5>
              </div>
              Votre visite médicale est fixé pour le {decode.date ? moment(decode.date).format('DD/MM/YYYY') : ''} à{' '}
              {decode.from} {decode.urlVisiteMedicale ? 'en téléconsultation' : `à l'adresse : ` + decode.address}
            </div>

            <div>
              <div className="text-center col-12 mb-2 text-modal">Etes-vous disponible à cette date ? </div>
              <label className="col-12 col-xl-12 mb-3">
                <select
                  className="form-select text-modal-select short-text"
                  value={isPresent}
                  onChange={(e) => {
                    setIsPresent(e.target.value);
                  }}
                  required
                >
                  <option value="1">Oui</option>
                  <option value="2">Non</option>
                </select>
              </label>
            </div>
            {isPresent === '2' && (
              <div>
                <div className="text-center mb-2 text-modal">Pour quelles raisons n'êtes vous pas disponible ? </div>
                <label className="col-12 col-xl-10 offset-xl-1 mb-3">
                  <select
                    className="form-select text-modal-select short-text"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    required
                  >
                    <option value="0" disabled>
                      Choisissez
                    </option>
                    <option value="1">Je suis en congé</option>
                    <option value="2">Je suis en déplacement</option>
                  </select>
                </label>
              </div>
            )}
            <div className="text-center col-10 col-xl-4">
              <button
                value={1}
                className="btn btn-kerij-bleu btn-modal col-12 mb-5"
                onClick={() => {
                  encodeToken({
                    idRhNotification: decode.idRhNotification,
                    idBusiness: decode.idBusiness,
                  }).then((val) => {
                    if (val.status === StatusCodes.OK) {
                      const token = val.data.token;
                      validateResponseVisitEmployee(token, {
                        isPresent: isPresent,
                        reason: reason,
                      })
                        .then((value) => {
                          if (value.status === StatusCodes.OK) {
                            navigate('/demarches');
                            setExpire("Ce lien n'est plus disponible");
                          }
                        })
                        .catch((error) => {
                          alert(JSON.stringify(error.response));
                          // setErr(JSON.stringify(error.response))
                        });
                    }
                  });
                }}
              >
                {' '}
                Valider
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionSalarieVisite;
