import React from 'react';
import { Box } from '@mui/material';
import { DeclarationFormSteps } from './steps/type';

export function defineSteps(
  type: string | null,
  steps: Record<keyof DeclarationFormSteps, React.ReactNode>,
  isEdit?: boolean,
) {
  if (isEdit === true)
    return [
      <Box key={'declaration-document'}>{steps.document}</Box>,
      <Box key={'declaration-contact'}>{steps.contact}</Box>,
      <Box key={'declaration-message'}>{steps.message}</Box>,
      <Box key={'declaration-validation'}>{steps.validation}</Box>,
      <Box key={'declaration-end'}>{steps.end}</Box>,
    ];

  if (type === 'MA')
    return [
      <Box key={'declaration-prolongation'}>{steps.prolongation}</Box>,
      <Box key={'declaration-type'}>{steps.type}</Box>,
      <Box key={'declaration-date'}>{steps.date}</Box>,
      <Box key={'declaration-document'}>{steps.document}</Box>,
      <Box key={'declaration-contact'}>{steps.contact}</Box>,
      <Box key={'declaration-message'}>{steps.message}</Box>,
      <Box key={'declaration-validation'}>{steps.validation}</Box>,
      <Box key={'declaration-end'}>{steps.end}</Box>,
    ];
  if (type === 'HO')
    return [
      <Box key={'declaration-date'}>{steps.date}</Box>,
      <Box key={'declaration-document'}>{steps.document}</Box>,
      <Box key={'declaration-contact'}>{steps.contact}</Box>,
      <Box key={'declaration-message'}>{steps.message}</Box>,
      <Box key={'declaration-validation'}>{steps.validation}</Box>,
      <Box key={'declaration-end'}>{steps.end}</Box>,
    ];
  if (type === 'AT')
    return [
      <Box key={'declaration-prolongation'}>{steps.prolongation}</Box>,
      <Box key={'declaration-type'}>{steps.type}</Box>,
      <Box key={'declaration-date'}>{steps.date}</Box>,
      <Box key={'declaration-document'}>{steps.document}</Box>,
      <Box key={'declaration-contact'}>{steps.contact}</Box>,
      <Box key={'declaration-message'}>{steps.message}</Box>,
      <Box key={'declaration-validation'}>{steps.validation}</Box>,
      <Box key={'declaration-end'}>{steps.end}</Box>,
    ];

  return [];
}
