import { UserTypes } from '../types/userTypes.enum';
import { PRO_URL } from '../constants/urls.constants';
import { ACCESS_TOKEN_EMPLOYEE, ACCESS_TOKEN_HR } from '@/hooks/useAuthentication';

export const TOKEN_DATA = 'token_data';
export const REDIRECT_PATH = 'redirect_path';
export const REFRESH_TOKEN = 'refreshToken';

interface TokenData {
  userType: UserTypes;
  token: string;
  exp: string;
  refreshToken?: string;
}

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const storeAuthData = ({ userType, token, exp, refreshToken }: TokenData) => {
  const tokenKey = getTokenKeyByUserType(userType);
  localStorage.setItem(tokenKey, token);
  refreshToken && localStorage.setItem(REFRESH_TOKEN, refreshToken);
  setTokenData({ userType, exp } as any);
};

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const removeAuthData = (userType: UserTypes = getTokenData()?.userType as UserTypes) => {
  const tokenKey = getTokenKeyByUserType(userType);
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(TOKEN_DATA);
  localStorage.removeItem(REFRESH_TOKEN);

  window.dispatchEvent(new Event('tokenChange'));
};

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const setTokenData = ({ userType, exp }: Omit<TokenData, 'token'>) => {
  const data = [userType, exp || ''].join(',');
  localStorage.setItem(TOKEN_DATA, data);
};

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const getTokenData = () => {
  const payload = localStorage.getItem(TOKEN_DATA);
  if (!payload) {
    return null;
  }
  const [userType, exp] = payload.split(',');
  return { userType, exp };
};

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const getToken = () => {
  const tokenData = getTokenData();
  if (!tokenData) {
    return null;
  }
  const tokenKey = getTokenKeyByUserType(tokenData.userType as UserTypes);
  return localStorage.getItem(tokenKey);
};

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

const getTokenKeyByUserType = (userType: UserTypes) => {
  let tokenKey;
  switch (userType) {
    case UserTypes.HR: {
      tokenKey = ACCESS_TOKEN_HR;
      break;
    }
    case UserTypes.EMPLOYEE: {
      tokenKey = ACCESS_TOKEN_EMPLOYEE;
      break;
    }
    default: {
      throw new Error('Invalid user type');
    }
  }
  return tokenKey;
};

/**
 * @deprecated Use useAppWorkspace
 */
export const isProPathName = (pathname: string) => /(^(\/pro\/).*)|(^\/pro$)/.test(pathname);

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const setRedirectPath = (path: string) => {
  localStorage.setItem(REDIRECT_PATH, path);
};

/**
 * @deprecated
 * TODO: refactor this to useAuthentication
 */
export const hasTokenExpired = (exp: string) => {
  const currentTimestamp = Date.now() / 1000; // convert to seconds
  if (!exp) {
    return true;
  }
  const expTimestamp = Number(exp);
  // Check if token will expire within the next minute
  const isExpire = expTimestamp - currentTimestamp <= 0;
  return (!!localStorage.getItem(ACCESS_TOKEN_HR) || !!localStorage.getItem(ACCESS_TOKEN_EMPLOYEE)) && isExpire;
};

/**
 * @deprecated
 * TODO: Delete this (and probably move to router configuration)
 */
export const isInterviewLink = (url: string) => {
  const listLink = [
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DETAILS_ABSENCE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.PREFERENCES_EMPLOYEE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_MANAGER}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_MANAGER_END}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_EMPLOYEE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_SST}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_EMPLOYEE_END}`,
  ];
  return listLink.includes(url);
};
