import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { KerijBox, KerijBoxType } from '../../../../components/base';
import { useError } from '@/shared/useError';
import { DateStartInput } from '@/features/dashboard-rh/create-absence-rh/components/Date/common';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';
import { DeclarationTitle } from '../DeclarationTitle';
import { Box, InputAdornment, styled, Typography } from '@mui/material';
import { AppTextField } from '@/components/AppTextField';
import { QuestionYesNoInput } from '@/components/QuestionYesNoInput';

const getDateDebutInitValue = (document: any) => {
  if (document.dateDebut) {
    return document.dateDebut;
  } else if (['1', '2', '3'].includes(document.absenceType.value) && document.period && document.period.length > 0) {
    return document.period.at(0).dateDebut;
  } else {
    return '';
  }
};

const getDateFinInitValue = (document: any) => {
  if (document.dateFin) {
    return document.dateFin;
  } else if (['1', '2', '3'].includes(document.absenceType.value) && document.period && document.period.length > 0) {
    return document.period.at(0).dateFin;
  } else {
    return '';
  }
};

export const HospiDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const document = location.state.document || {};
  const user = useAppSelector(AccountContext.getUser);

  const [dateDebut, setDateDebut] = useState<string | undefined>(getDateDebutInitValue(document));
  const [dateFin, setDateFin] = useState<string | undefined>(getDateFinInitValue(document));
  const { error, clearError, triggerError } = useError();

  const [lastDayWork, setLastDayWork] = useState<string | undefined>(document.lastDayWork);
  const [isWorkDay, setIsWorkDay] = useState<boolean>(document.isWorkDay || false);

  const [isShownDureeHospi, setIsShownDureeHospi] = useState<boolean>(document.isShownDureeHospi || false);
  const [nbJoursHospi, setNbJoursHospi] = useState<number>(document.nbJoursHospi || 0);

  useEffect(() => {
    handleOnChangeHospiDate();
  }, [dateDebut, nbJoursHospi]);

  const handleLastDayWorkChange = (value: string | undefined) => {
    setLastDayWork(value);
    clearError();
  };
  const handleDateStartChange = (value: string | undefined) => {
    setDateDebut(value);
    clearError();
  };

  const handleOnChangeHospiDate = () => {
    clearError();
    if (
      moment(dateDebut).isBefore(moment().subtract(7, 'days').format('YYYY-MM-DD')) &&
      dateDebut !== moment().format('YYYY-MM-DD')
    ) {
      triggerError(
        `Votre déclaration aurait du être effectuée au plus tard 7 jours après la date de début, veuillez vous rapprocher de votre service Ressources Humaines`,
      );
      return;
    }
    if (nbJoursHospi > 0) {
      setDateFin(
        moment(dateDebut)
          .add(nbJoursHospi - 1, 'days')
          .format('YYYY-MM-DD'),
      );
    } else setDateFin('');
  };

  const handleClick = async () => {
    const documentValue = {
      ...document,
      period: [{ dateDebut: dateDebut, dateFin: dateFin }],
      dateNaissance: null,
      lastDayWork: lastDayWork,
      isLinkHospi: null,
      isWorkDay: isWorkDay,
      idAbsenceHospi: null,
      dateDebut: dateDebut,
      dateFin,
      nbJoursHospi: nbJoursHospi || null,
      isShownDureeHospi: isShownDureeHospi || null,
      optionnal: null,
      dateEndReduction: null,
      hoursReduction: null,
      dateEndPromo: null,
    };
    navigate('/declaration-document', {
      state: {
        document: {
          ...documentValue,
        },
      },
    });
  };
  return (
    <div className="container-fluid">
      <DeclarationTitle
        title="Quelle est la période de votre hospitalisation ?"
        subtitle={
          <>
            Merci de <HighlightedSubtitleText>saisir les dates exactes</HighlightedSubtitleText> renseignées sur votre
            justificatif d’absence.
          </>
        }
      />
      <div className="mb-3 col-xl-4 col-12">
        <form onSubmit={handleClick} style={{ padding: 0, width: 350 }}>
          <AppTextField
            label={`1er jour d'hospitalisation`}
            value={lastDayWork}
            InputProps={{
              inputProps: { type: 'date' },
            }}
            onChange={(_, value) => handleLastDayWorkChange(value)}
            onBlur={(event) => handleLastDayWorkChange(event.target.value)}
          />

          {lastDayWork && user && (
            <DateStartInput
              className="col-12 pb-3"
              lastDayWork={lastDayWork}
              employeeTarget="self"
              employeeTimePackage={user.employee_information.time_package}
              onChange={(dateDebut) => handleDateStartChange(dateDebut)}
            />
          )}

          {dateDebut ? (
            <Box>
              <QuestionYesNoInput
                title={`La durée d'hospitalisation est-elle définie ?`}
                onChange={(_, value) => {
                  setIsShownDureeHospi(value);
                  if (!value) {
                    setDateFin('');
                    setNbJoursHospi(0);
                  }
                }}
              />
            </Box>
          ) : (
            <></>
          )}

          {isShownDureeHospi ? (
            <div>
              <Spacer />

              <AppTextField
                label={'Combien de jours êtes-vous hospitalisé ?'}
                value={String(nbJoursHospi)}
                onChange={(_, absoluteValue) => {
                  const _value = absoluteValue.trim() === '' ? 0 : Number.parseInt(absoluteValue);
                  setNbJoursHospi(_value);
                }}
                InputProps={{
                  type: 'number',
                  componentsProps: {
                    input: { min: 0, style: { textAlign: 'right' } },
                  },
                  sx(theme) {
                    return { width: theme.spacing(16) };
                  },
                  className: 'align-items-center',
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography fontSize={12}>{'jour(s)'}</Typography>
                    </InputAdornment>
                  ),
                }}
              />

              {dateFin && (
                <Typography fontWeight={400} fontSize={12}>
                  <em>Votre date de sortie est prévue le {moment(dateFin).format('DD/MM/YYYY')}</em>
                </Typography>
              )}
            </div>
          ) : (
            <></>
          )}

          {error && <KerijBox message={error} type={KerijBoxType.Error} />}

          <Spacer size="medium" />
          <ActionButton
            startIcon={null}
            type="submit"
            variant="contained"
            actionName="next"
            disabled={!(!error && dateDebut)}
            fullWidth
          />
          <Spacer />
          <Button
            color="secondary"
            onClick={() => {
              if (
                document.absenceType.value + document.absenceType1.value === '32' ||
                document.absenceType.value + document.absenceType1.value === '33' ||
                document.absenceType.value + document.absenceType1.value === '41' ||
                document.absenceType.value + document.absenceType1.value === '42'
              ) {
                navigate('/declaration-type-1', {
                  state: location.state,
                });
              } else if (document.absenceType.value === '2') {
                navigate('/declaration', {
                  state: location.state,
                });
              } else {
                navigate('/declaration-type-2', {
                  state: location.state,
                });
              }
            }}
            fullWidth
          >
            Précédent
          </Button>
        </form>
      </div>
    </div>
  );
};

const HighlightedSubtitleText = styled('span')(({ theme }) => ({
  fontWeight: 600,
  textDecoration: 'underline',
  fontStyle: 'italic',
}));
