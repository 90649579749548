import * as React from 'react';
import moment from 'moment';
import classNames from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, styled, Typography } from '@mui/material';
import { ParcoursState } from '@/types';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { HTMLViewer } from '../HTMLViewer';
import { parcoursEmailFooter } from '../ParcoursDetail';
import { VariableTranslate } from '../VariableTranslate';

const componentName = 'ParcoursStateListItem';

export interface ParcoursStateListItemProps {
  state: ParcoursState;
}

export function ParcoursStateListItem(props: ParcoursStateListItemProps) {
  const { state } = props;

  const employee = `${state.employee_information.firstname} ${state.employee_information.lastname}`.trim();
  const date = moment(state.updated_at).format('DD/MM/YYYY[ à ]HH:mm');
  const parcours = state.parcours.name ?? 'Nom du parcours inconnu';

  const actionEmail = React.useMemo(
    () =>
      state.trigger_parcours_data.script.action?.['email']._ === 'ParcoursActionEmail'
        ? state.trigger_parcours_data.script.action?.['email']
        : undefined,
    [state.trigger_parcours_data.script.action],
  );

  return (
    <Accordion className={classNames(componentName, componentName)} elevation={0} disableGutters>
      <MicroAccordionSummary expandIcon={<ArrowDropDownRounded />}>
        <Grid container>
          <Grid item xs={2.5}>
            <ItemText fontWeight={600}>{employee}</ItemText>
          </Grid>
          <Grid item xs={2.5}>
            <ItemText>{date}</ItemText>
          </Grid>
          <Grid item xs={7}>
            <ItemText>{parcours}</ItemText>
          </Grid>
        </Grid>
      </MicroAccordionSummary>

      <MicroAccordionDetails>
        <Grid container mb={4}>
          <Grid item xs={2.5}>
            <Typography fontWeight={600} fontSize={12}>
              Objet
            </Typography>
          </Grid>
          <Grid item xs={9.5} mt={-0.5}>
            <VariableTranslate fontSize={12}>{actionEmail?.subject}</VariableTranslate>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2.5}>
            <Typography fontWeight={600} fontSize={12}>
              Contenu
            </Typography>
          </Grid>
          <Grid item xs={9.5} maxHeight={210} overflow={'scroll'}>
            <Box fontSize={12}>
              <HTMLViewer
                htmlContent={actionEmail?.content ?? ''}
                after={<p style={{ whiteSpace: 'pre-wrap' }}>{parcoursEmailFooter}</p>}
              />
            </Box>
          </Grid>
        </Grid>
      </MicroAccordionDetails>
    </Accordion>
  );
}

const ItemText = (props: { fontWeight?: 400 | 600; children?: string }) => (
  <Typography pt={2} pb={2} pl={2} lineHeight={'normal'} fontSize={14} fontWeight={props.fontWeight ?? 400}>
    {props.children}
  </Typography>
);

const MicroAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  paddingRight: theme.spacing(1.5),
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const MicroAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.surface,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
}));
