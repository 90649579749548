import * as React from 'react';
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton';
import { useSlate } from 'slate-react';
import { CloseRounded, DataObject } from '@mui/icons-material';
import { Editor, Node, Range } from 'slate';
import { isMarkActive } from '../editor/isMarkActive';
import {
  Box,
  Divider,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Typography,
} from '@mui/material';
import { AllowedStringVariable, translateVariable } from '@/components/VariableTranslate';
import {
  getVariablesByCategorie,
  getVariablesCategorieLabel,
  VariableCategorie,
} from '@/components/VariableTranslate/categories';
import { Spacer } from '@/components/Spacer';

export interface VariableButtonProps extends Omit<ToolbarButtonProps, 'icon'> {
  element?: null | HTMLElement;
  excluded?: AllowedStringVariable[];
}

export const VariableButton = React.forwardRef(function VariableButton(
  props: VariableButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { element, excluded, ...otherProps } = props;
  const editor = useSlate();
  const selected = isMarkActive(editor, 'variable');

  const [eventValue, setEventValue] = React.useState<React.MouseEvent<HTMLButtonElement, MouseEvent> | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(element ?? null);
  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const onOpenMenu = () => {
    if (element) setAnchorEl(element);
    else throw 'Undefined element for VariableButton';
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
    setEventValue(null);
  };

  const handleMouseDown: ToolbarButtonProps['onMouseDown'] = (event) => {
    onOpenMenu();
    setEventValue(event);
  };

  const currentVariableText = React.useMemo(() => {
    if (open) {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const marks = Editor.marks(editor);
        if (marks && marks.variable) {
          const [node] = Editor.node(editor, selection);
          if (Node.string(node)) return Node.string(node);
        }
      }
    }
    return null;
  }, [open]);

  const onSelectVariable = (variableKey: string) => {
    const translation = translateVariable(variableKey);

    if (translation.translated === true) Editor.removeMark(editor, 'error');
    else Editor.addMark(editor, 'error', true);

    if (isMarkActive(editor, 'variable')) Editor.removeMark(editor, 'variable');

    Editor.addMark(editor, 'variable', true);
    Editor.insertText(editor, translation.label);
    Editor.insertNode(editor, { text: ' ' });

    if (eventValue) props.onMouseDown?.(eventValue);
    onCloseMenu();
  };

  const VariableIcon = styled(DataObject)(({ theme }) => ({
    color: selected ? theme.md3.sys.color.primary : theme.md3.sys.color.onSurface,
  }));

  return (
    <>
      {/* Bouton d'ajout */}
      <ToolbarButton
        ref={ref}
        icon={<VariableIcon />}
        {...otherProps}
        selected={selected}
        onMouseDown={handleMouseDown}
      />

      {/* Liste des variables */}
      <VariableMenu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
        <VariableMenuHeader onClick={() => onCloseMenu()} />
        <Divider />

        {Object.values(VariableCategorie).map((category) => {
          return (
            <Box key={category}>
              <Typography fontSize={12} fontWeight={600}>
                {getVariablesCategorieLabel(category)}
              </Typography>
              {getVariablesByCategorie(category).map((variableKey) => {
                const translation = translateVariable(variableKey);
                return (
                  <VariableItem
                    key={variableKey}
                    selected={currentVariableText === translation.label}
                    onClick={() => onSelectVariable(variableKey)}
                    disabled={excluded && excluded.includes(variableKey)}
                  >
                    <Typography fontSize={14}>{translation.label}</Typography>
                  </VariableItem>
                );
              })}
              <Spacer size="small" />
            </Box>
          );
        })}
      </VariableMenu>
    </>
  );
});

const VariableItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.spacing(0.75),
}));

const VariableMenu = (props: Pick<MenuProps, 'anchorEl' | 'open' | 'onClose' | 'children'>) => {
  const { children, ...otherProps } = props;

  return (
    <Menu
      disableScrollLock
      onScroll={(e) => e.preventDefault()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          style: {
            maxHeight: 352,
            marginLeft: 20,
            padding: 8,
          },
        },
      }}
      {...otherProps}
    >
      {children}
    </Menu>
  );
};

const VariableMenuHeader = (props: Pick<IconButtonProps, 'onClick'>) => {
  const { onClick } = props;

  return (
    <MenuItem disableRipple disableTouchRipple sx={{ ':hover': { background: 'inherit' }, cursor: 'default' }}>
      <Box display={'flex'} width={'100%'}>
        <Typography flexGrow={1} fontWeight={600}>
          Personnaliser
        </Typography>
        <Typography>
          <IconButton
            sx={(theme) => ({ position: 'absolute', right: 0, bottom: theme.spacing(-0.5) })}
            onClick={onClick}
          >
            <CloseRounded color="primary" />
          </IconButton>
        </Typography>
      </Box>
    </MenuItem>
  );
};
