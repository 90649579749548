import React from 'react';
import { Absence, AbsenceStatus } from '../../../types/absences.type';
import { isAccident, isMaladie } from '../../../services/rh/absences/data';

interface AbsenceLabelProperties {
  absence?: Absence;
  through?: boolean;
}

export const AbsenceLabel = ({ absence, through }: AbsenceLabelProperties) => {
  const deletedStyle = () =>
    through || absence?.status === AbsenceStatus.DELETED ? { textDecoration: 'line-through' } : {};

  return (
    <>
      {absence && (
        <>
          {isMaladie(absence) || isAccident(absence) ? (
            <>
              <strong style={{ ...deletedStyle(), color: 'inherit' }}>
                {absence.type_absence.description_1_label}
              </strong>
              {' - '}
              <small style={deletedStyle()}>{absence.type_absence.description_label}</small>
            </>
          ) : (
            <>
              <strong style={{ ...deletedStyle(), color: 'inherit' }}>{absence.type_absence.description_label}</strong>
            </>
          )}
        </>
      )}
    </>
  );
};
