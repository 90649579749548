import React, { useCallback } from 'react';
import { Button, TableCell, Tooltip } from '@mui/material';
import { colors } from '@/constants/theme.constants';
import { ReactComponent as IconValidateSVG } from '@/assets/icon-validate.svg';
import { ReactComponent as IconWarningSVG } from '@/assets/icon-warning.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AppInput } from '@/components/AppInput';

const buttonSize = 'medium';
const buttonSelectedColor = 'secondary';

interface ColumnProps {
  value: string;
  selected?: boolean;
}

export interface WarningVerificationColumnButtonProps extends ColumnProps {
  undefinedValue?: boolean;
  updatedTo?: string;
}
export const WarningVerificationColumnButton = (
  props: WarningVerificationColumnButtonProps & React.ComponentProps<typeof Button>,
) => {
  const { value, selected, undefinedValue, updatedTo, ...otherProps } = props;

  const renderButton = useCallback(() => {
    let buttonColor: 'primary' | 'secondary' | 'error' | 'info' = buttonSelectedColor;

    if (!selected) buttonColor = undefinedValue ? 'error' : 'primary';

    return (
      <Button
        disabled={value == null}
        size={buttonSize}
        sx={{ minWidth: '120px', textTransform: 'capitalize' /*, color: theme.palette.common.black*/ }}
        variant={selected ? 'contained' : 'outlined'}
        color={buttonColor}
        {...otherProps}
      >
        {value != null && value != '' ? value : 'Non détecté'}
      </Button>
    );
  }, [selected]);

  return (
    <TableCell className="align-middle">
      {undefinedValue ||
        (updatedTo && (
          <Tooltip
            followCursor
            title={
              <>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '0.5rem' }} />
                {undefinedValue
                  ? 'La donnée n’a pas pu être lue automatiquement lors de l’analyse. La valeur proposée a été générée par le système.'
                  : `La valeur a automatiquement été modifiée. Le collaborateur a saisi le ${updatedTo}.`}
              </>
            }
          >
            {renderButton()}
          </Tooltip>
        ))}

      {!undefinedValue && !updatedTo && renderButton()}
    </TableCell>
  );
};

export interface WarningVerificationColumnDateProps extends ColumnProps {
  onChange: (value: string) => void;
}

export const WarningVerificationColumnDate = (props: WarningVerificationColumnDateProps) => {
  const { value, selected, onChange } = props;
  return (
    <TableCell className="align-middle">
      <Button
        component={AppInput}
        variant={selected ? 'contained' : 'outlined'}
        color={selected ? buttonSelectedColor : 'primary'}
        size={buttonSize}
        type="date"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        style={{ ...(selected ? { borderColor: colors.secondary } : undefined) }}
        sx={{ padding: '0.25rem 1rem' }}
        disableRipple={true}
      />
    </TableCell>
  );
};

export const WarningVerificationColumnStatus = (props: { isValid?: boolean }) => (
  <TableCell className="text-center align-middle">
    {props.isValid ? <IconValidateSVG /> : <IconWarningSVG />}{' '}
  </TableCell>
);

export const WarningVerificationColumnEmpty = () => <TableCell></TableCell>;

export const WarningVerificationColumnLabel = (props: { label: string }) => (
  <TableCell
    scope="row"
    sx={(theme) => ({
      color: theme.palette.primaryBackground.contrastText,
    })}
  >
    <span>{props.label}</span>
  </TableCell>
);

export const WarningVerificationColumnNoError = (props: { value: string }) => (
  <TableCell style={{ color: 'rgba(0, 0, 0, 0.26)', textTransform: 'capitalize', textAlign: 'center' }}>
    {props.value}
  </TableCell>
);
