import { DocumentStatus } from './absences.type';

export interface DocumentOCR {
  documentStatus: DocumentStatus;
  date_start?: DocumentOCRValue<string | null, string | null>;
  date_end?: DocumentOCRValue<string | null, string | null>;
  pregnancy?: DocumentOCRValue<boolean | null, boolean | null>;
  work?: DocumentOCRValue<boolean | null, boolean | null>;
  identity?: DocumentOCRValue<string | null, string | null>;
  authorized_exit?: DocumentOCRValue<string | null, string | null>;
  proofTitle?: DocumentOCRValue<string | null, string | null>;
}

export type DocumentOCRValue<Declare, Detect> = {
  status: DocumentOCRComparatorStatus;
  value: { declare: Declare; detect: Detect };
};

export const DocumentOCRComparatorStatus = {
  Valid: 'valid',
  Invalid: 'invalid',
  Skip: 'skip',
} as const;
export type DocumentOCRComparatorStatus =
  (typeof DocumentOCRComparatorStatus)[keyof typeof DocumentOCRComparatorStatus];
export const isDocumentOCRComparatorStatus = (status: string): status is DocumentOCRComparatorStatus =>
  Object.values(DocumentOCRComparatorStatus).includes(status as any);
