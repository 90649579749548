import React from 'react';
import { Box, Divider, ListItem, ListItemProps } from '@mui/material';
import { NotificationVariant } from './type';
import { NotificationListSection, NotificationListSectionProps } from './NotificationListSection';

interface NotificationListDividerListProps {
  variant: typeof NotificationVariant.List;
  props?: Omit<ListItemProps, 'variant' | 'children'>;
}
interface NotificationListDividerMenuProps {
  variant: typeof NotificationVariant.Menu;
  props?: Omit<NotificationListSectionProps, 'variant' | 'children'>;
}

export type NotificationListDividerProps = NotificationListDividerMenuProps | NotificationListDividerListProps;

export function NotificationListDivider(props: NotificationListDividerProps) {
  return props.variant === NotificationVariant.List ? (
    <ListItem {...props.props} sx={{ p: 0, pl: 2, pr: 2 }}>
      <Box width={'100%'} pl={2} pr={2}>
        <Divider />
      </Box>
    </ListItem>
  ) : (
    <NotificationListSection variant="menu" {...props.props}>
      <Box width={'100%'} pl={4} pr={4}>
        <Divider />
      </Box>
    </NotificationListSection>
  );
}
