import axios from '@/lib/axios';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';

export const absenceCheckCrossover = async (
  params: absenceCheckCrossover.Parameters,
): Promise<absenceCheckCrossover.Return> => {
  const {
    params: { dateEnd, dateStart, idEmployeeInformation },
    options: { severity },
  } = params;

  if (dateStart == null || dateEnd == null)
    return {
      success: false,
      isCrossover: false,
      message:
        severity == 'error'
          ? "Erreur de récupération des données de l'absence"
          : "Les informations données ne permettent pas vérifier la conformité de l'absence",
    };

  const response = await checkCrossover({ dateStart, dateEnd, idEmployeeInformation });

  if (response?.status === StatusCodes.CONFLICT)
    return {
      success: false,
      isCrossover: true,
      message: generateCrossoverMessage(response.data, { severity }),
    };
  else if (response?.status !== StatusCodes.OK)
    return {
      success: false,
      isCrossover: false,
      message: "Nous n'avons pas pu valider la conformité de l'absence",
    };

  return {
    success: true,
    isCrossover: false,
    message: "La période de l'absence a été vérifiée",
  };
};

export namespace absenceCheckCrossover {
  export type Parameters = {
    params: RequestParams;
    options: { severity: 'info' | 'error' };
  };
  export type RequestParams = {
    dateStart: string | null | undefined;
    dateEnd: string | null | undefined;
    idEmployeeInformation?: string | null;
  };
  export type RequestResponse = {
    details: Array<{
      id: string;
      dateStart: string;
      dateEnd: string;
    }>;
  };
  export type Return = {
    success: boolean;
    message: string;
    isCrossover: boolean;
  };
}

const checkCrossover = async (params: absenceCheckCrossover.RequestParams) => {
  try {
    return await axios.post('/api/declaration/verify_crossover', params);
  } catch (e: unknown) {
    const error: AxiosError = e as any;
    return error.response;
  }
};

interface CrossoverAbsences {
  id: string;
  dateStart: string;
  dateEnd: string;
}

const generateCrossoverMessage = (
  crossovers: { details: CrossoverAbsences[] },
  options?: { severity: 'info' | 'error' },
): string => {
  const absences = crossovers.details;
  const count = absences?.length;

  const periods = absences?.map((absence) => ({
    dateStart: moment(absence.dateStart).format('DD/MM/YYYY'),
    dateEnd: moment(absence.dateEnd).format('DD/MM/YYYY'),
  }));

  const pluralSuffix = count > 1 ? 's' : '';

  const countText = `Vous avez ${count} déclaration${pluralSuffix} d'absence${pluralSuffix}`;
  const actionTypeInfo = count > 1 ? 'prises en compte dans vos événements' : 'prise en compte dans vos événements';
  const actionTypeError =
    count > 1 ? 'qui entrent en conflit avec votre déclaration' : 'qui entre en conflit avec votre déclaration';
  const joinedAbsence = periods.map((period) => `du ${period.dateStart} au ${period.dateEnd}`).join(', et ');

  return `${countText} ${options?.severity == 'info' ? actionTypeInfo : actionTypeError}: ${joinedAbsence}`;
};
