import * as ref from './ref';
import * as sys from './sys';

export * as ref from './ref';
export * as sys from './sys';

/**
 * Material UI v3 design tokens
 */
export type MuiToken = {
  ref: typeof ref;
  sys: typeof sys;
};
