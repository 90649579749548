import React from 'react';
import { Box, type BoxProps, Container, type ContainerProps, Divider, Typography, styled } from '@mui/material';
import classNames from 'clsx';

const componentName = 'RHPage';

export interface RHPageProps extends BoxProps {
  title?: string;
  actions?: React.ReactElement;
  topElement?: React.ReactElement;
  variant?: 'default' | 'form' | 'list';
  ContainerProps?: ContainerProps;
}

export function RHPage(props: RHPageProps) {
  const {
    variant = 'default',
    title,
    topElement,
    actions,
    className,
    children,
    ContainerProps,
    ...othersProps
  } = props;
  const isDefault = variant === 'default';
  return (
    <Root
      sx={(theme) => ({
        [`& .${componentName}__Container`]: {
          paddingTop: topElement ? theme.spacing(1) : theme.spacing(2.5),
        },
      })}
      className={classNames(componentName, `${componentName}--variant-${variant}`, className)}
      {...othersProps}
    >
      {title && (
        <>
          <Box sx={{ pl: 2.5, pr: 2.5, pt: 2, pb: 2, display: 'flex', minHeight: 68.5 }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', minHeight: 42 }}>
              <Typography color="primary" fontWeight={600} className={`${componentName}__Title`}>
                {title}
              </Typography>
            </Box>

            <Box sx={{ flexShrink: 0 }}>{actions}</Box>
          </Box>
          {topElement == null && <Divider />}
        </>
      )}

      {topElement && <Box width={'100%'}>{topElement}</Box>}

      <Container maxWidth={false} className={`${componentName}__Container`} {...ContainerProps}>
        {isDefault ? children : <Box className={`${componentName}__Content`}>{children}</Box>}
      </Container>
    </Root>
  );
}

const Root = styled(Box)(({ theme }) => ({
  [`& .${componentName}__Container`]: {
    // Warning : this style breaks disableGutters on Container (but we do not need it at the moment)
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(10.5), // Footer
  },
  [`&.${componentName}--variant-form .${componentName}__Container`]: {
    paddingTop: theme.spacing(1.5),
  },
  [`&.${componentName}--variant-form .${componentName}__Content`]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 630, // From figma, does not correspond to a breakpoint
  },
  [`&.${componentName}--variant-list .${componentName}__Content`]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
