import AccountIcon from '../../DashboardRH/Icons/AccountIcon';
import NotificationIcon from '../../DashboardRH/Icons/NotificationIcon';
import ContactsIcon from '../../DashboardRH/Icons/ContactsIcon';
import React, { useMemo, useState } from 'react';
import { EmployeeInformation } from '../../../types/user.interface';
import Typography from '@mui/material/Typography';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { EmployeeInformationModal } from '../../DashboardRH/shared/components/Modal/EmployeInformationModal';
import { Absence, displayEmployeeCivilityIcon } from '@/types';
import { displayDateEndOrHospi, toFormatDate } from '@/utils/date.utils';
import { ListCardHeader, ListCardHeaderAvatar, ListCardHeaderButton } from '../ListCard';
import { AbsenceCodeColor, isAbsenceCode } from '@/types/AbsenceCode';

interface AbsenceCardHeaderProps {
  absence: Absence;
  employeeInformation: EmployeeInformation;
  hideEmployeeActions?: boolean;
  secondaryAction?: React.ReactNode;
}

export const AbsenceCardHeader = (props: AbsenceCardHeaderProps) => {
  const { employeeInformation, absence, secondaryAction, hideEmployeeActions } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const absenceType = useMemo(
    () => absence.type_absence?.description_label ?? 'Arrêt de travail',
    [absence.type_absence],
  );
  const absenceCode = useMemo(() => absence.type_absence?.internal_code_absence ?? 'MA', [absence.type_absence]);
  const absenceDate = useMemo(() => {
    const startDate = toFormatDate(absence?.date_start);
    const endDate = displayDateEndOrHospi(absence);
    return `Du ${startDate} au ${endDate}`;
  }, [absence]);

  return (
    <>
      <ListCardHeader
        actions={
          <>
            {!hideEmployeeActions && (
              <>
                <AccountIcon employeeInformation={employeeInformation} size="small" />
                <NotificationIcon employeeInformation={employeeInformation} size="small" />
                <ContactsIcon employeeInformation={employeeInformation} size="small" />
              </>
            )}
            {secondaryAction}
          </>
        }
      >
        <ListCardHeaderButton style={{ cursor: 'default' }} disableRipple>
          <ListCardHeaderAvatar
            sx={(theme) => ({
              backgroundColor: isAbsenceCode(absenceCode)
                ? AbsenceCodeColor[absenceCode]
                : theme.palette.secondary.light,
            })}
          >
            {absenceCode}
          </ListCardHeaderAvatar>
        </ListCardHeaderButton>

        <ListCardHeaderButton onClick={() => setIsOpenModal(true)} disableRipple>
          <Typography fontSize={14} fontWeight={600}>
            {employeeInformation ? getFullName(employeeInformation) : 'Un salarié'}
            {'  '}
            {displayEmployeeCivilityIcon(employeeInformation?.civility)}
          </Typography>
        </ListCardHeaderButton>

        <ListCardHeaderButton style={{ cursor: 'default' }} disableRipple>
          <Typography fontSize={14} fontWeight={600}>
            {absenceType}
          </Typography>
        </ListCardHeaderButton>

        <ListCardHeaderButton style={{ cursor: 'default' }} sx={{ ':hover': { border: 0 }, border: 0 }} disableRipple>
          <Typography fontSize={14} fontWeight={400}>
            {absenceDate}
          </Typography>
        </ListCardHeaderButton>
      </ListCardHeader>

      <EmployeeInformationModal
        employeeInformation={employeeInformation}
        visible={isOpenModal}
        onClosed={() => setIsOpenModal(false)}
      />
    </>
  );
};
