import React from 'react';
import { DeclarationFormUI } from './DeclarationFormUI';
import { AppLayout } from '@/components/AppLayout';

export const DeclarationFormPage: React.FC = () => {
  return (
    <AppLayout mobileBackButton navbar={'desktop-only'} AppLayoutMobileBackProps={{ variant: 'close' }}>
      <DeclarationFormUI />
    </AppLayout>
  );
};
