import { ParcoursOwner } from './ParcoursOwner';
import type { ParcoursScript } from './ParcoursScript';

export interface Parcours {
  id: string;
  legal: boolean;
  name: string | undefined;
  script: ParcoursScript;
  active: boolean;
  owner: ParcoursOwner;

  created_by?: string | null;
  edited_by?: string | null;

  updated_at: string;
  created_at: string;
}
