import React from 'react';
import { AppLayout } from '@/components/AppLayout';
import { ServicesContent } from './ServicesContent';
import { RequireLogin } from '@/components/RequireLogin';
import './Services.css';

export const Services: React.FC = () => {
  return (
    <RequireLogin>
      <AppLayout>
        <ServicesContent />
      </AppLayout>
    </RequireLogin>
  );
};
