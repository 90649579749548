/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable xss/no-location-href-assign */
import Axios, { InternalAxiosRequestConfig } from 'axios';
import refreshToken from '../hooks/refreshToken';
import { getToken, getTokenData, hasTokenExpired, isProPathName, removeAuthData } from '../utils/auth.utils';
import { PRO_URL } from '../constants/urls.constants';
import { API_URL } from '../config';

const pathname = window.location.pathname;
async function authRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
  config.headers.Accept = 'application/json';
  const serverURL = config.url;

  if (
    serverURL == null ||
    isExcludedUrl(serverURL) ||
    // @ts-ignore TODO: check this !
    isInterviewLink(window.location)
  ) {
    return config;
  }
  const token = getToken();
  if (token) {
    try {
      const newToken = await refreshToken();
      config.headers['authorization'] = newToken ? `Bearer ${newToken}` : `Bearer ${token}`;
    } catch (error) {
      console.error(error);
      removeAuthData();

      // FIXME: utilisé en RH mais à retirer
      // eslint-disable-next-line xss/no-location-href-assign
      if (isProPathName(pathname) && !isInterviewLink(pathname)) {
        window.location.href = `${PRO_URL.PREFIXE_PRO}${PRO_URL.LOGIN}`;
      }
    }
  }
  return config;
}

const axios = Axios.create({ withCredentials: true, baseURL: API_URL });
axios.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const serverURL = error?.config?.url;
    if (serverURL == null || isExcludedUrl(serverURL)) {
      throw error;
    }
    const token = getToken();
    const tokenData = getTokenData();
    const exp = tokenData?.exp;
    // If the token is expired, we refresh the token and retry the request
    if (token && exp != null && hasTokenExpired(exp)) {
      try {
        const newToken = await refreshToken();
        const config = error.config;
        config.headers.authorization = newToken ? `Bearer ${newToken}` : `Bearer ${token}`;
        return axios.request(config);
      } catch (refreshError) {
        console.error(refreshError);
        removeAuthData();

        // FIXME: utilisé en RH mais à retirer
        // eslint-disable-next-line xss/no-location-href-assign
        if (isProPathName(pathname) && !isInterviewLink(pathname)) {
          window.location.href = `${PRO_URL.PREFIXE_PRO}${PRO_URL.LOGIN}`;
        }
      }
    }
    throw error;
  },
);

// Exclude the authentication and public routes from the interceptor
// URLs that contain a token are also not intercepted because they don't handle the token exchange mechanism
const isExcludedUrl = (url: string) => {
  // @ts-ignore TODO: check this !
  const parameters = new URL(window.location).searchParams;
  return !!parameters.get('token') || url.includes('/auth/') || url.includes('/public/');
};

const isInterviewLink = (url: string) => {
  const listLink = [
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DETAILS_ABSENCE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.PREFERENCES_EMPLOYEE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_MANAGER}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_MANAGER_END}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_EMPLOYEE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_SST}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_EMPLOYEE_END}`,
  ];
  return listLink.includes(url);
};

export default axios;
