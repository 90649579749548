import { styled } from '@mui/material';
import { Editable } from 'slate-react';

export const MUIEditable = styled(Editable)(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: theme.typography.fontFamily,
  outline: 'none',
  height: '100%',
  minHeight: theme.spacing(5),
}));
