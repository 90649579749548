import React from 'react';
import EntretiensLegalCardHeader from '../EntretiensLegalCardHeader';
import EntretiensLegalItem from '../EntretiensLegalItem';
import { ListCardContent, ListCardItem } from '@/components/DashboardRH/ListCard';

interface EntretiensLegalCardProps {
  title: string;
  dataItems: any;
}

const EntretiensLegalCard = ({ title, dataItems }: EntretiensLegalCardProps) => {
  return (
    <ListCardItem elevation={1}>
      <EntretiensLegalCardHeader title={title} />
      <ListCardContent>
        {dataItems.map((item: any, index: number) => {
          return <EntretiensLegalItem key={index} title={item.title} path={item?.path} />;
        })}
      </ListCardContent>
    </ListCardItem>
  );
};

export default EntretiensLegalCard;
