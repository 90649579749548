import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  employeeExtendAbsence,
  employeeUpdateAbsence,
  employeeUpdateDateReturn,
  employeeVerifyAbsence,
} from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { AppLayout, AppLayoutContent } from '../AppLayout';
import useQuestionnaire from '../../hooks/useQuestionnaire';
import { AccountContext, useAppSelector } from '@/store';
import { QUESTIONNAIRE_SUIVI_CHOICES, getTypeDocument } from './data';
import { Absence } from '@/types/absences.type';
import { QuestionnaireSuiviForm } from './QuestionnaireSuiviForm';
import { QuestionnaireSuiviDate } from './QuestionnaireSuiviDate';
import { QuestionnaireSuiviContact } from './QuestionnaireSuiviContact';
import { FormatDate } from '@/constants/date.constants';
import { InputFile } from '../InputFile';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Spacer } from '../Spacer';
import { InfoBox } from '../base';
import { ActionButton } from '../ActionButton';
import { QuestionnaireSuiviHeader } from './QuestionnaireSuiviHeader';
import { useToast } from '../Toast';

export function QuestionnaireSuivi() {
  const [searchParameters] = useSearchParams();
  const token = searchParameters.get('token_decode');
  const toast = useToast();
  const navigate = useNavigate();
  const loading = useAppSelector(AccountContext.isLoading);

  const user = useAppSelector(AccountContext.getUser);
  const [idSalarieInformation, setIdSalarieInformation] = useState();

  const [idAbsence, setIdAbsence] = useState<string>();
  const [absence, setAbsence] = useState<Absence>();
  const [etatAbsence, setEtatAbsence] = useState('1');
  const [dateRetour, setDateRetour] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [expire, setExpire] = useState('');

  const [decode, access, setAccess, loadingAbsence, setLoadingAbsence] = useQuestionnaire();

  async function uploadDocumentIfExist() {
    if (etatAbsence !== QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE) setFiles([]);
    if (absence == null) return { success: false };

    const document = files.at(0);
    if (document != null && user != null) {
      const formData = new FormData();
      formData.set('idEmployeeInformation', user?.employee_information.id);
      formData.set('idAbsence', absence.id);
      formData.set('skipValidationOCR', 'true');
      formData.append('documents', document);
      formData.append('typeDocument', getTypeDocument(`${absence.type_absence.description}`));

      const response = await employeeUpdateAbsence(formData);
      if (response.status !== StatusCodes.OK) return { success: false };
    }
    return { success: true };
  }

  async function normalReturn(notification: string | null, options?: { date?: string; updateEnd?: boolean }) {
    const typeNotification = notification ?? undefined;
    const log = files.length > 0 ? 'Reprise anticipée avec certificat' : 'Reprise anticipée sans certificat';

    if (absence) {
      const dateReturn = options?.date || absence.date_end || absence.date_end_hospi_theory;
      const documentUploaded = await uploadDocumentIfExist();
      if (documentUploaded.success) {
        await employeeUpdateDateReturn({
          idAbsence: absence.id,
          dateReturn,
          typeNotification,
          idEmployeeInformation: absence.employee_information?.id,
          updateEnd: options?.updateEnd,
          log: etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE ? log : undefined,
        });
      }
    }
  }

  async function extensionReturn() {
    await employeeExtendAbsence({
      idAbsence: idAbsence,
      idEmployeeInformation: idSalarieInformation,
      typeNotification: "Notification de suivi de l'arrêt",
    });
  }

  const handleClick = async () => {
    try {
      if (etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_PROLONGATION) {
        await extensionReturn();
      } else if (idAbsence && absence) {
        const dateDeRetour = QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE ? dateRetour : undefined;

        if (etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE && dateDeRetour == null)
          return toast.present({ message: 'Vous devez renseigner la date de retour', severity: 'error' });

        await (dateDeRetour
          ? normalReturn(null, {
              date: dateDeRetour,
              updateEnd: true,
            })
          : normalReturn("Notification de suivi de l'arrêt"));
      }
      navigate('/', { state: { etat: etatAbsence }, replace: true });
    } catch {
      return toast.present({ message: 'Une erreur est survenue lors de la vérification', severity: 'error' });
    }
  };

  const verifyAbsence = async () => {
    try {
      setIdSalarieInformation(decode.idEmployeeInformation);
      setIdAbsence(decode.idAbsence);
      setLoadingAbsence(true);

      const value = await employeeVerifyAbsence({
        token: token,
      });

      if (value.status === StatusCodes.OK) {
        if (value.data.absence?.action_level === '0') setAbsence(value.data.absence);
        else setExpire("Ce lien n'est plus disponible");

        setAccess(true);
      }
      setLoadingAbsence(false);
    } catch {
      setAccess(false);
      setLoadingAbsence(false);
    }
  };

  useEffect(() => {
    if (!token) return;
    if (decode?.idAbsence) verifyAbsence();
  }, [token, decode]);

  useEffect(() => {
    setError(null);
    setDateRetour(null);
  }, [etatAbsence]);

  function onDateSelected(value: string) {
    setDateRetour(value.trim() === '' ? null : value);

    const oldDateEnd = moment(absence?.date_end);
    const dateStart = moment(absence?.date_start);
    const newDateEnd = moment(value).set({ hour: 19, minute: 0, second: 0 });
    const maxEnd = oldDateEnd.subtract(1, 'd').set({ hour: 19, minute: 0, second: 0 });

    const isError = newDateEnd.isBefore(dateStart) || newDateEnd.isAfter(maxEnd);

    if (isError) {
      const baseError = 'Votre date de retour doit être';
      if (absence?.date_start === maxEnd.format(FormatDate.DATE_ONLY))
        setError(`${baseError} ${dateStart.format(FormatDate.FRENCH_DATE)}`);
      else
        setError(
          `${baseError} comprise entre ${moment(absence?.date_start).format(FormatDate.FRENCH_DATE)} et ${maxEnd.format(
            FormatDate.FRENCH_DATE,
          )}`,
        );
    } else setError(null);
  }
  return (
    // FIXME: Utiliser AppLayoutContent
    <AppLayout>
      <AppLayoutContent
        requireAuthentication
        contentTitle={
          !access && !loadingAbsence && user != null
            ? "Vous n'avez pas l'autorisation d'accéder à cette page"
            : "Questionnaire de suivi d'absence"
        }
      >
        {(loading || !user || loadingAbsence) && (
          <Box textAlign={'center'} p={8}>
            <CircularProgress />
          </Box>
        )}

        {!loading && user && !loadingAbsence && access && absence && (
          <Box>
            <QuestionnaireSuiviHeader absence={absence} />
            <Spacer spacing={1} />
            <QuestionnaireSuiviForm value={etatAbsence} absence={absence} onChange={setEtatAbsence} />

            {etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE && (
              <>
                <Spacer spacing={3} />

                <Typography fontSize={14} lineHeight={1.4} fontWeight={600}>
                  A quelle date êtes-vous revenu ?
                </Typography>

                <Spacer spacing={1} />

                <QuestionnaireSuiviDate
                  value={dateRetour}
                  onChange={onDateSelected}
                  min={absence.date_start}
                  max={moment(absence.date_end).subtract(1, 'd').format('YYYY-MM-DD')}
                />

                <Spacer spacing={1} />
                {error && <InfoBox type="error">{error}</InfoBox>}

                <Spacer spacing={2} />
                <InputFile value={files} onChange={(_, files) => setFiles(files)} detectDocument={true} />
              </>
            )}

            <Spacer spacing={3} />

            <Typography fontSize={14} lineHeight={1.4} fontWeight={600}>
              Désirez vous un entretien avec un Manager ou une personne des Ressources Humaines ?
            </Typography>

            <Spacer spacing={1} />

            {/* Cette partie ne fait absolument rien */}
            <QuestionnaireSuiviContact />

            <Spacer spacing={3} />

            <Box textAlign={'right'}>
              <ActionButton
                type="button"
                label={'Valider mes réponses'}
                color="primary"
                disabled={error != null}
                onClick={handleClick}
              />
            </Box>
          </Box>
        )}
      </AppLayoutContent>
    </AppLayout>
  );
}
