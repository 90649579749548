import React, { useEffect } from 'react';
import RdvLoginUI from './components/RdvLoginUI';
import { DefaultLayout } from '../../../components/layouts';
import { useAppFeature } from '@/store';
import { useNavigate } from 'react-router-dom';

export const RdvLoginPage: React.FC = () => {
  const navigate = useNavigate();
  const isActive = useAppFeature().InterviewManagement;

  useEffect(() => {
    if (isActive != null && isActive === false) navigate('/declaration');
  }, [isActive]);

  return (
    <DefaultLayout>
      <RdvLoginUI />
    </DefaultLayout>
  );
};
