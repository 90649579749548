import React, { useEffect } from 'react';
import { useAppSelector } from '@/store';
import { Box, BoxProps, Divider, List, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { AppCollapsibleField } from '@/components/AppCollapsibleField';
import { UserInterface } from '@/types';
import { EmployeeProfilField } from '@/components/EmployeeProfilField';
import { useAppVariant } from '@/hooks/useAppVariant';

const findAddress = (user: UserInterface | null) => {
  const userCity = user?.employee_information.locality;
  const userStreet = user?.employee_information.address1;
  const addressValue = `${userCity && userStreet ? userStreet + ', ' : (userStreet ?? '')}`;
  const cityValue = `${userCity ?? ''}`;
  return `${addressValue}${cityValue}`;
};

export interface ProfilFormUIFormContentProps extends BoxProps {
  onUpdated?: () => void;
}

export function ProfilFormUIFormContent(props: ProfilFormUIFormContentProps) {
  const { onUpdated, ...boxProps } = props;

  const appVariant = useAppVariant();
  const accountContext = useAppSelector((state) => state.accountContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setEmail] = React.useState(accountContext.user?.email);
  const [phone, setPhone] = React.useState(accountContext.user?.phone);
  const [address, setAddress] = React.useState(findAddress(accountContext.user));
  const [currentEditType, setCurrentEditType] = React.useState<'email' | 'phone' | 'address' | null>(null);

  useEffect(() => {
    if (!accountContext.loading) {
      setEmail(accountContext.user?.email);
      setPhone(accountContext.user?.phone);
      setAddress(findAddress(accountContext.user));
      setIsLoading(false);
    }
  }, [accountContext.loading, accountContext.user]);

  return (
    <Box {...boxProps}>
      {!isLoading && accountContext.user && (
        <Box>
          <List sx={{ bgcolor: 'background.paper', p: 0 }}>
            <EmployeeProfilField
              label={'Adresse e-mail'}
              type="email"
              value={email}
              infoContent={
                <Typography fontSize={14} lineHeight={1.4}>
                  Les moyens de contact que vous déclarez servent à vous adresser des notifications administratives &
                  légales en cas d’absence. Ils doivent donc être accessibles en cas d’absence et ne sont jamais
                  communiqués à votre entreprise sans votre accord explicite. Vous devez veiller à les mettre à jour.
                </Typography>
              }
              onStartEdit={() => setCurrentEditType('email')}
              onEndEdit={() => setCurrentEditType(null)}
              disabled={currentEditType !== 'email' && currentEditType != null}
              confirmTo={{
                phone: true,
                idEmployeeLogin: accountContext.user.employee_information.employee_login.id,
                idBusiness: accountContext.user.employee_information.id_business,
              }}
              onChange={onUpdated}
            />

            <Spacer spacing={3} />
            <Divider sx={{ ml: 1, mr: 1 }} />
            <Spacer spacing={3} />

            <EmployeeProfilField
              label={'Numéro de téléphone'}
              value={phone}
              type="tel"
              infoContent={
                <Typography>
                  Les moyens de contact que vous déclarez servent à vous adresser des notifications administratives &
                  légales en cas d’absence. Ils doivent donc être accessibles en cas d’absence et ne sont jamais
                  communiqués à votre entreprise sans votre accord explicite. Vous devez veiller à les mettre à jour.
                </Typography>
              }
              onStartEdit={() => setCurrentEditType('phone')}
              onEndEdit={() => setCurrentEditType(null)}
              disabled={currentEditType !== 'phone' && currentEditType != null}
              confirmTo={{
                email: true,
                idEmployeeLogin: accountContext.user.employee_information.employee_login.id,
                idBusiness: accountContext.user.employee_information.id_business,
              }}
              onChange={onUpdated}
            />

            <Spacer spacing={3} />
            <Divider sx={{ ml: 1, mr: 1 }} />
            <Spacer spacing={3} />

            <AppCollapsibleField
              label={'Adresse'}
              value={address}
              infoContent={
                <Typography>
                  En cas d’absence prolongée, votre entreprise peut nous mandater pour vous adresser différents types de
                  courriers importants. Veillez à mettre à jour votre adresse en vous adressant à votre entreprise ou en
                  utilisant les outils internes prévus à cet effet.
                </Typography>
              }
              onStartEdit={() => setCurrentEditType('address')}
              onEndEdit={() => setCurrentEditType(null)}
              disabled={currentEditType !== 'address' && currentEditType != null}
              readOnly
            />
          </List>
        </Box>
      )}
    </Box>
  );
}
