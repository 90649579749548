import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Absence } from '../../../../../../types/absences.type';
import moment from 'moment';
import { FormatDate } from '../../../../../../constants/date.constants';
import { ListCardContentLine } from '@/components/DashboardRH/ListCard';

interface AbsenceDetailsLogsProperties {
  absence: Absence;
}

export const AbsenceDetailsLogs = ({ absence }: AbsenceDetailsLogsProperties) => {
  const logList = useMemo(
    () => absence?.absence_logs?.filter((log) => log.triggered_by !== 'adp' && log.description) ?? [],
    [absence?.absence_logs],
  );

  const isEmpty = useMemo(() => logList.length > 0, [logList]);

  return (
    <Box textAlign="left" width="100%">
      {isEmpty &&
        logList.map((history) => (
          <ListCardContentLine
            separator={':'}
            lineHeight={'normal'}
            key={history.id}
            label={history.description ?? 'Erreur de récupération de l’événement'}
          >
            {moment(history.created_at).format(FormatDate.FRENCH_DATE)}
          </ListCardContentLine>
        ))}

      {!isEmpty && <>Aucun évènements disponible</>}
    </Box>
  );
};
