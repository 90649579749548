import React from 'react';
import { PolicyLayout } from '@/components/AppLayout';
import { TermsContent } from '@/components/TermsContent';
import { datesPolicies } from '@/config/policies.config';

export const Terms: React.FC = () => {
  return (
    <PolicyLayout title={"Conditions Générales d'Utilisation"} date={datesPolicies.terms}>
      <TermsContent />
    </PolicyLayout>
  );
};
