import { Box, Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface ListCardContentLineProps extends Omit<TypographyProps, 'sx'> {
  label: React.ReactNode;
  separator?: React.ReactNode;
  deleted?: boolean;
}

export const ListCardContentLine = (props: ListCardContentLineProps) => {
  const { label, children, separator, onClick, deleted, ...typographyProps } = props;
  const isButton = onClick != null;

  return (
    <Box display={'flex'}>
      <Typography
        fontWeight={600}
        lineHeight={2}
        fontSize={14}
        onClick={onClick}
        sx={(theme) => ({
          cursor: isButton ? 'pointer' : 'default',
          ':hover': isButton
            ? {
                color: theme.md3.sys.color.onSurfaceVariant,
                textDecoration: 'underline',
              }
            : undefined,
        })}
        {...typographyProps}
      >
        {label}
        {separator ? <>&nbsp;{separator}&nbsp;</> : null}
      </Typography>

      <Typography
        fontWeight={400}
        lineHeight={2}
        fontSize={14}
        sx={deleted ? { textDecoration: 'line-through' } : {}}
        {...typographyProps}
      >
        {children}
      </Typography>
    </Box>
  );
};
