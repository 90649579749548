import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { InfoBox } from '@/components/base';
import { DateStartInput } from '@/features/dashboard-rh/create-absence-rh/components/Date/common';
import { AccountContext, useAppSelector } from '@/store';
import { AbsenceDateRangeModel } from './data';
import { Spacer } from '../Spacer';
import { AppTextField } from '../AppTextField';
import { Box, InputAdornment, Typography } from '@mui/material';
import { QuestionYesNoInput } from '../QuestionYesNoInput';

type InputType = AbsenceDateRangeModel & { duration: number; show_duration: boolean };
export interface AbsenceDateRangeHospiProps {
  dense?: boolean;
  value: InputType;
  error: string | null;
  onChange: (newValue: InputType) => void;
  callback?: (model: InputType) => Promise<void>;
  onChangeSubmitStatus?: (canSubmit: boolean) => void;
  additionalFields?: JSX.Element;
}

export function AbsenceDateRangeHospi(props: AbsenceDateRangeHospiProps) {
  const { value, error, dense, onChange, onChangeSubmitStatus, callback, additionalFields } = props;
  const user = useAppSelector(AccountContext.getUser);

  const [debounceId, setDebounceId] = useState<NodeJS.Timeout>();
  const [model, setModel] = useState<InputType>(value);

  async function onStartChange(newValue: string | undefined) {
    setModel({ ...model, dateStart: newValue });
    onChange({ ...model, dateStart: newValue });
    callback?.({ ...model, dateStart: newValue });
  }

  async function onLastDayChange(newValue: string | undefined) {
    setModel({ ...model, lastDayWork: newValue, dateStart: newValue });
    onChange({ ...model, lastDayWork: newValue, dateStart: newValue });
    callback?.({ ...model, lastDayWork: newValue, dateStart: newValue });
  }

  useEffect(() => {
    onChangeSubmitStatus?.(false);

    if (debounceId) clearTimeout(debounceId);
    const id = setTimeout(async () => {
      await callback?.(model);
      onChangeSubmitStatus?.(true);
    }, 500);

    setDebounceId(id);
  }, [model.dateStart, model.dateEnd]);

  return (
    <>
      <AppTextField
        dense
        label={`1er jour d'hospitalisation`}
        value={value.dateStart}
        InputProps={{ inputProps: { type: 'date' } }}
        onChange={(_, value) => onLastDayChange(value)}
        onBlur={(event) => onLastDayChange(event.target.value)}
      />

      <Spacer spacing={4} />

      {model.lastDayWork != null && user != null && (
        <DateStartInput
          className="col-12 pb-3"
          lastDayWork={model.lastDayWork}
          employeeTarget="self"
          employeeTimePackage={user.employee_information.time_package}
          onChange={onStartChange}
        />
      )}

      {model.dateStart ? (
        <Box>
          <QuestionYesNoInput
            title={`La durée d'hospitalisation est-elle définie ?`}
            value={model.show_duration}
            onChange={(_, value) => {
              if (value) {
                setModel({ ...model, show_duration: true });
                onChange({ ...model, show_duration: true });
                callback?.({ ...model, show_duration: true });
              } else {
                setModel({ ...model, dateEnd: undefined, show_duration: false });
                onChange({ ...model, dateEnd: undefined, show_duration: false });
                callback?.({ ...model, dateEnd: undefined, show_duration: false });
              }
            }}
          />
        </Box>
      ) : (
        <></>
      )}

      {model.show_duration ? (
        <div>
          <Spacer />

          <AppTextField
            dense={dense}
            label={'Combien de jours êtes-vous hospitalisé ?'}
            value={String(model.duration)}
            onChange={(_, absoluteValue) => {
              const _value = absoluteValue.trim() === '' ? 0 : Number.parseInt(absoluteValue);
              setModel({ ...model, duration: _value });
              onChange({ ...model, duration: _value });
              callback?.({ ...model, duration: _value });
            }}
            InputProps={{
              type: 'number',
              componentsProps: {
                input: { min: 0, style: { textAlign: 'right' } },
              },
              sx(theme) {
                return { width: theme.spacing(16) };
              },
              className: 'align-items-center',
              endAdornment: (
                <InputAdornment position="end">
                  <Typography fontSize={12}>{'jour(s)'}</Typography>
                </InputAdornment>
              ),
            }}
            LabelProps={{
              style: { fontSize: 16, paddingBottom: 16 },
            }}
          />

          {value.dateEnd && (
            <Typography fontWeight={400} fontSize={12}>
              <em>Votre date de sortie est prévue le {moment(value.dateEnd).format('DD/MM/YYYY')}</em>
            </Typography>
          )}
        </div>
      ) : (
        <></>
      )}

      {additionalFields}

      <Spacer spacing={3} />

      {error && <InfoBox type="error">{error}</InfoBox>}
    </>
  );
}
