import { isProPathName } from '../utils/auth.utils';
import { ACCESS_TOKEN_EMPLOYEE, ACCESS_TOKEN_HR } from './useAuthentication';

/**
 * @deprecated Use useAuthentication
 */
export default function isAuthorized(pathname: string) {
  return isProPathName(pathname)
    ? !!localStorage.getItem(ACCESS_TOKEN_HR)
    : !!localStorage.getItem(ACCESS_TOKEN_EMPLOYEE);
}
