import axios from '@/lib/axios';
import { Notification } from '@/types';

export const notificationFindAllArchive = async (params: notificationFindAllArchive.Parameters) => {
  const response = await axios.get<notificationFindAllArchive.Return>(`/api/employee/notifications_history`, {
    params,
  });
  const responseBody = response.data;
  return responseBody.data;
};
export namespace notificationFindAllArchive {
  export interface Parameters {
    page: number;
    per_page: number;
    from: number;
  }

  export type Return = {
    data: Array<Notification>;
    meta: {
      total: number;
    };
  };
}
