import * as React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, Card, CardContent, Divider, Typography } from '@mui/material';
import { useController, useForm } from 'react-hook-form';
import { reportSend } from '@/services/report';
import { useNavigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { InputFile } from '@/components/InputFile';
import { Spacer } from '@/components/Spacer';
import { AppSelect } from '@/components/AppSelect';
import { AppTextField } from '@/components/AppTextField';
import { ActionButton } from '@/components/ActionButton';
import { useToast } from '@/components/Toast';
import { DrawRounded } from '@mui/icons-material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { AppLayoutContent } from '@/components/AppLayout';
import { emailRegex } from '@/utils/regex';
import { isEmail } from '@/utils/field-validator';

// Name and options
const componentName = 'ReportContent';

// Component properties
export interface ReportContentProps extends BoxProps {}

export function ReportContent(props: ReportContentProps) {
  const { handleSubmit, control, formState } = useForm();

  const toast = useToast();
  const appVariant = useAppVariant();
  const navigate = useNavigate();

  const requiredMessage = 'Ce champs est requis';
  const { field: email, fieldState: emailState } = useController({
    name: 'reporterEmail',
    rules: {
      required: requiredMessage,
    },
    control,
  });
  const { field: category } = useController({
    name: 'category',
    rules: {
      required: requiredMessage,
    },
    control,
  });
  const { field: description } = useController({
    name: 'description',
    rules: {
      required: requiredMessage,
    },
    control,
  });

  const [attachments, setAttachments] = React.useState<Array<File>>([]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = {
      reporterEmail: data['reporterEmail'],
      category: data['category'],
      description: data['description'],
      attachments: attachments,
    };

    if (!isEmail(true)(data['reporterEmail'])) {
      return toast.present({ message: "Le format de l'email semble incorrect", severity: 'error' });
    }

    const response = await reportSend(formData);
    if (response.status === StatusCodes.OK) {
      toast.present({ message: 'Votre signalement a bien été envoyé', severity: 'success' });
      navigate('/profil');
    }
  });

  React.useEffect(() => {
    if (formState.isSubmitted && !formState.isValid)
      toast.present({ message: 'Tous les champs doivent être renseignés', severity: 'error' });
  }, [formState.isSubmitted, formState.isValid, formState.isDirty]);

  return (
    <AppLayoutContent
      className={classNames(componentName)}
      contentTitle={'Signaler un problème'}
      contentRight={
        <Card color="surface">
          <CardContent>
            <Typography fontSize={48} lineHeight={0.5}>
              <DrawRounded />
            </Typography>

            <Spacer spacing={3} />

            <Typography fontSize={16} lineHeight={1.25} fontWeight={600}>
              En quoi mon signalement est-il utile ?
            </Typography>

            <Spacer spacing={3} />

            <Typography fontSize={14} lineHeight={1.4}>
              Vos signalements sont essentiels pour améliorer l&apos;expérience utilisateur de notre site. Grâce à vos
              retours, nous pouvons identifier et résoudre rapidement des problèmes techniques, optimiser les
              fonctionnalités existantes, et garantir une navigation plus fluide et intuitive pour tous les
              utilisateurs.
            </Typography>
          </CardContent>
        </Card>
      }
      {...props}
    >
      <form onSubmit={onSubmit} noValidate>
        <Typography fontWeight={600} fontSize={appVariant.small ? 16 : 14} lineHeight={'normal'} whiteSpace={'nowrap'}>
          Quel est la nature du problème rencontré ?*
        </Typography>

        <Spacer spacing={4} />

        <AppSelect
          value={category.value}
          items={["Bug d'affichage", 'Bug technique', 'Proposer une amélioration', 'Autre'].map((choice) => ({
            label: choice,
            value: choice,
          }))}
          dense
          placeholder="Veuillez sélectionner"
          onChange={(_, value) => {
            category.onChange(value);
          }}
        />

        <Spacer spacing={appVariant.small ? 6 : 4} />
        <Divider />
        <Spacer spacing={appVariant.small ? 6 : 4} />

        <Typography fontWeight={600} fontSize={appVariant.small ? 16 : 14} lineHeight={'normal'}>
          Veuillez décrire en détail les problèmes ou les bugs que vous avez rencontrés.*
        </Typography>

        <Spacer spacing={1} />

        <AppTextField
          dense
          value={description.value}
          name={description.name}
          onChange={(_, value) => {
            description.onChange(value);
          }}
          InputProps={{
            multiline: true,
            rows: appVariant.small ? 16 : 8,
            maxRows: 20,
            sx(theme) {
              return { p: theme.spacing(2) };
            },
          }}
        />

        <Box textAlign={'left'}>
          <InputFile
            value={attachments}
            onChange={(_, files) => {
              setAttachments(files);
            }}
          />
        </Box>

        <Spacer spacing={appVariant.small ? 6 : 4} />
        <Divider />
        <Spacer spacing={appVariant.small ? 6 : 4} />

        <Typography fontWeight={600} fontSize={appVariant.small ? 16 : 14} lineHeight={'normal'}>
          Quelle est votre adresse e-mail ?*
        </Typography>

        <Spacer spacing={1} />

        <AppTextField
          dense
          value={email.value}
          onChange={(_, value) => {
            email.onChange(value);
          }}
          type="email"
          placeholder="Adresse e-mail"
          error={Boolean(emailState.error)}
          InputProps={{ type: 'email' }}
        />

        <Spacer spacing={4} />
        <ActionButton
          type="submit"
          color="primary"
          variant="contained"
          label={'Enregistrer mon signalement'}
          startIcon={null}
          disabled={formState.isSubmitting}
        />
      </form>
    </AppLayoutContent>
  );
}
