import React, { useEffect, useState } from 'react';
import { Absence, AbsenceContactType } from '@/types/absences.type';
import CustomModal from '../../base/CustomModal';
import { IconButton, IconButtonProps } from '@/components/IconButton';
import { Email, Link, Phone } from '@/components/AppIcon';

interface LinkIconProps {
  absence: Absence;
  size?: IconButtonProps['size'];
}

interface LinkModalProperties {
  absence: Absence;
  visible: boolean;
  handleCloseModal: () => void;
}

const LinkModal = ({ visible, absence, handleCloseModal }: LinkModalProperties) => {
  const LinkModalContent = () => {
    return (
      <>
        <div className={'row'}>
          <p className={'m-10 text-center'}>
            Le collaborateur {absence.contact_type == null ? 'refuse' : 'accepte'} d’être contacté pendant son absence.
          </p>
          <p className={'m-10 text-center'}>
            Il privilégie un contact par son manager
            {/*et/ou son manager RH*/}
          </p>
          {absence.message && (
            <p className={'m-10 text-center'}>
              Il a déposé un message à son manager
              {/*et/ou son manager RH*/}
            </p>
          )}
          <p className={'m-10 text-center'}>Il peut être contacté par les moyens suivants</p>
          <div className={'col-6 text-center'}>
            <Email color="primary" />
            <h4>{!!absence.email ? absence.email : 'Non'}</h4>
          </div>
          <div className={'col-6 text-center'}>
            <Phone color="primary" />
            <h4>{!!absence.phone ? absence.phone : 'Non'}</h4>
          </div>
        </div>
      </>
    );
  };
  return (
    <CustomModal
      open={visible}
      onClose={handleCloseModal}
      renderHeader={() => <>Maintien du lien</>}
      renderContent={() => <LinkModalContent />}
      renderFooter={() => <></>}
    />
  );
};

const LinkIcon = ({ absence, size }: LinkIconProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentAbsence, setCurrentAbsence] = useState(absence);
  const displayInformation =
    currentAbsence.contact_type == null
      ? {
          tooltip: 'Le salarié ne souhaite pas être contacté',
          badgeColor: 'error' as const,
        }
      : {
          tooltip: (() => {
            const byMail = !!currentAbsence.email;
            const byPhone = !!currentAbsence.phone;
            let tooltip = "Le salarié accepte d'être contacté ";

            tooltip += 'par son ';
            tooltip += {
              [AbsenceContactType.Manager]: 'Manager',
              [AbsenceContactType.GestionnaireRh]: 'Manager RH',
              [AbsenceContactType.ManagerEtGestionnaireRH]: 'Manager et Manager RH',
            }[currentAbsence.contact_type];
            tooltip += ' ';

            tooltip += 'par ';
            tooltip += byMail ? 'mail' : '';
            tooltip += byMail && byPhone ? ' et ' : '';
            tooltip += byPhone ? 'téléphone' : '';
            return tooltip;
          })(),
          badgeColor: 'success' as const,
        };

  useEffect(() => {
    setCurrentAbsence(absence);
  }, [absence]);

  return (
    <>
      <IconButton
        size={size}
        onClick={() => {
          absence.contact_type != null && setModalIsOpen(true);
        }}
        badge={true}
        BadgeProps={{
          color: displayInformation.badgeColor,
        }}
        TooltipProps={{
          title: displayInformation.tooltip,
        }}
        icon={<Link sx={{ color: 'white' }} />}
        sx={{ mr: 1 }}
      />
      <LinkModal absence={currentAbsence} visible={modalIsOpen} handleCloseModal={() => setModalIsOpen(false)} />
    </>
  );
};

export default LinkIcon;
