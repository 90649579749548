import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { EmployeeStepper, EmployeeStepperAction, EmployeeStepperContent } from '@/components/EmployeeStepper';
import { Spacer } from '@/components/Spacer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { defineSteps } from './defineSteps';
import { defineTitle } from './defineTitle';
import { DeclarationFormControl } from './steps/type';
import * as Steps from './steps';
import { AppLayoutContent } from '@/components/AppLayout';
import { initControl, defineControl, verifyControl, clearControl } from './defineControl';

export const DeclarationFormUI = () => {
  const appVariant = useAppVariant();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const onEdit = useMemo(() => searchParams.get('edit') != null, [searchParams]);

  const titleRef = useRef<HTMLSpanElement>(null);

  const formControl: DeclarationFormControl = useState(initControl(searchParams));
  const [initialized, setInitialized] = useState(false);

  const declarationType = useMemo(() => searchParams.get('type'), [searchParams]);
  const step = useMemo(() => {
    const stepQuery = searchParams.get('step');
    return stepQuery ? Number.parseInt(stepQuery) : 1;
  }, [searchParams]);
  const title = useMemo(() => defineTitle(onEdit ? 'Edition' : declarationType, step), [declarationType, onEdit, step]);
  const isValidStep = useMemo(
    () => verifyControl(formControl[0], { step, type: declarationType, onEdit }),
    [formControl, step, declarationType, onEdit],
  );

  const steps = useMemo(
    () =>
      defineSteps(
        declarationType,
        {
          prolongation: <Steps.DeclarationFormProlongation formControl={formControl} />,
          type: <Steps.DeclarationFormType formControl={formControl} type={declarationType} />,
          date: <Steps.DeclarationFormDate formControl={formControl} type={declarationType} />,
          document: (
            <Steps.DeclarationFormDocument
              edit={onEdit}
              formControl={formControl}
              type={declarationType}
              onDocumentUpload={() => onStepChange('next')}
            />
          ),
          contact: <Steps.DeclarationFormContact formControl={formControl} />,
          message: <Steps.DeclarationFormMessage formControl={formControl} />,
          validation: (
            <Steps.DeclarationFormValidation formControl={formControl} type={declarationType} edit={onEdit} />
          ),
          end: <Steps.DeclarationFormEnd formControl={formControl} type={declarationType} edit={onEdit} />,
        },
        onEdit,
      ),
    [declarationType, formControl, onEdit],
  );

  const onStepChange = (direction: 'previous' | 'next') => {
    if (direction === 'next' && step === steps.length) {
      clearControl();
      return navigate(`/evenements`, { replace: true });
    }

    defineControl(formControl[0], searchParams);

    // FIXME: Reprise d'un hack chelou car auto-soumission du fichier
    const onContactForm =
      (step === 2 && onEdit) || (step === 5 && declarationType !== 'HO') || (step === 3 && declarationType === 'HO');
    if (onContactForm && direction === 'previous')
      formControl[1]((model) => ({ ...model, document: { data: null, file: null, status: null, no_file: false } }));

    searchParams.set('step', direction === 'previous' ? String(step - 1) : String(step + 1));
    navigate(`/declaration-form?${searchParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    setInitialized(false);
    if (step === 1) {
      clearControl(onEdit ? 'no_date' : 'all');
      formControl[1](initControl(searchParams));
    }
    setInitialized(true);
  }, [step]);

  useEffect(() => {
    setTimeout(() => titleRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 100);
  }, [step]);

  return (
    <>
      <AppLayoutContent requireAuthentication>
        <Spacer spacing={appVariant.medium ? 4 : 0} />
        <EmployeeStepper value={step} length={steps.length} lastStepLabel="Déclaration terminée" />
        <Spacer spacing={appVariant.medium ? 6 : 0} />

        <Typography ref={titleRef} fontWeight={600} fontSize={appVariant.medium ? 32 : 24} lineHeight={'normal'}>
          {title}
        </Typography>

        <Spacer spacing={4} />
        {initialized && <EmployeeStepperContent value={step} steps={steps} />}
      </AppLayoutContent>

      <EmployeeStepperAction
        ActionButtonPreviousProps={{
          disabled: step === 1,
          hide: step === steps.length,
          onClick: () => onStepChange('previous'),
        }}
        ActionButtonNextProps={{
          disabled: !isValidStep,
          label:
            step === steps.length
              ? 'Accéder à mes événements'
              : step === steps.length - 1
                ? 'Je valide ma déclaration'
                : undefined,
          onClick: () => onStepChange('next'),
        }}
      />
    </>
  );
};
