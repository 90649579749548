/**
 * Origine du déclenchement
 */
export const ParcoursStateStatus = {
  Running: 'running',
  Completed: 'completed',
} as const;
export type ParcoursStateStatus = (typeof ParcoursStateStatus)[keyof typeof ParcoursStateStatus];

export const isParcoursStateStatus = (value: unknown): value is ParcoursStateStatus =>
  value != null && Object.values(ParcoursStateStatus).includes(value as any);
