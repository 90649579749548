import React, { type JSXElementConstructor } from 'react';
import { Badge, badgeClasses, type BadgeProps } from '@mui/material';

export interface ActivityBadgeOwnProps {
  badgeProps?: BadgeProps;
  position?: { right: number; top: number };
}

/**
 * Wrap a component with an activity badge
 *
 * @param Component
 */
export function withActivityBadge<C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>(Component: C) {
  return function ActivityBadge(props: React.ComponentProps<C> & ActivityBadgeOwnProps) {
    const { badgeProps, position, ...iconProps } = props;

    return (
      <Badge
        variant="dot"
        sx={(theme) => ({
          [`& .${badgeClasses.badge}`]: {
            right: theme.spacing(position?.right ?? 1),
            top: theme.spacing(position?.top ?? 1),
            backgroundColor: theme.palette.infernoRed[500],
          },
        })}
        {...badgeProps}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Component {...iconProps} />
      </Badge>
    );
  };
}
