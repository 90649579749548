import * as React from 'react';
import classNames from 'clsx';
import { Box, Typography, type TypographyProps, type BoxProps, Stack, Divider } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { RequireLogin } from '../RequireLogin';

const paddingHorizontal = 2.5;

// Name and options
const componentName = 'AppLayoutContent';

// Component properties
export interface AppLayoutContentProps extends BoxProps {
  contentTitle?: React.ReactNode;
  contentTitleProps?: TypographyProps;
  contentTitleDivider?: boolean;
  contentMaxWidth?: 140; // spacing unit
  contentRight?: React.ReactNode;
  requireAuthentication?: boolean;
  /** default 'column */
  variant?: 'column' | 'fullWidth';
}

export function AppLayoutContent(props: AppLayoutContentProps) {
  const {
    requireAuthentication,
    variant,
    className,
    contentTitle,
    contentTitleProps,
    contentTitleDivider,
    contentMaxWidth = 140,
    contentRight,
    children,
    ...otherProps
  } = props;
  const appVariant = useAppVariant();
  const paddingVertical = 4;

  return (
    <Box
      className={classNames(componentName, className)}
      display={'flex'}
      flexDirection={'column'}
      alignSelf={'center'}
      width={'100%'}
      maxWidth={(theme) => theme.spacing(contentMaxWidth)}
      {...otherProps}
      paddingTop={appVariant.medium ? 6 : 0}
      paddingBottom={paddingVertical}
    >
      {contentTitle && (
        <Typography
          component="div"
          className={classNames(`${componentName}__Title`)}
          textAlign={'left'}
          fontWeight={600}
          fontSize={appVariant.medium ? 32 : 24}
          lineHeight={'normal'}
          padding={paddingVertical}
          paddingLeft={paddingHorizontal}
          paddingRight={paddingHorizontal}
          {...contentTitleProps}
        >
          {contentTitle}
        </Typography>
      )}
      {appVariant.small && contentTitle && contentTitleDivider && (
        <Divider sx={{ margin: 2, marginLeft: paddingHorizontal, marginRight: paddingHorizontal }} />
      )}

      {requireAuthentication === true ? (
        <RequireLogin>
          <LayoutContent contentRight={contentRight} variant={variant}>
            {children}
          </LayoutContent>
        </RequireLogin>
      ) : (
        <LayoutContent contentRight={contentRight} variant={variant}>
          {children}
        </LayoutContent>
      )}
    </Box>
  );
}

export function LayoutContent(props: Pick<AppLayoutContentProps, 'children' | 'contentRight' | 'variant'>) {
  const { children, contentRight, variant } = props;

  const appVariant = useAppVariant();
  const showRightPanel = appVariant.medium;

  return (
    <Stack
      className={classNames(`${componentName}__Body`)}
      direction="row"
      spacing={12}
      paddingLeft={paddingHorizontal}
      paddingRight={paddingHorizontal}
    >
      <Box
        className={classNames(`${componentName}__Main`)}
        width={showRightPanel && variant != 'fullWidth' ? 700 / 12 + '%' : '100%'}
      >
        {children}
      </Box>
      {showRightPanel && contentRight != null ? (
        <Box className={classNames(`${componentName}__Right`)} flex={1}>
          {contentRight}
        </Box>
      ) : null}
    </Stack>
  );
}
