import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useDebouncedCallback } from 'use-debounce';
import { KerijBox, KerijBoxType } from '../../../../../components/base';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { ActionButton } from '@/components/ActionButton';

function ForgetPasswordUI() {
  const [login, setLogin] = useState('');
  const [telephone, setTelephone] = useState('');
  const message: any = '';
  const user: any = null;
  const navigate = useNavigate();
  const state = useLocation();

  const [emailError, setEmailError] = useState<any>(null);
  const [phoneError, setPhoneError] = useState<any>(null);

  const validateEmail = useDebouncedCallback((email) => {
    if (validator.isEmail(email)) {
      setEmailError(null);
    } else {
      setEmailError('Veuillez entrer une adresse email valide.');
    }
  }, 1000);

  const validatePhone = useDebouncedCallback((phone) => {
    if (validator.isMobilePhone(phone, 'fr-FR')) {
      setPhoneError(null);
    } else {
      setPhoneError('Veuillez entrer un numéro de téléphone valide.');
    }
  }, 500);

  const handleSubmit = (event: any) => {
    try {
      event.preventDefault();
      if (!login && !telephone) {
        alert('Telephone et mail ne doivent pas être null en même temps');
      }
      if (message === `Cet identifiant n'existe pas`) {
        alert("Cet identifiant n'existe pas");
      } else if ((login || telephone) && message !== "Cet identifiant n'existe pas") {
        navigate('/resetPassword', {
          state: {
            id_salarie_login: user ? user.id : '',
            mail: login,
            telephone: telephone,
            nom: user ? user.employee_information.lastname : '',
            prenom: user ? user.employee_information.firstname : '',
            document: state.state.document,
            idBusiness: user ? user.employee_information.business.id : '',
            idEmployeeInformation: user ? user.employee_information.id : '',
          },
        });
      }
    } catch (err) {
      console.log(err);
      alert('erreur');
    }
  };

  return (
    <div>
      <Spacer size="large" />

      <div className="container-fluid">
        <h1 className="question-form mt-5 mb-5">Modifier le mot de passe</h1>
        {emailError && <KerijBox type={KerijBoxType.Error} message={emailError} />}
        {phoneError && <KerijBox type={KerijBoxType.Error} message={phoneError} />}

        <form onSubmit={handleSubmit} style={{ padding: 0, width: 350, textAlign: 'center' }}>
          <AppTextField
            label={'Identifiant'}
            error={message != null && message.trim() != ''}
            helperText={message}
            InputProps={{ type: 'email' }}
            onChange={(_, value) => {
              setLogin(value);
              validateEmail(value);
            }}
          />

          <Spacer />

          <AppTextField
            label={'Telephone'}
            error={message != null && message.trim() != ''}
            helperText={message}
            InputProps={{ type: 'tel' }}
            onChange={(_, value) => {
              setTelephone(value);
              validatePhone(value);
            }}
          />

          <Spacer />

          <ActionButton
            color="primary"
            type="submit"
            actionName="validate"
            disabled={!login || !telephone || emailError || phoneError}
            startIcon={null}
            fullWidth
          />
        </form>
      </div>
    </div>
  );
}

export default ForgetPasswordUI;
