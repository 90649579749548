import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, Chip, chipClasses } from '@mui/material';
import { Absence } from '@/types';

const componentName = 'EmployeeAbsenceCardType';

export interface EmployeeAbsenceCardTypeProps extends Omit<BoxProps, 'children'> {
  value: Partial<Pick<Absence, 'id_absence'>>;
}

export function EmployeeAbsenceCardType(props: EmployeeAbsenceCardTypeProps) {
  const { value, className, ...boxProps } = props;

  const typeLabel = value.id_absence == null ? 'Arrêt initial' : 'Prolongation';

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      <Chip
        label={typeLabel}
        sx={{
          background: '#fff',
          color: 'inherit',
          [`&.${chipClasses.root}`]: {
            padding: 1,
            fontSize: 12,
            lineHeight: 1,
            height: 'auto',
            [`& .MuiChip-label`]: { padding: 0 },
          },
        }}
      />
    </Box>
  );
}
