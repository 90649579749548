import { displayDate } from '@/utils/date.utils';
import { WarningColumnsTypes, TypeAbsence } from './data';
import { Absence, OCRAPIResponse } from '@/types';

export const ItemTypes = {
  TypeAbsence: 'Type',
  DateStart: 'DateStart',
  DateEnd: 'DateEnd',
  Pregnancy: 'Pregnancy',
  RelatedWork: 'RelatedWork',
} as const;
export type ItemTypes = (typeof ItemTypes)[keyof typeof ItemTypes];

/**
 * Liste des champs
 */
export type WarningItems = {
  [ItemTypes.DateStart]: WarningItem;
  [ItemTypes.DateEnd]: WarningItem;
  [ItemTypes.RelatedWork]?: WarningItem;
  [ItemTypes.TypeAbsence]?: WarningItem;
  [ItemTypes.Pregnancy]?: WarningItem;
};

export interface OCRValueUndefined {
  value: string;
  undefinedValue: true;
}
export interface OCRValueDefined {
  value: string;
  undefinedValue: false;
}
export type OCRValue = OCRValueUndefined | OCRValueDefined;
export const isUndefinedOCR = (value: OCRValue): value is OCRValueUndefined => value.undefinedValue;

export interface WarningItem {
  label: string;
  isValid: boolean;
  isDisabled: boolean;
  /**
   * Valeur confirmée
   */
  confirmed?: string;
  /**
   * Type de champs
   */
  type: WarningColumnsTypes;
  /**
   * Valeur initiales
   */
  data?: {
    declared: string | boolean | null;
    ocr?: string | boolean | null;
  };
  updatedTo?: string;
}

/**
 * Définition des champs du formulaires d'ocr
 * @param ocrResult Contenu de l'OCR
 * @param absence Absence en base de donnée
 * @returns Les champs du formulaire de modification de l'ocr
 */
export function defineFields(ocrResult: OCRAPIResponse, absence: Absence): WarningItems {
  const isArretMaladie = absence.type_absence.description === 1;
  const isProlongation = absence.id_absence != null;
  const dateEnd = absence.date_end ?? absence.date_end_hospi_theory;

  return {
    [ItemTypes.TypeAbsence]: isArretMaladie
      ? {
          label: 'Type d’arrêt',
          type: WarningColumnsTypes.Button,
          isValid: true,
          isDisabled: true,
          data: {
            declared: isProlongation ? TypeAbsence.Prolongation : TypeAbsence.Initial,
            ocr: isProlongation ? TypeAbsence.Prolongation : TypeAbsence.Initial,
          },
        }
      : undefined,
    [ItemTypes.DateStart]: {
      label: 'Date de début',
      type: WarningColumnsTypes.Date,
      isValid: ocrResult.date_start != null && ocrResult.date_start.status !== 'invalid',
      isDisabled: ocrResult.date_start != null && ocrResult.date_start.status === 'skip',
      data: {
        declared: absence.date_start ?? ocrResult.date_start?.value.declare ?? null,
        ocr: ocrResult.date_start?.value.detect ?? null,
      },
      updatedTo:
        ocrResult.date_start?.value.declare == null || absence.date_start == null
          ? undefined
          : ocrResult.date_start.value.declare == absence.date_start
            ? undefined
            : displayDate(ocrResult.date_start.value.declare),
    },
    [ItemTypes.DateEnd]: {
      type: WarningColumnsTypes.Date,
      label: 'Date de fin',
      isValid:
        (ocrResult.date_end != null && ocrResult.date_end.status !== 'invalid') ||
        absence.date_end_hospi_theory != null,
      isDisabled:
        (ocrResult.date_end != null && ocrResult.date_end.status === 'skip') || absence.date_end_hospi_theory != null,
      data: {
        declared: ocrResult.date_end?.value.declare ?? dateEnd ?? null,
        ocr: ocrResult.date_end?.value.detect ?? null,
      },
    },
    [ItemTypes.Pregnancy]:
      ocrResult.pregnancy && ocrResult.pregnancy.status === 'invalid'
        ? {
            label: 'Congé Patho.',
            type: WarningColumnsTypes.Button,
            isValid: false,
            isDisabled: false,
            data: { declared: ocrResult.pregnancy.value.declare, ocr: ocrResult.pregnancy.value.detect },
          }
        : undefined,
    [ItemTypes.RelatedWork]:
      ocrResult.work && ocrResult.work.status === 'invalid'
        ? {
            type: WarningColumnsTypes.Button,
            label: 'AT / MP',
            isValid: false,
            isDisabled: false,
            data: { declared: ocrResult.work.value.declare, ocr: ocrResult.work.value.detect },
          }
        : undefined,
  };
}
