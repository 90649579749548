import { Feature, isEmployeeNotificationType, Notification } from '@/types';
import { displayDate } from '@/utils/date.utils';

export function defineNotificationContent(
  notification: Pick<Notification, 'data' | 'type_notification' | 'absence'>,
  feature: Feature,
): { message: Array<string>; title: string; to: string } {
  const notificationType = notification.type_notification;
  const dateStart = notification?.absence?.date_start ? `${displayDate(notification.absence?.date_start)}` : null;
  const dateEnd = notification?.absence?.date_end ? `${displayDate(notification.absence?.date_end)}` : null;
  const absenceDates = `${dateStart ? (dateEnd ? 'du ' : 'commençant le ') : ''}${dateStart ?? ''}${dateStart && dateEnd ? ' au ' : dateEnd ? "jusqu'au " : ''}${dateEnd ?? ''}`;
  const typeArret = getType(notification.data);

  if (isEmployeeNotificationType(notificationType)) {
    if (notificationType === 'Notification des services') {
      return {
        title: 'Kerij vous accompagne',
        message: [
          `Vous venez de déclarer ${typeArret} ${absenceDates}, ${
            typeArret === 'un congé maternité' ? 'félicitations' : 'nous vous souhaitons un bon rétablissement'
          } !`,

          ...(feature.ServiceManagement
            ? [`\nBonne nouvelle, votre entreprise propose de nombreux services & programmes pour vous aider.`]
            : []),
        ],
        to: feature.ServiceManagement ? '/services' : '/evenements',
      };
    }

    if (notificationType === "Notification de suivi de l'arret") {
      return {
        title: 'Mise à jour de votre absence',
        message: [`Votre retour au travail est prévu aujourd’hui. Vous devez mettre à jour votre situation.`],
        to: feature.ServiceManagement ? '/services' : '/evenements',
      };
    }

    if (notificationType === 'Absence refusée') {
      return {
        title: 'Absence refusée par les Ressources Humaines',
        message: [`La déclaration de votre absence ${absenceDates}, a été refusée par les Ressources Humaines.`],
        to: '/evenements',
      };
    }

    if (notificationType === 'Absence modifiée') {
      const changeType = notification?.absence?.type_absence?.description_1_label;
      return {
        title: 'Absence modifiée par les Ressources Humaines',
        message: [
          `Suite à l'analyse de votre dossier, cette absence est requalifiée${changeType ? 'en ' + changeType : ''}.`,
        ],
        to: '/evenements',
      };
    }

    if (notificationType === 'Absence validée') {
      return {
        title: 'Absence validée',
        message: [`Votre absence a été validée par les Ressources Humaines.`],
        to: '/evenements',
      };
    }

    if (notificationType === 'Document accepté') {
      return {
        title: 'Votre document a été accepté',
        message: [
          `Suite à la déclaration de votre arrêt ${absenceDates}, le document que vous avez déposé a été accepté par les Ressources Humaines.`,
        ],
        to: '/evenements',
      };
    }

    if (notificationType === 'Document refusé') {
      return {
        title: 'Votre document a été refusé',
        message: [
          `Suite à la déclaration de votre arrêt ${absenceDates}, le document que vous avez déposé a été refusé par les Ressources Humaines.`,
          `Vous devez donc mettre un nouveau document pour valider votre arrêt.`,
        ],
        to: '/evenements',
      };
    }

    if (notificationType === 'Notification de document manquant') {
      return {
        title: 'Un justificatif est manquant',
        message: [
          `Vous avez déclaré ${typeArret} ${absenceDates}.`,
          `Pour valider votre arrêt, vous devez déposer votre justificatif.`,
        ],
        to: '/evenements',
      };
    }
  }

  return {
    title: notificationType,
    message: [`Vous avez reçu une notification concernant une absence.`],
    to: '/evenements',
  };
}

function getType(notification: Notification['data']) {
  if (notification?.typeAbsence === 'hospitalisation' || notification?.typeAbsence === 'une hospitalisation')
    return 'une hospitalisation';

  if (notification?.typeAbsence === 'congé maternité' || notification?.typeAbsence === 'un congé maternité')
    return 'un congé maternité';

  if (notification?.typeAbsence === 'congé parentalité' || notification?.typeAbsence === 'un congé parentalité')
    return 'un congé parentalité';

  return 'un arrêt de travail';
}
