import { z } from 'zod';
import { ParcoursStateActionResult } from './ParcoursStateActionResult';

export const ParcoursStateAction = z.discriminatedUnion('result', [
  z.object({
    result: z.literal(ParcoursStateActionResult.Success),
  }),
  z.object({
    result: z.literal(ParcoursStateActionResult.Pending),
  }),
  z.object({
    result: z.literal(ParcoursStateActionResult.Error),
    error: z.string(),
  }),
  z.object({
    result: z.literal(ParcoursStateActionResult.Failure),
    error: z.string(),
  }),
]);

export type ParcoursStateAction = z.infer<typeof ParcoursStateAction>;
