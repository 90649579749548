import { AllowedStringVariable } from './translation';

export const VariableCategorie = {
  Parcours: 'parcours',
  Employee: 'employee',
  Contact: 'contact',
  Absence: 'absence',
  Divers: 'divers',
} as const;
export type VariableCategorie = (typeof VariableCategorie)[keyof typeof VariableCategorie];

const VariableCategorieList = {
  [VariableCategorie.Parcours]: [AllowedStringVariable.ParcoursName],

  [VariableCategorie.Employee]: [
    AllowedStringVariable.EmployeeLastname,
    AllowedStringVariable.EmployeeFirstname,
    AllowedStringVariable.EmployeeDescription,
    AllowedStringVariable.OrganisationCode,
    AllowedStringVariable.OrganisationDescription,
  ],

  [VariableCategorie.Contact]: [
    AllowedStringVariable.Manager1Firstname,
    AllowedStringVariable.Manager1Lastname,
    AllowedStringVariable.Manager2Firstname,
    AllowedStringVariable.Manager2Lastname,
    AllowedStringVariable.ManagerRH1Firstname,
    AllowedStringVariable.ManagerRH1Lastname,
    AllowedStringVariable.ManagerRH2Firstname,
    AllowedStringVariable.ManagerRH2Lastname,
  ],

  [VariableCategorie.Absence]: [
    AllowedStringVariable.TriggerDataAbsenceLastDateEnd,
    AllowedStringVariable.TriggerDataTotalDuration,
    AllowedStringVariable.TriggerDataAbsenceLastInternalCode,
    AllowedStringVariable.TriggerDataAbsenceCount,
  ],

  [VariableCategorie.Divers]: [AllowedStringVariable.BusinessDisclaimer],
} satisfies Record<VariableCategorie, Array<AllowedStringVariable>>;
export const getVariablesByCategorie = (category: VariableCategorie): AllowedStringVariable[] => {
  return VariableCategorieList[category];
};

const VariableCategorieLabel: Record<VariableCategorie, string> = {
  [VariableCategorie.Parcours]: 'Parcours',
  [VariableCategorie.Employee]: 'Collaborateur',
  [VariableCategorie.Contact]: 'Eco-système',
  [VariableCategorie.Absence]: 'Absences',
  [VariableCategorie.Divers]: 'Divers',
} as const;
export const getVariablesCategorieLabel = (category: VariableCategorie): string => {
  return VariableCategorieLabel[category];
};
