import React, { memo } from 'react';
import { Document, Page } from 'react-pdf';
import './PDFPreview.scss';
import { Box } from '@mui/material';

export interface PDFPreviewProps {
  value: { name: string; url: string };
}

export const PDFPreviewComponent = (props: PDFPreviewProps) => {
  const { value } = props;

  const onDocumentLoadSuccess = () => {
    console.info('load pdf', value.url);
  };

  return (
    <Document file={value.url} onLoadSuccess={onDocumentLoadSuccess} loading={<Box></Box>}>
      <Page
        loading={<Box></Box>}
        className={'PDFPreview__Page'}
        pageNumber={1}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </Document>
  );
};

export const PDFPreview = memo(
  PDFPreviewComponent,
  (prevProps, nextProps) => prevProps.value.url != nextProps.value.url,
);
