import React from 'react';
import { AppRadioGroup } from '@/components/AppRadioGroup';
import { DeclarationFormControl } from './type';
import { Box, Divider, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { useAppVariant } from '@/hooks/useAppVariant';

export interface DeclarationFormMessageProps {
  formControl: DeclarationFormControl;
}

export function DeclarationFormMessage(props: DeclarationFormMessageProps) {
  const { formControl } = props;

  const appVariant = useAppVariant();

  return (
    <Box>
      <AppRadioGroup
        items={[
          { value: 'true', label: 'Oui' },
          { value: 'false', label: 'Non' },
        ]}
        value={formControl[0].message.enable == null ? null : String(formControl[0].message.enable)}
        onChange={(_, value) => {
          formControl[1]((model) => ({
            ...model,
            message: {
              ...model.message,
              enable: value === 'true' ? true : false,
            },
          }));
        }}
      />

      {formControl[0].message.enable && (
        <Box>
          <Spacer spacing={2} />
          <Divider />
          <Spacer spacing={4} />

          <Typography fontSize={appVariant.medium ? 16 : 14} lineHeight={1.5}>
            Conformément à la réglementation, veillez à ne pas faire de commentaire excessif ou insultant envers vos
            interlocuteurs.
          </Typography>

          <Spacer spacing={2} />

          <AppTextField
            dense
            value={formControl[0].message.content ?? undefined}
            placeholder="Rédigez votre message"
            onChange={(_, value) =>
              formControl[1]((model) => ({
                ...model,
                message: { ...model.message, content: value },
              }))
            }
            InputProps={{
              multiline: true,
              rows: 10,
              maxRows: 20,
              sx: { padding: 2, marginTop: '0 !important' },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
