import React, { ForwardedRef } from 'react';
import { styled, Tab as MUITab, Tabs as MUITabs, TabsProps, tabClasses } from '@mui/material';
import classNames from 'clsx';

const componentName = 'PageTab';
export type PageTabsValue = string | null;

export interface PageTabProps extends Omit<TabsProps, 'value' | 'onChange' | 'children'> {
  value: PageTabsValue;
  items: Array<{ value: PageTabsValue; label?: React.ReactNode }>;
  onChange?: (_: React.SyntheticEvent, value: NonNullable<PageTabsValue>) => void;
}

export const PageTab = React.forwardRef(function PageTab(props: PageTabProps, ref: ForwardedRef<HTMLDivElement>) {
  const { className, items, ...tabsProps } = props;

  return (
    <Tabs
      ref={ref}
      className={classNames(componentName, className)}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
      {...tabsProps}
    >
      {items.map((item) => (
        <Tab disableRipple key={item.value} {...item} />
      ))}
    </Tabs>
  );
});

const Tabs = styled(MUITabs)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: `100%`,
    backgroundColor: theme.md3.sys.color.onSurface,
    height: 1,
  },
}));

const Tab = styled(MUITab)(({ theme }) => ({
  marginRight: theme.spacing(3),

  [`&.${tabClasses.textColorPrimary}`]: {
    maxHeight: theme.spacing(4),
    minHeight: theme.spacing(2.5),
    minWidth: 0,
    padding: 0,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    ...theme.md3.sys.typescale.title.medium,
    color: theme.md3.sys.color.onSurfaceVariant,
  },

  [`&.${tabClasses.selected}`]: {
    color: theme.md3.sys.color.onSurface,
  },
}));
