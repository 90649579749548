import React from 'react';
import { Link } from 'react-router-dom';
import { Box, type BoxProps, styled, Typography } from '@mui/material';

export interface FooterLinkItem {
  label: string;
  url: string;
}

const defaultItems: Array<FooterLinkItem> = [
  {
    label: 'Problème de connexion ? Contactez-nous',
    url: '/report',
  },
];

export interface FooterLinksProps extends BoxProps {
  items?: Array<FooterLinkItem>;
}

export const FooterLinks = (props: FooterLinksProps) => {
  const { items = defaultItems, ...rootProps } = props;

  return (
    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} {...rootProps}>
      {items.map((link, index) => (
        <>
          <Link key={index} to={link.url}>
            <ButtonLink pl={2}>{link.label}</ButtonLink>
          </Link>

          {index < items.length - 1 ? (
            <Typography key={`SpaceFooter__${index}`} fontSize={12} lineHeight={1.3} sx={{ textDecoration: 'none' }}>
              &nbsp;·&nbsp;
            </Typography>
          ) : null}
        </>
      ))}
    </Box>
  );
};

const ButtonLink = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.3,
  padding: 0,
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.md3.sys.color.onSurface,
  ':hover': {
    color: theme.md3.sys.color.onSurfaceVariant,
  },
}));
