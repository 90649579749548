import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Signup.css';
import { Spacer } from '../Spacer';
import { AppTextField } from '../AppTextField';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ActionButton } from '../ActionButton';

function CreatePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  var [error, setError] = useState('');
  const state = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.state) {
      navigate('/signup');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Attention, les mots de passe ne correspondent pas.');
    } else {
      navigate('/createcontact', {
        state: { ...state.state, password: password },
      });
    }
  };

  return (
    <div>
      <div className="container-fluid" align="center">
        <Spacer size="large" />

        <form onSubmit={handleSubmit} style={{ padding: 0, width: 350, textAlign: 'center' }}>
          <h3 className="question-form mb-5">Création d'un mot de passe</h3>

          <AppTextField
            label={'Mot de passe'}
            type={'password'}
            value={password}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: (theme) => ({ paddingRight: theme.spacing(1) }),
            }}
            onChange={(_, value) => setPassword(value)}
            error={error != null && error.trim() != ''}
            helperText={error}
          />

          <Spacer size="small" />

          <div className="msg-pass">
            <em>
              Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins une
              lettre minuscule, et une valeur numérique.
            </em>
          </div>

          <AppTextField
            label={'Confirmation du mot de passe'}
            type={'password'}
            value={confirmPassword}
            InputProps={{
              type: showConfirmPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: (theme) => ({ paddingRight: theme.spacing(1) }),
            }}
            onChange={(_, value) => setConfirmPassword(value)}
            error={error != null && error.trim() != ''}
            helperText={error}
          />

          <Spacer />
          <ActionButton color="primary" type="submit" actionName="validate" startIcon={null} fullWidth />
        </form>
      </div>
    </div>
  );
}

export default CreatePassword;
