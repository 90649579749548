import { CardAbsenceHistoryItem } from './CardAbsenceHistoryItem';
import React from 'react';
import { Absence } from '../../../../../../types/absences.type';
import { EmployeeInformation } from '../../../../../../types/user.interface';
import { EmployeeCardHeader } from '@/components/DashboardRH/EmployeeCard';
import { ListCardActions, ListCardContent, ListCardItem } from '@/components/DashboardRH/ListCard';

interface CardHistoryInterface {
  absences: Absence[];
  employeeInformation: EmployeeInformation;
}

export const CardAbsenceHistory = ({ absences, employeeInformation }: CardHistoryInterface) => {
  return (
    <ListCardItem>
      <EmployeeCardHeader employeeInformation={{ ...employeeInformation }} withCount />

      <ListCardContent>
        {absences.length > 0 ? (
          <div>
            {absences?.slice(0, 3)?.map((value, ind) => {
              return <CardAbsenceHistoryItem absence={value} key={ind} />;
            })}
          </div>
        ) : (
          <></>
        )}
      </ListCardContent>

      {absences.length > 3 && (
        <ListCardActions collapse collapseTitle="Historique des absences">
          {absences.slice(3).map((absence: Absence, ind) => {
            return <CardAbsenceHistoryItem absence={absence} key={ind} />;
          })}
        </ListCardActions>
      )}
    </ListCardItem>
  );
};
