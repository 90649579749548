/**
 * FIXME
 * # Cette page condense la logique rassemblée lors à partir des 7 pages de la déclaration
 * Impossible de ne pas passer par ce fichier pour intégrer les 7 pages dans un seul fichier en étant certain
 * de ne rien casser
 */

import { DocumentStatus, isAbsenceContactType } from '@/types';
import { DeclarationFormSteps } from './steps/type';

export const initControl = (searchParams: URLSearchParams): DeclarationFormSteps => {
  const durationHospi = localStorage.getItem('declaration_duration');
  const showDuration = localStorage.getItem('declaration_show_duration');
  const contactType = searchParams.get('manager');
  const contact = searchParams.get('contact');
  const mail = searchParams.get('mail');
  const phone = searchParams.get('phone');
  const message = searchParams.get('message');

  return {
    prolongation: searchParams.get('prolongation'),
    type: searchParams.get('maladie-type'),
    date: {
      date_start: localStorage.getItem('declaration_date_start'),
      date_end: localStorage.getItem('declaration_date_end'),
      last_day_work: localStorage.getItem('declaration_last_day_work'),
      duration: durationHospi ? Number.parseInt(durationHospi) : null,
      show_duration: showDuration ? showDuration === 'true' : null,
      isVerified: null,
    },
    document: { data: null, file: null, status: null, no_file: false },
    contact: {
      type: isAbsenceContactType(contactType) ? contactType : null,
      permission: {
        canContact: contact ? contact === 'true' : null,
        canContactByMail: mail ? mail === 'true' : null,
        canContactByPhone: phone ? phone === 'true' : null,
      },
      email: localStorage.getItem('declaration_email'),
      phone: localStorage.getItem('declaration_phone'),
    },
    message: {
      enable: message ? message === 'true' : null,
      content: localStorage.getItem('declaration_message'),
    },
    validation: { code: null, cpam: null },
    end: undefined,
  };
};

export const defineControl = (value: DeclarationFormSteps, searchParams: URLSearchParams): URLSearchParams => {
  // Type
  if (value.prolongation) searchParams.set('prolongation', value.prolongation);
  if (value.type) searchParams.set('maladie-type', value.type);
  // Date maladie
  if (value.date.date_start) localStorage.setItem('declaration_date_start', value.date.date_start);
  else localStorage.removeItem('declaration_date_start');
  if (value.date.date_end) localStorage.setItem('declaration_date_end', value.date.date_end);
  else localStorage.removeItem('declaration_date_end');
  if (value.date.last_day_work) localStorage.setItem('declaration_last_day_work', value.date.last_day_work);
  else localStorage.removeItem('declaration_last_day_work');
  // Date hospitalisation
  if (value.date.duration) localStorage.setItem('declaration_duration', String(value.date.duration));
  else localStorage.removeItem('declaration_duration');
  if (value.date.show_duration) localStorage.setItem('declaration_show_duration', 'true');
  else localStorage.removeItem('declaration_show_duration');
  // Contact
  if (value.contact.type) searchParams.set('manager', value.contact.type);
  if (value.contact.permission?.canContact) searchParams.set('contact', 'true');
  if (value.contact.permission?.canContactByPhone) searchParams.set('phone', 'true');
  if (value.contact.permission?.canContactByMail) searchParams.set('email', 'true');
  if (value.contact.email) localStorage.setItem('declaration_email', value.contact.email);
  else localStorage.removeItem('declaration_email');
  if (value.contact.phone) localStorage.setItem('declaration_phone', value.contact.phone);
  else localStorage.removeItem('declaration_phone');
  // Message
  if (value.message.enable) searchParams.set('message', 'true');
  if (value.message.content) localStorage.setItem('declaration_message', value.message.content);
  else localStorage.removeItem('declaration_message');

  return searchParams;
};

export const verifyControl = (
  value: DeclarationFormSteps,
  options: { type: string | null; step: number; onEdit?: boolean },
) => {
  const { type, step, onEdit } = options;

  const isValidType = value.prolongation != null;
  const isValidHospiDate = value.date.date_start && value.date.isVerified;
  const isValidDate = value.date.date_start && value.date.date_end && value.date.isVerified;
  const isValidNature = value.type;
  const isValidFile =
    value.document.no_file ||
    value.document.status === DocumentStatus.APPROVED ||
    value.document.status === DocumentStatus.PENDING;
  const isValidContact =
    value.contact.permission?.canContact === false ||
    (value.contact.permission?.canContact === true &&
      value.contact.type &&
      (value.contact.permission?.canContactByMail || value.contact.permission?.canContactByPhone));
  const isValidMessage =
    value.message.enable === false ||
    (value.message.enable === true && value.message.content && value.message.content.length > 0);
  const isValidAbsence = true;

  switch (step) {
    case 1: {
      if (onEdit && isValidFile) return true;
      else if (onEdit) return false;
      if (type !== 'HO' && isValidType) return true;
      if (type === 'HO' && isValidHospiDate) return true;
      break;
    }
    case 2: {
      if (onEdit && isValidContact) return true;
      else if (onEdit) return false;
      if (type !== 'HO' && isValidNature) return true;
      if (type === 'HO' && isValidFile) return true;
      break;
    }
    case 3: {
      if (onEdit && isValidMessage) return true;
      else if (onEdit) return false;
      if (type !== 'HO' && isValidDate) return true;
      if (type === 'HO' && isValidContact) return true;
      break;
    }
    case 4: {
      if (onEdit && isValidAbsence) return true;
      else if (onEdit) return false;
      if (type !== 'HO' && isValidFile) return true;
      if (type === 'HO' && isValidMessage) return true;
      break;
    }
    case 5: {
      if (onEdit && isValidAbsence) return true;
      else if (onEdit) return false;
      if (type !== 'HO' && isValidContact) return true;
      if (type === 'HO' && isValidAbsence) return true;
      break;
    }
    case 6: {
      if (type !== 'HO' && isValidMessage) return true;
      if (type === 'HO' && isValidAbsence) return true;
      break;
    }
    case 7: {
      if (type !== 'HO' && isValidAbsence) return true;
      break;
    }
    case 8: {
      if (type !== 'HO' && isValidAbsence) return true;
      break;
    }
  }

  return false;
};

export const clearControl = (type: 'all' | 'no_date' = 'all') => {
  if (type !== 'no_date') {
    localStorage.removeItem('declaration_duration');
    localStorage.removeItem('declaration_show_duration');
    localStorage.removeItem('declaration_date_start');
    localStorage.removeItem('declaration_date_end');
    localStorage.removeItem('declaration_last_day_work');
  }

  localStorage.removeItem('declaration_email');
  localStorage.removeItem('declaration_phone');
  localStorage.removeItem('declaration_message');
};
