import React from 'react';
import { Box, Paper, PaperProps, Typography } from '@mui/material';
import { Error, Info } from '@mui/icons-material';

export interface InfoBoxProps extends Omit<PaperProps, 'sx'> {
  type: 'error' | 'info';
}

export function InfoBox(props: InfoBoxProps) {
  const { type, children, ...paperProps } = props;

  return (
    <Paper
      sx={(theme) => ({
        padding: 1,
        pl: 1.5,
        pr: 1.5,
        border: '1px solid',
        borderColor: theme.shape.borderColorVariant,
      })}
      {...paperProps}
    >
      <Box display={'flex'} gap={1}>
        {type === 'error' && <Error sx={{ fontSize: 24, mt: 'auto', mb: 'auto' }} color="error" />}
        {type === 'info' && <Info sx={{ fontSize: 24, mt: 'auto', mb: 'auto' }} color="secondary" />}
        <Typography
          fontSize={14}
          lineHeight={1.4}
          mt={'auto'}
          mb={'auto'}
          color={type === 'error' ? 'error' : undefined}
        >
          {children}
        </Typography>
      </Box>
    </Paper>
  );
}
