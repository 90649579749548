import { DocumentUploader } from '@/components/DashboardRH/DocumentUploader/DocumentUploader';
import CustomModal from '@/components/base/CustomModal';
import { employeeUpdateDateReturn } from '@/services/employee';
import { deleteDocument, finishAbsence } from '@/services/rh/absences/service';
import { Absence, DocumentAbsence, DocumentStatus } from '@/types/absences.type';
import { StatusCodes } from 'http-status-codes';
import React, { useMemo } from 'react';
import { CloseAbsenceModalContent } from './CloseAbsenceModalContent';
import { CloseAbsenceModalFooter } from './CloseAbsenceModalFooter';
import { CloseAbsenceOptions, verifyDateReturn } from './data';

import { EmployeeInformation } from '@/types';
import './CloseAbsenceModal.css';
import { useToast } from '@/components/Toast';

interface CloseAbsenceModalProperties {
  absence: Absence;
  visible: boolean;
  employee_information: EmployeeInformation;
  onClose: () => void;
  onChange: (description?: string) => void;
}

export const CloseAbsenceModal = ({
  absence,
  visible,
  employee_information,
  onClose,
  onChange,
}: CloseAbsenceModalProperties) => {
  const toast = useToast();
  const [absenceOption, setAbsenceOption] = React.useState<string | null>(null);
  const [selectingDocument, setSelectingDocument] = React.useState<boolean>(false);
  const [selectedDateReturn, setSelectedDateReturn] = React.useState<string>();
  const [selectedDocuments, setSelectedDocuments] = React.useState<DocumentAbsence[]>([]);

  const documentAdded = useMemo(() => selectedDocuments.length > 0, [selectedDocuments]);

  function emitChanges() {
    onChange();
    onClose();
    resetForm();
  }

  function resetForm() {
    setSelectedDocuments([]);
    setAbsenceOption(null);
    setSelectingDocument(false);
  }

  async function normalReturn(notification: string | null, options?: { date?: string; updateEnd?: boolean }) {
    const dateReturn = options?.date || absence.date_end || absence.date_end_hospi_theory;
    const typeNotification = notification ?? undefined;

    const log =
      selectedDocuments.length > 0 ? 'Reprise anticipée avec certificat' : 'Reprise anticipée sans certificat';

    const defaultLog = 'Le collaborateur a repris son activité à la date prévue';

    const value = await employeeUpdateDateReturn({
      idAbsence: absence.id,
      dateReturn,
      typeNotification,
      idEmployeeInformation: employee_information?.id,
      updateEnd: options?.updateEnd,
      log: absenceOption === CloseAbsenceOptions.ANTICIPE.value ? log : defaultLog,
    });
    if (value.status === StatusCodes.OK) {
      toast.present({ message: 'Absence clôturée', severity: 'success' });
      emitChanges();
    }
  }

  async function extensionReturn() {
    const value = await finishAbsence(absence.id);
    if (value.status === StatusCodes.OK) {
      toast.present({ message: 'Absence clôturée', severity: 'success' });
      emitChanges();
    }
  }

  function onItemAction(itemValue: string) {
    if (itemValue === CloseAbsenceOptions.ANTICIPE.value) setSelectingDocument(true);
  }

  async function removeSelectedDocuments() {
    let requests: Promise<void>[] = [];
    selectedDocuments.forEach((doc) => {
      requests = [...requests, deleteDocument(doc.id)];
    });
    await Promise.all(requests);
    setSelectedDocuments([]);
  }

  function onCancel() {
    removeSelectedDocuments();
    if (selectingDocument) return setSelectingDocument(false);
    onClose();
  }

  function onConfirm() {
    if (selectingDocument) return setSelectingDocument(false);

    switch (absenceOption) {
      case CloseAbsenceOptions.NORMAL.value: {
        normalReturn("Notification de suivi de l'arrêt");
        break;
      }
      case CloseAbsenceOptions.ANTICIPE.value: {
        const isValidDate = verifyDateReturn(absence, selectedDateReturn, new Date());
        if (isValidDate === true)
          normalReturn(null, {
            date: selectedDateReturn,
            updateEnd: true,
          });
        else toast.present({ message: isValidDate.error, severity: 'error' });
        break;
      }
      case CloseAbsenceOptions.PROLONGE.value: {
        extensionReturn();
        break;
      }
    }
  }
  return (
    <>
      <CustomModal
        open={visible || false}
        onClose={onCancel}
        renderHeader={() => `Clôturer l'absence`}
        renderContent={() => (
          <div>
            {!selectingDocument && (
              <CloseAbsenceModalContent
                value={absenceOption ?? undefined}
                dateValue={selectedDateReturn}
                documentSelected={documentAdded}
                onChange={(option) => {
                  setSelectedDateReturn('');
                  setSelectedDocuments([]);
                  setAbsenceOption(option);
                }}
                onChangeDate={setSelectedDateReturn}
                onItemAction={onItemAction}
              />
            )}

            {selectingDocument && employee_information && (
              <DocumentUploader
                absence={{
                  ...absence,
                  documents: selectedDocuments,
                }}
                infosEmployee={employee_information}
                onDocumentsChanged={setSelectedDocuments}
                status={DocumentStatus.ANNEXED}
              />
            )}
          </div>
        )}
        renderFooter={() => (
          <CloseAbsenceModalFooter
            cancelLabel={documentAdded && selectingDocument ? 'Supprimer' : 'Annuler'}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      />
    </>
  );
};
