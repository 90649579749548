import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './AccueilRH.css';
import { useNavigate } from 'react-router-dom';
import imgAbsences from '@/assets/img-absences-dashboard.svg';
import { PRO_URL } from '@/constants/urls.constants';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { getFullName } from '@/shared/utils/getFullName.service';
import { AbsencesFilters, fetchRhAbsences, fetchRhAbsencesCount } from '@/services/rh';
import { IfPermission } from '@/components/IfPermission';
import { Absence, AbsenceStatus } from '@/types';
import { ListCardActions } from '../ListCard';
import { ActionButton } from '@/components/ActionButton';
import { Badge } from '@/components/Badge';
import { PageSectionList } from '@/components/Page';

const ProgressAbsence = () => {
  const [data, setData] = React.useState<Absence[]>([]);
  const navigate = useNavigate();
  const [length, setLength] = useState(0);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const count = await fetchRhAbsencesCount();
    setLength(count.data);
    const filters: AbsencesFilters = {
      status: [AbsenceStatus.DRAFT, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
      detail: true,
      page: 1,
      per_page: 4,
    };
    const absences = await fetchRhAbsences(filters);
    setData(absences.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const noAbsences = () => {
    return (
      <Box minHeight={231} display={'flex'}>
        <Typography m={'auto'} variant="h6">
          {`Il n'y a aucune absence en cours`}
        </Typography>
      </Box>
    );
  };

  const renderLoading = () => {
    return (
      <Box minHeight={231} display={'flex'}>
        <Box m={'auto'}>
          {' '}
          <CircularProgress />
        </Box>
      </Box>
    );
  };

  const previewAbsences = () => (
    <PageSectionList PaperProps={{ elevation: 0, style: { borderRadius: 0 } }}>
      {data.slice(0, 4).map((value) => (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PROGRESS_ABSENCE)} disableRipple>
              <Grid container spacing={2.5} minHeight={69}>
                <Grid item xs={4} display={'flex'}>
                  <Typography fontSize={14} fontWeight={600} mt={'auto'} mb={'auto'}>
                    {value.employee_information ? getFullName(value.employee_information) : ''}
                  </Typography>
                </Grid>

                <Grid item xs={4} display={'flex'}>
                  <Typography fontSize={14} fontWeight={600} m={'auto'}>
                    {value.type_absence.description_label}
                  </Typography>
                </Grid>

                <Grid item xs={4} display={'flex'}>
                  <Typography fontSize={12} fontWeight={400} mt={'auto'} mb={'auto'} ml={'auto'}>
                    Depuis le {moment(value.date_start).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
        </>
      ))}
    </PageSectionList>
  );

  const buttonAllAbsences = () => {
    return (
      <IfPermission scope="absence-management" action="edit">
        <ActionButton
          color="secondary"
          actionName="next"
          label={'Tous les dossiers ouverts'}
          onClick={() => {
            navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PROGRESS_ABSENCE);
          }}
        />
      </IfPermission>
    );
  };
  return (
    <Card elevation={3} sx={(theme) => ({ border: '1px solid', borderColor: theme.shape.borderColorVariant })}>
      <CardHeader
        avatar={<img src={imgAbsences} />}
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          paddingTop: theme.spacing(1.75),
          paddingBottom: theme.spacing(1.75),
        })}
        title={
          <Box display={'flex'}>
            <Typography flexGrow={1} fontSize={14} fontWeight={600}>
              Dossiers ouverts
            </Typography>
            {length > 0 && <Badge label={length} />}
          </Box>
        }
      />

      <CardContent sx={{ p: 0 }}>
        {loading && <>{renderLoading()}</>}
        {!loading && <>{data.length > 0 ? previewAbsences() : noAbsences()}</>}
      </CardContent>

      <ListCardActions>{buttonAllAbsences()}</ListCardActions>
    </Card>
  );
};

export default ProgressAbsence;
