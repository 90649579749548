import React from 'react';
import { AppTextField } from '../AppTextField';

interface QuestionnaireSuiviDateProperties {
  value: string | null;
  min?: number | string;
  max?: number | string;
  onChange: (value: string) => void;
}

export function QuestionnaireSuiviDate({ value, min, max, onChange }: QuestionnaireSuiviDateProperties) {
  return (
    <AppTextField
      dense
      value={value ?? undefined}
      InputProps={{
        inputProps: {
          type: 'date',
          componentsProps: {
            input: { min, max },
          },
        },
      }}
      onChange={(_, value) => onChange?.(value)}
      onBlur={(event) => onChange?.(event.target.value)}
      noLabel
    />
  );
}
