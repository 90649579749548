/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useState } from 'react';
import '../DashboardRH.css';
import '../Administration/Admin.css';
import { runJob } from '@/services/rh/absences/service';
import { ActionButton } from '@/components/ActionButton';
import { PermissionBox } from '@/components/PermissionBox';
import { isProduction } from '@/config';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { absencesExtract } from '@/services/rh/absences/absencesExtract';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useToast } from '@/components/Toast';
import { RHPage } from '@/components/RHPage';
import { AbsenceStatus } from '@/types';

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: 'Extractions absences',
  fieldSeparator: ';',
});

export function ExtractionAbsences() {
  const toast = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState<'all-employees' | 'portfolio'>('all-employees');
  const [filterDuration, setFilterDuration] = useState<'all-duration' | 'custom-duration'>('all-duration');
  const [customDuration, setCustomDuration] = useState<string>();

  const disableSubmit = useMemo(() => {
    const isEmptyCustom = customDuration == null || customDuration.trim() === '';
    return (filterDuration === 'custom-duration' && isEmptyCustom) || submitting;
  }, [customDuration, filterDuration, submitting]);

  const handleExportAbsencesClick = async () => {
    const exportResponse = await runJob('export-absences');
    const { filename, base64, type } = exportResponse.data;

    const byteCharacters = atob(base64);
    const byteNumbers = Array.from({ length: byteCharacters.length });
    for (let i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.codePointAt(i);
    const byteArray = new Uint8Array(
      // @ts-ignore TODO: à typer !
      byteNumbers,
    );
    const blobValue = new Blob([byteArray], { type: `${type};charset=utf-8` });
    const urlValue = URL.createObjectURL(blobValue);

    const link = document.createElement('a');
    link.href = urlValue;
    link.download = filename;
    link.style.display = 'none';
    document.body.append(link);

    link.click();
    link.remove();
  };

  const handleExportJustificatifsClick = async () => {
    await runJob('export-justificatifs');
  };

  async function extractAbsences() {
    setSubmitting(true);
    try {
      const { data } = await absencesExtract({
        type: 'not_close',
        status: [AbsenceStatus.DRAFT, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
        portfolio: filterEmployee === 'portfolio' ? 'true' : undefined,
        duration: filterDuration === 'custom-duration' ? customDuration : undefined,
      });
      if (data.length === 0) {
        setSubmitting(false);
        return toast.present({ message: 'Aucune ligne à exporter', severity: 'error' });
      }

      download(csvConfig)(generateCsv(csvConfig)(data));

      const uniqueMatricules = new Set(data.map((item) => item.Matricule));
      toast.present({
        message: (
          <>
            <Typography fontWeight="bold">Export effectué</Typography>
            <Spacer />
            <Typography fontWeight={300}>Nombre d'absences:{' ' + data.length}</Typography>
            <Typography fontWeight={300}>Nombre de salariés:{' ' + uniqueMatricules.size}</Typography>
          </>
        ),
        severity: 'success',
      });
    } catch {
      toast.present({ message: "Une erreur est survenue durant l'export", severity: 'error' });
    }
    setSubmitting(false);
  }

  return (
    <div className="row">
      <PermissionBox scope="absence-management" action="access">
        <RHPage
          variant="form"
          title="Extraction des absences"
          actions={
            <Box>
              {isProduction() ? undefined : (
                <ActionButton label={`Réaliser l'export ADP`} onClick={handleExportAbsencesClick} sx={{ mr: 0.5 }} />
              )}
              {isProduction() ? undefined : (
                <ActionButton label={`Réaliser l'export des justificatifs`} onClick={handleExportJustificatifsClick} />
              )}
            </Box>
          }
        >
          <Box>
            <FormControl>
              <FormLabel>Filtrer les absences</FormLabel>
              <RadioGroup
                value={filterEmployee}
                onChange={(_, value) =>
                  value === 'all-employees' || value === 'portfolio'
                    ? setFilterEmployee(value)
                    : setFilterEmployee('all-employees')
                }
              >
                <FormControlLabel value="all-employees" control={<Radio />} label="Toutes les absences en cours" />
                <FormControlLabel value="portfolio" control={<Radio />} label="Mon portefeuille uniquement" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Spacer />
          <Box>
            <FormControl>
              <FormLabel>Filtrer la durée</FormLabel>
              <RadioGroup
                value={filterDuration}
                onChange={(_, value) => {
                  const newValue = value === 'custom-duration' ? value : 'all-duration';
                  setFilterDuration(newValue);
                }}
              >
                <FormControlLabel value="all-duration" control={<Radio />} label="Quelle que soit la durée" />
                <FormControlLabel
                  value="custom-duration"
                  control={<Radio />}
                  label={
                    <div style={{ display: 'flex' }}>
                      <Typography whiteSpace="nowrap">Les absences supérieures ou égales à</Typography>

                      <AppTextField
                        label={''}
                        value={customDuration}
                        onChange={(_, value) => setCustomDuration(value)}
                        disabled={filterDuration !== 'custom-duration'}
                        InputProps={{
                          type: 'number',
                          componentsProps: {
                            input: {
                              max: 999,
                              maxLength: 3,
                              style: {
                                textAlign: 'right',
                              },
                            },
                          },
                          sx: {
                            width: '8rem',
                            position: 'absolute',
                            left: '0.5rem',
                            bottom: '-0.5rem',
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography fontSize={12}>jour(s)</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box textAlign="right">
            <Spacer mt={2} />
            <Divider />
            <Spacer size="small" />
            <ActionButton
              variant="contained"
              actionName={'finish'}
              disabled={disableSubmit}
              onClick={extractAbsences}
              label={"Générer l'export"}
            />
          </Box>
        </RHPage>
      </PermissionBox>
    </div>
  );
}
