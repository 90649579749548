import * as React from 'react';
import { Box, Collapse, ListItemButton, ListItemButtonProps, ListItemText, Typography } from '@mui/material';
import { Spacer } from '../Spacer';

export interface AppCollapsibleFieldButtonProps extends Omit<ListItemButtonProps, 'action'> {
  label: React.ReactNode;
  value?: string;
  action?: string;
  hideValue?: boolean;
}

export function AppCollapsibleFieldButton(props: AppCollapsibleFieldButtonProps) {
  const { label, value, action, hideValue, ...itemProps } = props;

  const collapseValue = React.useMemo(() => !hideValue, [hideValue]);

  return (
    <ListItemButton
      sx={(theme) => ({ p: 0, pl: 1, pr: 1, borderRadius: theme.spacing(1.25), ':hover': { background: 'inherit' } })}
      {...itemProps}
    >
      <ListItemText
        primary={
          <Box display={'flex'} justifyContent={'space-between'} minHeight={20}>
            <Typography fontSize={16} lineHeight={1.4} fontWeight={600}>
              {label}
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.4}
              fontWeight={600}
              sx={(theme) => ({
                textDecoration: 'underline',
                color: theme.md3.sys.color.onSurfaceVariant,
                ':hover': { color: theme.md3.sys.color.onSurface },
              })}
            >
              {action}
            </Typography>
          </Box>
        }
        secondary={
          <Collapse in={collapseValue}>
            <Spacer spacing={1} />
            <Typography fontSize={14} lineHeight={1.4} color="primary">
              {value ?? 'Information non connue'}
            </Typography>
          </Collapse>
        }
      />
    </ListItemButton>
  );
}
