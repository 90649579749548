import * as React from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { ForwardedRef } from 'react';
import { Spacer } from '../Spacer';
import { employeeConfirmCode, employeeSendCode } from '@/services/employee';
import { UserTypes } from '@/types';
import { storeAuthData } from '@/utils/auth.utils';
import { StatusCodes } from 'http-status-codes';
import { AccountContext } from '@/store';
import { useDispatch } from 'react-redux';
import { useToast } from '../Toast';
import { AppCollapsibleFieldButton } from '../AppCollapsibleField/AppCollapsibleFieldButton';
import { AppCollapsibleFieldActions } from '../AppCollapsibleField/AppCollapsibleFieldActions';
import { AppCollapsibleFieldProps } from '../AppCollapsibleField';
import { AppCollapsibleFieldInput } from '../AppCollapsibleField/AppCollapsibleFieldInput';
import { EmployeeProfilFieldOTP } from './EmployeeProfilFieldOTP';
import { isEmail, isPhoneNumber } from '@/utils/field-validator';

export interface EmployeeProfilFieldProps extends Omit<AppCollapsibleFieldProps, 'readOnly'> {
  confirmTo?: { email?: boolean; phone?: boolean; idEmployeeLogin: string; idBusiness: string };
}

export const EmployeeProfilField = React.forwardRef(function EmployeeProfilField(
  props: EmployeeProfilFieldProps,
  ref: ForwardedRef<Element>,
) {
  const { label, infoContent, type, disabled, value, confirmTo, onChange, onStartEdit, onEndEdit } = props;

  const toast = useToast();
  const dispatch = useDispatch();

  const validator = type === 'tel' ? isPhoneNumber(true) : type === 'email' ? isEmail(true) : undefined;

  const infoContentElement = React.useRef<HTMLDivElement>();
  const [modelValue, setModelValue] = React.useState(value);
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);

  const [onConfirmation, setOnConfirmation] = React.useState(false);
  const [confirmationCode, setConfirmationCode] = React.useState('');

  const labelAction = React.useMemo(() => (openCollapse ? 'Annuler' : 'Modifier'), [openCollapse]);

  const scrollToInput = () => {
    setTimeout(() => infoContentElement.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 200);
  };

  const toggleCollapse = () => {
    if (disabled) return;

    setOpenCollapse((current) => {
      if (current) {
        setModelValue(value);
      } else scrollToInput();

      setOnConfirmation(false);
      return !current;
    });
  };

  async function sendValidationCode() {
    setSubmitting(true);
    if (confirmTo?.email || confirmTo?.phone) {
      try {
        await employeeSendCode({
          ...confirmTo,
          email: confirmTo.phone ? modelValue : undefined,
          phone: confirmTo.email ? modelValue : undefined,
        });
        toast.present({ message: 'Un code vous a été envoyé', severity: 'success' });
        setSubmitting(false);
        return true;
      } catch {
        toast.present({ message: "Le code n'a pas pu être envoyé", severity: 'error' });
        setSubmitting(false);
        return false;
      }
    }

    setSubmitting(false);
    return false;
  }

  async function confirmValidationCode() {
    setSubmitting(true);
    try {
      const { data, status } = await employeeConfirmCode({
        ...confirmTo,
        email: confirmTo?.phone ? modelValue : undefined,
        phone: confirmTo?.email ? modelValue : undefined,
        codeValidation: confirmationCode,
      });
      if (status === StatusCodes.OK) {
        const { token, exp, employee } = data;
        storeAuthData({ userType: UserTypes.EMPLOYEE, token, exp });
        dispatch(AccountContext.actions.setUser(employee));
        toast.present({ message: 'La confirmation a bien été prise en compte', severity: 'success' });
        setSubmitting(false);
        return true;
      }
    } catch {
      toast.present({
        message: "Le code de confirmation n'est pas valide ou l'identifiant déjà utilisé",
        severity: 'error',
      });
      setSubmitting(false);
      return false;
    }

    setSubmitting(false);
    return false;
  }

  async function submitField() {
    setSubmitting(true);
    const isValide = validator == null ? true : validator(modelValue);

    if (!isValide) {
      setSubmitting(false);
      const typeDisplay =
        type === 'tel'
          ? 'Le format du téléphone doit commencer par 06 ou 07 et contenir 10 chiffres'
          : "Le format de l'email semble incorrect";
      return toast.present({ message: typeDisplay, severity: 'error' });
    }

    if ((confirmTo?.email || confirmTo?.phone) && !onConfirmation) {
      const codeSended = await sendValidationCode();
      setSubmitting(false);
      return setOnConfirmation(codeSended);
    } else if (onConfirmation) {
      const isValideCode = await confirmValidationCode();
      if (!isValideCode) {
        setSubmitting(false);
        return;
      }
    }

    onChange?.(modelValue);
    setSubmitting(false);
    setOpenCollapse(false);
    setOnConfirmation(false);
  }

  React.useEffect(() => {
    if (disabled) setOpenCollapse(false);
  }, [disabled]);

  React.useEffect(() => {
    openCollapse ? onStartEdit?.() : onEndEdit?.();
  }, [openCollapse]);

  return (
    <Box>
      <AppCollapsibleFieldButton
        label={label}
        value={modelValue}
        action={labelAction}
        hideValue={openCollapse}
        onClick={toggleCollapse}
        disabled={disabled}
      />

      <Collapse in={openCollapse} unmountOnExit>
        <Box ref={infoContentElement} pr={1} pl={1} maxWidth={'80%'}>
          {infoContent}
          {onConfirmation && (
            <>
              <Spacer spacing={1} />
              <Typography
                fontSize={12}
                lineHeight={2}
                sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}
              >
                Veuillez entrer le code de vérification reçu par mail et/ou sms
              </Typography>
            </>
          )}
        </Box>

        <Spacer spacing={onConfirmation ? 0 : 4} />

        {!onConfirmation && (
          <AppCollapsibleFieldInput
            ref={ref}
            InputProps={{ type, style: { margin: 0 } }}
            value={modelValue}
            onChange={(_, newValue) => setModelValue(newValue)}
          />
        )}

        {onConfirmation && (
          <EmployeeProfilFieldOTP value={confirmationCode} onChange={(code) => setConfirmationCode(code)} />
        )}
        <Spacer spacing={3} />

        <AppCollapsibleFieldActions
          label={onConfirmation ? 'Valider' : 'Enregistrer'}
          onClick={submitField}
          SecondaryActionProps={
            onConfirmation
              ? {
                  label: "Je n'ai pas reçu le code",
                  onClick: sendValidationCode,
                }
              : undefined
          }
          submitting={submitting}
        />
      </Collapse>
    </Box>
  );
});
