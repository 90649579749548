import React, { useState, useEffect } from 'react';
import '../ChoixDateSalarie/ChoixDateSalarie.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import './PreferencesEmployee.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { StatusCodes } from 'http-status-codes';
import { encodeToken, verifyToken } from '../../../services/auth';
import { validatePreferencesEmployee } from '../../../services/rh/employees';
import { useToast } from '@/components/Toast';
import { declarationServices, rdvManagerial } from '@/components/AppPicto';

function PreferencesEmployee() {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const [err, setErr] = useState("")
  const token = searchParams.get('token_decode');
  const [decode, setDecode] = useState({
    idRhNotification: '',
    idBusiness: '',
    type: '',
  });
  const customStyles = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '80%',
      maxWidth: '60rem',
    },
  };
  const [expire, setExpire] = useState('');
  const [entretienLiaison, setEntretienLiaison] = useState('1');
  const [acceptEntretienLiaison, setAcceptEntretienLiaison] = useState('1');
  const [entretienLiaisonPersonne, setEntretienLiaisonPersonne] = useState('1');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === StatusCodes.OK) {
            setDecode(val.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  return (
    <div>
      {expire ? (
        <div className="container-fluid mt-5">
          <h1 className="question-form mb-5">{expire}</h1>
        </div>
      ) : (
        <div>
          <div className="px-4 my-3">
            <Link
              className="text-link mt-4 pt-5"
              to="/demarches"
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
              }}
            >
              <img src={logo} className="App-logo" alt="logo" width="73" />
            </Link>
          </div>
          <div className="container-fluid col-12 col-xl-6 mt-5">
            {decode.type === 'Planifier entretien de liaison' ? (
              <div className="text-center">
                <img alt="kerij" className="mb-3 img-rdv" src={declarationServices} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title card-title-rdv">
                    Planifier mon entretien de liaison &nbsp;
                    <FontAwesomeIcon
                      className="fa-1x modal-info"
                      style={{ cursor: 'pointer' }}
                      icon={faCircleInfo}
                      onClick={() => {
                        setModal(!modal);
                      }}
                    />
                  </h5>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <img alt="kerij" className="mb-3 img-rdv" src={rdvManagerial} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title card-title-rdv">
                    Planifier mon entretien de retour &nbsp;
                    <FontAwesomeIcon
                      className="fa-1x modal-info"
                      style={{ cursor: 'pointer' }}
                      icon={faCircleInfo}
                      onClick={() => {
                        setModal(!modal);
                      }}
                    />
                  </h5>
                </div>
              </div>
            )}
            {decode.type === 'Planifier entretien de liaison' && (
              <div>
                <div className="text-center col-12 mb-2 text-modal">
                  Souhaitez-vous réaliser un entretien de liaison ?{' '}
                </div>
                <label className="col-12 col-xl-12 mb-3">
                  <select
                    className="form-select text-modal-select short-text"
                    value={acceptEntretienLiaison}
                    onChange={(e) => {
                      setAcceptEntretienLiaison(e.target.value);
                    }}
                    required
                  >
                    <option value="1">Oui</option>
                    <option value="2">Non</option>
                  </select>
                </label>
              </div>
            )}
            {(acceptEntretienLiaison === '1' || decode.type === 'Planifier entretien de retour') && (
              <div>
                <div className="text-center mb-2 text-modal">
                  Vous souhaitez bénéficier de l'entretien en distanciel ou en présentiel ?{' '}
                </div>
                <label className="col-12 col-xl-10 offset-xl-1 mb-3">
                  <select
                    className="form-select text-modal-select short-text"
                    value={entretienLiaison}
                    onChange={(e) => {
                      setEntretienLiaison(e.target.value);
                    }}
                    required
                  >
                    <option value={1}>Je souhaite bénéficier de l'entretien en présentiel</option>
                    <option value={2}>Je souhaite bénéficier de l'entretien en distanciel</option>
                  </select>
                </label>
                <div className="text-center mb-2 text-modal">
                  Cet entretien est organisé avec votre manager mais il pourra aussi être organisé avec votre
                  gestionnaire RH.
                  <br />
                  <br />
                  Je souhaite réaliser mon entretien avec :{' '}
                </div>
                {entretienLiaison !== '' && (
                  <label className="col-12 col-xl-10 offset-xl-1 mb-3">
                    <select
                      className="form-select text-modal-select"
                      name="entretienLiaison"
                      value={entretienLiaisonPersonne}
                      onChange={(e) => {
                        setEntretienLiaisonPersonne(e.target.value);
                      }}
                      required
                    >
                      <option value={1}>Mon manager</option>
                      <option value={2}>Mon partenaire RH</option>
                    </select>
                  </label>
                )}
              </div>
            )}
            <div className="text-center col-10 col-xl-4">
              <button
                value={1}
                className="btn btn-kerij-bleu btn-modal col-12 mb-5"
                onClick={() => {
                  encodeToken({
                    idRhNotification: decode.idRhNotification,
                    idBusiness: decode.idBusiness,
                  }).then((val) => {
                    if (val.status === StatusCodes.OK) {
                      const token = val.data.token;
                      validatePreferencesEmployee(token, {
                        responseAccept: acceptEntretienLiaison === '2' ? 'refused' : '',
                        responseIndex: entretienLiaison,
                        responsePersonIndex: entretienLiaisonPersonne,
                      })
                        .then((value) => {
                          if (value.status === StatusCodes.OK) {
                            navigate('/demarches');
                            setExpire("Ce lien n'est plus disponible");
                          }
                        })
                        .catch((error) => {
                          alert(JSON.stringify(error.response));
                          // setErr(JSON.stringify(error.response))
                        });
                    }
                  });
                }}
              >
                {' '}
                Valider
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        style={customStyles}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modal}
        onRequestClose={() => {
          setModal(!modal);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModal(!modal);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">
          Qu'est qu'un{' '}
          {decode.type === 'Planifier entretien de liaison'
            ? 'entretien de liaison ?'
            : "entretien de retour à l'emploi ?"}
        </h3>
        <div className="text-center text-modal mt-3">
          {decode.type === 'Planifier entretien de liaison' ? (
            <div>
              <p className="card-text">L’entretien de liaison a été créé par la loi du 2 août 2021.</p>
              <p>
                Cet entretien est obligatoirement proposé par votre employeur dès que vous cumulez 30 jours d’arrêt de
                travail au cours des 12 derniers mois mais il peut aussi être sollicité directement par vous, si vous
                considérez qu’un élément de votre vie personnelle et/ou professionnelle pourrait entraver votre activité
                et que vous souhaitez envisager des solutions avec votre employeur.
              </p>
              <p>
                Cet entretien, qui n’est pas un RDV médical, est un moment privilégié pendant votre arrêt de travail
                pour maintenir le lien avec votre entreprise.
              </p>
            </div>
          ) : (
            <div>
              <p className="card-text">
                C’est un moment privilégié entre vous et votre manager pour vous permettre de revenir à votre poste dans
                les meilleures conditions.{' '}
              </p>
              <p>
                Cet entretien permettra à votre manager de vous décrire la manière dont les équipes se sont organisées
                pendant votre absence, les actions qui ont été mises en place et la manière dont vous allez reprendre
                vos activités. Plus largement, il pourra faire un point sur les évolutions récentes de l’entreprise.{' '}
              </p>
              <p>
                C’est aussi un moment privilégié pour partager vos doutes, vos besoins d’accompagnement, de formation ou
                tout autre aspect de votre vie personnelle/professionnelle qui pourraient avoir un impact sur votre
                activité au sein de l’entreprise et que vous souhaitez partager de manière à ce que les solutions
                requises puissent être mobilisées.
              </p>
            </div>
          )}
        </div>

        <div className="text-center"></div>
      </Modal>
    </div>
  );
}

export default PreferencesEmployee;
