export const AllowedStringVariable = {
  ParcoursName: 'parcours.name',
  EmployeeFirstname: 'employee.firstname',
  EmployeeLastname: 'employee.lastname',
  EmployeeDescription: 'employee.description',
  OrganisationDescription: 'organisation.description',
  OrganisationCode: 'organisation.code',
  Manager1Firstname: 'manager1.firstname',
  Manager1Lastname: 'manager1.lastname',
  Manager2Firstname: 'manager2.firstname',
  Manager2Lastname: 'manager2.lastname',
  ManagerRH1Firstname: 'managerRH1.firstname',
  ManagerRH1Lastname: 'managerRH1.lastname',
  ManagerRH2Firstname: 'managerRH2.firstname',
  ManagerRH2Lastname: 'managerRH2.lastname',
  TriggerDataAbsenceCount: 'trigger_data.absence_count',
  TriggerDataAbsenceLastDateEnd: 'trigger_data.absence_last_date_end',
  TriggerDataAbsenceLastInternalCode: 'trigger_data.absence_last_internal_code_absence',
  TriggerDataTotalDuration: 'trigger_data.absence_total_duration',
  BusinessDisclaimer: 'businessSetting.disclaimer',
} as const;

export type AllowedStringVariable = (typeof AllowedStringVariable)[keyof typeof AllowedStringVariable];
const isAllowedStringVariable = (variable: string): variable is AllowedStringVariable =>
  Object.values(AllowedStringVariable).includes(variable as any);

const StringVariableTranslation: Record<AllowedStringVariable, string> = {
  [AllowedStringVariable.ParcoursName]: 'Nom parcours',

  [AllowedStringVariable.EmployeeFirstname]: 'Prénom collaborateur',
  [AllowedStringVariable.EmployeeLastname]: 'Nom collaborateur',
  [AllowedStringVariable.EmployeeDescription]: 'Matricule collaborateur',
  [AllowedStringVariable.OrganisationDescription]: 'Libellé organisation',
  [AllowedStringVariable.OrganisationCode]: 'Code organisation',

  [AllowedStringVariable.Manager1Firstname]: 'Prénom manager 1',
  [AllowedStringVariable.Manager1Lastname]: ' Nom manager 1',
  [AllowedStringVariable.Manager2Firstname]: 'Prénom manager 2',
  [AllowedStringVariable.Manager2Lastname]: ' Nom manager 2',
  [AllowedStringVariable.ManagerRH1Firstname]: 'Prénom RH 1',
  [AllowedStringVariable.ManagerRH1Lastname]: ' Nom RH 1',
  [AllowedStringVariable.ManagerRH2Firstname]: 'Prénom RH 2',
  [AllowedStringVariable.ManagerRH2Lastname]: ' Nom RH 2',

  [AllowedStringVariable.TriggerDataAbsenceCount]: `Nombre d'absences`,
  [AllowedStringVariable.TriggerDataAbsenceLastDateEnd]: `Dernier jour d'absence`,
  [AllowedStringVariable.TriggerDataAbsenceLastInternalCode]: `Nature d'absence`,
  [AllowedStringVariable.TriggerDataTotalDuration]: `Durée totale des absences`,

  [AllowedStringVariable.BusinessDisclaimer]: `Disclaimer`,
} as const;

export type StringVariableTranslation = (typeof StringVariableTranslation)[keyof typeof StringVariableTranslation];
const isAllowedStringVariableTranslation = (variable: string): variable is StringVariableTranslation =>
  Object.values(StringVariableTranslation).includes(variable as any);

export const translateVariable = (variableName: string): { label: string; translated: boolean } => {
  if (isAllowedStringVariable(variableName))
    return { label: StringVariableTranslation[variableName], translated: true };
  return { label: variableName, translated: false };
};

export const reverseTranslateVariable = (variableLabel: string): { value: string; parsed: boolean } => {
  if (isAllowedStringVariableTranslation(variableLabel)) {
    const entries = Object.entries(StringVariableTranslation).find(([_, value]) => value === variableLabel);
    if (entries) return { value: entries[0], parsed: true };
  }
  return { value: variableLabel, parsed: false };
};
