import React from 'react';
import { styled } from '@mui/material';
import logoPointBleu from './assets/long-bleu-point-bleu.svg';
import logoBlanc from './assets/long-blanc-point-jaune.svg';
import logoDefault from './assets/long-bleu-point-jaune.svg';

export interface AppLogoProps extends Omit<React.ComponentProps<typeof Logo>, 'src'> {
  /** default: Bleu avec points jaune */
  variant?: 'dark' | 'light' | 'light-alt';
}

export const AppLogo = (props: AppLogoProps) => {
  const { variant, ...imgProps } = props;

  const logoSrc = (() => {
    if (variant === 'dark') return logoBlanc;
    if (variant === 'light-alt') return logoPointBleu;
    return logoDefault;
  })();

  return <Logo src={logoSrc} {...imgProps}></Logo>;
};

const Logo = styled('img')(({ theme }) => ({}));
