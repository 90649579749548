import React from 'react';
import { DeclarationFormControl } from './type';
import { Box, styled, Typography } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { Spacer } from '@/components/Spacer';
import { AbsenceDateRange, AbsenceDateRangeHospi } from '@/components/AbsenceDatesRange';
import { useError } from '@/shared/useError';
import { isEmptyDate, isInvalideDate } from '@/components/AbsenceDatesRange/data';
import { FormatDate } from '@/constants/date.constants';
import moment from 'moment';
import { absenceCheckCrossover } from '@/services/employee/absence/absenceCheckCrossover';

export interface DeclarationFormDateProps {
  type: string | null;
  formControl: DeclarationFormControl;
}

export function DeclarationFormDate(props: DeclarationFormDateProps) {
  const { type, formControl } = props;

  const appVariant = useAppVariant();
  const { error, triggerError, clearError } = useError();

  const triggerNewError = (message: string) => {
    triggerError(message);
    formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
  };

  const verifyDates = async ({ dateDebut, dateFin }: { dateFin?: string; dateDebut?: string }) => {
    if (isInvalideDate(dateDebut)) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return triggerNewError('Le format de la date de début est incorrect');
    } else if (isInvalideDate(dateFin)) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return triggerNewError('Le format de la date de fin est invalide');
    } else if (isEmptyDate(dateDebut) || isEmptyDate(dateFin)) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return;
    }

    const sevenDaysAgo = moment().subtract(7, 'days').format(FormatDate.DATE_ONLY);
    const momentDateStart = moment(dateDebut).startOf('day');
    const momentDateEnd = moment(dateFin).startOf('day');

    if (momentDateStart.isBefore(sevenDaysAgo)) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return triggerNewError(
        `Votre déclaration aurait du être effectuée au plus tard 7 jours après la date de début, veuillez vous rapprocher de votre service Ressources Humaines`,
      );
    }
    if (momentDateEnd.isBefore(momentDateStart)) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return triggerNewError('La date de fin doit être supérieure ou égale à la date de début');
    }

    const crossover = await absenceCheckCrossover({
      params: {
        dateEnd: momentDateEnd.format(FormatDate.DATE_ONLY),
        dateStart: momentDateStart.format(FormatDate.DATE_ONLY),
      },
      options: { severity: 'error' },
    });

    if (crossover.success === false) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return triggerNewError(crossover.message);
    }

    clearError();
    formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: true } }));
  };

  const verifyDatesHospi = async ({ dateDebut }: { dateDebut?: string }) => {
    clearError();

    const momentStart = dateDebut ? moment(dateDebut) : null;

    if (momentStart && momentStart.isBefore(moment().subtract(7, 'days').format('YYYY-MM-DD'))) {
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: false } }));
      return triggerNewError(
        `Votre déclaration aurait du être effectuée au plus tard 7 jours après la date de début, veuillez vous rapprocher de votre service Ressources Humaines`,
      );
    }

    if (dateDebut) {
      clearError();
      formControl[1]((model) => ({ ...model, date: { ...model.date, isVerified: true } }));
    }
  };

  return (
    <Box>
      <Spacer spacing={appVariant.medium ? 2 : 0} />

      <Subtitle>
        Merci de <HighlightedSubtitleText>saisir les dates exactes</HighlightedSubtitleText> renseignées sur votre
        justificatif d’absence.
      </Subtitle>

      <Spacer spacing={appVariant.medium ? 4 : 2} />

      {type !== 'HO' && (
        <AbsenceDateRange
          dense
          value={{
            dateStart: formControl[0].date.date_start ?? undefined,
            dateEnd: formControl[0].date.date_end ?? undefined,
            lastDayWork: formControl[0].date.last_day_work ?? undefined,
          }}
          callback={(model) => verifyDates({ dateDebut: model.dateStart, dateFin: model.dateEnd })}
          error={error}
          onChange={(value) => {
            formControl[1]((initialModel) => ({
              ...initialModel,
              date: {
                ...initialModel.date,
                date_start: value.dateStart ?? null,
                date_end: value.dateEnd ?? null,
                last_day_work: value.lastDayWork ?? null,
                duration: null,
                show_duration: null,
              },
            }));
          }}
        />
      )}

      {type === 'HO' && (
        <AbsenceDateRangeHospi
          dense
          value={{
            dateStart: formControl[0].date.date_start ?? undefined,
            dateEnd: formControl[0].date.date_end ?? undefined,
            lastDayWork: formControl[0].date.last_day_work ?? undefined,
            duration: formControl[0].date.duration ?? 0,
            show_duration: formControl[0].date.show_duration ?? false,
          }}
          callback={(model) => verifyDatesHospi({ dateDebut: model.dateStart })}
          error={error}
          onChange={(value) => {
            formControl[1]((initialModel) => ({
              ...initialModel,
              date: {
                ...initialModel.date,
                date_start: value.dateStart ?? null,
                date_end:
                  value.duration > 0
                    ? moment(value.dateStart)
                        .add((value.duration ?? 0) - 1, 'days')
                        .format('YYYY-MM-DD')
                    : null,
                last_day_work: value.lastDayWork ?? null,
                duration: value.duration ?? null,
                show_duration: value.show_duration ?? null,
              },
            }));
          }}
        />
      )}
    </Box>
  );
}

const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1.4,
  color: theme.md3.sys.color.onSurfaceVariant,
}));

const HighlightedSubtitleText = styled('span')(({ theme }) => ({
  fontWeight: 600,
}));
