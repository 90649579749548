import React from 'react';
import { ItemCloseAbsenceModal } from './data';
import { ActionButton } from '@/components/ActionButton';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import { AppTextField } from '@/components/AppTextField';

interface CloseAbsenceModalContentFormItemProperties {
  item: ItemCloseAbsenceModal;
  dateValue?: string;
  selectedValue?: string;
  documentSelected?: boolean;
  onChange?: (value?: string) => void;
  onAction?: (itemValue: string) => void;
}

export const CloseAbsenceModalContentFormItem = ({
  item,
  dateValue,
  selectedValue,
  documentSelected,
  onChange,
  onAction,
}: CloseAbsenceModalContentFormItemProperties) => {
  return (
    <>
      {(item.dateSelection || item.action) && (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <Typography fontSize={14} color="primary" margin="auto" mr={0.5}>
            {item.label}
          </Typography>
          {item.dateSelection && (
            <LabelDate item={item} value={dateValue} selectedValue={selectedValue} onChange={onChange} />
          )}
          {item.action != null && (
            <>
              <LabelAction item={item} selectedValue={selectedValue} onAction={onAction} />
              {documentSelected && (
                <CheckCircleOutline
                  style={{
                    marginTop: '0.5rem',
                    marginLeft: '0.25rem',
                  }}
                  color="success"
                />
              )}
            </>
          )}
        </div>
      )}
      {!item.dateSelection && !item.action && (
        <Typography fontSize={14} color="primary">
          {item.label}
        </Typography>
      )}
    </>
  );
};

interface LabelDateProperties {
  item: ItemCloseAbsenceModal;
  value?: string;
  selectedValue?: string;
  documentSelected?: boolean;
  onChange?: (value?: string) => void;
}

export const LabelDate = ({ item, value, selectedValue, onChange }: LabelDateProperties) => {
  return (
    <AppTextField
      InputProps={{
        inputProps: { type: 'date' },
        sx: { mt: '0 !important' },
      }}
      value={value}
      disabled={item.value !== selectedValue}
      onChange={(_, value) => onChange?.(value)}
      onBlur={(event) => onChange?.(event.target.value)}
      noHelper
    />
  );
};

interface LabelActionProperties {
  item: ItemCloseAbsenceModal;
  selectedValue?: string;
  onAction?: (itemValue: string) => void;
}

export const LabelAction = ({ item, selectedValue, onAction }: LabelActionProperties) => {
  return (
    <ActionButton
      actionName="add"
      sx={{ width: '100%', ml: 1 }}
      label={item.action ?? ''}
      onClick={() => onAction?.(item.value)}
      disabled={item.value !== selectedValue}
    />
  );
};
