/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from '../../../../lib/axios';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useError } from '@/shared/useError';
import { StatusCodes } from 'http-status-codes';
import { createRHRole, updateRHRole } from '../../../../services/rh';
import { ActionButton } from '@/components/ActionButton';
import { RHPermissionScope, RHRole } from '@/types';
import { Box } from '@mui/material';
import { useAppFeature } from '@/store';
import { AppTextField } from '@/components/AppTextField';

const RADIO_READWRITE = 'readwrite';
const RADIO_READONLY = 'readonly';
const RADIO_NOACCESS = 'noaccess';

type RHRoleCreate = any;
type RHRoleScope = any;
type RHScope = any;

export interface CreateProps {
  onRoleCreated: (role: RHRole) => void;
  role: RHRole | undefined; //TODO:
  readonly?: boolean;
}

export function Create({ onRoleCreated, role, readonly = false }: CreateProps) {
  const [newRole, setNewRole] = useState(role);
  const [roleScopes, setRoleScopes] = useState<RHRoleScope[]>([]);
  const [scopes, setScopes] = useState<RHScope[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { triggerError } = useError();
  const featureServiceManagement = useAppFeature().ServiceManagement;

  useEffect(() => {
    axios
      .get('/api/rh/scopes')
      .then((response) => {
        const scopesData = response.data.map((scope: any) => ({
          ...scope,
          checked: false,
          is_editable: false,
        }));
        setScopes(scopesData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de la récupération des scopes :", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (newRole?.id)
      axios
        .get('/api/rh/roles/' + newRole?.id + '/role_scopes')
        .then((response) => {
          setRoleScopes(response.data);
        })
        .catch((error) => {
          console.error("Une erreur s'est produite lors de la récupération des scopes :", error);
          setIsLoading(false);
        });
  }, [newRole?.id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const shouldDisplay = (scope: RHRole) => {
    return (
      [
        RHPermissionScope.ServiceManagement,
        RHPermissionScope.ServiceAnimation,
        RHPermissionScope.Bot,
      ] as RHPermissionScope[]
    ).includes(scope.slug)
      ? featureServiceManagement
      : true;
  };
  const isReadAvailable = (scope: RHRole) =>
    ([RHPermissionScope.ServiceAnimation, RHPermissionScope.Bot] as RHPermissionScope[]).includes(scope.slug)
      ? false
      : true;

  const isReadWriteAvailable = (scope: RHRole) =>
    ([RHPermissionScope.Statistic] as RHPermissionScope[]).includes(scope.slug) ? false : true;

  const handleRadioChange = (event: any, rowIndex: number) => {
    const value = event.target.value;
    const selectedScope = scopes.find((value, index) => index === rowIndex);
    setRoleScopes((state) => {
      const existingRoleScope = state.filter((roleScope) => roleScope?.id_scope !== selectedScope.id);

      if (value === RADIO_NOACCESS) return [...existingRoleScope];
      return [
        ...existingRoleScope,
        {
          id_role: newRole?.id,
          id_scope: selectedScope.id,
          is_editable: value === RADIO_READWRITE,
        },
      ];
    });
  };

  const createOrUpdateRole = async (role: RHRoleCreate) => {
    return role?.id
      ? updateRHRole(role, {
          role_name: newRole?.role_name,

          is_admin:
            //@ts-ignore TODO: check this
            newRole?.isAdmin,
        })
      : createRHRole({
          role_name: newRole?.role_name,
          is_admin: false,
        });
  };

  const handleValidation = () => {
    createOrUpdateRole(newRole)
      .then((responseNewRole) => {
        const rolesScopePost = roleScopes.map((roleScope) => ({
          ...roleScope,
        }));
        if (responseNewRole.status === StatusCodes.OK) {
          setNewRole(responseNewRole.data);
          axios
            .post('/api/rh/roles/' + responseNewRole.data.id + '/role_scopes', rolesScopePost)
            .then((response) => {
              // setModalIsOpenAddNewRole(false);
              // setModalIsOpenRole(false);
              onRoleCreated && onRoleCreated(responseNewRole.data);
              setNewRole(undefined);
              //setError('');
            })
            .catch((error) => {
              triggerError(error);
            });
        }
      })
      .catch((error) => {
        triggerError(error);
      });
  };

  const ListScopes = () => {
    return (
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-6 text-center mx-auto">
            <AppTextField
              label={'Nom du rôle'}
              disabled={readonly}
              autoFocus={true}
              value={newRole?.role_name}
              onChange={(_, value) =>
                // @ts-ignore TODO: fix typing
                setNewRole({
                  ...newRole,
                  role_name: value,
                })
              }
            />
          </div>

          <div className="col-12 row mt-4">
            <label className="label-add-user col-6">PERMISSIONS</label>
            <label className="label-add-user col-2 text-center">Aucun accès</label>
            <label className="label-add-user col-2 text-center" style={{ paddingRight: '1vw' }}>
              Lecture
            </label>
            <label className="label-add-user col-2 text-center">Lecture/Écriture</label>
          </div>

          {scopes.map((scope, rowIndex) => {
            const roleScope = roleScopes.find((selectedScope) => selectedScope.id_scope === scope.id);

            return shouldDisplay(scope) ? (
              <ListItem key={scope.id}>
                <ListItemText
                  className="col-4"
                  id={`checkbox-list-label-${scope.id}`}
                  primary={scope.name}
                  // onClick={() => {}}
                />
                <Box className="col-2 text-center">
                  <Radio
                    disabled={readonly}
                    checked={roleScope === undefined}
                    value={RADIO_NOACCESS}
                    name={`radio-buttons-${rowIndex}`}
                    onChange={(event) => handleRadioChange(event, rowIndex)}
                  />
                </Box>
                <Box className="col-2 text-center">
                  <Radio
                    disabled={readonly || !isReadAvailable(scope)}
                    checked={roleScope && !roleScope?.is_editable}
                    value={RADIO_READONLY}
                    name={`radio-buttons-${rowIndex}`}
                    onChange={(event) => handleRadioChange(event, rowIndex)}
                  />
                </Box>

                <Box className="col-2 text-center">
                  <Radio
                    disabled={readonly || !isReadWriteAvailable(scope)}
                    checked={roleScope && roleScope?.is_editable}
                    value={RADIO_READWRITE}
                    name={`radio-buttons-${rowIndex}`}
                    onChange={(event) => handleRadioChange(event, rowIndex)}
                  />
                </Box>
              </ListItem>
            ) : undefined;
          })}

          {!readonly && (
            <div className="col-12 mt-4">
              <div className="text-center">
                {newRole?.role_name?.length === 0 || (
                  <ActionButton actionName="validate" onClick={handleValidation} disabled={roleScopes.length === 0} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const EmptyScopes = () => {
    return <li>Aucun scope trouvé.</li>;
  };

  return <List sx={{ width: '100%' }}>{scopes.length > 0 ? <ListScopes /> : <EmptyScopes />}</List>;
}
