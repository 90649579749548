import React, { useEffect, useState } from 'react';
import { CreationAccountModal } from '../../../features/dashboard-rh/create-absence-rh/components/Modal/CreationAccount.modal';
import { EmployeeInformation } from '../../../types/user.interface';
import { Person } from '@/components/AppIcon';
import { IconButton, IconButtonProps } from '@/components/IconButton';

interface AccountIconProperties {
  employeeInformation: EmployeeInformation;
  size?: IconButtonProps['size'];
}

const AccountIcon = ({ employeeInformation, size }: AccountIconProperties) => {
  const [isOpenModalCreateAccount, setIsOpenModalCreateAccount] = useState(false);

  const [employee, setEmployee] = useState<EmployeeInformation>(employeeInformation);
  const hasPreAccount = !!employee?.employee_login?.email;
  const hasAccount = !!employee?.employee_login?.last_change_password;

  useEffect(() => {
    setEmployee(employeeInformation);
  }, [employeeInformation]);

  const handleCloseModalCreateAccount = () => {
    setIsOpenModalCreateAccount(false);
  };

  let title;
  let badgeColor: 'warning' | 'error' | 'default' = 'default';

  switch (true) {
    case hasAccount: {
      title = 'Compte actif';
      break;
    }
    case hasPreAccount: {
      title = 'Compte provisionné';
      badgeColor = 'warning';
      break;
    }
    default: {
      badgeColor = 'error';
      title = 'Compte inactif';
      break;
    }
  }

  return (
    <>
      <IconButton
        size={size}
        onClick={() => {
          if (!hasAccount && !hasPreAccount) {
            setIsOpenModalCreateAccount(true);
          }
        }}
        badge={true}
        BadgeProps={{
          color: badgeColor,
        }}
        TooltipProps={{
          title: title,
        }}
        icon={<Person sx={{ color: 'white' }} />}
        sx={{ mr: 1 }}
      />
      <CreationAccountModal
        isOpenModal={isOpenModalCreateAccount}
        handleCloseModal={handleCloseModalCreateAccount}
        employeeInformation={employeeInformation}
        onEmployeeCreated={(employee) => setEmployee((state) => ({ ...state, ...employee }))}
      />
    </>
  );
};
export default AccountIcon;
