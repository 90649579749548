import { Box } from '@mui/material';
import React from 'react';

interface SpacerCustomProps {
  /** @deprecated use spacing props */
  mt: number;
}
interface SpacerStandardProps extends Partial<SpacerCustomProps> {
  size?: 'small' | 'medium' | 'large';
  spacing?: number;
}

type SpacerProps = SpacerStandardProps;

/**
 * Size : (default: medium)
 *   - small = theme.spacing(1)
 *   - medium : theme.spacing(2.5)
 *   - large : theme.spacing(4)
 */
export const Spacer = (props: SpacerProps) => {
  const { size, mt, spacing } = props;
  const marginTopSize = size === 'small' ? 1 : size === 'large' ? 4 : 2.5;
  const marginTopCustom = mt ? mt * 2 : undefined;

  return (
    <>
      <Box sx={(theme) => ({ marginTop: theme.spacing(spacing ?? marginTopCustom ?? marginTopSize) })} />
    </>
  );
};
