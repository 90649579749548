import React, { useCallback } from 'react';
import { Button, TableCell, Tooltip } from '@mui/material';
import { colors } from '@/constants/theme.constants';
import { ReactComponent as IconValidateSVG } from '@/assets/icon-validate.svg';
import { ReactComponent as IconWarningSVG } from '@/assets/icon-warning.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AppInput } from '@/components/AppInput';

const buttonSize = 'medium';
const buttonSelectedColor = 'secondary';

interface ColumnProps {
  value: string | null;
  selected?: boolean;
}

export interface WarningVerificationColumnButtonProps extends ColumnProps {
  updatedTo?: string;
  inverseOf?: string;
}
export const WarningVerificationColumnButton = (
  props: WarningVerificationColumnButtonProps & Omit<React.ComponentProps<typeof Button>, 'value'>,
) => {
  const { value, selected, disabled, inverseOf, updatedTo, ...otherProps } = props;

  const isInverseOf = value == null || value === '' ? !disabled : false;
  const isAutoUpdate = updatedTo != null && updatedTo !== value;
  const labelValue = value != null && value != '' ? value : disabled ? 'Non détecté' : inverseOf;
  const isDisabled = disabled ?? value == null;

  const renderButton = useCallback(() => {
    let buttonColor: 'primary' | 'secondary' | 'error' | 'info' = buttonSelectedColor;

    if (!selected) buttonColor = 'primary';

    return (
      <Button
        size={buttonSize}
        sx={{ minWidth: '120px', textTransform: 'capitalize' /*, color: theme.palette.common.black*/ }}
        variant={selected ? 'contained' : 'outlined'}
        color={buttonColor}
        disabled={isDisabled}
        {...otherProps}
      >
        {labelValue}
      </Button>
    );
  }, [selected]);

  return (
    <TableCell className="align-middle">
      {(isInverseOf || isAutoUpdate) && (
        <Tooltip
          followCursor
          title={
            <>
              <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '0.5rem' }} />
              {isInverseOf
                ? 'La donnée n’a pas pu être lue automatiquement lors de l’analyse. La valeur proposée a été générée par le système.'
                : `La valeur a automatiquement été modifiée. Le collaborateur a saisi le ${updatedTo}.`}
            </>
          }
        >
          {renderButton()}
        </Tooltip>
      )}
      {!(isInverseOf || isAutoUpdate) && renderButton()}
    </TableCell>
  );
};

export interface WarningVerificationColumnDateProps extends ColumnProps {
  onChange: (value: string) => void;
}

export const WarningVerificationColumnDate = (props: WarningVerificationColumnDateProps) => {
  const { value, selected, onChange } = props;
  return (
    <TableCell className="align-middle">
      <Button
        component={AppInput}
        variant={selected ? 'contained' : 'outlined'}
        color={selected ? buttonSelectedColor : 'primary'}
        size={buttonSize}
        type="date"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        style={{ ...(selected ? { borderColor: colors.secondary } : undefined) }}
        sx={{ padding: '0.25rem 1rem' }}
        disableRipple={true}
      />
    </TableCell>
  );
};

export const WarningVerificationColumnStatus = (props: { isValid?: boolean }) => (
  <TableCell className="text-center align-middle">
    {props.isValid ? <IconValidateSVG /> : <IconWarningSVG />}{' '}
  </TableCell>
);

export const WarningVerificationColumnEmpty = () => <TableCell></TableCell>;

export const WarningVerificationColumnLabel = (props: { label: string }) => (
  <TableCell
    scope="row"
    sx={(theme) => ({
      color: theme.palette.primaryBackground.contrastText,
    })}
  >
    <span>{props.label}</span>
  </TableCell>
);

export const WarningVerificationColumnNoError = (props: { value: string | null }) => (
  <TableCell style={{ color: 'rgba(0, 0, 0, 0.26)', textTransform: 'capitalize', textAlign: 'center' }}>
    {props.value ?? 'Non détecté'}
  </TableCell>
);
