import React from 'react';
import classNames from 'clsx';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import { NotificationVariant } from './type';
import { NotificationListSection } from './NotificationListSection';

const componentName = 'NotificationListTitle';

export interface NotificationListTitleProps extends Omit<TypographyProps, 'variant'> {
  variant: NotificationVariant;
}

export function NotificationListTitle(props: NotificationListTitleProps) {
  const { children, variant, className, ...typographyProps } = props;

  const theme = useTheme();

  return (
    <NotificationListSection variant={variant} boxProps={{ padding: 4, paddingLeft: 2.5, paddingRight: 2.5 }}>
      <Typography
        className={classNames(componentName, className)}
        fontSize={(theme) => theme.md3.sys.typescale.title.large.fontSize}
        fontWeight={(theme) => theme.md3.sys.typescale.title.large.fontWeight}
        lineHeight={'normal'}
        whiteSpace={'break-spaces'}
        sx={{ color: theme.md3.sys.color.onSurfaceVariant }}
        {...typographyProps}
      >
        {children}
      </Typography>
    </NotificationListSection>
  );
}
