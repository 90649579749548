import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, Typography } from '@mui/material';
import { Absence } from '@/types';
import { Spacer } from '../Spacer';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { getAbsenceCodeLabel, isAbsenceCode } from '@/types/AbsenceCode';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';

const componentName = 'EmployeeAbsenceCardResume';

export interface EmployeeAbsenceCardResumeProps extends Omit<BoxProps, 'children'> {
  value: {
    date_start: Absence['date_start'];
    date_end?: Absence['date_end'];
    date_end_hospi_theory?: Absence['date_end_hospi_theory'];
    type_absence?: Pick<Absence['type_absence'], 'internal_code_absence'>;
  };
}

export function EmployeeAbsenceCardResume(props: EmployeeAbsenceCardResumeProps) {
  const { value, className, ...boxProps } = props;

  const internalCode = isAbsenceCode(value.type_absence?.internal_code_absence)
    ? value.type_absence?.internal_code_absence
    : undefined;
  const typeAbsence = internalCode ? getAbsenceCodeLabel(internalCode) : undefined;

  const dateStart = moment(value.date_start).format(FormatDate.FRENCH_DATE);
  const dateEnd = moment(value.date_end ?? value.date_end_hospi_theory).format(FormatDate.FRENCH_DATE);

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      <Typography lineHeight={'normal'} fontWeight={600} margin={'auto'} fontSize={16}>
        {typeAbsence ?? 'Arrêt de travail'}
      </Typography>

      <Spacer spacing={1} />

      <Box display={'flex'} gap={1}>
        <CalendarTodayOutlined sx={{ height: 16, width: 'auto' }} />
        <Typography fontSize={12} fontWeight={600} mt={'auto'} mb={'auto'}>
          du {dateStart} au {dateEnd}
        </Typography>
      </Box>
    </Box>
  );
}
