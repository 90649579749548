import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spacer } from '../Spacer';
import { StatusCodes } from 'http-status-codes';
import { verifyEmployee } from '../../services/employee';
import { AppTextField } from '../AppTextField';
import { Box, Card, CardContent, Checkbox, FormControlLabel, InputAdornment, styled, Typography } from '@mui/material';
import { AppTooltipInfo } from '../AppTooltipInfo';
import { useToast } from '../Toast';
import { ActionButton } from '../ActionButton';
import { useAppVariant } from '@/hooks/useAppVariant';
import { AppLogo } from '../AppLogo';
import { AppLayout } from '../AppLayout';

const isEmpty = (value?: string): value is '' | undefined => value == null || value.trim() === '';
const isValidDepartment = (value: string) => /^(0[1-9]|[1-8]\d|9[0-5]|2A|2B|97[1-8]|98[1-8]|99)$/.test(value);

export function Signup() {
  const appVariant = useAppVariant();
  const toast = useToast();
  const navigate = useNavigate();
  const state = useLocation();

  // inclut la Corse et les DOM/TOM
  const isValidCodePostal = (value?: string) =>
    !isEmpty(value) && /^(0[1-9]|[1-8]\d|9[0-5]|2A|2B|97|98)\d{3}$/.test(value);

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    prenom: '',
    dateNaissance: '',
    dptNaissance: '',
    codePostal: '',
    checkbox: false,
  });

  const isValideForm = () => {
    return (
      !isEmpty(form.prenom) &&
      !isEmpty(form.dateNaissance) &&
      isValidCodePostal(form.codePostal) &&
      isValidDepartment(form.dptNaissance)
    );
  };

  const handleInputChange = (key: string, value: string) => {
    setForm({ ...form, [key]: value });
  };

  const handleCheckboxChange = (value: boolean) => {
    setForm((previousForm) => ({
      ...previousForm,
      checkbox: value,
      dptNaissance: value ? '99' : '',
    }));
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    setIsSubmitting(true);

    if (isValideForm()) {
      try {
        const response = await verifyEmployee(form);
        setIsSubmitting(false);

        if (response?.status === StatusCodes.NOT_FOUND)
          return toast.present({ message: response?.data.message, severity: 'error' });
        if (response?.status === StatusCodes.CONFLICT) {
          navigate('/verifyselect', {
            state: {
              salarie: response?.data.conflicts,
              document: state.state?.document,
              page: state.state?.page,
              form: {
                firstname: form.prenom,
                birthday: form.dateNaissance,
                birthCommun: form.dptNaissance,
                zipcode: form.codePostal,
              },
            },
          });
        } else if (response?.data) {
          const hasAccount = !!response?.data.employee_login;
          navigate('/verify', {
            state: {
              salarie: response?.data,
              hasAccount,
              document: state.state?.document,
              page: state.state?.page,
            },
          });
        }
      } catch {
        setIsSubmitting(false);
        return toast.present({ message: 'Une erreur est survenue, veuillez ressayer', severity: 'error' });
      }
    } else {
      setIsSubmitting(false);
      if (isEmpty(form.prenom) || isEmpty(form.dateNaissance))
        return toast.present({ message: "Vous n'avez pas rempli tous les champs requis", severity: 'error' });
      else if (!isValidDepartment(form.dptNaissance))
        return toast.present({ message: 'Votre département de naissance ne semble pas correct', severity: 'error' });
      else if (!isValidCodePostal(form.codePostal))
        return toast.present({ message: 'Votre code postal ne semble pas correct', severity: 'error' });
    }
  };

  return (
    <AppLayout navbar={false}>
      <Box pl={4} pr={4}>
        <Spacer spacing={4} />

        <Box width={'100%'} textAlign={'center'}>
          <AppLogo variant="light-alt" />
        </Box>

        <Spacer spacing={appVariant.medium ? 3 : 4} />

        <Typography fontWeight={600} fontSize={20} textAlign={'center'} lineHeight={'normal'}>
          Activation du dossier personnel
        </Typography>

        <Spacer spacing={3} />

        <form onSubmit={handleSubmit}>
          <Box>
            <Card sx={(theme) => ({ maxWidth: appVariant.small ? 420 : undefined, margin: 'auto' })} color="surface">
              <CardContent>
                <AppTextField
                  dense
                  label={'Votre prénom'}
                  value={form.prenom}
                  error={isEmpty(form.prenom) && isSubmit}
                  onChange={(_, value) => handleInputChange('prenom', value)}
                  InputProps={{ type: 'text', autoComplete: 'given-name' }}
                  required
                />
                <Spacer spacing={3} />

                <AppTextField
                  dense
                  label="Votre date de naissance"
                  value={form.dateNaissance}
                  InputProps={{ type: 'date', autoComplete: 'bday' }}
                  onChange={(_, value) => handleInputChange('dateNaissance', value)}
                  onBlur={(event) => handleInputChange('dateNaissance', event.target.value)}
                  error={isEmpty(form.dateNaissance) && isSubmit}
                />

                <Spacer spacing={3} />

                <FormControlLabel
                  label={'Êtes-vous né(e) à l’étranger ?'}
                  control={
                    <Checkbox
                      style={{ marginRight: '0.5rem' }}
                      checked={form.checkbox}
                      onChange={(_, isCheck) => handleCheckboxChange(isCheck)}
                    />
                  }
                />
                <Spacer spacing={3} />

                <AppTextField
                  dense
                  label={'Votre département de naissance'}
                  value={form.dptNaissance}
                  placeholder="92"
                  InputProps={{
                    endAdornment: appVariant.medium ? (
                      <InputAdornment position="end">
                        <AppTooltipInfo title={'Veuillez entrer un code postal à cinq caractères'} />
                      </InputAdornment>
                    ) : undefined,
                  }}
                  error={!isValidDepartment(form.dptNaissance) && isSubmit}
                  disabled={form.checkbox}
                  onChange={(_, value) => handleInputChange('dptNaissance', value)}
                  required
                  helperText={'Numéro de département à deux chiffres'}
                  noHelper={appVariant.medium ? true : false}
                />

                <Spacer spacing={3} />

                <AppTextField
                  dense
                  label={`Votre code postal d'habitation`}
                  value={form.codePostal}
                  placeholder="78200"
                  InputProps={{
                    endAdornment: appVariant.medium ? (
                      <InputAdornment position="end">
                        <AppTooltipInfo title={'Veuillez entrer un code postal à cinq caractères'} />
                      </InputAdornment>
                    ) : undefined,
                  }}
                  error={!isValidCodePostal(form.codePostal) && isSubmit}
                  onChange={(_, value) => handleInputChange('codePostal', value)}
                  required
                  helperText={'Code postal à cinq caractères'}
                  noHelper={appVariant.medium ? true : false}
                />

                <Spacer spacing={3} />

                <ActionButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  actionName="validate"
                  label={'Valider'}
                  startIcon={null}
                  fullWidth
                  submitting={isSubmitting}
                />
              </CardContent>
            </Card>

            <Spacer spacing={4} />

            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
              <ButtonLink fontSize={14} pl={0.5} color="primary" onClick={() => navigate('/login', { replace: true })}>
                Se connecter
              </ButtonLink>
            </Box>
          </Box>
        </form>

        <Spacer spacing={appVariant.medium ? 4 : 0} />
      </Box>
    </AppLayout>
  );
}

const ButtonLink = styled(Typography)(({ theme, color }) => ({
  fontWeight: 400,
  lineHeight: 'normal',
  cursor: 'pointer',
  color: color ? undefined : theme.md3.sys.color.onSurfaceVariant,
  textDecoration: 'underline',
  ':hover': {
    color: theme.md3.sys.color.onSurface,
  },
}));
