import React from 'react';
import { BottomNavigation, Paper, PaperProps, styled } from '@mui/material';
import classNames from 'clsx';
import { NavbarMobileTab } from './NavbarMobileTab';
import { Close, Menu as MenuIcon } from '../AppIcon';
import { useNavbarMobile } from './useNavbarMobile';
import { UseMUIDialogController } from '@/hooks/useMUIDialogController';
import { useAuthentication } from '@/hooks/useAuthentication';
import { AccountCircleRounded } from '@mui/icons-material';
import { useMenu } from './useMenu';

const NavbarPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  borderTop: '1px solid',
  borderColor: theme.md3.sys.color.outlineVariant,
  paddingBottom: theme.spacing(1),
  bottom: 0,
  left: 0,
  right: 0,
  position: 'fixed',
}));

const componentName = 'NavbarMobile';

export interface NavbarMobileProps extends PaperProps {
  menuController?: UseMUIDialogController['control'];
}

export function NavbarMobile(props: NavbarMobileProps) {
  const navbarMobile = useNavbarMobile();
  const { className, menuController, ...rootProps } = props;
  const { isAuthenticated } = useAuthentication();
  const menuEntries = useMenu();

  return navbarMobile && menuController ? (
    <>
      <NavbarPaper elevation={3} {...rootProps} className={classNames(componentName, className)}>
        <BottomNavigation showLabels={true}>
          {menuEntries.map((entry, index) => {
            return (
              <NavbarMobileTab
                key={index}
                to={entry.to}
                label={entry.label}
                icon={entry.icon}
                feature={entry.feature}
                active={menuController.isOpen ? false : undefined}
                onClick={() => menuController.setOpen(false)}
              />
            );
          })}

          {isAuthenticated && (
            <NavbarMobileTab
              className={classNames(componentName, className)}
              to={undefined}
              label={'Menu'}
              icon={menuController.isOpen ? <Close /> : <MenuIcon />}
              onClick={() => menuController?.setOpen(!menuController.isOpen)}
              active={menuController.isOpen ? true : undefined}
            />
          )}

          {!isAuthenticated && (
            <NavbarMobileTab
              className={classNames(componentName, className)}
              to={'/login'}
              label={'Connexion'}
              icon={<AccountCircleRounded />}
              onClick={() => menuController.setOpen(false)}
              active={menuController.isOpen ? false : undefined}
            />
          )}
        </BottomNavigation>
      </NavbarPaper>
    </>
  ) : null;
}
