import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Badge, styled, type BadgeProps, type AvatarProps } from '@mui/material';
import { Person } from '@mui/icons-material';

type User = {
  employee_information: {
    firstname: string;
    lastname: string;
  };
};

export interface UserAvatarProps extends BadgeProps {
  AvatarProps?: AvatarProps;
  user?: User | null;
  hasNotification?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { user, AvatarProps, hasNotification = false, ...otherProps } = props;

  return (
    <Badge
      overlap="circular"
      color="warning"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      invisible={!hasNotification}
      {...otherProps}
    >
      <AvatarRounded {...AvatarProps}>
        {user && `${user.employee_information.firstname[0]}${user.employee_information.lastname[0]}`}
        {user == null && <Person />}
      </AvatarRounded>
    </Badge>
  );
};

const AvatarRounded = styled(Avatar)(({ theme }) => ({
  border: '0',
  aspectRatio: 1,
  background: theme.palette.common.white,
  fontWeight: theme.md3.sys.typescale.label.medium.fontWeight,
  backgroundColor: theme.palette.goldenHarvest[200],
  color: theme.md3.sys.color.onPrimary,
}));
