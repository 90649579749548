import React from 'react';
import { Absence } from '../../../types/absences.type';
import { EmployeeInformation } from '../../../types/user.interface';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { Box, Button, Typography } from '@mui/material';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { AbsenceTypeConversion } from '../Absences/AbsenceTypeConversion';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';

interface ChangeTypeAbsenceResultValidationProperties {
  employee: EmployeeInformation;
  absences?: Absence[];
  isSubmit?: boolean;
  onReset: () => void;
}

export const ChangeTypeAbsenceResultValidation = ({
  employee,
  absences,
  isSubmit,
  onReset,
}: ChangeTypeAbsenceResultValidationProperties) => {
  return (
    <>
      {!isSubmit && (
        <Box>
          <Typography fontWeight={600}>
            Vous êtes sur le point de modifier la nature des arrêts suivants pour le collaborateur{' '}
            <em>{employee && getFullName(employee)}</em>
          </Typography>

          <Spacer spacing={2.5} />

          {absences?.map((absence: Absence) => (
            <Box key={absence.id}>
              <AbsenceTypeConversion absence={absence} />
              {' - '}
              <AbsenceDate value={absence} />
            </Box>
          ))}
        </Box>
      )}
      {isSubmit && (
        <Box>
          <Typography fontWeight={600}>
            Votre modification a bien été transmise et le manager informé de ce changement
          </Typography>
          <Typography fontSize={12} fontWeight={600}>
            Le collaborateur va recevoir une notification
          </Typography>

          <Spacer spacing={1.25} />

          <ActionButton variant="outlined" label={'Modifier un nouvel arrêt'} onClick={onReset} sx={{ mt: 1, mr: 1 }} />
        </Box>
      )}
    </>
  );
};
