import React from 'react';
import { PolicyLayout } from '../../components/AppLayout';
import { datesPolicies } from '../../config/policies.config';
import { LegalDataControllerText } from '@/components/LegalDataControllerText';

export const LegalDataController: React.FC = () => {
  return (
    <PolicyLayout title={'Responsable de traitement'} date={datesPolicies.privacyPolicy}>
      <LegalDataControllerText />
    </PolicyLayout>
  );
};
