import * as React from 'react';
import { Box } from '@mui/material';
import { ActionButton, ActionButtonProps } from '../ActionButton';

export interface AppCollapsibleFieldActionsProps extends Pick<ActionButtonProps, 'label' | 'onClick' | 'submitting'> {
  SecondaryActionProps?: Pick<ActionButtonProps, 'label' | 'onClick'>;
}

export function AppCollapsibleFieldActions(props: AppCollapsibleFieldActionsProps) {
  const { submitting, SecondaryActionProps, ...buttonProps } = props;

  return (
    <Box display={'flex'} gap={2} pl={1} pr={1}>
      <ActionButton
        type="submit"
        color="primary"
        variant="contained"
        actionName="validate"
        startIcon={null}
        disabled={submitting}
        {...buttonProps}
      />
      {SecondaryActionProps && (
        <ActionButton
          type="submit"
          variant="text"
          disabled={submitting}
          sx={(theme) => ({
            border: '1px solid',
            borderColor: theme.shape.borderColorVariant,
          })}
          {...SecondaryActionProps}
        />
      )}
    </Box>
  );
}
