import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Tooltip } from '@mui/material';
import { colors } from '../../../../constants/theme.constants';
import React from 'react';
import { Link } from 'react-router-dom';
import ViewPdfModal from '../../../../components/base/ViewPdfModal';
import { ListCardContentLine } from '@/components/DashboardRH/ListCard';
interface EntretiensLegalItemProps {
  title: string;
  path: string | null;
}

const EntretiensLegalItem = ({ title, path }: EntretiensLegalItemProps) => {
  const [visibleModal, setVisibleModal] = React.useState(false);
  return (
    <ListCardContentLine label={title} onClick={() => setVisibleModal(true)} width={'100%'}>
      <Box textAlign={'right'}>
        <Tooltip title="Télécharger">
          <Link to={path || ''} target="_blank" download>
            <IconButton>
              <DownloadIcon
                style={{
                  color: colors.darkblue,
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>

      <ViewPdfModal visibleModal={visibleModal} setVisibleModal={setVisibleModal} title={title} path={path} />
    </ListCardContentLine>
  );
};

export default EntretiensLegalItem;
