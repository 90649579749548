import {
  aquaSerenity,
  goldenHarvest,
  royalLavender,
  sageMeadow,
  sunsetEmber,
} from '@/components/ThemeComponent/config/color';

export const AbsenceCode = {
  Maladie: 'MA',
  MaladiePro: 'MP',
  Hospitalisation: 'HO',
  CongeMat: 'CM',
  AccidentTravail: 'AT',
  AccidentTrajet: 'AJ',
  CongePat: 'CP',
  PathologieGrossesse: 'GP',
} as const;
export type AbsenceCode = (typeof AbsenceCode)[keyof typeof AbsenceCode];

export const isAbsenceCode = (code: string): code is AbsenceCode => Object.values(AbsenceCode).includes(code as any);

export const AbsenceCodeLabel = {
  [AbsenceCode.Maladie]: 'Maladie',
  [AbsenceCode.MaladiePro]: 'Maladie professionnelle',
  [AbsenceCode.Hospitalisation]: 'Hospitalisation',
  [AbsenceCode.CongeMat]: 'Congé maternité',
  [AbsenceCode.AccidentTravail]: 'Accident de travail',
  [AbsenceCode.AccidentTrajet]: 'Accident de trajet',
  [AbsenceCode.CongePat]: 'Congé paternité',
  [AbsenceCode.PathologieGrossesse]: 'Grossesse patho.',
} satisfies Record<AbsenceCode, string>;

export const AbsenceCodeColor = {
  [AbsenceCode.Maladie]: royalLavender[100],

  [AbsenceCode.Hospitalisation]: sageMeadow[100],

  [AbsenceCode.AccidentTravail]: goldenHarvest[300],
  [AbsenceCode.AccidentTrajet]: goldenHarvest[200],
  [AbsenceCode.MaladiePro]: goldenHarvest[100],

  [AbsenceCode.CongeMat]: aquaSerenity[100],
  [AbsenceCode.CongePat]: aquaSerenity[200],

  [AbsenceCode.PathologieGrossesse]: sunsetEmber[100],
} satisfies Record<AbsenceCode, string>;
