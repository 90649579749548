import * as React from 'react';
import { InputProps } from '@/hooks/useInput';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { HTMLEditor } from '@/components/HTMLEditor';
import { Parcours } from '@/types';
import { ParcoursActionEmail } from '@/types/ParcoursAction';
import { parcoursEmailFooter } from '@/components/ParcoursDetail';
import { AppInputLabel } from '../AppInputLabel';

export const defaultParcoursScriptAction = {
  email: {
    _: 'ParcoursActionEmail',
    contentType: 'text/html',
    subject: 'Éligibilité de {{ parcours.employee }} au parcours {{ parcours.name }}',
    // eslint-disable-next-line xss/no-mixed-html
    content: [
      'Message concernant : {{employee.firstname}} {{employee.lastname}} ({{employee.description}})',
      'Vous êtes éligible à un parcours',
      '',
      'Si vous souhaitez le suivre, vous pouvez en faire la demande <strong>directement depuis votre espace KERij</strong>',
    ]
      .map((_) => `<p>${_}</p>`)
      .join('\n'),
    to: [],
    cc: [],
  } satisfies ParcoursActionEmail,
} satisfies Parcours['script']['action'];

type ParcoursActions = any; //TODO: implement this

export interface ParcoursActionsInputProps extends InputProps<'div', ParcoursActions> {}

export function ParcoursActionsInput(props: ParcoursActionsInputProps) {
  const { value, onChange } = props;

  const theme = useTheme();

  const useActionEdit = (actionName: string, defaultValue?: any) => [
    value[actionName] ?? defaultValue,
    (event: any, valueNew: any) => {
      onChange?.(event, {
        ...value,
        [actionName]: valueNew,
      });
    },
  ];
  const [actionEmail, setActionEmail] = useActionEdit('email');
  const [actionContent, setActionContent] = React.useState(actionEmail?.content ?? '');
  const [actionSubject, setActionSubject] = React.useState(actionEmail?.subject ?? '');

  React.useEffect(() => {
    setActionContent(actionEmail?.content ?? defaultParcoursScriptAction.email.content);
    setActionSubject(actionEmail?.subject ?? defaultParcoursScriptAction.email.subject);
    setActionEmail({}, actionEmail ?? defaultParcoursScriptAction.email);
  }, []);

  React.useEffect(() => {
    setActionEmail(
      {},
      {
        ...actionEmail,
        contentType: 'text/html',
        content: actionContent,
        subject: actionSubject,
      },
    );
  }, [actionContent, actionSubject]);

  return (
    <Stack direction={'column'} gap={4}>
      <Box>
        <AppInputLabel shrink>{'Objet'}</AppInputLabel>
        <HTMLEditor
          initialValue={actionEmail?.subject ?? ''}
          onValueChange={setActionSubject}
          type="text"
          rows={1}
          style={{
            padding: theme.spacing(1),
            fontSize: 12,
            lineHeight: '190%',
          }}
        />
      </Box>

      <Box>
        <AppInputLabel shrink>{'Contenu'}</AppInputLabel>
        <HTMLEditor
          initialValue={actionEmail?.content ?? ''}
          onValueChange={setActionContent}
          afterEditable={
            <Box sx={{ padding: 2, whiteSpace: 'pre', cursor: 'not-allowed' }}>
              <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>{parcoursEmailFooter}</Typography>
            </Box>
          }
          rows={10}
        />
      </Box>
    </Stack>
  );
}
