import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, styled, Typography } from '@mui/material';

const componentName = 'DeclarationTitle';

export interface DeclarationTitleProps extends BoxProps {
  title: string;
  subtitle?: React.ReactNode;
}

export const DeclarationTitle = (props: DeclarationTitleProps) => {
  const { title, color, subtitle, className, ...boxProps } = props;

  return (
    <Box className={classNames(componentName, className)} mt={6} mb={6} {...boxProps}>
      <Title color={color}>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Box>
  );
};

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '150%',
  textAlign: 'center',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 'normal',
  textAlign: 'center',
  color: theme.md3.sys.color.onSurfaceVariant,
}));
