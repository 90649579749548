import React from 'react';

export const LegalDataControllerText = () => {
  return (
    <span>
      Votre employeur est le responsable du traitement de vos données dans le cadre de la déclaration de votre arrêt de
      travail en application de la réglementation qui lui est applicable. Pour en savoir plus sur la gestion de vos
      données personnelles et pour exercer vos droits, veuillez-vous reporter à la politique de confidentialité de votre
      employeur.
    </span>
  );
};
