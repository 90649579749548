import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { customStyles } from '../../../shared/models/custom_modal.models';
import { Typography } from '@mui/material';

// TODO: remove this uglyness
const { borderRadius, padding, height, ...customModalDefaultStyle } = customStyles.content;

interface BootstrapDialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const BootstrapDialogTitle = (props: BootstrapDialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        m: 0,
        p: 2.5,
      })}
      {...other}
    >
      <Typography
        sx={(theme) => ({
          fontSize: 16,
          fontWeight: 600,
        })}
      >
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 12,
            top: 12,
            color: theme.palette.primary.contrastText,
          })}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

/**
 * TODO: Change the API to be identical to MUIDialog with few extra properties
 */
export interface CustomModalProps extends DialogProps {
  renderContent?: () => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  contentProps?: DialogContentProps;
  disabledBackdropDismiss?: boolean;
  showExitIcon?: boolean;
  /**
   * @deprecated Use onClose + useCustomModalState()
   */
  setOpen?: (open: boolean) => void;
  dense?: boolean;
}

export function CustomModal({
  PaperProps,
  renderContent,
  renderHeader,
  renderFooter,
  style,
  contentProps = {},
  showExitIcon = true,
  open,
  setOpen,
  onClose,
  disabledBackdropDismiss,
  children,
  fullScreen,
  dense = false,
  ...otherProps
}: CustomModalProps) {
  const defaultModalProps = {
    fullWidth: true,
    maxWidth: 'md',
  } as const;
  const handleClose =
    (setOpen
      ? () => {
          if (setOpen) {
            setOpen(false);
          }
        }
      : // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose) ?? (() => {});

  return (
    <Dialog
      {...defaultModalProps}
      slotProps={{
        backdrop: {
          sx: (backdropTheme: any) => ({
            backgroundColor: dense ? 'rgba(240, 238, 249, 0.80)' : 'rgba(255, 255, 255, 0.9)',
            backdropFilter: dense ? 'none' : 'blur(2px)',
          }),
        },
      }}
      PaperProps={{
        sx: (theme) =>
          ({
            ...customModalDefaultStyle,
            // Dirty fix because of global css
            padding: 0,
            boxShadow: theme.shadows[5],
            borderColor: theme.shape.borderColorVariant,
            borderStyle: 'solid',
            borderWidth: 1,
            width: 'calc(100% - 2rem)',
            ...style,
          }) as any,
        ...PaperProps,
      }}
      onClose={disabledBackdropDismiss ? undefined : handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={fullScreen}
      {...otherProps}
    >
      {renderHeader && (
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={showExitIcon ? () => handleClose({}, 'backdropClick') : undefined}
        >
          {renderHeader()}
        </BootstrapDialogTitle>
      )}
      {renderContent && (
        <DialogContent
          dividers
          sx={(theme) => ({
            borderColor: theme.shape.borderColorVariant,
            backgroundColor: dense ? '#fff' : theme.palette.primaryBackground.main,
            color: theme.palette.primaryBackground.textContrast,
            fontSize: 14,
            borderWidth: dense ? 0 : 1,
          })}
          style={{ padding: 20 }}
          {...contentProps}
        >
          {renderContent()}
        </DialogContent>
      )}
      {renderFooter && <DialogActions>{renderFooter()}</DialogActions>}
      {children || <></>}
    </Dialog>
  );
}
