import { SvgIcon, type SvgIconProps } from '@mui/material';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import * as React from 'react';

export function createFontAwesomeIcon(iconDefinition: IconDefinition, displayName: string): typeof SvgIcon {
  const {
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    icon: [width, height, , , svgPathData],
  } = iconDefinition;
  const iconContent =
    typeof svgPathData === 'string' ? (
      <path d={svgPathData} />
    ) : (
      /**
       * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
       * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
       * of a duotone icon. 40% is the default opacity.
       *
       * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
       */
      svgPathData.map((d: string, i: number) => <path key={i} style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />)
    );

  // eslint-disable-next-line no-inner-declarations
  function Component(props: SvgIconProps, ref: React.Ref<any>) {
    return (
      <SvgIcon ref={ref} data-testid={`${displayName}Icon`} viewBox={`0 0 ${width} ${height}`} {...props}>
        {iconContent}
      </SvgIcon>
    );
  }

  if (process.env['NODE_ENV'] !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore We know what we are doing here
  return React.memo(React.forwardRef(Component));
}
