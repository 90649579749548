import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserAvatar } from './UserAvatar';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { IfFeature } from '@/components/IfFeature';
import { BrandIcon } from '@/components/BrandIcon';

export function Navbar() {
  const user = useAppSelector(AccountContext.getUser);
  const loading = useAppSelector(AccountContext.isLoading);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNotification = () => {
    if (user) {
      navigate('/activites');
    } else {
      navigate('/activiteslogin');
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('access_token_employee')) {
      localStorage.removeItem('access_token_employee');
      dispatch(AccountContext.actions.setUser(null));
      switch (location.pathname) {
        case '/activites': {
          navigate('/activiteslogin');

          break;
        }
        case '/services': {
          navigate('/serviceslogin');

          break;
        }
        case '/rdv': {
          navigate('/rdvlogin');

          break;
        }
        case '/profil': {
          navigate('/login');

          break;
        }
        // No default
      }
    }
  }, [loading, location, user]);

  return (
    <nav className="NavbarItems navbar sticky-top px-5">
      <div className="">
        <Link className="navbar-brand" to="/" style={{ color: 'inherit', textDecoration: 'inherit', display: 'block' }}>
          <BrandIcon style={{ width: 73 }} dark />
        </Link>
      </div>
      {/* <div id="installContainer" class="hidden">
                <button id="butInstall" type="button">
                    Installer l'application
                </button>
            </div> */}

      <div className="navbar-nav align-items-center" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="nav-item-custom">
          <Link
            className="nav-link text-link"
            to="/declaration"
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            Déclarer une absence{' '}
          </Link>
        </div>
        <IfFeature name="ServiceManagement">
          <div className="nav-item-custom">
            <Link
              className="nav-link text-link"
              to={user ? '/services' : '/serviceslogin'}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              Services{' '}
            </Link>
          </div>
        </IfFeature>
        <IfFeature name="InterviewManagement">
          <div className="nav-item-custom">
            <Link
              className="nav-link text-link"
              to={user ? '/rdv' : '/rdvlogin'}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              Rdv{' '}
            </Link>
          </div>
        </IfFeature>
        <div className="nav-item-custom">
          <div
            className="nav-link text-link"
            style={{
              color: 'inherit',
              textDecoration: 'inherit',
              cursor: 'pointer',
            }}
            onClick={handleNotification}
          >
            Activités
          </div>
        </div>
        <div className="nav-item-custom">
          {user ? (
            <Link
              className="nav-link text-link"
              to="/profil"
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
              }}
            >
              <UserAvatar user={user} />
            </Link>
          ) : (
            <Link
              className="nav-link text-link"
              to="/profil"
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
              }}
            >
              Se connecter{' '}
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}
