/**
 * Origine du déclenchement
 */
export const ParcoursStateOrigin = {
  ManualRH: 'manual-rh',
  ManualEmployee: 'manual-employee',
  Automatic: 'automatic',
} as const;
export type ParcoursStateOrigin = (typeof ParcoursStateOrigin)[keyof typeof ParcoursStateOrigin];

export const isParcoursStateOrigin = (value: unknown): value is ParcoursStateOrigin =>
  value != null && Object.values(ParcoursStateOrigin).includes(value as any);
