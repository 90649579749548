import * as React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

export interface AppCollapsibleFieldReadOnlyValueProps extends BoxProps {}

export function AppCollapsibleFieldReadOnlyValue(props: AppCollapsibleFieldReadOnlyValueProps) {
  const { children, ...boxProps } = props;

  return (
    <Box pr={1} pl={1} {...boxProps}>
      <Typography fontSize={14} lineHeight={1.4}>
        {children}
      </Typography>
    </Box>
  );
}
