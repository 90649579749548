import React from 'react';
import { AppRadioGroup } from '@/components/AppRadioGroup';
import { DeclarationFormControl } from './type';
import { Box, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { AbsenceContactType, isAbsenceContactType } from '@/types';
import { Email, Phone } from '@mui/icons-material';
import { EmployeeProfilField } from '@/components/EmployeeProfilField';
import { AccountContext, useAppSelector } from '@/store';

export interface DeclarationFormContactProps {
  formControl: DeclarationFormControl;
}

export function DeclarationFormContact(props: DeclarationFormContactProps) {
  const { formControl } = props;

  const user = useAppSelector(AccountContext.getUser);

  return (
    <Box>
      <AppRadioGroup
        value={
          formControl[0].contact.permission?.canContact == null
            ? null
            : String(formControl[0].contact.permission.canContact)
        }
        items={[
          { value: 'true', label: 'J’accepte d’être contacté' },
          { value: 'false', label: 'Je refuse d’être contacté' },
        ]}
        onChange={(_, value) =>
          formControl[1]((model) => ({
            ...model,
            contact: {
              ...model.contact,
              type: value === 'true' ? model.contact.type : null,
              permission: {
                canContact: value === 'true' ? true : false,
                canContactByMail: model.contact.permission?.canContactByMail ?? false,
                canContactByPhone: model.contact.permission?.canContactByPhone ?? false,
              },
            },
          }))
        }
      />

      <Spacer spacing={4} />

      {formControl[0].contact.permission?.canContact && (
        <Box>
          <Typography fontSize={24} lineHeight={'normal'} fontWeight={600}>
            Par qui souhaitez vous être contacté ?
          </Typography>

          <Spacer spacing={4} />

          <AppRadioGroup
            value={formControl[0].contact.type}
            items={[
              { value: AbsenceContactType.Manager, label: 'Mon manager' },
              { value: AbsenceContactType.GestionnaireRh, label: 'Mon partenaire RH' },
              { value: AbsenceContactType.ManagerEtGestionnaireRH, label: 'Mon manager & mon partenaire RH' },
            ]}
            onChange={(_, value) =>
              formControl[1]((model) => ({
                ...model,
                contact: {
                  ...model.contact,
                  type: isAbsenceContactType(value) ? value : null,
                },
              }))
            }
          />

          <Spacer spacing={4} />

          <Typography fontSize={24} lineHeight={'normal'} fontWeight={600}>
            Par quel moyen de communication ?
          </Typography>

          <Spacer spacing={4} />

          <Card color="surface">
            <CardContent>
              <FormControl fullWidth>
                <RadioGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formControl[0].contact.permission.canContactByPhone ?? false}
                        onClick={() =>
                          formControl[1]((model) => {
                            const userPhone = model.contact.phone ?? user?.phone ?? null;
                            const canContact = !(model.contact.permission?.canContactByPhone ?? false);
                            return {
                              ...model,
                              contact: {
                                ...model.contact,
                                permission: {
                                  canContact: true,
                                  canContactByMail: model.contact.permission?.canContactByMail ?? false,
                                  canContactByPhone: canContact,
                                },
                                phone: canContact ? userPhone : null,
                              },
                            };
                          })
                        }
                      />
                    }
                    labelPlacement="start"
                    label={<Phone fontSize="medium" />}
                    sx={{ justifyContent: 'space-between', m: 0 }}
                  />
                </RadioGroup>

                <EmployeeProfilField
                  label={'Numéro de téléphone'}
                  type="tel"
                  value={formControl[0].contact.phone ?? user?.phone}
                  onChange={(value) =>
                    formControl[1]((model) => ({
                      ...model,
                      contact: { ...model.contact, phone: value ?? null },
                    }))
                  }
                />
              </FormControl>
            </CardContent>
          </Card>

          <Spacer spacing={4} />

          <Card color="surface">
            <CardContent>
              <FormControl fullWidth>
                <RadioGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formControl[0].contact.permission.canContactByMail ?? false}
                        onClick={() => {
                          formControl[1]((model) => {
                            const userEmail = model.contact.email ?? user?.email ?? null;
                            const canContact = !(model.contact.permission?.canContactByMail ?? false);
                            return {
                              ...model,
                              contact: {
                                ...model.contact,
                                permission: {
                                  canContact: true,
                                  canContactByPhone: model.contact.permission?.canContactByPhone ?? false,
                                  canContactByMail: canContact,
                                },
                                email: canContact ? userEmail : null,
                              },
                            };
                          });
                        }}
                      />
                    }
                    labelPlacement="start"
                    label={<Email fontSize="medium" />}
                    sx={{ justifyContent: 'space-between', m: 0 }}
                  />
                </RadioGroup>

                <EmployeeProfilField
                  label={'Adresse email'}
                  type="email"
                  value={formControl[0].contact.email ?? user?.email}
                  onChange={(value) =>
                    formControl[1]((model) => ({
                      ...model,
                      contact: { ...model.contact, email: value ?? null },
                    }))
                  }
                />
              </FormControl>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}
