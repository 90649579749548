import { Box, Typography } from '@mui/material';
import React from 'react';

interface TitleProps {
  title: string;
  customStyle?: any;
  customStyleTitle?: any;
  className?: string;
}

const TitleProcess = ({ title, customStyleTitle, className }: TitleProps) => {
  return (
    <Box>
      <Typography
        fontSize={20}
        fontWeight={600}
        sx={{
          mt: 2.5,
          ...customStyleTitle,
        }}
        className={className || 'question-form'}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default TitleProcess;
