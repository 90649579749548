import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useAppWorkspace } from '@/hooks/useAppWorkspace';
import { PRO_URL } from '@/constants/urls.constants';

export interface RequireLoginProps {
  children: React.ReactElement;
}

export const RequireLogin: React.FC<RequireLoginProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const appWorkspace = useAppWorkspace();

  const { isAuthenticated } = useAuthentication();
  const isEmployeeApp = appWorkspace.match('employee');

  const redirectPath = isEmployeeApp ? '/login' : PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(redirectPath, {
        state: { fromPath: isEmployeeApp ? `${location.pathname}${location.search}` : undefined },
      });
    }
  }, [isAuthenticated]);

  return children ?? null;
};
