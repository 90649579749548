export const EmployeeNotificationType = {
  Absence: "Notification de suivi de l'arret",
  AccepteAbsence: 'Absence validée',
  ModifieAbsence: 'Absence modifiée',
  RefuseAbsence: 'Absence refusée',
  AccepteDocument: 'Document accepté',
  DocumentManquant: 'Notification de document manquant',
  RefuseDocument: 'Document refusé',
  Service: 'Notification des services',
} as const;
export type EmployeeNotificationType = (typeof EmployeeNotificationType)[keyof typeof EmployeeNotificationType];

export const isEmployeeNotificationType = (status?: string): status is EmployeeNotificationType =>
  status != null && Object.values(EmployeeNotificationType).includes(status as any);
