import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { ArrowBackIosNewRounded, CloseRounded } from '@mui/icons-material';

export const MOBILE_NAV_HEIGHT = 56;

export interface AppLayoutMobileBackProps extends Omit<IconButtonProps, 'onClick'> {
  variant?: 'back' | 'close';
  onBack?: () => void;
}

export function AppLayoutMobileBack(props: AppLayoutMobileBackProps) {
  const { variant = 'back', onBack, ...buttonProps } = props;
  const navigate = useNavigate();

  const back = () => {
    if (onBack == null) navigate(-1);
    else onBack();
  };

  return (
    <Box p={3}>
      <IconButton color="primary" sx={{ p: 0.5, position: 'relative' }} onClick={back} {...buttonProps}>
        {variant === 'back' && <ArrowBackIosNewRounded sx={{ height: 24, width: 'auto' }} />}
        {variant === 'close' && <CloseRounded sx={{ height: 24, width: 'auto' }} />}
      </IconButton>
    </Box>
  );
}
