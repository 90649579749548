import React from 'react';
import { AppRadioGroup } from '@/components/AppRadioGroup';
import { DeclarationFormControl } from './type';
import { AccountContext, useAppSelector } from '@/store';
import { EmployeeCivility } from '@/types';
import { Box } from '@mui/material';
import { InfoBox } from '@/components/base';

export interface DeclarationFormTypeProps {
  type: string | null;
  formControl: DeclarationFormControl;
}

export function DeclarationFormType(props: DeclarationFormTypeProps) {
  const user = useAppSelector(AccountContext.getUser);

  const { type, formControl } = props;

  const items = [
    ...(type === 'MA' ? itemsMaladie : []),
    ...(type === 'AT' ? itemsAccident : []),
    ...(type === 'MA' && user?.employee_information?.civility === EmployeeCivility.Female ? itemsFemale : []),
  ];

  return (
    <Box>
      <AppRadioGroup
        items={items}
        value={formControl[0].type}
        onChange={(_, value) => {
          formControl[1]((initialModel) => ({ ...initialModel, type: value }));
        }}
      />

      {type === 'MA' && formControl[0].type === '3' && (
        <InfoBox type="error">
          Note : Cette information est confidentielle. Elle ne sera pas communiquée à votre employeur. Elle pourra être
          communiquée à l’assureur prévoyance de l’entreprise si la responsabilité civile d’un tiers est engagée.
        </InfoBox>
      )}
    </Box>
  );
}

export const itemsMaladie = [
  {
    value: '1',
    label: 'Aucun lien',
  },
  {
    value: '3',
    label: 'Accident de la vie privée impliquant un tiers',
  },
];

export const itemsFemale = [
  {
    value: '5',
    label: 'État pathologique résultant de la grossesse',
  },
];

export const itemsAccident = [
  {
    value: '1',
    label: 'Accident de travail',
  },
  {
    value: '2',
    label: 'Accident de trajet',
  },
  {
    value: '3',
    label: 'Maladie professionnelle',
  },
];
