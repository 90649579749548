import * as React from 'react';
import { Box } from '@mui/material';
import OTPInput from 'react-otp-input';
import { AppInputDense } from '../AppInput';

export interface EmployeeProfilFieldOTPProps {
  value?: string;
  onChange: (value: string) => void;
}

export function EmployeeProfilFieldOTP(props: EmployeeProfilFieldOTPProps) {
  const { ...inputProps } = props;

  return (
    <Box pl={1} pr={1}>
      <OTPInput
        {...inputProps}
        numInputs={4}
        renderSeparator={<Box mr={1} />}
        renderInput={(props) => (
          <AppInputDense inputProps={props} sx={(theme) => ({ maxWidth: theme.spacing(6.75) })} />
        )}
        shouldAutoFocus={true}
        {...{ autocomplete: 'one-time-code' }}
      />
    </Box>
  );
}
