import { ADPExportStatus } from './ADPExportStatus';
import { DocumentOCR } from './DocumentOCR';
import { Notification } from './Notification';
import { EmployeeInformation } from './user.interface';

export const TypeAbsenceLabels = {
  Hospitalisation: 'Hospitalisation',
  ArretDeTravail: 'Arrêt de travail',
  Parentalite: 'Parentalité',
  CongePaternite: 'Congé paternité',
  CongeMaternite: 'Congé maternité',
};

export interface TypeAbsence {
  description: number;
  description_1: number;
  description_1_label: string;
  description_2: number;
  description_2_label: string;
  description_label: string;
  internal_code_absence: string;
  id: string;
}

export enum DocumentStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ANNEXED = 'annexed',
}

export interface DocumentAbsence {
  created_at: string;
  id: string;
  id_absence: string;
  name: string;
  type: string;
  updated_at: string;
  url: string;
  bucket_key: string;
  status:
    | DocumentStatus.DRAFT
    | DocumentStatus.PENDING
    | DocumentStatus.APPROVED
    | DocumentStatus.REJECTED
    | DocumentStatus.ANNEXED;
  validation_source: 'ocr' | 'manual';
  ocr_result?: DocumentOCR | null;
}

export enum AbsenceStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  VALIDATED = 'validated',
  DELETED = 'deleted',
  FINISHED = 'finished',
}

export enum AbsenceContactType {
  Manager = 'manager',
  GestionnaireRh = 'rh',
  ManagerEtGestionnaireRH = 'manager_rh',
}
export const isAbsenceContactType = (str: unknown): str is AbsenceContactType =>
  Object.values(AbsenceContactType).includes(str as AbsenceContactType);

export interface Absence {
  id_absence: string;
  id: string;
  type_absence: TypeAbsence;
  message: string;
  date_start: string;
  date_end: string;
  date_end_final: string | null;
  date_return: string;
  date_end_hospi_theory: string;
  documents: Array<DocumentAbsence>;
  contact_type: AbsenceContactType | null;
  email?: string;
  phone?: string;
  is_verified: boolean;
  is_link_hospitalisation: boolean;
  is_send_email_return_to_manager: boolean;
  is_reduction_time: boolean;
  is_promo_time: boolean;
  is_father: boolean;
  birthday: string;
  employee_information?: EmployeeInformation;
  created_at?: string;
  code_absence?: string;
  status: AbsenceStatus;
  history?: Array<AbsenceHistory>;
  absence_logs?: AbsenceLog[];
  prolongations?: Absence[];
  absence_exports_id?: string | null;
  export_status?: ADPExportStatus | null;
  notifications?: Array<Pick<Notification, 'type_notification' | 'url'>>;
  absence_exports?: {
    id: string;
    time: number;
  } | null;
}
export interface AbsenceLog {
  id: string;
  id_absence: string;
  id_rh_information?: string | null;
  rh_lastname?: string | null;
  rh_firstname?: string | null;
  id_employee_information?: string | null;
  employee_lastname?: string | null;
  employee_firstname?: string | null;
  type_absence?: string | null;
  action?: string | null;
  description?: string | null;
  category: AbsenceLogCategory;
  triggered_by: AbsenceLogTrigger;
  level: AbsenceLogLevel;
  active: boolean;
  created_at?: string;
}

export const AbsenceLogCategory = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Unspecified: 'unspecified',
} as const;
export type AbsenceLogCategory = (typeof AbsenceLogCategory)[keyof typeof AbsenceLogCategory];

export const isAbsenceLogCategory = (status: string): status is AbsenceLogCategory =>
  Object.values(AbsenceLogCategory).includes(status as any);

export const AbsenceLogLevel = {
  Success: 'success',
  Error: 'error',
} as const;
export type AbsenceLogLevel = (typeof AbsenceLogLevel)[keyof typeof AbsenceLogLevel];

export const isAbsenceLogLevel = (status: string): status is AbsenceLogLevel =>
  Object.values(AbsenceLogLevel).includes(status as any);

export const AbsenceLogTrigger = {
  RH: 'rh',
  Employee: 'employee',
  ADP: 'adp',
  Automatic: 'automatic',
} as const;
export type AbsenceLogTrigger = (typeof AbsenceLogTrigger)[keyof typeof AbsenceLogTrigger];

export const isAbsenceLogTrigger = (status: string): status is AbsenceLogTrigger =>
  Object.values(AbsenceLogTrigger).includes(status as any);

export interface AbsenceHistory {
  id: string;
  id_rh_information: string;
  rh_lastname: string;
  rh_firstname: string;
  id_employee_information: string;
  employee_lastname: string;
  employee_firstname: string;
  id_absence: string;
  type_absence: string;
  action: string;
  description: string;
  created_at: string;
}

export const TypeAbsenceDescription = {
  ATMP: {
    MALADIE_PRO: 'Maladie professionnelle',
    ACCIDENT_TRAVAIL: 'Accident de travail',
    ACCIDENT_TRAJET: 'Accident de trajet',
    ACCIDENT_TRAVAIL_MALADIE_PRO: 'Accident du travail/Maladie professionnelle',
  },
  ACCIDENT_IMPLIQUANT_TIERS: 'Accident implicant un tiers',
};
