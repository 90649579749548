import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { InfoBox, KerijBoxType } from '@/components/base';
import { DateStartInput } from '@/features/dashboard-rh/create-absence-rh/components/Date/common';
import { AccountContext, useAppSelector } from '@/store';
import { AbsenceDateRangeInput } from './AbsenceDateRangeInput';
import { AbsenceDateRangeModel, isEmptyDate, isVerifyingDate } from './data';
import { Spacer } from '../Spacer';

export interface AbsenceDateRangeProps {
  dense?: boolean;
  value: AbsenceDateRangeModel;
  error: string | null;
  onChange: (newValue: AbsenceDateRangeModel) => void;
  callback: (model: AbsenceDateRangeModel) => Promise<void>;
  onChangeSubmitStatus?: (canSubmit: boolean) => void;
  additionalFields?: JSX.Element;
}

export function AbsenceDateRange(props: AbsenceDateRangeProps) {
  const { value, error, dense, onChange, onChangeSubmitStatus, callback, additionalFields } = props;
  const user = useAppSelector(AccountContext.getUser);
  const sevenDaysAgo = moment().subtract(7, 'd').format('YYYY-MM-DD');

  const [debounceId, setDebounceId] = useState<NodeJS.Timeout>();
  const [model, setModel] = useState<AbsenceDateRangeModel>(value);

  const [editing, setEditing] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [hasVerifiedOnce, setHasVerifiedOnce] = useState(false);

  const minDateEnd = useMemo(
    () => (model.lastDayWork ? moment(model.lastDayWork).format('YYYY-MM-DD') : sevenDaysAgo),
    [model.lastDayWork, sevenDaysAgo],
  );

  const helperText = useMemo(() => {
    if (isVerifyingDate({ verifying, editing, hasVerifiedOnce })) return 'Vérification en cours';
    if (isEmptyDate(model.dateStart)) return "Renseignez le premier jour de l'arrêt";
    if (isEmptyDate(model.dateEnd)) return "Renseignez la date de fin de l'arrêt";
    return error ?? 'Période valide';
  }, [error, verifying, editing, hasVerifiedOnce, model.dateStart, model.dateEnd]);

  const helperType = useMemo(
    () =>
      isVerifyingDate({ verifying, editing, hasVerifiedOnce }) || error == null
        ? KerijBoxType.Info
        : KerijBoxType.Error,
    [error, verifying, editing, hasVerifiedOnce],
  );

  function onStartChange(newValue: string | undefined) {
    setModel({ ...model, dateStart: newValue });
    onChange({ ...model, dateStart: newValue });
  }

  function onEndChange(newValue: string | undefined) {
    setModel({ ...model, dateEnd: newValue });
    onChange({ ...model, dateEnd: newValue });
  }

  function onLastDayChange(newValue: string | undefined) {
    setModel({ ...model, lastDayWork: newValue, dateStart: newValue });
    onChange({ ...model, lastDayWork: newValue, dateStart: newValue });
  }

  useEffect(() => {
    setEditing(true);
    onChangeSubmitStatus?.(false);

    if (debounceId) clearTimeout(debounceId);
    const id = setTimeout(async () => {
      setHasVerifiedOnce(true);
      setVerifying(true);

      await callback(model);

      setVerifying(false);
      setEditing(false);
      onChangeSubmitStatus?.(true);
    }, 500);

    setDebounceId(id);
  }, [model.dateStart, model.dateEnd]);

  return (
    <>
      <AbsenceDateRangeInput
        dense={dense}
        name="dateDebut"
        label="1er jour de l'arrêt de travail"
        placeholder="Date de début"
        value={model.lastDayWork}
        min={sevenDaysAgo}
        disabled={verifying}
        onChange={onLastDayChange}
      />

      <Spacer spacing={4} />

      {model.lastDayWork != null && user != null && (
        <DateStartInput
          className="col-12 pb-3"
          lastDayWork={model.lastDayWork}
          employeeTarget="self"
          employeeTimePackage={user.employee_information.time_package}
          onChange={onStartChange}
        />
      )}

      <AbsenceDateRangeInput
        dense={dense}
        name="dateFin"
        label="Date de fin de l’arrêt"
        placeholder="Date de fin"
        value={model.dateEnd}
        min={minDateEnd}
        disabled={verifying}
        onChange={onEndChange}
      />

      {additionalFields}

      <Spacer spacing={3} />

      <InfoBox type={helperType}>{helperText}</InfoBox>
    </>
  );
}
