import { ButtonProps } from '@mui/material';

export const ButtonSecondaryStyle: ButtonProps['sx'] = (theme) => {
  return {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.md3.sys.color.outlineVariant,
    boxShadow: theme.shadows[1],
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.md3.sys.color.outlineVariant,
    },
    '&:active': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.md3.sys.color.outlineVariant,
      boxShadow: theme.shadows[0],
    },
  };
};
