import React, { useEffect, useState } from 'react';
import { Spacer } from '@/components/Spacer';
import {
  EmojiTransportationOutlined,
  LocalHospital,
  MasksRounded,
  PregnantWomanOutlined,
  Sick,
  WorkOutline,
} from '@mui/icons-material';
import { SearchField } from '@/components/DashboardRH/shared/components/Form';
import { Box } from '@mui/material';
import { FilterButton } from '@/components/FilterButton';
import { AbsenceSearchFilter } from './data';
import { AbsenceCode, AbsenceCodeLabel } from '@/types/AbsenceCode';
import { CurrentAbsenceSearchBarFilters } from './CurrentAbsenceSearchBarFilters';

export const emptyAbsenceSearchFilter: AbsenceSearchFilter = {
  content: '',
  portfolio: false,
  type: {
    [AbsenceCode.Maladie]: {
      label: AbsenceCodeLabel[AbsenceCode.Maladie],
      icon: <MasksRounded />,
      checked: false,
    },
    [AbsenceCode.Hospitalisation]: {
      label: AbsenceCodeLabel[AbsenceCode.Hospitalisation],
      icon: <LocalHospital />,
      checked: false,
    },
    [AbsenceCode.MaladiePro]: {
      label: AbsenceCodeLabel[AbsenceCode.MaladiePro],
      icon: <Sick />,
      checked: false,
    },
    [AbsenceCode.AccidentTravail]: {
      label: AbsenceCodeLabel[AbsenceCode.AccidentTravail],
      icon: <WorkOutline />,
      checked: false,
    },
    [AbsenceCode.AccidentTrajet]: {
      label: AbsenceCodeLabel[AbsenceCode.AccidentTrajet],
      icon: <EmojiTransportationOutlined />,
      checked: false,
    },
    [AbsenceCode.PathologieGrossesse]: {
      label: AbsenceCodeLabel[AbsenceCode.PathologieGrossesse],
      icon: <PregnantWomanOutlined />,
      checked: false,
    },
  },
};

interface CurrentAbsenceSearchBarProps {
  onSubmit?: (filters: AbsenceSearchFilter) => void;
  onCancel?: () => void;
  onSearchFilterChanged?: (filters: AbsenceSearchFilter) => void;
}

export const CurrentAbsenceSearchBar = ({
  onSubmit,
  onCancel,
  onSearchFilterChanged,
}: CurrentAbsenceSearchBarProps): JSX.Element => {
  const [filters, setFilters] = useState<AbsenceSearchFilter>(emptyAbsenceSearchFilter);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSearchFilterChanged?.(filters);
  }, [filters]);
  return (
    <>
      <Box ml={2.5} mr={2.5} display="flex">
        <Box>
          <SearchField
            placeholder="Par nom, par prénom ou par matricule"
            onChange={(searchString: string) => {
              const newFilters = {
                ...filters,
                content: searchString,
              };
              setFilters(newFilters);
              onSubmit?.(newFilters);
            }}
          />
        </Box>

        <Box>
          <FilterButton
            open={isOpen}
            onClick={() => {
              const initialValue = isOpen;
              setIsOpen(!initialValue);
              if (initialValue) onSubmit?.(filters);
            }}
          />

          <CurrentAbsenceSearchBarFilters
            open={isOpen}
            value={filters}
            onChange={(value) => setFilters(value)}
            onSubmit={(value) => {
              onSubmit?.(value);
              setIsOpen(false);
            }}
            onCancel={() => {
              setFilters(emptyAbsenceSearchFilter);
              onCancel?.();
              setIsOpen(false);
            }}
          />
        </Box>
        <Spacer />
      </Box>
    </>
  );
};
