import React from 'react';
import {
  styled,
  Box,
  Avatar as MUIAvatar,
  Card as MUICard,
  CardActionArea as MUICardActionArea,
  CardContent as MUICardContent,
  Skeleton,
} from '@mui/material';

const componentName = 'KRJCardButtonSkeleton';

export interface CardButtonSkeletonProps {
  avatar?: boolean;
}

export function CardButtonSkeleton(props: CardButtonSkeletonProps) {
  const { avatar } = props;

  return (
    <Card elevation={1} className={componentName}>
      <CardActionArea
        disableRipple
        disableTouchRipple
        sx={(theme) => ({ borderColor: theme.shape.borderColorVariant })}
      >
        <Box display="flex">
          {avatar && (
            <Avatar variant="square">
              <Skeleton variant="rectangular" width={80} height={80} />
            </Avatar>
          )}

          <CardContentContainer>
            <CardContent></CardContent>
          </CardContentContainer>
        </Box>
      </CardActionArea>
    </Card>
  );
}

const Card = styled(MUICard)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.surface,
}));

const CardActionArea = styled(MUICardActionArea)(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const CardContent = styled(MUICardContent)(({ theme }) => ({
  flex: '1 0 auto',
  padding: '0 !important',
}));

const CardContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Avatar = styled(MUIAvatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginRight: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1.25),
  borderBottomRightRadius: theme.spacing(1.25),
  opacity: 0.5,
}));
