// https://material-web.dev/theming/typography/#typeface

export interface Typeface {
  fontFamily?: string;
  letterSpacing?: string;
}

export const typeface = {
  brand: {
    fontFamily: ["'Montserrat'", 'sans-serif'].join(','),
    letterSpacing: '0.00938em',
  },
  plain: {
    fontFamily: ["'Montserrat'", 'sans-serif'].join(','),
    letterSpacing: '0.00938em',
  },
  monospace: {
    fontFamily: ['Courier New', 'monospace'].join(','),
  },
} satisfies Record<string, Typeface>;
