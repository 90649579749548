import { z } from 'zod';
import { ParcoursStateData } from './ParcoursStateData';
import { ParcoursStateStatus } from './ParcoursStateStatus';
import { ParcoursStateOrigin } from './ParcoursStateOrigin';
import { ParcoursActionName } from './ParcoursAction';
import { toZodEnumValues } from './toZodEnumValues';
import { Parcours } from './Parcours';

export const ParcoursState = Object.assign(
  z.object({
    id: z.string().uuid(),
    parcours_id: z.string().uuid(),
    employee_information_id: z.string().uuid(),
    status: z.enum(toZodEnumValues(ParcoursStateStatus)),
    trigger_origin: z.enum(toZodEnumValues(ParcoursStateOrigin)),
    /**
     * IMPORTANT : This property should be never updated after creation !
     */
    trigger_parcours_data: z.object(
      {
        script: z.any({}),
      },
      {
        description: 'A snapshot of the parcours that was triggered',
      },
    ),
    state: ParcoursStateData,
    wait_until: z.string().date().optional(),
    trigger_action_at: z.string({ description: 'Date when the action has to be run (null for now)' }).date(),

    created_at: z.string(),
    updated_at: z.string(),
    employee_information: z.object({ firstname: z.string().optional(), lastname: z.string().optional() }),
  }),
  {
    /**
     * Return true when parcoursState can be run
     *
     * @param parcoursState
     * @param actionName
     * @param timeNow
     */
    shouldActionRun(parcoursState: ParcoursState, actionName: ParcoursActionName, timeNow: number): boolean {
      // eslint-disable-next-line security/detect-object-injection
      const runAt = parcoursState.trigger_action_at;
      if (runAt != null) {
        return timeNow >= Date.parse(runAt);
      }

      return true;
    },
  },
);

export interface ParcoursState extends z.infer<typeof ParcoursState> {
  parcours: Parcours;
}
export interface ParcoursStateCreation extends Omit<ParcoursState, 'id' | 'created_at' | 'updated_at'> {}
