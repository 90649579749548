/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Tooltip, ToggleButton, type ToggleButtonProps, type TooltipProps, styled } from '@mui/material';
import { useSlate } from 'slate-react';
import { Range } from 'slate';

export interface ToolbarButtonProps extends Omit<ToggleButtonProps, 'value'> {
  tooltip?: string;
  TooltipProps?: TooltipProps;
  /**
   * If true, disables the button if there is a text selected on the editor.
   *
   * Disable a button means that the button cannot be clicked.
   *
   * Use either disableOnSelection or disableOnCollapse, but not both.
   */
  disableOn?: 'selection-collapsed' | 'selection-expanded' | 'none';
  icon: React.ReactNode;
}

/**
 * ToolbarButton is the base button for any button on the toolbars.
 * It requires the `type` of action to perform and the format that will be added.
 *
 * It displays a tooltip text on hover. If tooltip text is not passed as a prop it will use the capitalized text of the format
 */
export const ToolbarButton = React.forwardRef(function ToolbarButton(
  { tooltip, TooltipProps, icon, disabled, disableOn, selected, ...otherProps }: ToolbarButtonProps,
  ref,
) {
  const editor = useSlate();

  /**
   * Conditionally disables the button
   */
  const isDisabled = () => {
    return (
      disabled ||
      (editor.selection == null
        ? false
        : (disableOn === 'selection-collapsed' && Range.isCollapsed(editor.selection)) ||
          (disableOn === 'selection-expanded' && Range.isExpanded(editor.selection)))
    );
  };
  const button = (
    /* @ts-ignore */
    <StyledToggleButton aria-label={tooltip} selected={selected} disabled={isDisabled()} {...otherProps} ref={ref}>
      {icon}
    </StyledToggleButton>
  );

  const TooltipElement = styled(Tooltip)(({ theme }) => ({
    color: theme.palette.primaryBackground.dark,
  }));

  return isDisabled() ? (
    button
  ) : (
    <TooltipElement title={tooltip} placement="top" {...TooltipProps}>
      {button}
    </TooltipElement>
  );
});

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(4),
  fontSize: 24,
}));
