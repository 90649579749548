import React from 'react';
import { AppLayout, AppLayoutContent } from '@/components/AppLayout';
import { DeclarationTabContent } from './DeclarationTabContent';
import { PageTab } from '@/components/PageTab';
import { useMUITabController } from '@/hooks/useMUITabController';
import { RDVTabContent } from './RDVTabContent';
import { Feature, useAppFeature, useAppSelector } from '@/store';
import { Box, Skeleton, Typography } from '@mui/material';
import { CardButtonSkeleton } from '@/components/CardButton';
import { Spacer } from '@/components/Spacer';

export const DeclarationPage: React.FC = () => {
  const appFeature = useAppFeature();
  const isInitializedFeatures = useAppSelector(Feature.isInitialized);

  const tabController = useMUITabController({
    initialValue: 'declare' as 'declare' | 'rdv',
    items: [
      { label: 'Déclarer', value: 'declare' },
      ...(appFeature.InterviewManagement ? [{ label: 'Solliciter un RDV', value: 'rdv' }] : []),
    ],
  });

  const { children, ...tabsProps } = tabController.getTabsProps();

  const renderFeatureContent = (publicContent: React.ReactNode) => {
    const hasFeatureDeclaration = appFeature.InterviewManagement;

    if (hasFeatureDeclaration) {
      return (
        <React.Fragment>
          {tabController.control.items.length > 0 ? (
            <PageTab {...tabsProps} items={tabController.control.items} />
          ) : null}
          {
            {
              declare: publicContent,
              rdv: <RDVTabContent />,
            }[tabController.control.value]
          }
        </React.Fragment>
      );
    }

    return publicContent;
  };

  return (
    <AppLayout>
      <AppLayoutContent requireAuthentication contentTitle={'KERij simplifie vos démarches'}>
        {isInitializedFeatures && renderFeatureContent(<DeclarationTabContent />)}
        {!isInitializedFeatures && (
          <Box>
            <Typography height={32} pt={1}>
              <Skeleton width={210} />
            </Typography>
            <Spacer spacing={4} />
            <CardButtonSkeleton avatar />
            <Spacer spacing={3} />
            <CardButtonSkeleton avatar />
            <Spacer spacing={3} />
            <CardButtonSkeleton avatar />
            <Spacer spacing={3} />
          </Box>
        )}
      </AppLayoutContent>
    </AppLayout>
  );
};
