import React, { useCallback } from 'react';
import classNames from 'clsx';
import { Box, BoxProps } from '@mui/material';

const componentName = 'EmployeeStepperContent';

export interface EmployeeStepperContentProps extends Omit<BoxProps, 'children'> {
  value: number;
  steps: Array<React.ReactNode>;
}

export function EmployeeStepperContent(props: EmployeeStepperContentProps) {
  const { value, steps, className, ...boxProps } = props;

  const renderStep = useCallback(() => steps.at(value - 1), [steps, value]);

  return (
    <Box className={classNames(componentName, className)} pb={13} {...boxProps}>
      {renderStep()}
    </Box>
  );
}
