import axios from '@/lib/axios';
import { ParcoursStateActionResult, ParcoursStateStatus } from '@/types';
import { ParcoursState } from '@/types/ParcoursState';

export const parcoursStateFindAll = async (params: parcoursStateFindAll.Parameters) => {
  const response = await axios.get<parcoursStateFindAll.Return>(`/api/rh/parcours_state`, {
    params: { status: [ParcoursStateStatus.Completed], results: [ParcoursStateActionResult.Success], ...params },
  });
  const responseBody = response.data;
  return responseBody.data;
};
export namespace parcoursStateFindAll {
  export interface Parameters {
    page: number;
    per_page: number;
  }

  export type Return = {
    data: Array<ParcoursState>;
    meta: {
      total: number;
    };
  };
}
