import { Editor, Element, Text, Transforms } from 'slate';
import { CustomEditor, CustomElement } from '../type';
import { parseVariable, variableRegex } from './parseVariable';
import { isSameKeys } from './isSameKeys';

export const insertVariableInEditor = (editor: CustomEditor) => {
  const { children } = editor;

  const processNode = (node: CustomElement, path: any) => {
    variableRegex.lastIndex = 0;

    node.children.forEach((child: any, index: any) => {
      variableRegex.lastIndex = 0;

      const previousChild = index > 0 ? node.children[index - 1] : undefined;
      const previousChildText = Text.isText(previousChild) ? previousChild : undefined;
      const nextChild = index < node.children.length - 1 ? node.children[index + 1] : undefined;
      const nextChildText = Text.isText(nextChild) ? nextChild : undefined;

      const canConcat = isSameKeys(previousChildText, nextChildText);

      if (canConcat && previousChildText && nextChildText) {
        const { text, ...type } = child;
        const newNodes = [previousChildText, ...parseVariable(text, type), nextChildText];
        Transforms.removeNodes(editor, { at: [...path, index] });
        Transforms.removeNodes(editor, { at: [...path, index - 1] });
        Transforms.insertNodes(editor, newNodes, { at: [...path, index - 1] });
      } else {
        if (Text.isText(child) && variableRegex.test(child.text)) {
          const { text, ...type } = child;
          const newNodes = parseVariable(text, type);
          Transforms.removeNodes(editor, { at: [...path, index] });
          Transforms.insertNodes(editor, newNodes, { at: [...path, index] });
        } else if (Element.isElement(child)) {
          processNode(child, [...path, index]);
        }
      }
    });
  };

  children.forEach((node, index) => {
    if (Element.isElement(node) && Editor.isBlock(editor, node)) {
      processNode(node, [index]);
    }
  });
};
