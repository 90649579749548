import React, { useEffect } from 'react';
import { useAppSelector } from '@/store';
import { Divider } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { ProfilMobileContent } from './ProfilMobileContent';
import { ProfilDesktopContent } from './ProfilDesktopContent';
import { useAppVariant } from '@/hooks/useAppVariant';
import { ProfilResume } from '@/components/ProfilResume';
import { AppLayoutContent } from '@/components/AppLayout';

export function Profil() {
  const accountContext = useAppSelector((state) => state.accountContext);

  const appVariant = useAppVariant();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <AppLayoutContent
      requireAuthentication
      contentTitle={'Profil'}
      variant="fullWidth"
      contentTitleProps={{ paddingTop: 4, textAlign: 'left' }}
    >
      <ProfilResume user={accountContext.user} />

      <Spacer spacing={appVariant.medium ? 6 : 4} />
      <Divider />
      <Spacer spacing={appVariant.medium ? 6 : 3.5} />

      {appVariant.small ? <ProfilDesktopContent /> : <ProfilMobileContent />}
    </AppLayoutContent>
  );
}
