import '../Admin/Admin.css';
import React, { useState } from 'react';
import axios from '../../../lib/axios';
import '@/components/DashboardRH/DashboardRH.css';
import { useAppSelector } from '@/store';
import { PermissionBox } from '@/components/PermissionBox';
import { Service, ServiceDocumentType } from '@/types';
import { useNavigate } from 'react-router-dom';
import { ADMIN_URL, PRO_URL } from '@/constants/urls.constants';
import { ServiceForm, ServiceFormDocument, ServiceFormValue } from '@/components/ServicesForm';
import { useToast } from '@/components/Toast';
import { RHPage } from '@/components/RHPage';
import { IfPermission } from '@/components/IfPermission';
import { ActionButton } from '@/components/ActionButton';
import { Box, Divider, Grid, Stack } from '@mui/material';

export function AddService() {
  const toast = useToast();
  const navigate = useNavigate();
  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  const [submit, setSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const defineFormData = (value: ServiceFormValue) => {
    const data = value.service;

    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('short_description', data.short_description);

    if (data.email) formData.append('email', data.email);
    if (data.phone) formData.append('phone', data.phone);
    if (data.identifiant) formData.append('identifiant', data.identifiant);
    if (data.password) formData.append('password', data.password);
    if (data.address) formData.append('address', data.address);
    if (data.website) formData.append('website', data.website);
    if (data.provider) formData.append('provider', data.provider);
    if (data.adder) formData.append('adder', data.adder);
    if (data.funding) formData.append('funding', data.funding);
    if (data.promo) formData.append('promo', data.promo);
    if (data.address_complement) formData.append('address_complement', data.address_complement);
    if (data.zipcode) formData.append('zipcode', data.zipcode);
    if (data.city) formData.append('city', data.city);

    data.themes.forEach((themes) => formData.append('themes', themes));

    data.categories.forEach((category) => formData.append('categories', category));
    data.subcategories.forEach((subcategory) => formData.append('subcategories', subcategory));

    data.eligibilities.forEach((eligibility) => formData.append('eligibilities', eligibility));

    return formData;
  };

  const addServiceDocument = async (params: {
    id_service: string;
    id_business: string;
    type: ServiceDocumentType;
    file: File;
    extension?: string;
  }) => {
    const formData = new FormData();
    formData.set('type', params.type);
    formData.set('id_service', params.id_service);
    formData.append('documents', params.file, `${params.type}.${params.extension || 'pdf'}`);
    await axios.post(`/api/rh/admin/add_service_document/${params.id_business}`, formData, {});
  };

  const addDocuments = async (service: Service, documents: ServiceFormDocument | undefined) => {
    try {
      if (documents) {
        if (documents.logo) {
          await addServiceDocument({
            id_service: service.id,
            id_business: service.id_business,
            type: ServiceDocumentType.Logo,
            file: documents.logo,
            extension: documents.logo.name.split('.').pop(),
          });
        }
        if (documents.referential) {
          await addServiceDocument({
            id_service: service.id,
            id_business: service.id_business,
            type: ServiceDocumentType.Referential,
            file: documents.referential,
            extension: 'pdf',
          });
        }
      }
    } catch {
      toast.present({ message: "Une erreur s'est produite lors de l'enregistrement des documents", severity: 'error' });
    }
  };

  const handleSubmit = async (value: ServiceFormValue) => {
    setSubmitting(true);
    const formData = defineFormData(value);

    const idBusiness = listSelectBusiness.some((value: any) => value.isChecked)
      ? listSelectBusiness.filter((value) => value.isChecked).at(0)?.id
      : selectedIdBusiness;

    const result = await axios.post<Service>(`/api/rh/admin/add_service/${idBusiness}`, formData, {});

    if (result && result.status === 200) {
      await addDocuments(result.data, value.documents);
      toast.present({ message: 'Le service a été ajouté', severity: 'success' });
      setSubmitting(false);
      navigate(PRO_URL.PREFIXE_PRO + ADMIN_URL.SERVICES);
    } else {
      setSubmitting(false);
      toast.present({
        message: "Une erreur s'est produite. Rechargez votre navigateur et réessayez",
        severity: 'error',
      });
    }
  };

  return (
    <PermissionBox scope="service-management" action="edit">
      <RHPage
        title="Ajouter un service"
        actions={
          <IfPermission scope="service-management" action="access">
            <ActionButton
              variant={'text'}
              actionName={'cancel'}
              onClick={() => navigate(PRO_URL.PREFIXE_PRO + ADMIN_URL.SERVICES)}
            />
          </IfPermission>
        }
      >
        <ServiceForm onSubmit={handleSubmit} onSubmitted={() => setSubmit(false)} submit={submit} />

        <Divider sx={{ marginTop: 2.5, marginBottom: 1.5 }} />

        <Stack direction="row" spacing={2}>
          <Box flex={1} />
          <Grid container ml="0 !important">
            <Grid item xs textAlign="right">
              <ActionButton
                variant={'text'}
                actionName={'cancel'}
                onClick={() => navigate(PRO_URL.PREFIXE_PRO + ADMIN_URL.SERVICES)}
              />
              <ActionButton actionName="finish" label="Valider" onClick={() => setSubmit(true)} sx={{ ml: 1.5 }} />
            </Grid>
          </Grid>
        </Stack>
      </RHPage>
    </PermissionBox>
  );
}
