import axios from '@/lib/axios';
import { StatisticDashboardKey } from '@/types';

export const statisticGetDashboardURL = async (
  params?: statisticGetDashboardURL.Parameters,
): Promise<statisticGetDashboardURL.Return> => {
  const response = await axios.get<any>(`/api/rh/statistic/dashboard`, { params });
  // TODO: handle JSONAPI error here (using a helper)
  return response.data?.data;
};

export namespace statisticGetDashboardURL {
  export type Parameters = {
    key: StatisticDashboardKey;
  };

  export type Return = {
    id: string;
    type: 'StatisticDashboard';
    attributes: {
      url: string | null;
    };
  };
}
