import { InputLabel, styled } from '@mui/material';

export const AppInputLabel = styled(InputLabel)(({ theme }) => ({
  marginLeft: theme.spacing(0.25),
  fontSize: theme.spacing(1.5),
  fontWeight: 600,
  lineHeight: 1.33,
  transform: 'inherit !important',
  width: '100%',
}));
