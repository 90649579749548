import { AppLayout } from '../components/AppLayout';
import React from 'react';
import DepotDocumentHistorique from '../components/DashboardSalarie/DepotDocumentHistorique';

export const DepotDocumentHistoriquePage: React.FC = () => {
  return (
    <AppLayout>
      <DepotDocumentHistorique />
    </AppLayout>
  );
};
