import React from 'react';
import './AccueilRH.css';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '../../../constants/urls.constants';
import { Card, CardContent } from '@mui/material';
import { IfPermission } from '@/components/IfPermission';
import { ListCardActions } from '../ListCard';
import { ActionButton } from '@/components/ActionButton';
import { StatisticQuery } from '@/query';

const WelcomeStats = () => {
  const navigate = useNavigate();

  const absenceDashboard = StatisticQuery.useAbsence();
  const dashboardAbsenceURL = absenceDashboard.data?.attributes?.url;

  const BtnAllStats = () => {
    return (
      <ActionButton
        color="secondary"
        actionName="next"
        label={'Toutes les statistiques'}
        onClick={() => {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.STATS);
        }}
      />
    );
  };

  const CardStatistique = () => {
    return (
      <Card elevation={3}>
        <CardContent sx={{ p: 0 }}>
          {dashboardAbsenceURL == null ? null : (
            <iframe
              className="metabase-iframe"
              src={dashboardAbsenceURL}
              frameBorder="0"
              style={{
                height: '100%',
                width: '100%',
                minHeight: '48vw',
                border: 0,
                boxShadow: 'none',
              }}
              height="100%"
              width="100%"
            ></iframe>
          )}
        </CardContent>

        <ListCardActions>{<BtnAllStats />}</ListCardActions>
      </Card>
    );
  };

  return (
    <div className="col-12">
      <IfPermission scope="statistic" action="access">
        <CardStatistique />
      </IfPermission>
    </div>
  );
};

export default WelcomeStats;
