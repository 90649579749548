import * as React from 'react';
import classNames from 'clsx';
import { Typography, type TypographyProps } from '@mui/material';
import { Code } from '../Code';
import { translateVariable } from './translation';
import { variableRegex } from '../HTMLEditor/editor/parseVariable';

// Name and options
const componentName = 'VariableTranslate';

// Component properties
export interface VariableTranslateProps extends Omit<TypographyProps, 'fontSize'> {
  fontSize?: number;
  ratio?: number;
}

/**
 * Rend les variables plus jolies dans une string ou un élément React
 * Une variable à le même format que pour le back
 *
 * @param props
 */
export function VariableTranslate(props: VariableTranslateProps) {
  const { className, children, fontSize = 'inherit', ratio, ...otherProps } = props;

  const variableSize = typeof fontSize === 'number' && fontSize > 12 && ratio ? fontSize * ratio : fontSize;

  const parseString = (text: string): React.ReactNode[] => {
    const decodedText = decodeHTML(text);

    variableRegex.lastIndex = 0;
    const parts = decodedText.split(variableRegex);
    return parts.map((part, index) => {
      if (index % 2 !== 1) return part;
      const trimPart = part.trim();

      const translation = translateVariable(trimPart);

      return (
        <Code
          key={`Code__${index}`}
          fontSize={variableSize}
          sx={(theme) => ({
            background: translation.translated ? undefined : theme.md3.sys.color.errorContainer,
            color: translation.translated ? undefined : theme.md3.sys.color.error,
            zIndex: 10_000,
          })}
        >
          {translation.label}
        </Code>
      );
    });
  };

  const parseNode = (node: React.ReactNode): React.ReactNode => {
    if (typeof node === 'string') {
      return parseString(node);
    }

    if (Array.isArray(node)) {
      return node.map((child, index) => <React.Fragment key={index}>{parseNode(child)}</React.Fragment>);
    }

    if (React.isValidElement<{ children?: React.ReactNode }>(node)) {
      const children = node.props.children ? React.Children.map(node.props.children, parseNode) : null;
      return React.cloneElement(node, { children });
    }

    return node;
  };

  const childrenValue = parseNode(children);

  return (
    <Typography component="span" className={classNames(componentName, className)} fontSize={fontSize} {...otherProps}>
      {childrenValue}
    </Typography>
  );
}

const decodeHTML = (text: string): string => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(text, 'text/html').body.textContent;
  return decodedString || text;
};
