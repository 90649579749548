import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, styled } from '@mui/material';
import { ActionButton, ActionButtonProps } from '../ActionButton';
import { useAppVariant } from '@/hooks/useAppVariant';

const componentName = 'EmployeeStepperAction';

export interface EmployeeStepperActionProps extends Omit<BoxProps, 'children'> {
  ActionButtonPreviousProps?: Pick<ActionButtonProps, 'onClick' | 'disabled'> & { hide?: boolean };
  ActionButtonNextProps?: Pick<ActionButtonProps, 'onClick' | 'disabled' | 'label'>;
}

export function EmployeeStepperAction(props: EmployeeStepperActionProps) {
  const { className, ActionButtonPreviousProps, ActionButtonNextProps, ...boxProps } = props;

  const appVariant = useAppVariant();

  return (
    <ActionBox className={classNames(componentName, className)} {...boxProps}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Box pt={4} pb={4} display={'flex'} flexDirection={'row-reverse'} gap={2.5}>
            <ActionButton actionName="next" startIcon={null} {...ActionButtonNextProps} />
            {!ActionButtonPreviousProps?.hide && <ActionButton actionName="previous" {...ActionButtonPreviousProps} />}
          </Box>
        </Box>
      </Box>
    </ActionBox>
  );
}

const ActionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  background: '#fff',
  zIndex: 1000,
  borderTop: '1px solid',
  borderColor: theme.shape.borderColorVariant,
}));
