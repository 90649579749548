import React from 'react';
import { Box, Divider, List, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { useAppVariant } from '@/hooks/useAppVariant';

export interface ProfilFormUISkeletonProps {}

export function ProfilFormUISkeleton() {
  const appVariant = useAppVariant();

  return (
    <Box pl={appVariant.small ? 1 : 3} pr={appVariant.small ? 1 : 3}>
      <List sx={{ bgcolor: 'background.paper', p: 0 }}>
        <ProfilFormUISkeletonItem />
        <Spacer spacing={3} />
        <Divider sx={{ ml: 1, mr: 1 }} />
        <Spacer spacing={3} />
        <ProfilFormUISkeletonItem />
        <Spacer spacing={3} />
        <Divider sx={{ ml: 1, mr: 1 }} />
        <Spacer spacing={3} />
        <ProfilFormUISkeletonItem />
      </List>
    </Box>
  );
}

function ProfilFormUISkeletonItem() {
  return (
    <ListItem sx={(theme) => ({ p: 0, pl: 1, pr: 1, borderRadius: theme.spacing(1.25) })}>
      <ListItemText
        primary={
          <Box display={'flex'} justifyContent={'space-between'} minHeight={20}>
            <Typography fontSize={16} lineHeight={1.4} fontWeight={600}>
              <Skeleton width={122} />
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.4}
              fontWeight={600}
              sx={(theme) => ({
                textDecoration: 'underline',
                color: theme.md3.sys.color.onSurfaceVariant,
              })}
            >
              <Skeleton width={60} />
            </Typography>
          </Box>
        }
        secondary={
          <>
            <Spacer spacing={1} />
            <Typography fontSize={14} lineHeight={1.4} color="primary">
              <Skeleton width={150} />
            </Typography>
          </>
        }
      />
    </ListItem>
  );
}
