import * as React from 'react';
import classNames from 'clsx';
import { Descendant, Editor, Element as SlateElement, Node as SlateNode } from 'slate';
import { ReactEditor, Slate } from 'slate-react';
import { MarkdownShortcuts } from './withShortcut';
import type { EditableProps } from 'slate-react/dist/components/editable';
import { EditorType, useEditor } from './useEditor';
import { useHTMLRender } from './useHTMLRender';
import { MUIEditable } from './MUIEditable';
import { useHTMLSerializer } from './useHTMLSerializer';
import { MUIToolbar } from './MUIToolbar';
import { Paper, styled, ToggleButtonGroup } from '@mui/material';
import { insertVariableInEditor } from './editor/insertVariableInEditor';
import { VariableButton } from './MUIToolbar/VariableButton';
import { AllowedStringVariable } from '../VariableTranslate';

// Name and options
const componentName = 'MarkdownEditor';

// Component properties
export interface HTMLEditorProps extends EditableProps {
  /**
   * Markdown initial content
   */
  initialValue?: string;
  /**
   * Markdown initial content
   */
  onValueChange?: (value: string) => void;
  /**
   * Element to be displayed before editable
   */
  beforeEditable?: React.ReactNode;
  /**
   * Element to be displayed after editable
   */
  afterEditable?: React.ReactNode;
  /**
   * Field type (default: EditorType.Rich)
   */
  type?: EditorType;
}

export function HTMLEditor(props: HTMLEditorProps) {
  const {
    className,
    initialValue: initialValueProps,
    onValueChange,
    beforeEditable,
    afterEditable,
    type,
    ...otherProps
  } = props;
  const markdownSerializer = useHTMLSerializer();
  const markdownRender = useHTMLRender();

  const editor = useEditor(type ?? EditorType.Rich);
  const editorRef = React.useRef<HTMLDivElement>(null);

  const initialValue = React.useMemo(
    () => (initialValueProps == null ? [] : markdownSerializer.parse(initialValueProps)),
    [initialValueProps],
  );

  const handleChange = React.useCallback((value: Descendant[]): void => {
    const isASTChange = editor.operations.some((op) => 'set_selection' !== op.type);
    if (isASTChange) {
      // Save the value to Local Storage.
      const content = markdownSerializer.stringify(value);
      onValueChange?.(content);
    }
  }, []);

  const disablePasteLineBreak = (e: InputEvent) => {
    //  Désactive le copier/coller de retour à la ligne
    const pastedText = e.dataTransfer?.getData('text') || e.data;
    if (pastedText && /\r?\n/.test(pastedText)) {
      const cleanedText = pastedText.replaceAll(/\r?\n/g, ' ');
      e.preventDefault();
      editor.insertText(cleanedText);
    }
  };

  const handleDOMBeforeInput = React.useCallback(
    (e: InputEvent) => {
      if (type === EditorType.Text && (e.inputType === 'insertFromPaste' || e.inputType === 'insertText'))
        return disablePasteLineBreak(e);

      if (type === EditorType.Text && (e.inputType === 'insertParagraph' || e.data === '\n')) return e.preventDefault();

      queueMicrotask(() => {
        const pendingDiffs = ReactEditor.androidPendingDiffs(editor);

        const scheduleFlush = pendingDiffs?.some(({ diff, path }) => {
          if (!diff.text.endsWith(' ')) {
            return false;
          }

          const { text } = SlateNode.leaf(editor, path);
          const beforeText = text.slice(0, diff.start) + diff.text.slice(0, -1);
          if (!(beforeText in MarkdownShortcuts)) {
            return;
          }

          const blockEntry = Editor.above(editor, {
            at: path,
            match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
          });
          if (!blockEntry) {
            return false;
          }

          const [, blockPath] = blockEntry;
          return Editor.isStart(editor, Editor.start(editor, path), blockPath);
        });

        if (scheduleFlush) {
          ReactEditor.androidScheduleFlush(editor);
        }
      });
    },
    [editor],
  );

  React.useEffect(() => insertVariableInEditor(editor), [editor]);

  return (
    <EditorPaper elevation={1}>
      <Slate editor={editor} initialValue={initialValue} onChange={handleChange}>
        {type !== EditorType.Text && <MUIToolbar />}
        {beforeEditable}

        <EditorContainer>
          <MUIEditable
            ref={editorRef}
            onDOMBeforeInput={handleDOMBeforeInput}
            {...markdownRender}
            spellCheck
            autoFocus
            className={classNames(componentName, className)}
            {...otherProps}
          />

          {type === EditorType.Text ? (
            <EditorInnerButton>
              <VariableButton
                tooltip="Personnaliser"
                element={editorRef.current}
                excluded={[AllowedStringVariable.BusinessDisclaimer]}
              />
            </EditorInnerButton>
          ) : null}
        </EditorContainer>

        {afterEditable}
        {props.children}
      </Slate>
    </EditorPaper>
  );
}

const EditorPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  border: '1px solid',
  borderColor: theme.shape.borderColorVariant,
  flexDirection: 'column',
  height: '100%',
}));

const EditorContainer = styled('div')(({ theme }) => ({
  flex: 1,
  position: 'relative',
}));

const EditorInnerButton = styled(ToggleButtonGroup)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0.4),
  right: theme.spacing(0.4),
  zIndex: 10,
  background: '#ffffffcc',
  '& .Mui-selected': {
    backgroundColor: '#ffffffe6 !important',
  },
}));
