import React from 'react';
import ImgServiceKerijblanc from '@/assets/service-logokerij-blanc.jpg';
import { Tab, Tabs, tabsClasses, type TabsProps } from '@mui/material';
import { useInput, type InputProps } from '@/hooks/useInput';

const componentName = 'FilterInput';

export interface FilterInputProps<InputValue>
  extends Omit<TabsProps, keyof InputProps<any, any>>,
    InputProps<Element, InputValue | undefined> {
  items: FilterInputElement<InputValue>[];
}

export interface FilterInputElement<InputValue> {
  value: InputValue;
  title: string;
  icon?: string;
}

export function FilterInput<InputValue extends string | undefined>(props: FilterInputProps<InputValue>) {
  const { items, value, ...otherProps } = props;
  const input = useInput({ componentName, props: { ...otherProps, value: value ?? null } });
  const rootProps = input.useRootProps();
  const inputProps = input.useSlotProps();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore hack undefined value
    <Tabs
      variant="scrollable"
      scrollButtons={true}
      {...inputProps}
      {...rootProps}
      {...otherProps}
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          borderRadius: '10px',
          '&.Mui-disabled': { opacity: 0.5 },
        },
        borderWidth: 0,
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderStyle: 'none',
        borderColor: (theme) => theme.palette.divider,
        ...otherProps.sx,
      }}
    >
      {items.map((item, index) => (
        <Tab
          key={index}
          label={item.title}
          value={item.value ?? null}
          icon={<img src={item.icon || ImgServiceKerijblanc} style={{ height: '1rem' }} />}
          disableRipple
        />
      ))}
    </Tabs>
  );
}
