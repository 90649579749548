import * as React from 'react';
import classNames from 'clsx';
import { PageTitle } from '@/components/PageTitle';
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from '@/components/Button';
import { useController, useForm } from 'react-hook-form';
import { reportSend } from '@/services/report';
import { DefaultLayout } from '@/components/layouts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { InputFile } from '@/components/InputFile';
import { PRO_URL } from '@/constants/urls.constants';

// Name and options
const componentName = 'ReportPage';

// Component properties
export interface ReportPageProps extends React.ComponentProps<'div'> {}

export function ReportPage(props: ReportPageProps) {
  const { register, handleSubmit, setValue, control, formState } = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [submitSuccess, setSubmitSuccess] = React.useState(false);

  const requiredMessage = 'Ce champs est requis';
  const { field: email, fieldState: emailState } = useController({
    name: 'reporterEmail',
    rules: {
      required: requiredMessage,
    },
    control,
  });
  const { field: category, fieldState: categoryState } = useController({
    name: 'category',
    rules: {
      required: requiredMessage,
    },
    control,
  });
  const { field: description, fieldState: descriptionState } = useController({
    name: 'description',
    rules: {
      required: requiredMessage,
    },
    control,
  });
  const { field: attachments, fieldState: attachmentsState } = useController({
    name: 'attachments',
    control,
  });

  function getBackURL() {
    return searchParams.get('origin') === 'pro' ? PRO_URL.PRO : '/';
  }

  return (
    <DefaultLayout className={classNames(componentName, props.className)}>
      <Container fixed>
        {submitSuccess ? (
          <>
            <PageTitle className={classNames(`${componentName}__Title`)} label={'Merci pour votre retour'} />
            <Typography variant="h5">Les équipes KERij vous répondrons par mail dans les plus brefs délais</Typography>
            <Button color="primary" type="button" onClick={() => navigate(getBackURL())} sx={{ mt: 2 }}>
              {"Retour à l'accueil"}
            </Button>
          </>
        ) : (
          <>
            <PageTitle className={classNames(`${componentName}__Title`)} label={'Signaler un problème'} />
            <form
              style={{ width: 'auto' }}
              onSubmit={handleSubmit(async (data) => {
                const formData = {
                  reporterEmail: data['reporterEmail'],
                  category: data['category'] === 'other' ? data['otherCategory'] : data['category'],
                  description: data['description'],
                  attachments: Array.isArray(data['attachments']) ? data['attachments'] : [],
                };
                const response = await reportSend(formData);
                if (response.status === StatusCodes.OK) {
                  setSubmitSuccess(true);
                }
              })}
              noValidate
            >
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={'Votre email'}
                    value={email.value}
                    name={email.name}
                    error={Boolean(emailState.error)}
                    onChange={(event) => {
                      email.onChange(event.target.value);
                    }}
                    required
                    helperText={emailState.error?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={Boolean(categoryState.error)}>
                    <FormLabel>Quelle est la nature du problème rencontré ? *</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      aria-required
                      onChange={(event) => {
                        category.onChange(event.target.value);
                      }}
                      name={category.name}
                      value={category.value ?? null}
                    >
                      {["Bug d'affichage", 'Bug technique', 'Lacune dans les fonctionnalités'].map((choice) => {
                        return (
                          <FormControlLabel
                            key={choice}
                            control={<Radio checked={category.value === choice} />}
                            label={choice}
                            value={choice}
                          />
                        );
                      })}
                      <FormControlLabel
                        control={<Radio checked={category.value === 'other'} />}
                        value="other"
                        label={
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            Autre{' '}
                            <TextField
                              aria-required
                              {...register('otherCategory')}
                              onChange={() => {
                                setValue('category', 'other');
                              }}
                              sx={{ ml: 2 }}
                            />
                          </Box>
                        }
                      />
                      <FormHelperText>{categoryState.error?.message}</FormHelperText>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={Boolean(descriptionState.error)}>
                    <FormLabel>
                      {`Veuillez décrire en détail les problèmes ou les bugs que vous avez rencontrés. Incluez les étapes nécessaires pour reproduire le problème, les messages d'erreur éventuels et tout autre détail pertinent, notamment vos nom et prénom. *`}
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={description.value}
                      name={description.name}
                      onChange={(event) => {
                        description.onChange(event.target.value);
                      }}
                      required
                      error={Boolean(descriptionState.error)}
                      placeholder="Votre description"
                      helperText={descriptionState.error?.message}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <InputFile
                    value={attachments.value ?? []}
                    onChange={(_, files) => {
                      attachments.onChange(files);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button color="primary" type="submit" disabled={formState.isSubmitting}>
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            </form>{' '}
          </>
        )}
      </Container>
    </DefaultLayout>
  );
}
