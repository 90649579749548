import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './loginInformation.css';
import { employeeSendCodeNoAuth } from '../../../../../services/employee';

function LoginInformationUI() {
  // const cookies = Cookies();
  const navigate = useNavigate();
  const state = useLocation();
  const user = state.state.employee;
  useEffect(() => {
    if (!state.state.employee) {
      navigate('/login', { state: { fromPath: '/demarches' } });
    }
  }, []);

  const handleClickSendCode = (event: any) => {
    event.preventDefault();
    employeeSendCodeNoAuth({
      idEmployeeLogin: user.id,
      email: user.email,
      idEmployeeInformation: user.employee_information.id,
      phone: user.phone,
    })
      .then((value) => {
        navigate('/confirmation', {
          state: {
            id: user.id,
            ...user,
            document: { ...state.state.document },
          },
        });
      })
      .catch((err) => {
        navigate('/confirmation', {
          state: {
            id: user.id,
            ...user,
            document: { ...state.state.document },
          },
        });
      });
  };

  const handleClickModifier = (event: any) => {
    event.preventDefault();
    navigate('/modifycontact', {
      state: {
        data: user,
        page: 'LoginInformation',
        document: state.state.document,
      },
    });
  };

  return (
    <div className="LoginInformationUI">
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center col-lg-4">
          {`Vous n'avez pas encore validé l'activation de votre dossier`}
        </h3>

        <div className="mb-5 text-center">
          <div>
            {`Vous devez valider l'activation de votre dossier en
                        renseignant le code transmis par SMS et Email`}
          </div>
          <div className="mb-5">Voici un récapitulatif des informations de votre dossier :</div>

          <div>
            Nom Prénom :{' '}
            <strong>
              {user.employee_information.lastname} {user.employee_information.firstname}
            </strong>
          </div>
          <div>
            Date de naissance : <strong>{user.employee_information.birthday}</strong>
          </div>
          <div>
            Adresse : <strong>{user.employee_information.address}</strong>
          </div>
          <div>
            Localité : <strong>{user.employee_information.locality}</strong>
          </div>
          <div>
            Code postal : <strong>{user.employee_information.zipcode}</strong>
          </div>
          <div>
            Société : <strong>{user.employee_information.business.name}</strong>
          </div>
          <hr className="hr-kerij col-5 mb-3 col-12" />
          <div>
            Téléphone : <strong>{user.phone}</strong>
          </div>
          <div>
            Email : <strong>{user.email}</strong>
          </div>

          <div className="col-12 text-center mt-5">
            <button className="btn col-6 m-2 btn-kerij " onClick={handleClickSendCode}>
              Envoyer un nouveau code
            </button>
            <button className="btn btn-kerij-outline btn-sm col-4 m-2" onClick={handleClickModifier}>
              Modifier Téléphone et Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginInformationUI;
