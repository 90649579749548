import React from 'react';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmployeeContact, EmployeeContactType, EmployeeContactTypeModule } from '@/types/user.interface';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { addEmployeeContact } from '@/services/rh/employees';
import { StatusCodes } from 'http-status-codes';
import { useForm } from 'react-hook-form';
import { ActionButton } from '@/components/ActionButton';
import { AppSelect } from '@/components/AppSelect';
import { AppTextField } from '@/components/AppTextField';

const listSelection = [
  {
    value: '',
    label: 'Choisissez',
  },
  ...Object.values(EmployeeContactType).map((employeeContactType) => ({
    value: employeeContactType,
    label: EmployeeContactTypeModule.format(employeeContactType),
  })),
];

export interface AddContactFormProps extends BoxProps {
  listContact: Array<EmployeeContact>;
  idEmployeeInformation: string;
  idBusiness: string;
  refreshPage?: () => void;
}

export const AddContactForm = ({
  listContact,
  idEmployeeInformation,
  idBusiness,
  refreshPage,
}: AddContactFormProps) => {
  const { formState, register, handleSubmit, reset } = useForm();
  const formMargin = 'dense' as const;
  const [isAddContact, setIsAddContact] = React.useState(false);

  const handleChangeAddContactOption = () => {
    setIsAddContact((isAddContact) => !isAddContact);
    reset();
  };

  const handleFormSubmit = handleSubmit(async (data) => {
    const response = await addEmployeeContact({
      idBusiness,
      id_employee_information: idEmployeeInformation,
      type: data['type'],
      firstname: data['firstName'],
      lastname: data['lastName'],
      email: data['email'],
    });
    if (response.status === StatusCodes.OK) {
      reset();
      refreshPage?.();
    }
  });

  const renderFormError = (fieldName: string) => {
    const value = formState.errors[fieldName]?.message;
    return value == null ? value : String(value);
  };

  if (isAddContact) {
    return (
      <Box component="form" onSubmit={handleFormSubmit} sx={{ width: 'auto' }} noValidate>
        <hr />
        <Grid container>
          <Grid item xs={12}>
            <Typography fontSize={16} fontWeight={600}>
              Nouveau contact
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <AppSelect
              items={listSelection}
              label="Type"
              inputProps={{
                ...register('type', {
                  required: 'Ce champ est requis',
                }),
              }}
              helperText={renderFormError('type')}
              error={Boolean(formState.errors['type'])}
            />
          </Grid>
          <Grid item xs={8} pl={1.5}>
            <AppTextField
              label={'Adresse email'}
              autoComplete="off"
              type="email"
              InputProps={{
                inputProps: {
                  ...register('email', {
                    required: 'Ce champ est requis',
                    validate: {
                      email: (_) => validator.isEmail(_) || 'Ce champ doit être un email valide',
                    },
                  }),
                },
              }}
              error={Boolean(formState.errors['email'])}
              helperText={renderFormError('email')}
            />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              label={'Nom / Organisme'}
              InputProps={{
                inputProps: {
                  ...register('lastName', { required: 'Ce champ est requis' }),
                },
              }}
              error={Boolean(formState.errors['lastName'])}
              helperText={renderFormError('lastName')}
            />
          </Grid>
          <Grid item xs={6} pl={1.5}>
            <AppTextField
              label={'Prénom'}
              InputProps={{
                inputProps: { ...register('firstName', {}) },
              }}
              error={Boolean(formState.errors['firstName'])}
              helperText={renderFormError('firstName')}
            />
          </Grid>
        </Grid>
        <Box className="mt-3 justify-content-end" display={'flex'}>
          <ActionButton actionName="cancel" onClick={handleChangeAddContactOption} sx={{ mr: 1 }} />
          <ActionButton actionName="validate" type="submit" disabled={formState.isSubmitting} />
        </Box>
      </Box>
    );
  }
  return (
    <Box className="mt-3 justify-content-end" display={'flex'}>
      <ActionButton actionName="add" label={'Ajouter un contact'} onClick={handleChangeAddContactOption} />
    </Box>
  );
};
