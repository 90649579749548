/**
 * Résultat d'une action
 */
export const ParcoursStateActionResult = {
  Success: 'success',
  Pending: 'pending',
  /**
   * An error occurred, the process will be retried
   */
  Error: 'error',
  /**
   * The action failed and never will retry
   */
  Failure: 'failure',
} as const;
export type ParcoursStateActionResult = (typeof ParcoursStateActionResult)[keyof typeof ParcoursStateActionResult];
export const isParcoursStateActionResult = (value: unknown): value is ParcoursStateActionResult =>
  value != null && Object.values(ParcoursStateActionResult).includes(value as any);
