import React from 'react';
import { PolicyLayout } from '../../components/AppLayout';
import { datesPolicies } from '../../config/policies.config';

export const PrivacyPolicyKERij: React.FC = () => {
  return (
    <PolicyLayout title={'Politique de confidentialité - Kerij'} date={datesPolicies.privacyPolicy}>
      <ol>
        <li>
          <strong>
            <em>PREAMBULE</em>
          </strong>
          <ol>
            <li className="not-before">
              <p>
                Bienvenue sur notre page consacrée à notre « <strong>Politique de confidentialité</strong> » pour la
                plateforme KERIJ éditée par la société WITHBOT accessible à{' '}
                <a href="http://www.kerij.fr">www.kerij.fr</a> (la « <strong>Plateforme</strong> »).
              </p>
              <p>
                Nous vous remercions d’utiliser notre Plateforme et nos services qui y sont disponibles (les «{' '}
                <strong>Services</strong> »). La protection de la vie personnelle est un point capital pour nous et nous
                prenons la protection de vos données très au sérieux.
              </p>
              <p>Cette page vous permet notamment de savoir :</p>
              <ul>
                <li>quelles données personnelles nous traitons à votre sujet ;</li>
                <li>pourquoi et comment nous les traitons ;</li>
                <li>d’où proviennent les données ;</li>
                <li>qui intervient et ;</li>
                <li>sur quelles bases juridiques nous nous reposons pour le faire.</li>
              </ul>
              <p>
                La société WITHBOT, société par actions simplifiée à associé unique au capital social de 100.000 €,
                immatriculée sous le numéro 880 118 419 au RCS de Paris, dont le siège social est situé au 128, rue de
                la Boétie, 75008 Paris ( « <strong>WITHBOT</strong> », « <strong>nous</strong> », «{' '}
                <strong>notre</strong> » ou « <strong>nos</strong> »), {`s'engage`} à protéger et à respecter la vie
                privée de toute personne dont nous traitons les données personnelles dans le cadre de la fourniture de
                nos Services.
              </p>
              <p>
                WITHBOT est le responsable du traitement des données personnelles dans le cadre des Services (le «{' '}
                <strong>Responsable de traitement</strong> »). Cela signifie que nous décidons de la manière dont nous
                conservons et utilisons les données personnelles vous concernant. Nous sommes tenus, en vertu du RGPD,
                de vous fournir
                {`l'ensemble`} des informations présentes dans la Politique de confidentialité
              </p>
              <p>
                Nous tenons absolument à vous expliquer ici comment nous traitons vos données personnelles et comment
                nous nous y prenons pour respecter votre intégrité conformément au règlement européen nᵒ 2016/679, dit
                règlement général sur la protection des données (« <strong>RGPD</strong> »), à la loi française n°78-17,
                dite loi informatique et libertés (« <strong>LIL</strong> ») et toutes lignes directrices et
                recommandations des organes et autorités compétentes, ensemble (la « <strong>Réglementation</strong> »).
                Nous vous encourageons à lire la présente Politique de confidentialité. Son utilisation devrait vous
                aider à prendre des décisions en toute connaissance de cause.
              </p>
              <p>
                Pour toute question à propos de la présente Politique de confidentialité ou d’ordre général concernant
                vos données personnelles, n’hésitez pas à nous contacter à l’adresse{' '}
                <a href="mailto:privacy@kerij.fr">privacy@kerij.fr</a>.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <em>QUELLES SONT LES PERSONNES CONCERNEES PAR LA PRESENTE POLITIQUE DE CONFIDENTIALITE ?</em>
          </strong>
          <p>
            Les traitements mis en œuvre par WITHBOT à travers la Plateforme concernent les Utilisateurs de la
            Plateforme souhaitant utiliser les Services (les « <strong>Personnes concernées</strong> »).
          </p>
        </li>
        <li>
          <strong>
            <em>COMMENT VOS DONNEES PERSONNELLES SONT-ELLES COLLECTEES ?</em>
          </strong>
          <p>
            Vos données personnelles sont collectées directement auprès de vous (i) par votre employeur dans le cadre du
            suivi de vos arrêts maladie ainsi que (ii) par nous lorsque vous téléversez votre arrêt maladie et/ou vous
            inscrivez sur la Plateforme, dans le cadre de votre compte personnel ou de votre utilisation des Services.
          </p>
          <p>
            Des informations techniques (par exemple, adresse IP, information sur votre navigateur, etc.) sont également
            transmises à WITHBOT, par votre terminal lors de votre utilisation de la Plateforme.
          </p>
        </li>
        <li>
          <strong>
            <em>TYPOLOGIE DE DONNEES PERSONNELLES, FINALITES ET BASES LEGALES</em>
          </strong>
          <p>
            {`Par « Données personnelles », nous entendons toute
                        information sur une personne à partir de laquelle cette
                        personne peut être identifiée de manière directe ou
                        indirecte. Cela n'inclut pas les données pour lesquelles
                        l'identité a été supprimée (données anonymes).`}
          </p>
          <p>Vous trouverez ci-dessous une vue {`d'ensemble`} des :</p>
          <ul>
            <li>type de Données personnelles vous concernant, que nous utilisons et stockons ;</li>
            <li>finalités pour lesquelles ces Données personnelles sont collectées ;</li>
            <li>bases légales sur lesquelles repose les traitements.</li>
          </ul>
          <ol>
            <li>
              <strong>Type de Données personnelles</strong>
              <p>
                WITHBOT collecte vos Données personnelles, notamment lorsque vous téléversez des documents en ce inclus
                un arrêt maladie sur la Plateforme, créez un Compte Utilisateur, utilisez les Services, ou contactez le
                service clientèle.
              </p>
              <p>
                WITHBOT peut également collecter vos Données personnelles lorsque vous utilisez ou consultez la
                Plateforme via des cookies et autres traceurs.
              </p>
              <p>Les Données personnelles traitées par WITHBOT si vous êtes un Utilisateur sont les suivantes :</p>
              <ul>
                <li>état civil ;</li>
                <li>nom, prénom ;</li>
                <li>matricule salarié ;</li>
                <li>date de naissance ;</li>
                <li>courrier électronique personnel ou professionnel ;</li>
                <li>nom de l’entreprise ;</li>
                <li>numéro de téléphone personnel ou professionnel ;</li>
                <li>adresse postale ;</li>
                <li>département de naissance ;</li>
                <li>les dates d’arrêt de travail ;</li>
                <li>
                  toute autre information renseignée par l’Utilisateur à travers son utilisation de la Plateforme et des
                  Services (par exemple, vos rendez-vous pris, vos demandes de livraison, vos envois de messages, les
                  services demandés, etc. ).
                </li>
              </ul>
              <p>
                WITHBOT collecte également des informations concernant votre activité de navigation sur la Plateforme,
                par exemple :
              </p>
              <ul>
                <li>le type de terminal que vous utilisez (smartphone, ordinateur, tablette...) ;</li>
                <li>le système {`d'exploitation`} de votre terminal ;</li>
                <li>votre fournisseur de services internet ;</li>
                <li>le navigateur utilisé ;</li>
                <li>{`l'adresse`} IP de votre terminal.</li>
              </ul>
            </li>
            <li>
              <strong>Finalités et base légale du traitement</strong>
              <p>
                Voici les finalités de traitement pour lesquelles nous collectons vos Données personnelles avec les
                bases légales de traitement associées :
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Finalité</th>
                    <th>Base légale du traitement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Permettre le bon fonctionnement de la Plateforme</td>
                    <td>Notre intérêt légitime : la gestion du service</td>
                  </tr>
                  <tr>
                    <td>Fournir les Services demandés</td>
                    <td>Votre consentement</td>
                  </tr>
                  <tr>
                    <td>
                      Assurer la création, la sécurisation, la personnalisation et la gestion de votre Compte
                      Utilisateur
                    </td>
                    <td>Notre intérêt légitime : la gestion du Compte Utilisateur</td>
                  </tr>
                  <tr>
                    <td>
                      Conserver des Données personnelles requises pour être en mesure de répondre aux obligations
                      légales et gérer les demandes de communication de données des autorités habilitées
                    </td>
                    <td>Répondre à nos obligations légales ou règlementaires</td>
                  </tr>
                  <tr>
                    <td>Assurer le service clientèle en cas de demande</td>
                    <td>Notre intérêt légitime : la gestion du service</td>
                  </tr>
                  <tr>
                    <td>Gestion des plaintes et réclamations</td>
                    <td>Exécution du contrat (CGU)</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Nous ne traiterons pas vos Données personnelles pour l’utilisation des Services sans votre consentement.
                Conformément aux lignes directrices du CEPD, votre consentement sera renouvelé tous les 6 mois.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <strong>SI VOUS OMETTEZ DE FOURNIR DES DONNEES PERSONNELLES</strong>
          <p>
            Si vous choisissez de ne pas fournir les Données personnelles que nous demandons, nous pourrions ne pas être
            en mesure de vous fournir les Services que vous avez demandés ou de remplir les finalités pour lesquelles
            nous avons demandé les Données personnelles.
          </p>
        </li>
        <li>
          <strong>QUELS SONT LES FLUX DE DONNEES PERSONNELLES ?</strong>
          <p>L’accès à vos Données personnelles est strictement limité aux :</p>
          <ul>
            <li>
              employés de WITHBOT habilités, en raison de leurs fonctions, et tenus à une obligation de confidentialité
              ;
            </li>
            <li>
              aux sous-traitants de WITHBOT chargés contractuellement de l’exécution des tâches nécessaires au bon
              fonctionnement de la Plateforme et de ses Services, à savoir l’hébergement de la Plateforme et de vos
              Données personnelles, l’envoi de messages, la prise en charge des visioconférences, etc.
            </li>
          </ul>
          <p>
            Dans le cadre de l’exécution de leurs prestations, les sous-traitants de WITHBOT respectent les dispositions
            de la Réglementation. WITHBOT pourra partager vos Données personnelles avec les autorités judiciaires, les
            autorités administratives indépendantes ou toute autre organisation si la loi le requiert.
          </p>
          <p>
            Les tiers avec lesquels nous partageons vos Données personnelles sont limités (par la loi et par contrat)
            dans leur capacité {`d'utiliser`} vos Données personnelles aux fins spécifiques que nous avons identifiées.
            Nous ferons toujours tout notre possible pour nous assurer que les tiers avec lesquels nous partageons vos
            Données personnelles sont soumis à des obligations de confidentialité et de sécurité conformes à la présente
            Politique de confidentialité et aux lois applicables. Nous ne leur permettons de traiter vos Données
            personnelles {`qu'à`} des fins précises et conformément à nos instructions.
          </p>
          <p>
            L’adresse email et le numéro de téléphone personnels du salarié ne seront pas communiqués à l’entreprise
            sauf accord express du salarié.
          </p>
          <p>
            À {`l'exception`} de ce qui est expressément indiqué ci-dessus, nous ne partagerons, ne vendrons ou ne
            louerons jamais vos Données personnelles à un tiers sans vous en aviser et/ou obtenir votre consentement. Si
            vous nous avez donné votre consentement pour que nous utilisions vos informations {`d'une`} manière
            particulière, mais que vous changez {`d'avis`} par la suite, vous devez nous contacter via privacy@kerij.fr
            et nous cesserons de le faire.
          </p>
        </li>
        <li>
          <strong>TRAITEMENT DES DONNEES HORS DE L’UNION EUROPEENNE</strong>
          <p>
            WITHBOT ne procède à aucun transfert de Données personnelles en dehors de l’EEE, vers des pays n’ayant pas
            fait l’objet d’une décision {`d' adéquation`} par la Commission Européenne au sens de l’article 45 du RGPD,
            ou sans que des clauses contractuelles types de la Commission Européenne aient été conclues.
          </p>
        </li>
        <li>
          <strong>DUREE DE CONSERVATION DE VOS DONNEES PERSONNELLES</strong>
          <p>
            Nous ne conserverons vos Données personnelles que le temps nécessaire à la réalisation des finalités pour
            lesquelles nous les avons recueillies, y compris pour satisfaire à toute exigence légale ou comptable.
          </p>
          <p>
            Pour déterminer la durée de conservation appropriée des Données personnelles, nous tenons compte de la
            quantité, de la nature et de la sensibilité des Données personnelles, du risque potentiel de préjudice
            résultant de {`l'utilisation`} ou de la divulgation non autorisée de vos Données personnelles, des finalités
            pour lesquelles nous traitons vos Données personnelles et de la possibilité {`d'atteindre`} ces finalités
            par {`d'autres`}
            moyens, ainsi que des exigences légales applicables.
          </p>
          <p>
            Pour les Données personnelles relatives aux Utilisateurs de la Plateforme, nous conservons les Données
            personnelles :
          </p>
          <ul>
            <li>
              réclamation, questions, plaintes : 3 ans suivant une réclamation, question ou plainte ayant été clôturée ;
            </li>
            <li>Création de compte : jusqu’à la suppression du compte.</li>
          </ul>
          <p>
            Passé les délais fixés, les données sont supprimées. Elles peuvent être conservées en cas de précontentieux
            et contentieux. Il est rappelé que la suppression ou l’anonymisation sont des opérations irréversibles et
            que WITHBOT n’est plus, par la suite, en mesure de les restaurer.
          </p>
        </li>

        <li>
          <strong>
            <em>DROIT DES PERSONNES CONCERNEES</em>
          </strong>
          <p>
            En tant que Personne concernée, vous possédez différents droits. Ces droits ne sont pas absolus et chacun de
            ces droits est soumis à certaines conditions conformément au RGPD et plus largement à la Réglementation.
          </p>
          <ul>
            <li>
              <strong>le droit {`d'accès`}</strong> - vous avez le droit {`d'obtenir`} de notre part la confirmation que
              vos Données personnelles sont ou non traitées par nous, ainsi que certaines autres informations
              (similaires à celles fournies dans la présente Politique de confidentialité) sur la manière dont elles
              sont utilisées. Vous avez également le droit
              {`d'accéder`} à vos Données personnelles, en demandant une copie des Données personnelles vous concernant.
              Cela vous permet de savoir et de vérifier que nous utilisons vos informations conformément aux lois sur la
              protection des données. Nous pouvons refuser de fournir des informations lorsque cela peut révéler des
              Données personnelles sur une autre personne ou affecter négativement les droits {`d'une`} autre personne.
            </li>
            <li>
              <strong>le droit de rectification</strong> - vous pouvez nous demander de prendre des mesures pour
              corriger vos Données personnelles si elles sont inexactes ou incomplètes (par exemple, si nous avons le
              mauvais nom ou la mauvaise adresse).
            </li>
            <li>
              <strong>le droit à {`l'effacement`}</strong> - aussi connu sous le nom de « droit à {`l'oubli`} », ce
              droit vous permet, en termes simples de demander
              {`l'effacement`} ou la suppression de vos Données personnelles lorsque, par exemple, il {`n'y`} a aucune
              raison impérieuse pour nous de continuer à les utiliser ou leur utilisation est illégale. Il ne
              {`s'agit`} toutefois pas {`d'un`} droit général à{`l'effacement`} et il existe quelques exceptions, par
              exemple lorsque nous devons utiliser les informations pour défendre une action en justice ou pour être en
              mesure de respecter une obligation légale.
            </li>
            <li>
              <strong>le droit de limiter le traitement</strong> - vous avez le droit de « bloquer » ou {`d'empêcher`}
              {`l'utilisation`} ultérieure de vos Données personnelles lorsque nous évaluons une demande de
              rectification ou comme alternative à {`l'effacement`}. Lorsque le traitement est limité, nous pouvons
              toujours conserver vos Données personnelles, mais nous ne pouvons pas les utiliser davantage.
            </li>
            <li>
              <strong>le droit à la portabilité des données</strong> - vous avez le droit {`d'obtenir`} et de réutiliser
              certaines Données personnelles pour vos propres besoins dans différentes sociétés (qui sont des
              responsables de traitement de données distincts). Ceci ne {`s'applique qu'aux`} Données personnelles que
              vous nous avez fournies, que nous traitons avec votre consentement et à des fins {`d'exécution`} du
              contrat, qui sont traitées par des moyens automatisés. Dans ce cas, nous vous fournirons une copie de vos
              données dans un format structuré, couramment utilisé et lisible par une machine ou (lorsque cela est
              techniquement possible) nous pourrons transmettre vos données directement à autre Responsable de
              traitement.
            </li>
            <li>
              <strong>le droit {`d'opposition`}</strong> - vous avez le droit de vous opposer à certains types de
              traitement, pour des raisons liées à votre situation particulière, à tout moment, dans la mesure où ce
              traitement a lieu aux fins {`d'intérêts`} légitimes poursuivis par WITHBOT. Nous serons autorisés à
              continuer à traiter les Données personnelles si nous pouvons démontrer que le traitement est justifié par
              des raisons impérieuses et légitimes qui {`l'emportent`}
              sur vos intérêts, vos droits et libertés ou si nous en avons besoin pour {`l'établissement, l'exercice`}{' '}
              ou la défense {`d'actions`} en justice. Si vous vous opposez au traitement de vos Données personnelles à
              des fins de marketing direct, nous ne traiterons plus vos Données personnelles à de telles fins.
            </li>
            <li>
              <strong>le droit de retirer votre consentement pour l’accès aux services des tiers</strong> - lorsque nous
              traitons vos Données personnelles sur la base de votre consentement, vous avez le droit de retirer votre
              consentement à tout moment. Toutefois, un tel retrait{' '}
              {`n'affecte pas la licéité du
                            traitement qui a eu lieu avant ce retrait.`}
            </li>
            <li>
              <strong>
                {`le droit de nous fournir des directives sur
                                l'utilisation de vos Données personnelles après
                                votre décès`}
              </strong>{' '}
              - En France, vous avez le droit de nous fournir des instructions sur la gestion (par exemple, la
              conservation, {`l'effacement`} et la divulgation) de vos données après votre décès. Vous pouvez modifier
              ou révoquer vos instructions à tout moment.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            <em>EXERCICE DES DROITS</em>
          </strong>
          <p>
            {`Si vous avez des questions au sujet de cette Politique
                        de confidentialité, sur la façon dont nous traitons vos
                        Données personnelles ou si vous souhaitez exercer l'un
                        de vos droits, veuillez contacter notre service dédié à
                        l'adresse suivante:`}{' '}
            <a href="mailto:privacy@kerij.fr">privacy@kerij.fr</a>.
          </p>
          <p>
            Toute demande sera examinée dans les délais prévus par la loi applicable. Veuillez noter, toutefois, que
            certaines Données personnelles peuvent être exemptées de telles demandes dans certaines circonstances,
            notamment si WITHBOT doit continuer à traiter vos Données personnelles pour ses intérêts légitimes ou pour
            se conformer à une obligation légale.
          </p>
          <p>
            L’exercice de votre droit d’accès (ou tout autre droit) ne générera aucun frais. Parfois, nous ne pourrons
            accéder à votre demande si celle-ci est manifestement non fondée ou excessive.
          </p>
          <p>
            {`Si vous n'êtes pas satisfait de notre réponse à votre
                        réclamation ou si vous pensez que le traitement de vos
                        Données personnelles n'est pas conforme aux lois
                        applicables sur la protection des données, vous pouvez
                        déposer une réclamation auprès de l'autorité de contrôle
                        compétente en matière de protection des données. La
                        Commission Informatique et Libertés (« CNIL ») est
                        l'autorité de protection des données chef de file de
                        WITHBOT.`}
          </p>
          <p>
            {`Il se peut que nous ayons besoin de vous demander des
                        informations spécifiques pour nous aider à confirmer
                        votre identité et à garantir votre droit d'accéder à ces
                        informations (ou d'exercer vos autres droits). Il s'agit
                        d'une mesure de sécurité appropriée pour s'assurer que
                        les Données personnelles ne sont pas divulguées à une
                        personne qui n'a pas le droit de les recevoir.`}
          </p>
        </li>
        <li>
          <strong>
            <em>COMMENT LES DONNEES SONT-ELLES SECURISEES ?</em>
          </strong>
          <p>
            WITHBOT s’assure que les Données personnelles sont traitées en toute sécurité et confidentialité, y compris
            lorsque certaines opérations sont réalisées par des sous-traitants. A cet effet, les mesures techniques et
            organisationnelles appropriées pour éviter la perte, la mauvaise utilisation, l’altération et la suppression
            des Données personnelles vous concernant sont mises en place. Ces mesures sont adaptées selon le niveau de
            sensibilité des données traitées et selon le niveau de risque que présente le traitement ou sa mise en
            œuvre. Nous avons mis en place des procédures pour traiter toute atteinte présumée à la sécurité des données
            et nous vous aviserons, ainsi que toute autorité de contrôle compétente, {`d'une`} atteinte présumée lorsque
            nous sommes légalement tenus de le faire.
          </p>
          <p>
            Malheureusement, la sécurité des transmissions de données par Internet ou des systèmes de stockage de
            données ne peut être garantie à 100 %. Si vous avez des raisons de croire que votre interaction avec nous
            n’est plus sûre, veuillez-nous en informer immédiatement en nous contactant aux coordonnées ci-dessus.
          </p>
        </li>
        <li>
          <strong>
            <em>TIERS</em>
          </strong>
          <p>
            La Plateforme peut contenir des liens vers des sites internet (et/ou applications) exploités par des tiers.
            Veuillez noter que cette Politique de confidentialité
            {`s'applique`} uniquement aux Données personnelles collectées par WITHBOT. Nous ne sommes pas responsables
            des Données personnelles que les tiers peuvent collecter, sauvegarder et utiliser sur leurs propres sites
            internet et/ou applications. Nous vous recommandons de lire attentivement la politique de confidentialité de
            chaque site internet que vous visitez et/ou application que vous utilisez.
          </p>
          <p>
            Par ailleurs, WITHBOT n’est pas responsable des liens hypertextes dirigeant vers la Plateforme, que des
            sites internet tiers pourraient intégrer quand bien même WITHBOT aurait autorisé le tiers éditeur dudit site
            tiers à placer un tel lien.
          </p>
        </li>
        <li>
          <strong>
            <em>COORDONNEES</em>
          </strong>
          <p>
            Si vous avez {`d'autres`} questions ou commentaires sur notre Politique de confidentialité, veuillez nous
            contacter à l’adresse : <a href="mailto:privacy@kerij.fr">privacy@kerij.fr</a>.
          </p>
        </li>
        <li>
          <strong>
            <em>MODIFICATION DE LA PRESENTE POLITIQUE DE CONFIDENTIALITE</em>
          </strong>
          <p>
            WITHBOT a la possibilité de modifier cette Politique de confidentialité périodiquement afin de refléter
            l’évolution de nos pratiques en la matière. Lorsque nous modifions la présente Politique de confidentialité,
            nous modifions également la date de « Dernière mise à jour » située en haut de la première page. Nous vous
            invitons à consulter régulièrement la présente Politique de confidentialité pour être informé de la façon
            dont WITHBOT protège vos Données personnelles.
          </p>
        </li>
      </ol>
    </PolicyLayout>
  );
};
