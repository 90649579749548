import { Editor, Element as SlateElement, Range, Transforms, Text } from 'slate';
import { parseVariable, variableRegex } from './editor/parseVariable';

export const withVariables = (editor: Editor) => {
  const { insertData, insertText, deleteBackward } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const current = Editor.nodes(editor, {
        match: (n) => (Text.isText(n) && n.variable) ?? false,
      })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore C'est ok
        ?.toArray()
        .at(0);

      if (current) {
        const [, path] = current;
        const start = Editor.start(editor, path);
        const end = Editor.end(editor, path);

        if (Range.includes(editor.range(start, end), path)) {
          editor.removeNodes({ at: path });
          insertText(text);
          return;
        }
      }

      insertText(text);

      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });

      if (block) {
        const [blockNode, path] = block;

        blockNode.children.forEach((child, index) => {
          if (Text.isText(child)) {
            const { text, ...type } = child;

            if (variableRegex.test(text)) {
              const newNodes = [...parseVariable(text, type)];
              Transforms.removeNodes(editor, { at: [...path, index] });
              Transforms.insertNodes(editor, newNodes, { at: [...path, index] });

              const lastVariableNode = newNodes.find((node) => node.variable);

              if (lastVariableNode) {
                const newSelection = Range.end(editor.range([...path, index + 1]));
                Transforms.select(editor, newSelection);
              }
            }
          }
        });
      }
    }
  };

  editor.deleteBackward = (unit) => {
    deleteBackward(unit);

    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const point = selection.anchor;
      const [node] = Editor.node(editor, point.path);
      if (Text.isText(node) && node.variable) {
        const path = point.path;
        Transforms.removeNodes(editor, { at: path });
      }
    }
  };

  editor.insertData = (data) => {
    insertData(data);
  };

  return editor;
};
