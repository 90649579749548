import * as React from 'react';
import { Box, InputAdornment, Typography } from '@mui/material';
import { AppSelect } from '@/components/AppSelect';
import { AppTextField, AppTextFieldProps } from '@/components/AppTextField';

type InputValue = number;

export interface DurationInputProps extends Pick<AppTextFieldProps, 'label'> {
  unit?: 'days' | 'years';
  value?: InputValue;
  onChange?: (value: InputValue) => void;
}

export function DurationInput(props: DurationInputProps) {
  const { value = 0, unit = 'days', onChange, ...textFieldProps } = props;

  const directionDelayItems = React.useMemo(
    () => [
      { value: 'future', label: 'après la date de référence' },
      { value: 'past', label: 'avant la date de référence' },
    ],
    [],
  );

  const delayDays = React.useMemo(() => Math.abs(value), [value]);
  const delayDirection = React.useMemo(() => (value && value < 0 ? 'past' : 'future'), [value]);

  return (
    <Box display={'flex'}>
      <Box sx={(theme) => ({ width: theme.spacing(14) })}>
        <AppTextField
          value={String(delayDays)}
          onChange={(_, absoluteValue) => {
            const _value = absoluteValue.trim() === '' ? 0 : Number.parseInt(absoluteValue);
            onChange?.(delayDirection === 'future' ? _value : -_value);
          }}
          LabelProps={{ sx: { overflow: 'visible' } }}
          InputProps={{
            type: 'number',
            componentsProps: {
              input: { min: 0, style: { textAlign: 'right' } },
            },
            className: 'align-items-center',
            endAdornment: (
              <InputAdornment position="end">
                <Typography fontSize={12}>{unit === 'days' ? 'jour(s)' : 'année(s)'}</Typography>
              </InputAdornment>
            ),
          }}
          noHelper
          {...textFieldProps}
        />
      </Box>

      <Box flexGrow={1} ml={1.5}>
        <AppSelect
          value={delayDirection}
          items={directionDelayItems}
          disabled={delayDays === 0}
          onChange={(event, direction) => {
            const _value = direction === 'future' ? direction : 'past';
            onChange?.(_value === 'future' ? delayDays : -delayDays);
          }}
          noHelper
        />
      </Box>
    </Box>
  );
}
