import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AppTextField } from '@/components/AppTextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ChangePasswordModalInput = ({ label, name, rules, control, handleChange }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      render={({
        field: { onChange, value, ...rest },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <AppTextField
          label={label}
          error={error?.message != null}
          InputProps={{
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: (theme) => ({ paddingRight: theme.spacing(1), maxWidth: theme.spacing(40) }),
            inputProps: { ...rest },
          }}
          onChange={(_, value) => {
            onChange(value);
            handleChange?.(value);
          }}
          helperText={error?.message}
        />
      )}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export default ChangePasswordModalInput;
