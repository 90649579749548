import * as React from 'react';
import classNames from 'clsx';
import { AppLayoutContent, type AppLayoutContentProps } from '@/components/AppLayout';
import { NavMenu } from '@/components/NavMenu';
import { Gavel, PersonOutline, Cookie, Policy, Contract } from '@/components/AppIcon';

const menuEntries = [
  {
    primary: 'Mentions légales',
    secondary:
      'Consultez nos mentions légales pour tout savoir sur les informations légales liées à l’utilisation de cette application.',
    to: '/mentions-legales-information',
    icon: <Gavel />,
  },
  {
    primary: 'Politique de confidentialité',
    secondary: 'Consultez les informations sur la façon dont vos données sont collectées, utilisées et protégées.',
    to: '/politique-de-confidentialite',
    icon: <Policy />,
  },
  {
    primary: 'Conditions Générales d’Utilisation',
    secondary: 'Prenez connaissance des règles et conditions régissant l’utilisation de nos services.',
    to: '/conditions',
    icon: <Contract />,
  },
  {
    primary: 'Politique de cookies',
    secondary:
      'Découvrez comment nous utilisons les cookies pour améliorer votre expérience et personnaliser nos services.',
    to: '/politique-de-cookies',
    icon: <Cookie />,
  },
  {
    primary: 'Responsable de traitement',
    secondary: 'Découvrez comment vos données personnelles sont gérées et vos droits protégés.',
    to: '/responsable-de-traitement',
    icon: <PersonOutline />,
  },
];

// Name and options
const componentName = 'LegalMentionsContent';

// Component properties
export interface LegalMentionsContentProps extends AppLayoutContentProps {}

export function LegalMentionsContent(props: LegalMentionsContentProps) {
  const { className, ...otherProps } = props;
  return (
    <AppLayoutContent
      className={classNames(componentName, className)}
      contentTitle={'Mentions légales'}
      contentTitleDivider={true}
      contentTitleProps={{ textAlign: 'left' }}
      variant={'fullWidth'}
      {...otherProps}
    >
      <NavMenu items={menuEntries} sx={{ marginTop: 2 }} />
    </AppLayoutContent>
  );
}
