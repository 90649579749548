import React, { useCallback, useMemo } from 'react';
import { NotificationVariant } from './type';
import { Notification, useAppFeature, useAppSelector } from '@/store';
import moment from 'moment';
import type { NotificationFilterType } from '../FilterNotification';
import { NotificationListTitle } from './NotificationListTitle';
import { NotificationListDivider } from './NotificationListDivider';
import { defineNotificationContent } from './defineNotificationContent';
import { NotificationListItemSkeleton } from './NotificationListItemSkeleton';
import { NotificationListSection } from './NotificationListSection';
import { Skeleton, Typography } from '@mui/material';
import { NotificationListContentSection } from './NotificationListContentSection';
import { Notification as NotificationType } from '@/types';

export interface NotificationListContentProps {
  filter: NotificationFilterType;
  variant: NotificationVariant;
  additionalData?: Array<NotificationType>;
  emptyMessage?: string;
  onClickItem?: () => void;
}

export function NotificationListContent(props: NotificationListContentProps) {
  const { filter, variant, additionalData, emptyMessage, onClickItem } = props;

  const feature = useAppFeature();

  const isLoading = useAppSelector(Notification.isLoading);
  const pages = useAppSelector(Notification.getPages);
  const notifications = useAppSelector(Notification.getList);

  const dataToValue = useCallback(
    (value: NotificationType) => ({
      id: value.id,
      content: defineNotificationContent(value, feature),
      date: value.last_reminder,
      hasRead: value.is_checked,
      age: moment().startOf('day').diff(moment(value.last_reminder).startOf('day'), 'days'),
    }),
    [feature],
  );

  const notificationsFilteredByAge = useMemo(
    () =>
      notifications
        .filter((notification) => filter === 'all' || !notification.is_checked)
        .map((notification) => dataToValue(notification)),
    [notifications, filter, dataToValue],
  );

  const additional = useMemo(
    () =>
      (additionalData ?? [])
        .filter((notification) => filter === 'all' || !notification.is_checked)
        .map((notification) => ({
          id: notification.id,
          content: defineNotificationContent(notification, feature),
          date: notification.last_reminder,
          hasRead: notification.is_checked,
          age: moment().startOf('day').diff(moment(notification.last_reminder).startOf('day'), 'days'),
        })),
    [additionalData, filter, feature],
  );

  const notificationsToday = notificationsFilteredByAge.filter((notification) => notification.age < 1);
  const notificationsLastYesterday = notificationsFilteredByAge.filter((notification) => notification.age === 1);
  const notificationsLastWeek = notificationsFilteredByAge.filter(
    (notification) => notification.age < 7 && notification.age > 1,
  );
  const notificationsLastMonth = notificationsFilteredByAge.filter(
    (notification) => notification.age <= 30 && notification.age >= 7,
  );

  const hasNews = [...notificationsFilteredByAge, ...additional].some((value) => !value.hasRead);

  const emptyNotifications = [...notificationsFilteredByAge, ...additional].length === 0;

  return (
    <React.Fragment>
      {(emptyNotifications || (!hasNews && filter === 'news')) && !isLoading && pages > 0 && (
        <NotificationListTitle variant={variant} fontSize={16}>
          {emptyMessage ?? "Vous n'avez aucune notification"}
        </NotificationListTitle>
      )}

      <NotificationListContentSection
        title="Aujourd'hui"
        values={notificationsToday}
        filter={filter}
        variant={variant}
        onClickItem={onClickItem}
      />

      <NotificationListContentSection
        title="Hier"
        values={notificationsLastYesterday}
        filter={filter}
        variant={variant}
        onClickItem={onClickItem}
      />

      <NotificationListContentSection
        title="7 derniers jours"
        values={notificationsLastWeek}
        filter={filter}
        variant={variant}
        onClickItem={onClickItem}
      />

      <NotificationListContentSection
        title="30 derniers jours"
        values={notificationsLastMonth}
        filter={filter}
        variant={variant}
        onClickItem={onClickItem}
      />

      <NotificationListContentSection
        title={hasNews ? 'Il y a plus de 30 jours' : undefined}
        values={additional}
        filter={filter}
        variant={variant}
        onClickItem={onClickItem}
      />

      {pages === 0 && (
        <>
          <NotificationListSection variant={variant} boxProps={{ p: 4 }}>
            <Typography fontSize={24} fontWeight={600} lineHeight={'normal'}>
              <Skeleton width={200} />
            </Typography>
          </NotificationListSection>
          <NotificationListItemSkeleton key={`NotificationItemToday__${1}`} variant={variant} />
          <NotificationListDivider variant={variant} />
          <NotificationListItemSkeleton key={`NotificationItemToday__${1}`} variant={variant} />
        </>
      )}
    </React.Fragment>
  );
}
