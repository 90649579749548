import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { getFullName } from '../../../../shared/utils/getFullName.service';
import { StatusCodes } from 'http-status-codes';
import { EmployeeCivility } from '@/types';

const ReturnInterviewModal = ({
  onRequestClose,
  select,
  selectedReturnInterviews,
  onLoadingChange,
  onSelectChange,
  onUpdate,
}) => {
  const [error, setError] = useState('');
  const [contactLabel, setContactLabel] = useState('');
  const [contactMail, setContactMail] = useState('');
  const [contact, setContact] = useState('0');
  const [addContact, setAddContact] = useState(false);
  const [addBtnContact, setAddBtnContact] = useState(false);

  return (
    <div>
      <div className="container-fluid text-center">
        {!selectedReturnInterviews.absence &&
        select &&
        selectedReturnInterviews.length > 0 &&
        selectedReturnInterviews.rh_notification_histories.at(0).action ===
          "L'organisateur est indisponible pour réaliser cet entretien de retour" ? (
          <h3 className="question-form titre-modal mt-3 p-2">
            {getFullName(selectedReturnInterviews.employee_information)} a sollicité un entretien de retour à l’emploi{' '}
            {selectedReturnInterviews.type_demande_interview === 'present' ? 'en présentiel,' : 'en distanciel,'}{' '}
            {selectedReturnInterviews.person_demande_interview === 'Mon manager'
              ? 'son manager'
              : 'son gestionnaire RH'}{' '}
            n'est pas disponible
          </h3>
        ) : (
          selectedReturnInterviews.type_notification === "Entretien de retour a l'emploi" && (
            <h3 className="question-form titre-modal mt-3 p-2">
              {getFullName(selectedReturnInterviews.employee_information)} a sollicité un entretien de retour à l’emploi{' '}
              {selectedReturnInterviews.type_demande_interview === 'present' ? 'en présentiel' : 'en distanciel'} avec{' '}
              {selectedReturnInterviews.person_demande_interview === 'Mon manager'
                ? 'son manager'
                : 'son gestionnaire RH'}
            </h3>
          )
        )}

        {selectedReturnInterviews.absence && (
          <div>
            <h3 className="question-form titre-modal mt-3 p-2">
              {getFullName(selectedReturnInterviews.employee_information)} doit suivre un entretien de retour à
              l’emploi.
            </h3>
            <p className="card-text text-center mb-2 contacts-libelle">
              {getFullName(selectedReturnInterviews.employee_information)} a repris son poste après{' '}
              {selectedReturnInterviews.absence.type_absence.description_2_label.includes('Accident')
                ? '30 jours ou plus, suite à un accident.'
                : '60 jours ou plus, suite à une maladie.'}{' '}
              {selectedReturnInterviews.employee_information.civility === EmployeeCivility.Male ? 'Il' : 'Elle'}{' '}
              souhaite passer son entretien{' '}
              {selectedReturnInterviews.type_demande_interview === 'present' ? ' en présentiel' : ' en distanciel'} avec{' '}
              {selectedReturnInterviews.person_demande_interview === 'Mon manager'
                ? 'son manager'
                : 'son gestionnaire RH'}
            </p>
          </div>
        )}
        {(selectedReturnInterviews.type_notification === "Entretien de retour a l'emploi" ||
          selectedReturnInterviews.type_notification === 'Planifier entretien de retour') &&
        select &&
        selectedReturnInterviews.length > 0 &&
        selectedReturnInterviews.rh_notification_histories.at(0).action ===
          "L'organisateur est indisponible pour réaliser cet entretien de retour" ? (
          <p className="question-form titre-modal mb-3">Merci de confier cette tâche à son gestionnaire RH</p>
        ) : (
          <p className="question-form titre-modal mb-3">Vous êtes invité à organiser cet entretien</p>
        )}
        <div className="card col-12">
          <div className="text-center contact-header-style p-2">CONTACTS DE L’ÉQUIPE</div>
          <div className="card-body card-style">
            <div className="row col-12">
              <p className="card-text text-end col-2 contacts-libelle"></p>
              <p className="card-text col-5 text-start mail-active"></p>
              <div className="col-2 text-center" style={{ cursor: 'pointer' }}>
                <span className="check-contact"> POUR ACTION</span>
              </div>
              <div className="col-2 text-center" style={{ cursor: 'pointer' }}>
                <span className="check-contact">INFORMATION</span>
              </div>
              <div className=" form-check form-switch col-1 text-end"></div>
            </div>
            {selectedReturnInterviews.employee_information.employee_contacts.length > 0 &&
              selectedReturnInterviews.employee_information.employee_contacts.map((value, index) => {
                return (
                  <div className="row col-12" key={index}>
                    <p className="card-text text-end col-2 contacts-libelle">{value.type}</p>
                    <p className="card-text col-5 text-start mail-active">{value.email}</p>
                    <div
                      className=" form-check form-switch col-2 text-end"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <input
                        className="form-check-input m-0 kerij-check"
                        type="checkbox"
                        id={value.id}
                        style={{
                          cursor: 'pointer',
                        }}
                        onChange={(e) => {
                          onLoadingChange(true);
                          axios
                            .put('/api/rh/update_active_employee_contact', {
                              idContactEmployee: value.id,
                              isActif: !value.is_actif,
                              idEmployeeInformation: value.id_employee_information,
                              idBusiness: selectedReturnInterviews.employee_information.id_business,
                            })
                            .then((value) => {
                              if (value.status === 200) {
                                onUpdate();
                                onLoadingChange(false);
                              }
                            })
                            .catch((error) => {
                              onLoadingChange(false);
                              triggerError(error);
                            });
                        }}
                        checked={value.is_actif}
                      />
                    </div>
                    <div
                      className=" form-check form-switch col-2 text-end"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <input
                        className="form-check-input m-0 kerij-check"
                        type="checkbox"
                        id={value.id}
                        style={{
                          cursor: 'pointer',
                        }}
                        onChange={(e) => {
                          onLoadingChange(true);
                          axios
                            .put('/api/rh/update_info_employee_contact', {
                              idContactEmployee: value.id,
                              isInfo: !value.is_info,
                              idBusiness: selectedReturnInterviews.employee_information.id_business,
                            })
                            .then((value) => {
                              if (value.status === 200) {
                                onUpdate();
                                onLoadingChange(false);
                              }
                            })
                            .catch((error) => {
                              onLoadingChange(false);
                              triggerError(error);
                            });
                        }}
                        checked={value.is_info}
                      />
                    </div>
                    <div className=" form-check form-switch col-1 text-end">
                      <FontAwesomeIcon
                        icon="fa-solid fa-trash"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          onLoadingChange(true);
                          axios
                            .delete(`/api/rh/employee_contact/${value.id}`, {
                              params: {
                                idBusiness: selectedReturnInterviews.employee_information.id_business,
                              },
                            })
                            .then((value) => {
                              if (value.status === 200) {
                                onUpdate();
                                onLoadingChange(false);
                              }
                            });
                        }}
                      />
                    </div>
                  </div>
                );
              })}

            {addContact ? (
              <div className="text-start mb-3 hide-add-contact"></div>
            ) : (
              <div className="text-start mb-3">
                <button type="button" className="btn ajouter-contact" onClick={() => setAddContact(!addContact)}>
                  <FontAwesomeIcon icon="fa-solid fa-plus" /> &nbsp; Ajouter un contact
                </button>
              </div>
            )}

            {addContact && (
              <div className="row col-12 test-center">
                <hr className="col-12"></hr>
                <div className="col-12 row">
                  <div className="col-4 text-start my-2">POSTE</div>
                  <div className="col-8 text-start my-2">ADRESSE E-MAIL</div>
                </div>
                <label className="card-text text-end col-4 contacts-libelle">
                  <select
                    className="form-select"
                    name="addContact"
                    value={contact}
                    onChange={(e) => {
                      setContact(e.target.value);
                      const index = e.nativeEvent.target.selectedIndex;
                      setContactLabel(e.nativeEvent.target[index].text);
                    }}
                    required
                  >
                    <option value="0" disabled>
                      Choisissez
                    </option>
                    {selectedReturnInterviews.employee_information.employee_contacts.filter(
                      (val) => val.type === 'Manager',
                    ).length === 0 && <option value="1">Manager</option>}
                    <option value="2">Gestionnaire RH</option>
                    <option value="3">Gestionnaire Paie</option>
                    <option value="4">Infirmier(e)</option>
                    {selectedReturnInterviews.employee_information.employee_contacts.filter((val) => val.type === 'SST')
                      .length === 0 && <option value="5">SST</option>}
                    <option value="6">DRH</option>
                    <option value="7">Médecin du travail</option>
                    <option value="8">Référence handicap</option>
                    <option value="9">Autre</option>
                  </select>
                </label>
                {/* <p className="card-text text-end col-2 contacts-libelle">Salarié</p> */}
                <input
                  type="text"
                  className="card-text col-8 text-start add-mail"
                  name="contactmail"
                  value={contactMail}
                  onChange={(e) => {
                    setContactMail(e.target.value);
                  }}
                  placeholder="Adresse mail"
                ></input>

                <div className="text-center mt-3">
                  <button
                    type="button"
                    className="btn ajouter-contact-2"
                    onClick={() => {
                      onLoadingChange(true);
                      if (
                        contactMail !== '' &&
                        contact !== '0' &&
                        contactMail
                          .toLowerCase()
                          .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          )
                      ) {
                        axios
                          .post('/api/rh/add_employee_contact', {
                            idEmployeeInformation: selectedReturnInterviews.employee_information.id,
                            type: contactLabel,
                            email: contactMail,
                            idBusiness: selectedReturnInterviews.employee_information.id_business,
                            isActif:
                              selectedReturnInterviews.employee_information.employee_contacts.length === 0
                                ? true
                                : false,
                          })
                          .then((value) => {
                            if (value.status === StatusCodes.OK) {
                              onUpdate();
                              setError('');
                              setAddContact(!addContact);
                              setAddBtnContact(!addBtnContact);
                              setContactMail('');
                              setContact('0');
                              onLoadingChange(false);
                            }
                          })
                          .catch((error) => {
                            onLoadingChange(false);
                            triggerError(error);
                          });
                      } else {
                        onLoadingChange(false);
                        contactMail.length === 0
                          ? setError('Veuillez entrer une adresse mail')
                          : contactLabel.length === 0
                            ? setError('Type de contact non défini')
                            : setError('Adresse mail non valide');
                      }
                    }}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-plus" /> &nbsp; Valider le contact
                  </button>
                  <div className="text-end p-0 m-0">
                    <button className="btn btn-annuler" onClick={() => setAddContact(!addContact)}>
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="text-center mt-3">
        <p style={{ color: '#F70202', marginTop: '10px' }}>{error ? error : ''}</p>
        {selectedReturnInterviews.employee_information.employee_contacts.length === 0 ? (
          ''
        ) : (
          <button
            className="btn btn-kerij-bleu btn-modal col-4"
            onClick={() => {
              onLoadingChange(true);
              if (
                selectedReturnInterviews.employee_information.employee_contacts.length === 0 ||
                selectedReturnInterviews.employee_information.employee_contacts.filter((val) => val.is_actif).length ===
                  0
              ) {
                setError('Vous devez confirmer au moins un contact en "POUR ACTION"');
                return onLoadingChange(false);
              } else if (
                selectedReturnInterviews.person_demande_interview === 'Mon manager' &&
                selectedReturnInterviews.employee_information.employee_contacts.filter(
                  (val) => val.is_actif && val.type === 'Manager',
                ).length === 0
              ) {
                setError('Vous devez confirmer au contact de type "Manager" en "POUR ACTION"');
                return onLoadingChange(false);
              } else if (
                selectedReturnInterviews.person_demande_interview !== 'Mon manager' &&
                selectedReturnInterviews.employee_information.employee_contacts.filter(
                  (val) => val.is_actif && val.type === 'Gestionnaire RH',
                ).length === 0
              ) {
                setError('Vous devez confirmer au contact de type "Gestionnaire RH" en "POUR ACTION"');
                return onLoadingChange(false);
              }
              const employeeInformation = selectedReturnInterviews.employee_information;
              const name =
                employeeInformation.firstname.at(0).toUpperCase() +
                employeeInformation.firstname.slice(1).toLowerCase() +
                ' ' +
                employeeInformation.lastname;
              axios
                .post('/api/rh/notify_manager_interview_return', {
                  idRhNotification: selectedReturnInterviews.id,
                  listEmail: selectedReturnInterviews.employee_information.employee_contacts
                    .filter((val) => val.is_actif)
                    .map((val) => val.email)
                    .join(','),
                  idEmployeeInformation: selectedReturnInterviews.employee_information.id,
                  idBusiness: selectedReturnInterviews.employee_information.id_business,
                  listEmailContact: selectedReturnInterviews.employee_information.employee_contacts
                    .filter((val) => val.is_info)
                    .map((val) => val.email)
                    .join(','),
                  civility: selectedReturnInterviews.employee_information.civility,
                  name: name,
                  type:
                    selectedReturnInterviews.current_level === 3
                      ? 'Planifier entretien de retour'
                      : 'entretien de retour',
                  currentLevel: selectedReturnInterviews.current_level,
                  maxLevel: selectedReturnInterviews.max_level,
                  place: selectedReturnInterviews.type_demande_interview
                    ? selectedReturnInterviews.type_demande_interview === 'present'
                      ? 'en présentiel'
                      : 'en distanciel'
                    : '',
                  type_contact: selectedReturnInterviews.employee_information.employee_contacts
                    .filter((val) => val.is_actif)
                    .map((val) => val.type)
                    .join(','),
                })
                .then((value) => {
                  if (value.status === StatusCodes.OK) {
                    onSelectChange();
                    onUpdate();
                    onRequestClose();
                  }
                })
                .catch((error) => triggerError(error));
            }}
          >
            Envoyer
          </button>
        )}
      </div>
    </div>
  );
};

export default ReturnInterviewModal;
