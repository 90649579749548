import { EmployeeInformation } from '../../types/user.interface';
import React from 'react';
import { CardActions, CardHeader, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { Spacer } from '../Spacer';

interface HtmlNotificationInterface {
  employeeInformation: EmployeeInformation;
  children: React.ReactNode;
  title?: string;
}

export const EmployeeNotificationCard = ({
  employeeInformation,
  children,
  title = 'Kerij Vous accompagne',
}: HtmlNotificationInterface) => {
  return (
    <Card variant="outlined" sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        className={'p-5'}
        sx={(theme) => ({
          color: theme.md3.sys.color.white,
          background: 'linear-gradient(to bottom, rgba(12, 5, 74, 1), rgba(255, 201, 201, 1))', // FIXME
        })}
      ></CardHeader>
      <CardContent className={'text-center'}>
        <Typography variant="h4">{`Bonjour ${employeeInformation.firstname}`}</Typography>
        <Spacer />
        {children}
        <footer>{"L'équipe Kerij"}</footer>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};
