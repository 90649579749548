import React, { useState } from 'react';
import classNames from 'clsx';
import { Box, Collapse, BoxProps } from '@mui/material';
import { ActionButton } from '../ActionButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const componentName = 'CollapseSection';
export type CollapseSectionsValue = string | null;

export interface CollapseSectionProps extends BoxProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

export function CollapseSection(props: CollapseSectionProps) {
  const { className, label, children, ...boxProps } = props;

  const [open, setOpen] = useState(false);

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      <ActionButton
        color="tertiary"
        label={
          <Box fontSize={14} fontWeight={600} pr={1}>
            {label}
          </Box>
        }
        sx={{ pl: 0, pr: 0, ':hover': { background: 'inherit' } }}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        onClick={() => setOpen((isOpen) => !isOpen)}
      />

      <Collapse in={open}>{children}</Collapse>
    </Box>
  );
}
