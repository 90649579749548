import * as React from 'react';
import classNames from 'clsx';
import { styled, Typography, type TypographyProps } from '@mui/material';

// Name and options
const componentName = 'Code';

// Component properties
export interface CodeProps extends TypographyProps {}

/**
 * Display inline code
 *
 * @param props
 */
export function Code(props: CodeProps) {
  const { className, fontSize, ...otherProps } = props;
  return (
    <Root
      component="code"
      variant="code"
      className={classNames(componentName, className)}
      fontSize={fontSize ?? 'inherit'}
      {...otherProps}
    >
      {props.children}
    </Root>
  );
}

const Root = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.primaryContainer,
  color: theme.md3.sys.color.onSurface, // this should be onPrimaryContainer ?
  padding: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.25),
  paddingTop: theme.spacing(0.25),
  borderRadius: theme.spacing(0.25),
  fontWeight: 700,
}));
