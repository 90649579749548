import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImgArret from '@/assets/arret_travail.jpg';
import ImgHospi from '@/assets/hospitalisation.jpg';
import ImgAccident from '@/assets/accident_du_travail.jpg';
import { AccountContext, useAppSelector } from '@/store';
import { DeclarationDesktopWarning } from './DeclarationDesktopWarning';
import { Box, Grid } from '@mui/material';
import { CardButton } from '@/components/CardButton';
import { useAppVariant } from '@/hooks/useAppVariant';

export const DeclarationTabContent = () => {
  const appVariant = useAppVariant();
  const user = useAppSelector(AccountContext.getUser);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [arretTypeDeclaration, setArretTypeDeclaration] = useState<'MA' | 'HO' | 'AT'>();

  const startDeclaration = (type?: 'MA' | 'HO' | 'AT') => {
    const declarationType = type ?? arretTypeDeclaration;
    return navigate(`/declaration-form?step=1&type=${declarationType}`);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} paddingTop={2}>
        {/* ARRET MALADIE */}
        <Grid item xs={12}>
          <CardButton
            AvatarProps={{ src: ImgArret }}
            onClick={() => {
              if (user == null)
                navigate('/login', { replace: true, state: { fromPath: '/declaration-form?step=1&type=MA' } });
              else if (appVariant.small) {
                setArretTypeDeclaration('MA');
                setOpenModal(true);
              } else {
                startDeclaration('MA');
              }
            }}
          >
            Un arrêt maladie
          </CardButton>
        </Grid>

        {/* HOSPITALISATION */}
        <Grid item xs={12}>
          <CardButton
            AvatarProps={{ src: ImgHospi }}
            onClick={() => {
              if (user == null)
                navigate('/login', { replace: true, state: { fromPath: '/declaration-form?step=1&type=HO' } });
              else if (appVariant.small) {
                setArretTypeDeclaration('HO');
                setOpenModal(true);
              } else {
                startDeclaration('HO');
              }
            }}
          >
            Une hospitalisation
          </CardButton>
        </Grid>

        {/* ACCIDENT DE TRAVAIL */}
        <Grid item xs={12}>
          <CardButton
            AvatarProps={{ src: ImgAccident }}
            onClick={() => {
              if (user == null)
                navigate('/login', { replace: true, state: { fromPath: '/declaration-form?step=1&type=MA' } });
              else if (appVariant.small) {
                setArretTypeDeclaration('AT');
                setOpenModal(true);
              } else {
                startDeclaration('AT');
              }
            }}
          >
            Un accident de travail
          </CardButton>
        </Grid>
      </Grid>
      <DeclarationDesktopWarning open={openModal} onClose={() => setOpenModal(false)} onConfirm={startDeclaration} />
    </Box>
  );
};
