import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import LoginInformationUI from './components/LoginInformationUI';

export const LoginInformationPage: React.FC = () => {
  return (
    <AppLayout>
      <LoginInformationUI />
    </AppLayout>
  );
};
