import { fetchRhProfile } from '@/services/rh';
import { AppDispatch, RHAccountContext, RHNavbarContext } from '@/store';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { removeAuthData } from '@/utils/auth.utils';
import { UserTypes } from '@/types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function findRHAccount(dispatch: AppDispatch) {
  const token = localStorage.getItem('access_token_rh');

  if (token) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedToken: any = jwtDecode(token);

      const value = await fetchRhProfile();
      dispatch(RHAccountContext.actions.setRh(value.data.rh));
      dispatch(RHAccountContext.actions.setAdmin(Boolean(decodedToken.isAdmin)));
      dispatch(RHAccountContext.actions.setPermissionScopes(value.data.rh?.permission_scopes ?? {}));
      if (value.data.rh.rh_information.id_business)
        dispatch(RHNavbarContext.actions.setSelectedIdBusiness(value.data.rh.rh_information.id_business));

      if (value.data.rh.rh_information.id_businesses) {
        dispatch(
          RHNavbarContext.actions.setSelectedIdBusiness(
            value.data.rh.rh_information.id_business || JSON.parse(value.data.rh.rh_information.id_businesses).at(0),
          ),
        );
      }

      if (value.data.rh.rh_information.id_businesses) {
        dispatch(
          RHNavbarContext.actions.setSelectedIdBusiness(
            value.data.rh.rh_information.id_business || JSON.parse(value.data.rh.rh_information.id_businesses).at(0),
          ),
        );
      }
      if (
        !value.data.rh.rh_information.id_business &&
        !value.data.rh.rh_information.id_group &&
        value.data.rh.rh_information.id_parent_company &&
        value.data.rh.rh_information.id_businesses
      ) {
        axios
          .get('/api/rh/admin/get_business', {
            params: {
              listIdBusiness: JSON.stringify(JSON.parse(value.data.rh.rh_information.id_businesses)),
            },
          })
          .then((value_) => {
            dispatch(
              RHNavbarContext.actions.setListSelectBusiness(
                value_.data.businesses.map((v: any) => {
                  v.isChecked = true;
                  return v;
                }),
              ),
            );
            dispatch(RHNavbarContext.actions.setListBusiness(value_.data.businesses.map((v: any) => v.id)));
          });
      }

      if (
        !value.data.rh.rh_information.id_business &&
        !value.data.rh.rh_information.id_parent_company &&
        value.data.rh.rh_information.id_group &&
        value.data.rh.rh_information.id_parent_companies
      ) {
        axios
          .get('/api/rh/admin/get_company', {
            params: {
              listIdCompany: JSON.stringify(Object.keys(JSON.parse(value.data.rh.rh_information.id_parent_companies))),
            },
          })
          .then((value_) => {
            dispatch(
              RHNavbarContext.actions.setListSelectCompany(
                value_.data.companies.map((v: any) => {
                  v.isChecked = false;
                  return v;
                }),
              ),
            );
          });
      }

      dispatch(RHAccountContext.actions.setLoading(false));
    } catch (error) {
      removeAuthData(UserTypes.HR);
      throw error;
    }
  } else {
    // FIXME: change this behavior !!
    dispatch(RHAccountContext.actions.reset());
  }
}
