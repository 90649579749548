import * as React from 'react';
import { Box, Collapse } from '@mui/material';
import { ForwardedRef } from 'react';
import { Spacer } from '../Spacer';
import { AppTextFieldProps } from '../AppTextField';
import { AppCollapsibleFieldButton } from './AppCollapsibleFieldButton';
import { AppCollapsibleFieldActions } from './AppCollapsibleFieldActions';
import { AppCollapsibleFieldReadOnlyValue } from './AppCollapsibleFieldReadOnlyValue';
import { AppCollapsibleFieldInput } from './AppCollapsibleFieldInput';

export interface AppCollapsibleFieldProps
  extends Pick<AppTextFieldProps, 'label' | 'value' | 'disabled' | 'readOnly' | 'type'> {
  infoContent?: React.ReactNode;
  onChange?: (value: AppTextFieldProps['value']) => void;
  onStartEdit?: () => void;
  onEndEdit?: () => void;
}

export const AppCollapsibleField = React.forwardRef(function AppCollapsibleField(
  props: AppCollapsibleFieldProps,
  ref: ForwardedRef<Element>,
) {
  const { label, value, infoContent, type, disabled, readOnly, onChange, onStartEdit, onEndEdit } = props;

  const infoContentElement = React.useRef<HTMLDivElement>();
  const [modelValue, setModelValue] = React.useState(value);
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const labelAction = React.useMemo(() => {
    if (readOnly) return openCollapse ? 'Réduire' : 'En savoir plus';
    else return openCollapse ? 'Annuler' : 'Modifier';
  }, [openCollapse, readOnly]);

  const scrollToInput = () => {
    setTimeout(() => infoContentElement.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 200);
  };

  const toggleCollapse = () => {
    setOpenCollapse((current) => {
      if (current) setModelValue(value);
      scrollToInput();
      return !current;
    });
  };

  async function submitField() {
    onChange?.(modelValue);
    setOpenCollapse(false);
  }

  React.useEffect(() => {
    if (disabled) setOpenCollapse(false);
  }, [disabled]);

  React.useEffect(() => {
    openCollapse ? onStartEdit?.() : onEndEdit?.();
  }, [openCollapse]);

  return (
    <Box>
      <AppCollapsibleFieldButton
        label={label}
        value={modelValue}
        action={labelAction}
        hideValue={openCollapse}
        onClick={toggleCollapse}
        disabled={disabled}
      />

      <Collapse in={openCollapse} unmountOnExit>
        <Box ref={infoContentElement} pr={1} pl={1} maxWidth={'80%'}>
          {infoContent}
        </Box>

        <Spacer spacing={readOnly ? 3 : 4} />

        {readOnly && <AppCollapsibleFieldReadOnlyValue>{modelValue}</AppCollapsibleFieldReadOnlyValue>}

        {!readOnly && (
          <>
            <AppCollapsibleFieldInput
              ref={ref}
              InputProps={{ type, style: { margin: 0 } }}
              value={modelValue}
              onChange={(_, newValue) => setModelValue(newValue)}
            />
            <AppCollapsibleFieldActions label={'Valider'} onClick={submitField} />
          </>
        )}
      </Collapse>
    </Box>
  );
});
