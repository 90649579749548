import { createSVGIcon } from './createSVGIcon';
import { ReactComponent } from '../assets/kerij.svg';
import { styled } from '@mui/material';

export const KERij = createSVGIcon(
  styled(ReactComponent)({
    padding: '2px',
  }),
  'KERij',
);
