import * as React from 'react';
import classNames from 'clsx';
import { ENVIRONMENT_NAME, isProduction } from '@/config';
import './BrandIcon.css';
import { AppLogo } from '@/components/AppLogo';
import { Box, type BoxProps } from '@mui/material';

// Name and options
const componentName = 'BrandIcon';

// Component properties
export interface BrandIconProps extends BoxProps {
  noCaption?: boolean;
  dark?: boolean;
}

export function BrandIcon(props: BrandIconProps) {
  const { className, dark, noCaption, children, ...otherProps } = props;
  const environmentName = ENVIRONMENT_NAME;
  return (
    <Box
      className={classNames(
        componentName,
        {
          [`${componentName}--dark`]: dark,
        },
        className,
      )}
      {...otherProps}
    >
      {noCaption || !environmentName || isProduction() ? undefined : (
        <svg
          className={classNames(`${componentName}__Caption`)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 600 100"
        >
          <text x="0" y="50%" textAnchor="start" dominantBaseline="middle">
            {environmentName}
          </text>
        </svg>
      )}
      <AppLogo
        variant={dark ? 'dark' : 'light-alt'}
        className={classNames(`${componentName}__Image`)}
        alt="KERij logo"
      />
    </Box>
  );
}
