/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable xss/no-mixed-html */
import * as React from 'react';
import classNames from 'clsx';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { VariableTranslate } from '../VariableTranslate';
import { Box } from '@mui/material';

// Name and options
const componentName = 'HTMLViewer';

type HTMLContent = string;

// Component properties
export interface HTMLViewerProps extends React.ComponentProps<'div'> {
  before?: React.ReactNode;
  htmlContent?: HTMLContent;
  after?: React.ReactNode;
  fontSize?: number;
}

const replaceVariablesInNode = (node: React.ReactNode, fontSize: number): React.ReactNode => {
  console.log(node);
  if (Array.isArray(node)) return node.map((child, key) => <VariableTranslate key={key}>{child}</VariableTranslate>);

  return <VariableTranslate>{node}</VariableTranslate>;
};

export function HTMLViewer(props: HTMLViewerProps) {
  const { className, children, before, after, htmlContent, fontSize = 14, ...otherProps } = props;

  const safeHTML = React.useMemo(() => (htmlContent == null ? '' : DOMPurify.sanitize(htmlContent)), [htmlContent]);

  const parsedContent = React.useMemo(() => {
    const parsedNodes = parse(safeHTML);
    return replaceVariablesInNode(parsedNodes, fontSize);
  }, [safeHTML, fontSize]);

  return (
    <div className={classNames(componentName, className)} {...otherProps}>
      {before}
      <Box
        sx={(theme) => ({
          '& ul, & ol, & p, & h1, & h2, & h3': { marginBottom: fontSize / 8 },
        })}
      >
        {parsedContent}
      </Box>
      {children}
      {after}
    </div>
  );
}
