import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { pdfjs } from 'react-pdf';
import './services/CrashReporter'; // initialize crash reporter

// eslint-disable-next-line xss/no-mixed-html
const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
root.render(<App />);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
