import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import CheckCodeUI from './components/CheckCodeUI';

export const CheckCodePage: React.FC = () => {
  return (
    <AppLayout>
      <CheckCodeUI />
    </AppLayout>
  );
};
