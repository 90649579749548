import React from 'react';
import { AppLayout } from '@/components/AppLayout';
import { ReportContent } from './ReportContent';

export const Report: React.FC = () => {
  return (
    <AppLayout mobileBackButton>
      <ReportContent />
    </AppLayout>
  );
};
