import React, { useEffect } from 'react';
import { DeclarationFormControl } from './type';
import { Box, Typography } from '@mui/material';
import { ProfilResume } from '@/components/ProfilResume';
import { AccountContext, useAppSelector } from '@/store';
import { Spacer } from '@/components/Spacer';
import { CollapseSection } from '@/components/CollapseSection';
import { itemsAccident, itemsFemale, itemsMaladie } from './DeclarationFormType';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { AbsenceContactType, DocumentStatus } from '@/types';
import { useToast } from '@/components/Toast';
import { getAbsenceCode } from '@/services/employee';
import { StatusCodes } from 'http-status-codes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppVariant } from '@/hooks/useAppVariant';
import { LegalDataControllerText } from '@/components/LegalDataControllerText';

export interface DeclarationFormValidationProps {
  formControl: DeclarationFormControl;
  type: string | null;
  edit?: boolean;
}

export function DeclarationFormValidation(props: DeclarationFormValidationProps) {
  const { formControl, type, edit } = props;

  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useAppSelector(AccountContext.getUser);

  const getTypeLabel = () => {
    if (type === 'MA')
      return [...itemsMaladie, ...itemsFemale].find((value) => value.value === formControl[0].type)?.label;
    if (type === 'AT') return itemsAccident.find((value) => value.value === formControl[0].type)?.label;
  };

  const typeAbsence =
    type === 'HO' ? [{ value: 'Hospitalisation' }] : [{ value: 'Arrêt de travail' }, { value: getTypeLabel() }];

  const period =
    formControl[0].date.date_end == null
      ? [{ value: `À partir du ${moment(formControl[0].date.date_start).format(FormatDate.FRENCH_DATE)}` }]
      : [
          {
            value: `Du ${moment(formControl[0].date.date_start).format(FormatDate.FRENCH_DATE)} au ${moment(formControl[0].date.date_end).format(FormatDate.FRENCH_DATE)}`,
          },
        ];

  const renderDocument = () => {
    if (formControl[0].document.status === DocumentStatus.PENDING) return [{ value: 'Transmis - À valider RH' }];
    if (formControl[0].document.status === DocumentStatus.APPROVED) return [{ value: 'Transmis - Validé' }];
    return [{ value: 'Non transmis', error: true }];
  };

  const contactDetail =
    formControl[0].contact.type === AbsenceContactType.Manager
      ? "Vous acceptez d'être contacté par votre manager"
      : formControl[0].contact.type === AbsenceContactType.GestionnaireRh
        ? "Vous acceptez d'être contacté par votre partenaire RH"
        : "Vous acceptez d'être contacté";

  const contact = formControl[0].contact.permission?.canContact
    ? [{ value: contactDetail }]
    : [{ value: "Vous refusez d'être contacté" }];

  const message = formControl[0].message.enable
    ? [{ value: 'Vous avez écrit un message à votre manager' }]
    : [{ value: "Vous n'avez pas écrit de message à votre manager" }];

  const fetchAbsenceCode = async () => {
    const description = type === 'HO' ? '2' : '1';

    if (typeof description === 'string') {
      const { data, status } = await getAbsenceCode({
        description: description,
        description1: type === 'HO' ? undefined : type === 'MA' ? '1' : '2',
        description2: formControl[0].type ?? undefined,
      });

      if (status === StatusCodes.OK)
        formControl[1]((model) => ({ ...model, validation: { ...model.validation, code: data.code } }));
      else
        return toast.present({
          message: "Une erreur est survenue lors de la récupération du code d'absence",
          severity: 'error',
        });
    } else {
      return toast.present({
        message: "Impossible de récupérer le type d'absence.\n Veuillez nous excusez.",
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    const isInvalidFile = formControl[0].document.status == null || formControl[0].document.no_file;
    const emptyAbsence = searchParams.get('edit') == null;
    if (edit === true && emptyAbsence) {
      toast.present({ message: "Impossible de récupérer l'absence", severity: 'error' });
      return navigate(`/demarches`, { replace: true });
    } else if (edit === true && isInvalidFile) {
      searchParams.set('step', '1');
      toast.present({ message: 'Impossible de récupérer le document', severity: 'error' });
      return navigate(`/declaration-form?${searchParams.toString()}`, { replace: true });
    }
    fetchAbsenceCode();
  }, []);

  return (
    <Box>
      <Spacer spacing={2} />
      <ProfilResume user={user} />

      <Spacer spacing={4.5} />

      <CollapseSection label="Responsable de traitement">
        <Spacer spacing={1.5} />
        <Typography fontSize={16} lineHeight={1.5} sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}>
          <LegalDataControllerText />
        </Typography>
        <Spacer spacing={1.5} />
      </CollapseSection>

      <DeclarationFormValidationSection primary="Détails de la déclaration" values={typeAbsence} />
      <DeclarationFormValidationSection primary="Période" values={period} />
      <DeclarationFormValidationSection primary="Justificatif" values={renderDocument()} />
      <DeclarationFormValidationSection primary="Lien avec l’entreprise" values={[...contact, ...message]} />

      {message.length > 0 && formControl[0].message.enable && (
        <Typography
          fontSize={14}
          lineHeight={1.4}
          pt={1}
          pb={1}
          sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}
        >
          “{formControl[0].message.content}”
        </Typography>
      )}
    </Box>
  );
}

interface DeclarationFormValidationSectionProps {
  primary: React.ReactNode;
  values: Array<{ value: React.ReactNode; error?: boolean }>;
}

const DeclarationFormValidationSection = (props: DeclarationFormValidationSectionProps) => {
  const { primary, values } = props;

  const appVariant = useAppVariant();

  return (
    <Box>
      <Typography fontSize={appVariant.medium ? 24 : 20} fontWeight={600} lineHeight={'normal'} pt={4} pb={4}>
        {primary}
      </Typography>

      {values.map((item, index) => (
        <Box key={index}>
          <Typography
            fontSize={16}
            lineHeight={1.5}
            pt={appVariant.medium ? 2 : 1}
            pb={appVariant.medium ? 2 : 1}
            color={item.error ? 'error' : undefined}
          >
            {item.value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
