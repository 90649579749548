import React from 'react';
import { AppRadioGroup } from '@/components/AppRadioGroup';
import { DeclarationFormControl } from './type';

export interface DeclarationFormProlongationProps {
  formControl: DeclarationFormControl;
}

export function DeclarationFormProlongation(props: DeclarationFormProlongationProps) {
  const { formControl } = props;

  return (
    <AppRadioGroup
      items={[
        { value: '1', label: 'Un arrêt initial' },
        { value: '2', label: 'Une prolongation' },
      ]}
      value={formControl[0].prolongation}
      onChange={(_, value) => {
        formControl[1]((initialModel) => ({ ...initialModel, prolongation: value }));
      }}
    />
  );
}
