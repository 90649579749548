import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './changePassword.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { employeeChangePassword, employeeVerifyPassword, fetchEmployeeProfile } from '../../../../../services/employee';
import { AccountContext, useAppDispatch } from '@/store';
import { ActionButton } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function ChangePasswordUI() {
  const dispatch = useAppDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const state = useLocation();
  const [error, setError] = useState<string>('');

  const handleCheckPassword = async () => {
    if (oldPassword) {
      const res = await employeeVerifyPassword({
        password: oldPassword,
      });
      return !!res?.data?.isValid;
    } else {
      return false;
    }
  };

  const handleClick = async (event: any) => {
    event.preventDefault();
    const valid = await handleCheckPassword();
    try {
      if (valid) {
        if (newPassword !== confirmPassword) {
          setError('Attention, les mots de passe ne correspondent pas.');
          return;
        }
        employeeChangePassword({
          password: newPassword,
        }).then(async () => {
          await fetchEmployeeProfile().then((value) => {
            dispatch(AccountContext.actions.setUser(value.data));
            navigate('/profilvalidation', {
              state: {
                document: state.state ? state.state.document : null,
              },
            });
          });
        });
      } else {
        setMessage('Le mot de passe est erroné');
      }
    } catch {
      setError('Erreur');
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <Spacer size="large" />

        <form onSubmit={handleClick} style={{ padding: 0, width: 350, textAlign: 'center' }}>
          <h3 className="question-form mb-5">Changement du mot de passe</h3>

          <AppTextField
            label={'Mot de passe actuel'}
            type={'password'}
            value={oldPassword}
            InputProps={{
              type: showOldPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: (theme) => ({ paddingRight: theme.spacing(1) }),
            }}
            onChange={(_, value) => {
              message && setMessage('');
              setOldPassword(value);
            }}
            error={message != null && message.trim() != ''}
            helperText={message}
          />

          <Spacer />

          <AppTextField
            label={'Nouveau mot de passe'}
            type={'password'}
            value={newPassword}
            InputProps={{
              type: showNewPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: (theme) => ({ paddingRight: theme.spacing(1) }),
            }}
            onChange={(_, value) => {
              error && setError('');
              setNewPassword(value);
            }}
            error={error != null && error.trim() != ''}
            helperText={error}
          />

          <div className="msg-pass">
            <em>
              Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins une
              lettre minuscule, et une valeur numérique.
            </em>
          </div>

          <AppTextField
            label={'Confirmation du mot de passe'}
            type={'password'}
            value={confirmPassword}
            InputProps={{
              type: showConfirmPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: (theme) => ({ paddingRight: theme.spacing(1) }),
            }}
            onChange={(_, value) => {
              error && setError('');
              setConfirmPassword(value);
            }}
            error={error != null && error.trim() != ''}
            helperText={error}
          />

          <Spacer />
          <ActionButton color="primary" type="submit" actionName="validate" startIcon={null} fullWidth />
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordUI;
