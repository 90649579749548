import React from 'react';
import { ParcoursPublic } from '@/types';
import { ActionButton } from '@/components/ActionButton';
import { Box, Tooltip, Typography } from '@mui/material';
import { CardButton } from '@/components/CardButton';
import { declarationServices, rdvManagerial, rdvVisiteMedicale, rdvVisitePreReprise } from '@/components/AppPicto';

export interface RDVItemProps {
  value: ParcoursPublic;
  onTrigger?: () => void;
}

export function RDVItem(props: RDVItemProps) {
  const { value, onTrigger } = props;

  const renderAction = () => {
    return (
      <Box mt="auto" pt={1.5} pb={2}>
        <ActionButton
          actionName="next"
          label="Je fais une demande"
          onClick={onTrigger}
          disabled={value.parcours_id == null}
        />
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Box>
        {value.description.map((line, key) => (
          <Typography
            key={key}
            fontSize={14}
            lineHeight={1.4}
            mt={2}
            pb={0.5}
            sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}
          >
            {line}
          </Typography>
        ))}

        {value.parcours_id == null && (
          <Tooltip
            title={"Cet entretien n'a pas encore été paramétré par votre entreprise"}
            sx={{ position: 'relative' }}
          >
            {renderAction()}
          </Tooltip>
        )}

        {value.parcours_id != null && renderAction()}
      </Box>
    );
  };

  return (
    <CardButton AvatarProps={{ src: renderParcoursIcon(value.code) }} content={renderContent()}>
      {value.title}
    </CardButton>
  );
}

const renderParcoursIcon = (code: ParcoursPublic['code']) => {
  switch (code) {
    case 'demande_pour_un_entretien_de_liaison_a_linitiative_du_salarie': {
      return declarationServices;
    }
    case 'demande_pour_un_entretien_de_retour_a_lemploi_a_linitiative_du_salarie': {
      return rdvManagerial;
    }
    case 'demande_de_visite_medicale_a_linitiative_du_salarie': {
      return rdvVisiteMedicale;
    }
    case 'demande_pour_une_visite_medicale_de_prereprise_a_linitiative_du_salarie': {
      return rdvVisitePreReprise;
    }
    default: {
      return declarationServices;
    }
  }
};
