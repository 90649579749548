import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps } from '@mui/material';
import { DocumentAbsence } from '@/types';
import { DescriptionOutlined } from '@mui/icons-material';
import { ActionButton } from '../ActionButton';
import { Spacer } from '../Spacer';

const componentName = 'EmployeeAbsenceCardDocument';

export interface EmployeeAbsenceCardDocumentProps extends Omit<BoxProps, 'children'> {
  value: DocumentAbsence;
}

export function EmployeeAbsenceCardDocument(props: EmployeeAbsenceCardDocumentProps) {
  const { value, className, ...boxProps } = props;

  const onOpenDocument = () => {
    window.open(value.url, '_blank');
  };

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      <Spacer spacing={2.5} />

      <ActionButton
        fullWidth
        variant="text"
        label={
          <Box fontSize={12} lineHeight={'normal'} fontWeight={600} textAlign={'left'}>
            Voir mon justificatif d’absence
          </Box>
        }
        startIcon={<DescriptionOutlined sx={{ height: 16, width: 'auto', mr: 0.5 }} />}
        color="tertiary"
        sx={(theme) => ({
          textAlign: 'left',
          width: `calc(100% + ${theme.spacing(2)})`,
          position: 'relative',
          right: theme.spacing(1),
        })}
        onClick={onOpenDocument}
      />
    </Box>
  );
}
