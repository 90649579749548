import React from 'react';
import { AppWorkspace, useAppWorkspace } from '@/hooks/useAppWorkspace';

export const ACCESS_TOKEN_EMPLOYEE = 'access_token_employee';

export const ACCESS_TOKEN_HR = 'access_token_rh';

export type UseAuthentication = (
  | {
      isAuthenticated: true;
      token: string;
    }
  | {
      isAuthenticated: false;
      token: null;
    }
) & {
  /**
   * Set a new persistent token value for workspace
   * @param token
   */
  setToken(token: string): void;
  /**
   * Clear token value (and all related auth values)
   */
  clear(): void;
};

/**
 * Returns an auth controller
 *
 * @param workspace
 * @returns
 */
export function useAuthentication(workspace?: AppWorkspace): UseAuthentication {
  const defaultAppWorkspace = useAppWorkspace();

  const appWorkspace = workspace ?? defaultAppWorkspace;
  const storageKey = appWorkspace.match('employee') ? ACCESS_TOKEN_EMPLOYEE : ACCESS_TOKEN_HR;
  const storageValue = () => localStorage.getItem(storageKey) ?? null;
  const [token, _setToken] = React.useState<string | null>(storageValue() ?? null);

  const setToken = (value: string) => {
    localStorage.setItem(storageKey, value);
    window.dispatchEvent(new Event('tokenChange'));
  };
  const clear = () => {
    localStorage.removeItem(storageKey);
    window.dispatchEvent(new Event('tokenChange'));
  };

  React.useEffect(() => {
    const handleStorageEvent = () => {
      window.dispatchEvent(new Event('tokenChange'));
    };
    const handleTokenChange = () => {
      _setToken(storageValue());
    };

    globalThis.addEventListener('storage', handleStorageEvent);
    globalThis.addEventListener('tokenChange', handleTokenChange);
    return () => {
      globalThis.removeEventListener('storage', handleStorageEvent);
      globalThis.removeEventListener('tokenChange', handleTokenChange);
    };
  }, []);

  return token == null
    ? {
        isAuthenticated: false,
        token,
        setToken,
        clear,
      }
    : {
        isAuthenticated: true,
        token,
        setToken,
        clear,
      };
}
