import { Signup } from '@/components/Signup/Signup';
import { AppLayout } from '../../components/AppLayout';
import React from 'react';

export const SignupPage: React.FC = () => {
  return (
    <AppLayout navbar={false}>
      <Signup />
    </AppLayout>
  );
};
