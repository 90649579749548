import { useEffect, useState } from 'react';
import { EmployeeContact, EmployeeContactType, EmployeeInformation } from '../../../types/user.interface';
import { EmployeeContactListModal } from '../EmployeeContactListModal';
import React from 'react';
import { IconButton, IconButtonProps } from '@/components/IconButton';
import { Group } from '@/components/AppIcon';

interface ContactsIconProperties {
  employeeInformation: EmployeeInformation;
  size?: IconButtonProps['size'];
  noPadding?: boolean;
}

const ContactsIcon = ({ employeeInformation, size, noPadding }: ContactsIconProperties) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [employee, setEmployee] = useState(employeeInformation);
  useEffect(() => {
    setEmployee(employeeInformation);
  }, [employeeInformation]);

  const manager = employee?.employee_contacts?.find(
    (contact: EmployeeContact) => contact.type === EmployeeContactType.Manager1,
  );
  return (
    <>
      <IconButton
        size={size}
        onClick={() => {
          setModalIsOpen(true);
        }}
        badge={!manager}
        BadgeProps={{
          color: 'error',
        }}
        TooltipProps={{
          title: manager ? 'Gestion des contacts' : "L'employé n'a pas de manageur renseigné",
        }}
        icon={<Group sx={{ color: 'white' }} />}
        sx={{ mr: noPadding ? 0 : 1 }}
      />
      {modalIsOpen && (
        <EmployeeContactListModal
          open={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
          idEmployeeInformation={employee.id}
          idBusiness={employee?.business?.id}
        />
      )}
    </>
  );
};

export default ContactsIcon;
