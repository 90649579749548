import React from 'react';
import { Navbar, NavbarMobile } from '@/components/Navbar';
import './PolicyLayout.scss';
import { useAppVariant } from '@/hooks/useAppVariant';
import { AppLayoutMobileBack } from './AppLayoutMobileBack';

export interface PolicyLayoutProps extends React.ComponentProps<'div'> {
  title: string;
  date: string;
  withNavigation?: boolean;
}

/**
 * @deprecated use AppLayout
 */
export const PolicyLayout = ({ children, title, date, withNavigation = true }: PolicyLayoutProps) => {
  const appVariant = useAppVariant();
  return (
    <>
      {withNavigation && <Navbar />}
      {!appVariant.medium && <AppLayoutMobileBack />}
      <div className="policy-layout">
        <div className="policy-layout-header">
          <h1>{title}</h1>
          <h4>DERNIERE MISE A JOUR : {date}</h4>
        </div>
        <div className="policy-layout-content">{children}</div>
      </div>
      {withNavigation && <NavbarMobile />}
    </>
  );
};
