import axios from '@/lib/axios';
import { Parcours } from '@/types/Parcours';

export const parcoursFindOneById = async (id: parcoursFindOneById.Parameters): Promise<parcoursFindOneById.Return> => {
  const { data } = await axios.get<Parcours>(`/api/rh/parcours/${id}`);
  return data;
};

export namespace parcoursFindOneById {
  export type Parameters = Parcours['id'];
  export type Return = Parcours;
}
