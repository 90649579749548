import React from 'react';
import { AppLayout } from '@/components/AppLayout';
import { LegalMentionsContent } from './LegalMentionsContent';

export const LegalMentions: React.FC = () => {
  return (
    <AppLayout mobileBackButton>
      <LegalMentionsContent />
    </AppLayout>
  );
};
