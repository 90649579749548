import React from 'react';
import { Box, BoxProps, ListItem, ListItemProps, MenuItem, MenuItemProps, styled } from '@mui/material';
import { NotificationVariant } from './type';

interface NotificationListSectionListProps {
  variant: typeof NotificationVariant.List;
  children: ListItemProps['children'];
  props?: Omit<ListItemProps, 'variant' | 'children'>;
}
interface NotificationListSectionMenuProps {
  variant: typeof NotificationVariant.Menu;
  children: MenuItemProps['children'];
  props?: Omit<MenuItemProps, 'variant' | 'children'>;
}

export type NotificationListSectionProps = (NotificationListSectionMenuProps | NotificationListSectionListProps) & {
  boxProps?: Omit<BoxProps, 'variant' | 'children'>;
};

export function NotificationListSection(props: NotificationListSectionProps) {
  return props.variant === NotificationVariant.List ? (
    <ListItem sx={{ p: 0 }} {...props.props}>
      <Box width={'100%'} {...props.boxProps}>
        {props.children}
      </Box>
    </ListItem>
  ) : (
    <MenuSection {...props.props}>
      <Box width={'100%'} {...props.boxProps}>
        {props.children}
      </Box>
    </MenuSection>
  );
}

const MenuSection = styled(MenuItem)(({ theme }) => ({
  minWidth: theme.spacing(49),
  cursor: 'default',
  padding: 0,
  ':hover': { background: '#fff' },
}));

MenuSection.defaultProps = {
  disableGutters: true,
  disableRipple: true,
  disableTouchRipple: true,
};
