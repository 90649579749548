import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { verifyToken } from '../../services/auth';
import { StatusCodes } from 'http-status-codes';
import { useToast } from '@/components/Toast';

export default function useQuestionnaire<T>(): any {
  const toast = useToast();
  // State and setters for debounced value

  const [searchParameters] = useSearchParams();

  const token = searchParameters.get('token_decode');
  const [decode, setDecode] = useState();
  const [access, setAccess] = useState(false);
  const [loadingAbsence, setLoadingAbsence] = useState(true);

  useEffect(() => {
    if (token) {
      verifyToken({ token })
        .then((value) => {
          if (value.status === StatusCodes.OK) {
            setDecode(value.data.decode);
          }
        })
        .catch(() => {
          setAccess(false);
          setLoadingAbsence(false);
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);
  return [decode, access, setAccess, loadingAbsence, setLoadingAbsence];
}
