import axios from '../../lib/axios';
import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import { customStyles } from '../../shared/models/custom_modal.models';
import { TermsContent } from '@/components/TermsContent';
import { useError } from '@/shared/useError';
import { PolicyLayout } from '../AppLayout';
import { datesPolicies } from '../../config/policies.config';
import { employeeAcceptNewCgu } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';

enum ModalState {
  ACCEPT_CGU = 'accept-cgu',
  TERMS = 'terms',
}

const ModalAcceptNewCGU: React.FC = () => {
  const [modalState, setModalState] = useState<ModalState | null>(ModalState.ACCEPT_CGU);
  const [loading, setLoading] = useState<boolean>(false);
  const { error, triggerError, clearError } = useError();

  const handleAccept = useCallback(() => {
    setLoading(true);
    employeeAcceptNewCgu({
      withCredentials: true,
      credentials: 'include',
    })
      .then((value) => {
        if (value.status === StatusCodes.OK) {
          setModalState(null);
          clearError();
        } else {
          triggerError();
        }
      })
      .catch((error) => {
        triggerError(error.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }, [triggerError]);

  return (
    <div>
      <Modal
        // @ts-ignore
        style={customStyles}
        className="Modal modal-style"
        overlayClassName="Overlay"
        closeTimeoutMS={200}
        isOpen={!!modalState}
        onRequestClose={() => setModalState(null)}
        ariaHideApp={false}
        aria-labelledby="modalTitle"
      >
        {error && <div className="err">{error}</div>}
        {loading ? (
          <div className="container-fluid container-loading">
            <h2>Veuillez patienter</h2>
            <div className="container">
              <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)" />
            </div>
          </div>
        ) : modalState === ModalState.TERMS ? (
          <div>
            <button className="btn-close exit-modal" onClick={() => setModalState(ModalState.ACCEPT_CGU)} />
            <PolicyLayout
              title={"Conditions Générales d'Utilisation"}
              date={datesPolicies.terms}
              withNavigation={false}
            >
              <TermsContent />
            </PolicyLayout>
          </div>
        ) : (
          <div>
            <h3 className="question-form text-center titre-modal" id="modalTitle">
              Avertissement
            </h3>
            <div className="text-center text-modal">Les Conditions Générales d'Utilisation de Kerij ont changé</div>
            <div className="text-center mb-4 text-modal">Vous devez lire et accepter les nouvelles formalités</div>
            <div className="col-12 text-center mb-3">
              <label>
                <u style={{ cursor: 'pointer' }} onClick={() => setModalState(ModalState.TERMS)}>
                  Je lis les nouvelles CGU
                </u>
              </label>
            </div>
            <div className="col-12 text-center">
              <button className="btn btn-kerij-bleu btn-modal col-6" onClick={handleAccept}>
                J'accepte les nouvelles CGU
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalAcceptNewCGU;
