import React from 'react';
import { Box, BoxProps, Grid } from '@mui/material';
import { CreateRounded } from '@mui/icons-material';
import { AccountCircleOutlined } from '@/components/AppIcon';
import { NavMenuCardButton } from '@/components/NavMenu';

export interface ProfilDesktopContentProps extends BoxProps {}

export function ProfilDesktopContent(props: ProfilDesktopContentProps) {
  const { ...boxProps } = props;
  const defaultState = { page: 'Login', fromPath: '/profil' };
  return (
    <Box {...boxProps}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <NavMenuCardButton
            to={'/profil/edit'}
            state={{ data: '/profil/edit', ...defaultState }}
            icon={<AccountCircleOutlined />}
            primary={'Informations personnelles'}
            secondary={'Fournissez des renseignements personnels et indiquez comment nous pouvons vous joindre'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <NavMenuCardButton
            to={'/changepassword'}
            state={{ data: '/changepassword', ...defaultState }}
            icon={<CreateRounded />}
            primary={'Changer de mot de passe'}
            secondary={'Mettre à jour votre mot de passe'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
