import React from 'react';
import '../DashboardRH.css';
import useCheckRhAuthentication from '@/shared/hooks/chechRhAuthentication.hooks';
import ProgressAbsence from './ProgressAbsence';
import ToDoAction from './ToDoAction';
import WelcomeStats from './WelcomeStats';
import { RHPage } from '@/components/RHPage';
import { Grid } from '@mui/material';

function AccueilRH() {
  useCheckRhAuthentication();
  return (
    <RHPage title="Dashboard">
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <ProgressAbsence />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToDoAction />
        </Grid>
        <Grid item xs={12}>
          <WelcomeStats />
        </Grid>
      </Grid>
    </RHPage>
  );
}

export default AccueilRH;
