import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import { EvenementsContent } from './EvenementsContent';

export const Evenements: React.FC = () => {
  return (
    <AppLayout>
      <EvenementsContent />
    </AppLayout>
  );
};
