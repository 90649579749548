import React from 'react';
import classNames from 'clsx';
import {
  styled,
  Box,
  AvatarProps,
  CardProps,
  CardActionAreaProps,
  Avatar as MUIAvatar,
  Card as MUICard,
  CardActionArea as MUICardActionArea,
  CardContent as MUICardContent,
  Typography,
  Collapse as MUICollapse,
} from '@mui/material';
import { KeyboardArrowDownRounded, KeyboardArrowRightRounded, KeyboardArrowUpRounded } from '@mui/icons-material';

const componentName = 'KRJCardButton';

export interface CardButtonProps
  extends Omit<CardProps, 'onClick' | 'content'>,
    Pick<CardActionAreaProps, 'disabled' | 'onClick'> {
  AvatarProps?: Pick<AvatarProps, 'src' | 'children'>;
  content?: React.ReactNode;
}

export function CardButton(props: CardButtonProps) {
  const { className, children, content, onClick, disabled, AvatarProps, ...otherProps } = props;
  const [openContent, setOpenContent] = React.useState(false);

  const contentRef = React.useRef<HTMLDivElement>(null);

  const handleClick: CardActionAreaProps['onClick'] = (event) => {
    if (content) setOpenContent((open) => !open);
    onClick?.(event);
  };

  React.useEffect(() => {
    if (openContent && contentRef.current)
      setTimeout(() => contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
  }, [openContent]);

  return (
    <Card
      elevation={1}
      className={classNames(componentName, className)}
      {...otherProps}
      sx={{ opacity: disabled ? 0.75 : undefined, ...otherProps.sx }}
    >
      <CardActionArea
        disableRipple
        disableTouchRipple
        onClick={handleClick}
        disabled={disabled}
        sx={(theme) => ({
          borderBottom: openContent ? '1px solid' : undefined,
          borderColor: theme.shape.borderColorVariant,
        })}
      >
        <Box display="flex">
          {AvatarProps && <Avatar {...AvatarProps} variant="square" />}

          <CardContentContainer style={disabled ? { opacity: 0.5 } : undefined}>
            <CardContent>
              <Box display={'flex'} width={'100%'} height={'100%'}>
                <Typography fontSize={16} fontWeight={600} lineHeight={'normal'} mt={'auto'} mb={'auto'}>
                  {children}
                </Typography>
              </Box>
            </CardContent>
          </CardContentContainer>

          <Box sx={{ pr: 3, pl: 1 }}>
            <Box display={'flex'} width={'100%'} height={'100%'}>
              <Typography mt={'auto'} mb={'auto'} fontSize={24}>
                {content == null ? (
                  <KeyboardArrowRightRounded />
                ) : openContent ? (
                  <KeyboardArrowUpRounded />
                ) : (
                  <KeyboardArrowDownRounded />
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>

      {content && (
        <Collapse in={openContent} timeout="auto">
          <Box pl={2} pr={2}>
            <CardContentAdditional ref={contentRef}>{content}</CardContentAdditional>
          </Box>
        </Collapse>
      )}
    </Card>
  );
}

const Card = styled(MUICard)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.surface,
}));

const CardActionArea = styled(MUICardActionArea)(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const Collapse = styled(MUICollapse)(({ theme }) => ({
  background: '#fff',
}));

const CardContent = styled(MUICardContent)(({ theme }) => ({
  flex: '1 0 auto',
  padding: '0 !important',
}));

const CardContentAdditional = styled(MUICardContent)(({ theme }) => ({
  padding: '0 !important',
}));

const CardContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Avatar = styled(MUIAvatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginRight: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1.25),
  borderBottomRightRadius: theme.spacing(1.25),
}));
