import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Absence, DocumentStatus, TypeAbsenceDescription } from '../../../../../../types/absences.type';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { ADPExportStatus } from '@/types/ADPExportStatus';
import { Info } from '@mui/icons-material';
import { ListCardContentLine } from '@/components/DashboardRH/ListCard';

interface AbsenceDetailsInterface {
  absence: Absence;
}

export const AbsenceDetails = ({ absence }: AbsenceDetailsInterface) => {
  const approvedDocument = absence?.documents?.find((doc) => doc.status === DocumentStatus.APPROVED);

  function displaySortieAutorisee() {
    if (!approvedDocument || approvedDocument.ocr_result?.authorized_exit?.value?.detect == null) return '-';
    return approvedDocument.ocr_result.authorized_exit.value?.detect ? 'Oui' : 'Non';
  }

  function displayDateExport() {
    if (absence.absence_exports == null) return '-';
    return moment(absence.absence_exports.time).format(FormatDate.FRENCH_DATE);
  }

  const adpLog = useMemo(
    () => absence?.absence_logs?.filter((log) => log.triggered_by === 'adp' && log.description) ?? [],
    [absence?.absence_logs],
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <ListCardContentLine separator={':'} label="Type d’arrêt">
            {absence?.id_absence ? 'Prolongation' : 'Initial'}
          </ListCardContentLine>

          <ListCardContentLine separator={':'} label="En lien avec un accident impliquant un tiers">
            {absence?.type_absence.description_2_label === TypeAbsenceDescription.ACCIDENT_IMPLIQUANT_TIERS
              ? 'Oui'
              : 'Non'}
          </ListCardContentLine>

          <ListCardContentLine separator={':'} label="En lien avec un(e) ATMP">
            {Object.values(TypeAbsenceDescription.ATMP).includes(absence?.type_absence.description_2_label)
              ? 'Oui'
              : 'Non'}
          </ListCardContentLine>
        </Box>

        <Box>
          {approvedDocument?.type === 'arret de travail' && (
            <ListCardContentLine separator={':'} label="Sorties autorisées">
              {displaySortieAutorisee()}
            </ListCardContentLine>
          )}

          {absence.absence_exports != null && (
            <ListCardContentLine
              separator={':'}
              label={absence.export_status === ADPExportStatus.Integrated ? 'Exportée et intégrée le ' : 'Exportée le '}
            >
              {displayDateExport()}
            </ListCardContentLine>
          )}
        </Box>
      </Box>

      {adpLog.map((log) => (
        <>
          <Box mt={1}>
            {log.description && (
              <ListCardContentLine
                separator={':'}
                label={
                  <span style={{ fontWeight: 'bold' }}>
                    <Info color="error" sx={{ mr: 1 }} />
                    Erreur d&apos;intégration dans le logiciel des temps
                  </span>
                }
              >
                {log.description}
              </ListCardContentLine>
            )}
          </Box>
        </>
      ))}
    </Box>
  );
};
