import { Typeface, typeface } from '../ref';

export type TypescaleName = 'headline' | 'title' | 'body' | 'label'; // 'display'

export type TypescaleSize = 'small' | 'medium' | 'large';

export interface Typescale extends Typeface {
  fontSize: string;
  fontWeight: number | string;
  lineHeight: number | string;
}

const size = (fontSize: number, lineHeight: number | 'auto') => ({
  fontSize: `${fontSize / 16}rem`,
  lineHeight: lineHeight === 'auto' ? 'normal' : lineHeight / fontSize,
});

/**
 * TODO: to implement
 */
export const typescale = {
  headline: {
    small: { ...typeface.plain, fontWeight: 600, ...size(24, 'auto') },
    medium: { ...typeface.plain, fontWeight: 600, ...size(28, 'auto') },
    large: { ...typeface.plain, fontWeight: 600, ...size(32, 'auto') },
  },
  title: {
    small: { ...typeface.plain, fontWeight: 600, ...size(14, 20) },
    medium: { ...typeface.plain, fontWeight: 600, ...size(16, 'auto') },
    large: { ...typeface.plain, fontWeight: 600, ...size(20, 'auto') },
  },
  body: {
    small: { ...typeface.plain, fontWeight: 400, ...size(12, 'auto') },
    medium: { ...typeface.plain, fontWeight: 400, ...size(14, 20) },
    large: { ...typeface.plain, fontWeight: 400, ...size(16, 24) },
  },
  label: {
    small: { ...typeface.plain, fontWeight: 600, ...size(10, 'auto') },
    medium: { ...typeface.plain, fontWeight: 600, ...size(12, 16) },
    large: { ...typeface.plain, fontWeight: 600, ...size(14, 'auto') },
  },
} satisfies Record<TypescaleName, Record<TypescaleSize, Typescale>>;
