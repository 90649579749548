import { RHNotification } from '../../../../../../types/rh.interface';
import React, { useState } from 'react';
import { DeleteAbsenceModal } from '../ActionCardModal/DeleteAbsenceModal';
import { ContentStructure } from './common';
import { VisualisationFileModal } from '../../../../Modal/VisualisationFileModal';
import { acceptAbsence, deleteAbsence } from '../../../../../../services/rh/absences/service';
import AcceptAbsenceModal from '../ActionCardModal/AcceptAbsenceModal';
import { useToast } from '@/components/Toast';
import { ActionButton } from '@/components/ActionButton';

export interface MissingDocumentActionsProps {
  rhNotification: RHNotification;
  onActionDone: () => void;
}

export const MissingDocumentContent = () => {
  return (
    <ContentStructure
      title={"Aucun justificatif n'a été déposé"}
      description={
        'Malgré nos relances le collaborateur n’a pas déposé son justificatif. Sans celui-ci, son dossier administratif ne peut être mis à jour, avec des impacts en paie. Que souhaitez-vous faire?'
      }
    />
  );
};

export const MissingDocumentActions = ({ rhNotification, onActionDone }: MissingDocumentActionsProps) => {
  const toast = useToast();

  const [deleteAbsenceModalVisible, setDeleteAbsenceModalVisible] = useState(false);
  const [closeAbsenceModalVisible, setCloseAbsenceModalVisible] = useState(false);
  const [modalIsOpenDocumentManquant, setModalIsOpenDocumentManquant] = useState(false);

  return (
    <div>
      <ActionButton
        actionName="validate"
        label={"Accepter l'absence sans justificatif"}
        onClick={() => setCloseAbsenceModalVisible(true)}
        sx={{ mr: 1 }}
      />
      <ActionButton
        actionName="add"
        label={'Déposer un justificatif'}
        onClick={() => setModalIsOpenDocumentManquant(true)}
        sx={{ mr: 1 }}
      />
      <ActionButton
        actionName="delete"
        label={"Supprimer l'absence"}
        onClick={() => setDeleteAbsenceModalVisible(true)}
      />

      <DeleteAbsenceModal
        visible={deleteAbsenceModalVisible}
        setVisible={setDeleteAbsenceModalVisible}
        onDeleteAbsence={async (description) => {
          if (rhNotification.absence)
            await deleteAbsence({
              params: {
                idAbsence: rhNotification.absence.id,
              },
              body: {
                action: 'delete',
                description: description,
              },
            }).then(() => {
              toast.present({ message: `Absence supprimée`, severity: 'success' });
              onActionDone();
            });
          setDeleteAbsenceModalVisible(false);
        }}
      />
      <AcceptAbsenceModal
        visible={closeAbsenceModalVisible}
        setVisible={setCloseAbsenceModalVisible}
        onAcceptAbsence={async (description) => {
          if (rhNotification.absence)
            await acceptAbsence({
              params: {
                idAbsence: rhNotification.absence.id,
              },
              body: {
                action: 'accept',
                description: description,
              },
            }).then(() => {
              toast.present({ message: `La déclaration est désormais validée`, severity: 'success' });
              onActionDone();
            });

          setCloseAbsenceModalVisible(false);
        }}
      />

      {rhNotification.employee_information && (
        <VisualisationFileModal
          employee_information={rhNotification.employee_information}
          absence={rhNotification.absence}
          medical_documents={rhNotification.medical_documents ?? []}
          visible={modalIsOpenDocumentManquant}
          handleCloseModal={() => {
            onActionDone();
            setModalIsOpenDocumentManquant(false);
          }}
        />
      )}
    </div>
  );
};
