import React from 'react';
import { ResetPasswordContent } from './ResetPasswordContent';
import { AppLayout } from '@/components/AppLayout';

export const ResetPassword: React.FC = () => {
  return (
    <AppLayout>
      <ResetPasswordContent />
    </AppLayout>
  );
};
