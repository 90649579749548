import { type Absence } from '../../../types/absences.type';
import {
  TypesAbsencesAccident,
  TypesAbsencesHospitalisation,
  TypesAbsencesMaladie,
  TypesAbsencesParentalite,
} from '../types/data';

export interface createAbsenceRequest {
  params: {
    idEmployeeInformation: string;
    idBusiness: string;
  };
  body: object;
}

export interface updateAbsenceRequest {
  params: {
    idAbsence: string;
  };
  body: UpdateAbsenceRequestBody;
}

export interface UpdateAbsenceRequestBody {
  date_start?: string | null;
  date_end?: string | null;
  id_type_absence?: string;
  log?: string;
}

export interface absenceRequest {
  params: {
    idAbsence: string;
  };
  body: object;
}

export function isAccident(absence: Absence) {
  return absence.code_absence && (TypesAbsencesAccident as readonly string[]).includes(absence.type_absence.id);
}

export function isHospitalisation(absence: Absence) {
  return absence.code_absence && (TypesAbsencesHospitalisation as readonly string[]).includes(absence.type_absence.id);
}

export function isMaladie(absence: Absence) {
  return (TypesAbsencesMaladie as readonly string[]).includes(absence.type_absence.id);
}

export function isParentalite(absence: Absence) {
  return absence.code_absence && (TypesAbsencesParentalite as readonly string[]).includes(absence.type_absence.id);
}
