import React, { useEffect, useMemo, useState } from 'react';
import { DeclarationFormControl } from './type';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AccountContext, useAppSelector } from '@/store';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { DocumentStatus } from '@/types';
import { useToast } from '@/components/Toast';
import { formatDate } from '@/utils/date.utils';
import { employeeDeclareAbsence, employeeUpdateAbsence } from '@/services/employee';
import { StatusCodes } from 'http-status-codes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppVariant } from '@/hooks/useAppVariant';

export interface DeclarationFormEndProps {
  formControl: DeclarationFormControl;
  type: string | null;
  edit?: boolean;
}

export function DeclarationFormEnd(props: DeclarationFormEndProps) {
  const { formControl, type, edit } = props;

  const appVariant = useAppVariant();
  const toast = useToast();
  const navigate = useNavigate();
  const user = useAppSelector(AccountContext.getUser);
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);

  const isUpdated = useMemo(() => searchParams.get('updated') === 'true', [searchParams]);
  const isCreated = useMemo(() => searchParams.get('created') === 'true', [searchParams]);
  const isActed = useMemo(() => isUpdated || isCreated, [isCreated, isUpdated]);

  const createAbsence = async () => {
    setLoading(true);

    if (user == null) {
      setLoading(false);
      return toast.present({
        message: "Nous n'arrivons pas à récupérer vos informations, vérifiez votre connexion",
        severity: 'error',
      });
    }

    if (isActed) {
      setLoading(false);
      return toast.present({ message: 'Votre déclaration est terminée', severity: 'success' });
    }

    const formData = new FormData();
    formData.set('zipcode', user?.employee_information?.zipcode);
    // Prolongation
    formData.set('prolongation', formControl[0].prolongation === '2' ? 'true' : 'false');

    // Date
    if (formControl[0].date.last_day_work != null)
      formData.append('lastdayWork', formatDate(formControl[0].date.last_day_work));
    if (formControl[0].date.date_start != null)
      formData.append('dateStart', formatDate(formControl[0].date.date_start));
    if (formControl[0].date.date_end != null) formData.append('dateEnd', formatDate(formControl[0].date.date_end));

    // Type
    formData.set('description', type === 'HO' ? '2' : '1');
    formData.set('description1', type === 'HO' ? '' : type === 'MA' ? '1' : '2');
    formData.set('description2', formControl[0].type ?? '');
    formData.set('codeAbsence', formControl[0].validation.code ?? '');

    // Contact
    if (formControl[0].message.enable === true) formData.append('message', formControl[0].message.content ?? '');
    if (formControl[0].contact.permission?.canContact === true)
      formData.append('contactType', formControl[0].contact.type ?? '');
    if (formControl[0].contact.permission?.canContactByMail === true)
      formData.append('email', formControl[0].contact.email ?? '');
    if (formControl[0].contact.permission?.canContactByPhone === true)
      formData.append('phone', formControl[0].contact.phone ?? '');

    // Document
    formData.append('documents', formControl[0].document.file || '');
    formData.append('ocrResult', JSON.stringify(formControl[0].document.data) || '');
    formData.append('documentStatus', formControl[0].document.data?.documentStatus || DocumentStatus.PENDING);
    formData.append('typeDocument', getTypeDocument(type === 'HO' ? '2' : '1'));

    // Common
    formData.set('declareBy', '1');
    formData.set('actionLevel', '0');
    formData.set('dateAction', moment().format(FormatDate.DATE_ONLY));

    const response = await employeeDeclareAbsence(formData);
    if (response.status === StatusCodes.OK) {
      formControl[1]((model) => ({
        ...model,
        validation: {
          ...model.validation,
          cpam: response.data.cpam ?? null,
        },
      }));

      searchParams.set('created', 'true');
      navigate(`/declaration-form?${searchParams.toString()}`, { replace: true });

      setLoading(false);
    } else {
      toast.present({ message: 'Une erreur est survenue lors de la déclaration de votre absence', severity: 'error' });
      setLoading(false);
    }
  };

  const updateAbsence = async () => {
    setLoading(true);

    const id = searchParams.get('edit');

    if (user == null) {
      setLoading(false);
      return toast.present({
        message: "Nous n'arrivons pas à récupérer vos informations, vérifiez votre connexion",
        severity: 'error',
      });
    }

    if (id == null) {
      setLoading(false);
      toast.present({
        message: "Une erreur est survenue lors de la récupération de l'absence",
        severity: 'error',
      });
      return navigate(`/demarches`, { replace: true });
    }

    if (isActed) {
      setLoading(false);
      return toast.present({ message: 'Votre déclaration est terminée', severity: 'success' });
    }

    try {
      const formData = new FormData();
      formData.set('idEmployeeInformation', user?.employee_information.id);
      formData.set('idAbsence', id);
      formData.append('documents', formControl[0].document.file || '');
      formData.append('ocrResult', JSON.stringify(formControl[0].document.data) || '');
      formData.append('documentStatus', formControl[0].document.data?.documentStatus || DocumentStatus.PENDING);
      formData.append('typeDocument', getTypeDocument(type === 'HO' ? '2' : '1'));

      await employeeUpdateAbsence(formData);

      searchParams.set('updated', 'true');
      navigate(`/declaration-form?${searchParams.toString()}`, { replace: true });
    } catch {
      toast.present({
        message: "Une erreur est survenue lors de la mise à jour de l'absence",
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && !isCreated && !edit) createAbsence();
    if (user && !isUpdated && edit) updateAbsence();
    if (isActed) setLoading(false);
  }, [user]);

  return loading ? (
    <Box textAlign={'center'} p={8}>
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <Typography fontSize={appVariant.medium ? 16 : 14} lineHeight={1.5}>
        Votre dossier est bien pris en compte.
      </Typography>

      {(formControl[0].document.no_file || formControl[0].document.status == null) && isCreated && (
        <Typography fontSize={appVariant.medium ? 16 : 14} lineHeight={1.5} color="error" mt={4}>
          Attention si votre justificatif n’a pas été télétransmis vous devez impérativement adresser le volet 1 de
          votre document à la CPAM dans les 48h, sinon vous risquez de ne pas être indemnisé.
        </Typography>
      )}

      {formControl[0].validation.cpam && (
        <Box mt={4}>
          <Typography fontSize={24} fontWeight={600} lineHeight={'normal'} pt={4} pb={1}>
            Votre CPAM :
          </Typography>
          {formControl[0].validation.cpam.Nom && (
            <Typography fontSize={14} lineHeight={1.4}>
              {formControl[0].validation.cpam.Nom}
            </Typography>
          )}
          {formControl[0].validation.cpam['Adresse 1'] && (
            <Typography fontSize={14} lineHeight={1.4}>
              {formControl[0].validation.cpam['Adresse 1']}
            </Typography>
          )}
          {formControl[0].validation.cpam['Adresse 2'] && (
            <Typography fontSize={14} lineHeight={1.4}>
              {formControl[0].validation.cpam['Adresse 2']}
            </Typography>
          )}
          {formControl[0].validation.cpam.Code && (
            <Typography fontSize={14} lineHeight={1.4}>
              {formControl[0].validation.cpam.Code}
            </Typography>
          )}
          {formControl[0].validation.cpam.Ville && (
            <Typography fontSize={14} lineHeight={1.4}>
              {formControl[0].validation.cpam.Ville}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

const getTypeDocument = (absenceType: string) => {
  switch (absenceType) {
    case '1': {
      return 'arret de travail';
    }
    case '2': {
      return 'hospitalisation';
    }
    case '3': {
      return 'certificat de grossesse';
    }
    default: {
      return '';
    }
  }
};
