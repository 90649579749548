import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Badge, styled, type BadgeProps } from '@mui/material';

type User = {
  employee_information: {
    firstname: string;
    lastname: string;
  };
};

export interface UserAvatarProps {
  BadgeProps?: BadgeProps;
  user: User;
  hasNotification?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { user, BadgeProps, hasNotification = false } = props;

  return (
    <Badge
      overlap="circular"
      color="warning"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      invisible={!hasNotification}
      {...BadgeProps}
    >
      <AvatarRounded
        sx={(theme) => ({ backgroundColor: theme.md3.sys.color.primary, color: theme.md3.sys.color.onPrimary })}
      >
        {`${user.employee_information.firstname[0]}${user.employee_information.lastname[0]}`}
      </AvatarRounded>
    </Badge>
  );
};

const AvatarRounded = styled(Avatar)(({ theme }) => ({
  border: '0',
  height: theme.spacing(3),
  width: theme.spacing(3),
  background: theme.palette.common.white,
  fontSize: 10,
  fontWeight: 600,
}));
