import moment from 'moment';
import { AbsenceCreation, StepProperties } from '../../props/step.interface';
import React, { useEffect, useState } from 'react';
import { statementStep } from '../../constants/step.constants';
import TitleProcess from '../TitleProcess';
import { DateStartInput } from './common';
import { isInvalideDate, isToLate } from '@/components/AbsenceDatesRange/data';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { AppTextField } from '@/components/AppTextField';
import { Spacer } from '@/components/Spacer';
import { QuestionYesNoInput } from '@/components/QuestionYesNoInput';

export const HospiDate = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isShownDureeHospi, setIsShownDureeHospi] = useState<boolean>(false);
  const [nbJoursHospi, setNbJoursHospi] = useState<number>(0);

  useEffect(() => {
    handleOnChangeHospiDate();

    const dateStart = absence.dateDebut;
    if (isInvalideDate(dateStart)) setErrorText('Le format de la date de début est incorrect');
    else if (isToLate(dateStart)) setErrorText('Vous ne pouvez pas déclarer une absence à plus de 2 ans');
    else if (absence.dateFin && moment(absence?.dateDebut).isAfter(moment(absence.dateFin))) {
      setErrorText('La date de fin doit être supérieure ou égale à la date de début');
    } else {
      setErrorText(null);
    }
  }, [absence?.dateDebut, nbJoursHospi]);

  const handleOnChangeLastDayWork = (value: string | undefined) => {
    setAbsence((absence: AbsenceCreation) => ({
      ...absence,
      lastDayWork: value,
      // withNoDoc: false,
    }));
  };

  const handleDateStartChange = (value: string | undefined) => {
    setAbsence((absence: AbsenceCreation) => ({
      ...absence,
      dateDebut: value,
    }));

    if (isInvalideDate(absence.dateDebut)) setErrorText('Le format de la date de début est incorrect');
    else if (absence.dateFin && moment(value).isAfter(moment(absence.dateFin))) {
      setErrorText('La date de fin doit être supérieure ou égale à la date de début');
    } else {
      setErrorText(null);
    }
  };

  const handleOnChangeHospiDate = () => {
    /**
     * Rule: when user choose n days for hospi, we always count the first date, so n-1 days from now
     * exemple: 13 june + 7 days = 19 june
     */
    if (nbJoursHospi > 0) {
      setAbsence((absence: AbsenceCreation) => ({
        ...absence,
        dateFin: moment(absence.dateDebut)
          .add(nbJoursHospi - 1, 'days')
          .format('YYYY-MM-DD'),
      }));
    } else {
      setAbsence((absence: AbsenceCreation) => ({
        ...absence,
        dateFin: undefined,
      }));
    }
  };

  useEffect(() => {
    if (errorText == null && absence.dateDebut && !isInvalideDate(absence.dateDebut)) {
      handleChangeStep(statementStep.Document);
    } else {
      handleChangeStep(statementStep.Date);
    }
  }, [errorText, absence]);

  return (
    <div>
      <TitleProcess title={`Quelle est la durée de son arrêt de travail ?`} />

      <Spacer />

      <Grid container>
        <Grid item xs={8} lg={6}>
          <AppTextField
            value={absence.lastDayWork}
            label="1er jour de l'arrêt de travail"
            InputProps={{
              inputProps: { type: 'date' },
            }}
            onChange={(event, _value) => {
              console.log('change', _value);
              // FIXME: use _value
              const target = event.target as any;
              handleOnChangeLastDayWork(target.value);
            }}
            onBlur={(event) => {
              console.log('blur', event);
              handleOnChangeLastDayWork(event.target.value);
            }}
            error={errorText != null && errorText.trim() != ''}
            helperText={errorText}
          />

          <Spacer />

          {absence.lastDayWork && infosEmployee && (
            <DateStartInput
              className="col-12 pb-3"
              lastDayWork={absence.lastDayWork}
              employeeTarget="other-employee"
              employeeTimePackage={infosEmployee.time_package}
              onChange={(dateDebut) => handleDateStartChange(dateDebut)}
            />
          )}

          <Spacer />

          {absence.dateDebut ? (
            <Box>
              <QuestionYesNoInput
                title="La durée d'hospitalisation est-elle définie ?"
                onChange={(_, value) => {
                  setIsShownDureeHospi(value);
                  if (!value) {
                    setAbsence((absence: AbsenceCreation) => ({
                      ...absence,
                      dateFin: '',
                    }));
                    setNbJoursHospi(0);
                  }
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <Spacer />

      {isShownDureeHospi ? (
        <div>
          <AppTextField
            label={'Combien de jours êtes-vous hospitalisé ?'}
            value={String(nbJoursHospi)}
            onChange={(_, absoluteValue) => {
              const _value = absoluteValue.trim() === '' ? 0 : Number.parseInt(absoluteValue);
              setNbJoursHospi(_value);
            }}
            InputProps={{
              type: 'number',
              componentsProps: {
                input: { min: 0, style: { textAlign: 'right' } },
              },
              sx(theme) {
                return { width: theme.spacing(16) };
              },
              className: 'align-items-center',
              endAdornment: (
                <InputAdornment position="end">
                  <Typography fontSize={12}>{'jour(s)'}</Typography>
                </InputAdornment>
              ),
            }}
          />

          {absence.dateFin && (
            <Typography fontWeight={400}>
              <em>Votre date de sortie est prévue le {moment(absence.dateFin).format('DD/MM/YYYY')}</em>
            </Typography>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
