import AccountIcon from '../Icons/AccountIcon';
import NotificationIcon from '../Icons/NotificationIcon';
import ContactsIcon from '../Icons/ContactsIcon';
import React, { useState } from 'react';
import { EmployeeInformation } from '../../../types/user.interface';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { EmployeeInformationModal } from '../shared/components/Modal/EmployeInformationModal';
import { displayEmployeeCivilityIcon } from '@/types';
import { ListCardHeader, ListCardHeaderAvatar, ListCardHeaderButton } from '../ListCard';

export interface EmployeeCardHeaderProps {
  employeeInformation: EmployeeInformation;
  withCount?: boolean;
  secondaryAction?: React.ReactNode;
}

export const EmployeeCardHeader = (props: EmployeeCardHeaderProps) => {
  const { employeeInformation, withCount, secondaryAction } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <ListCardHeader
        actions={
          <>
            <AccountIcon employeeInformation={employeeInformation} size="small" />
            <NotificationIcon employeeInformation={employeeInformation} size="small" />
            <ContactsIcon employeeInformation={employeeInformation} size="small" noPadding={secondaryAction == null} />
            {secondaryAction != null && secondaryAction}
          </>
        }
      >
        <ListCardHeaderButton
          onClick={() => setIsOpenModal(true)}
          sx={withCount ? {} : { ':hover': { border: 0 }, border: 0 }}
          disableRipple
        >
          <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
            {employeeInformation ? getFullName(employeeInformation) : 'Un salarié'}
            {'  '}
            {displayEmployeeCivilityIcon(employeeInformation?.civility)}
          </Typography>
        </ListCardHeaderButton>

        {withCount && (
          <>
            <Tooltip title={`Nombre de jours d'absences au cours des 12 derniers mois`}>
              <ListCardHeaderButton
                sx={{ padding: 2.5, minWidth: 0, ':hover': { border: 0 }, border: 0 }}
                disableRipple
              >
                <ListCardHeaderAvatar sx={{ mr: 0 }}>
                  {employeeInformation?.totalLastYearAbsencesDays || 0}
                </ListCardHeaderAvatar>
              </ListCardHeaderButton>
            </Tooltip>
            <Tooltip title={`Nombre d'arrêts au cours des 12 derniers mois`}>
              <ListCardHeaderButton sx={{ padding: 0, minWidth: 0, ':hover': { border: 0 }, border: 0 }} disableRipple>
                <ListCardHeaderAvatar>{employeeInformation?.totalLastYearAbsences || 0}</ListCardHeaderAvatar>
              </ListCardHeaderButton>
            </Tooltip>
          </>
        )}
      </ListCardHeader>

      <EmployeeInformationModal
        employeeInformation={employeeInformation}
        visible={isOpenModal}
        onClosed={() => setIsOpenModal(false)}
      />
    </>
  );
};
