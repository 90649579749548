// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppLayout__Header {
  grid-area: header;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.AppLayout__Content {
  overflow-x: hidden;
  overflow-y: scroll;
}
.AppLayout__Container {
  grid-area: content;
}
.AppLayout__Footer {
  grid-area: footer;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AppLayout/AppLayout.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,wBAAA;EAAA,gBAAA;EACA,MAAA;EACA,WAAA;AAAJ;AAGE;EACE,kBAAA;EACA,kBAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,wBAAA;EAAA,gBAAA;EACA,SAAA;AAHJ","sourcesContent":[".AppLayout {\n  &__Header {\n    grid-area: header;\n    position: sticky;\n    top: 0;\n    z-index: 10;\n  }\n\n  &__Content {\n    overflow-x: hidden;\n    overflow-y: scroll;\n  }\n\n  &__Container {\n    grid-area: content;\n  }\n\n  &__Footer {\n    grid-area: footer;\n    position: sticky;\n    bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
