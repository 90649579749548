import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import ProfilValidationUI from './components/ProfilValidationUI';

export const ProfilValidationPage: React.FC = () => {
  return (
    <AppLayout>
      <ProfilValidationUI />
    </AppLayout>
  );
};
