/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { AbsenceCreation, StepProperties } from '../props/step.interface';
import { statementStep } from '../constants/step.constants';
import { absenceType, toSelect } from '../constants/absenceType.constant';
import { AppSelect } from '@/components/AppSelect';
import { Grid, SelectChangeEvent } from '@mui/material';

const getAbsenceType = (absence: AbsenceCreation) => {
  const type = absence?.absenceType || '';
  const type1 = absence?.absenceType1 || '';

  if (type === `${absenceType.hospitalisation}`) return absenceType.hospitalisation;
  if (type === `${absenceType.arret_maladie}`)
    return type1 === '2' ? absenceType.accident_arret_professionnel : absenceType.arret_maladie;

  return toSelect;
};

export const AbsenceType = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const handleChangeTypeAbsence = (event: SelectChangeEvent<number>) => {
    if (!infosEmployee) return;

    const baseValidAbsence = {
      stateAbsence: '0',
      dateDebut: '',
      dateFin: '',
      period: [{ dateDebut: '', dateFin: '', error: -1 }],
    };
    let currentAbsence: Partial<AbsenceCreation>;
    let nextStep: statementStep;
    const inputValue =
      typeof event.target.value === 'string' ? Number.parseInt(event.target.value) : event.target.value;

    switch (inputValue) {
      case toSelect: {
        currentAbsence = { absenceType: `${toSelect}` };
        nextStep = statementStep.TypeAbsence;
        break;
      }
      case absenceType.arret_maladie: {
        currentAbsence = { ...baseValidAbsence, absenceType: '1', absenceType1: '1' };
        nextStep = statementStep.IsProlongation;
        break;
      }
      case absenceType.accident_arret_professionnel: {
        currentAbsence = { ...baseValidAbsence, absenceType: '1', absenceType1: '2' };
        nextStep = statementStep.IsProlongation;
        break;
      }
      case absenceType.hospitalisation: {
        currentAbsence = { ...baseValidAbsence, absenceType: String(inputValue), absenceType1: '0' };
        nextStep = statementStep.Date;
        break;
      }
      default: {
        currentAbsence = { ...baseValidAbsence, absenceType: String(inputValue), absenceType1: '0' };
        nextStep = statementStep.TypeAbsence1;
        break;
      }
    }
    setAbsence((absence: AbsenceCreation) => ({ ...absence, ...currentAbsence }));
    handleChangeStep(nextStep);
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        <AppSelect
          items={[
            { value: toSelect, label: `Choisir type d'absence` },
            { value: absenceType.arret_maladie, label: `Arrêt maladie` },
            { value: absenceType.hospitalisation, label: `Hospitalisation` },
            { value: absenceType.accident_arret_professionnel, label: `Accident/Maladie professionnelle` },
          ]}
          value={getAbsenceType(absence)}
          onChange={(_) => handleChangeTypeAbsence(_)}
        />
      </Grid>
    </Grid>
  );
};
