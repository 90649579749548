import React, { ForwardedRef } from 'react';
import classNames from 'clsx';
import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  Radio,
  radioClasses,
  RadioGroup,
  styled,
  typographyClasses,
  useTheme,
} from '@mui/material';
import { AppSelectItem } from '../AppSelect';

const componentName = 'AppRadioGroup';

export interface AppRadioGroupProps extends Omit<FormControlProps, 'value' | 'onChange' | 'defaultValue'> {
  items: Array<Omit<AppSelectItem, 'tooltip' | 'key'>>;
  value: string | null;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const AppRadioGroup = React.forwardRef(function AppRadioGroup(
  props: AppRadioGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className, items, value, defaultValue, onChange, ...controlProps } = props;

  const theme = useTheme();
  const selectedBorder = (selected: boolean) => ({
    borderColor: selected ? theme.md3.sys.color.onSurface : theme.shape.borderColorVariant,
  });

  return (
    <FormControl ref={ref} fullWidth className={classNames(componentName, className)} {...controlProps}>
      <RadioGroup defaultValue={defaultValue} value={value} onChange={onChange}>
        {items.map((item) => (
          <PaperRadio
            key={item.value}
            control={<Radio />}
            style={selectedBorder(value === item.value)}
            labelPlacement="start"
            {...item}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});

const PaperRadio = styled(FormControlLabel)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  border: '1px solid',
  borderColor: theme.shape.borderColorVariant,
  padding: theme.spacing(2),
  margin: 0,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
  backgroundColor: theme.md3.sys.color.surface,
  [`&.${radioClasses.checked}`]: {
    backgroundColor: 'blue',
  },
  [`& .${typographyClasses.root}`]: {
    fontSize: 16,
    fontWeight: 600,
  },
}));
