import React, { useEffect } from 'react';
import { defaultParcoursScriptAction, ParcoursActionsInput } from '@/components/ParcoursInput';
import { Parcours } from '@/types';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

export interface ParcoursActionStepProps {
  value?: Parcours['script']['action'];
  onChange?: (value: Parcours['script']['action']) => void;
}

export const ParcoursActionStep = (props: ParcoursActionStepProps) => {
  const { value, onChange } = props;
  useEffect(() => {
    if (value == null) {
      onChange?.(defaultParcoursScriptAction);
    }
  });

  return (
    <PageFormBody
      before={
        <PageFormHelperText>
          <p>Disclaimer !</p>
          <p>
            Les mails élaborés sur la plateforme KERij ont pour finalité exclusive le suivi et l’accompagnement du
            salarié confronté à un arrêt de travail. Ils ne peuvent servir de support à une mesure disciplinaire et ne
            sauraient constituer l’amorce d’une procédure disciplinaire.
          </p>
          <p>
            {' '}
            WithBot, décline toute responsabilité quant à la formulation retenue des messages, intervenant dans un cadre
            professionnel, à l’attention des différents interlocuteurs par les entreprises souscriptrices.
          </p>
        </PageFormHelperText>
      }
    >
      <ParcoursActionsInput
        value={value}
        onChange={(e, value) => {
          onChange?.(value);
        }}
      />
    </PageFormBody>
  );
};
