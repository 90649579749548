/* eslint-disable unicorn/prefer-query-selector */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '@/components/DashboardRH/DashboardRH.css';
import '../Admin/Admin.css';
import Modal from 'react-modal';
import ImgUser from '../../../assets/icon-user.svg';
import ImgFiles from '../../../assets/icon-files.svg';
import ImgParametres from '../../../assets/icon-settings.svg';
import ImgTrash from '../../../assets/icon-trash.svg';
import ImgUpdateFiles from '../../../assets/icon-update-files.svg';
import ImgFileUploaded from '../../../assets/icon-files-uploaded.svg';
import ImgReglesGenerales from '../../../assets/icon-regles-generales.svg';
import ImgServices from '../../../assets/icon-services.svg';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../../lib/axios';
import { Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { useError } from '@/shared/useError';
import { PRO_URL } from '../../../constants/urls.constants';
import { NotConnectedRH } from '../../../components/DashboardRH/shared/components/NotConnectedRH';
import { RHAccountContext, useAppSelector } from '@/store';

const handleOpenFile = (file: any) => {
  const src = URL.createObjectURL(file);
  window.open(src, '_blank');
};

function AdminFichiers() {
  const { triggerError } = useError();
  const rh = useAppSelector(RHAccountContext.getProfile);
  const isAdmin = useAppSelector(RHAccountContext.isAdmin);
  const [modalIsOpenUpdateFile, setModalIsOpenUpdateFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectFile, setSelectFile] = useState('');
  const [file, setFile] = useState<any>();
  const [isUploaded, setIsUploaded] = useState<any>();

  const state = useLocation();

  const ref = useRef<any>(null);

  const handleClickShow = async (index: any) => {
    axios
      .get(`/api/history/${files[index].file_url}`, {
        params: {
          idBusinesses: rh?.rh_information.id_business
            ? JSON.stringify([rh?.rh_information.id_business])
            : rh?.rh_information.id_businesses
              ? JSON.stringify(JSON.parse(rh?.rh_information.id_businesses))
              : null,
          idGroup: rh?.rh_information.id_group,
        },
      })
      .then((value) => {
        if (value.status === 200) {
          setFiles((data) =>
            data.map((val, ind) => {
              if (ind === index) {
                val.isShown = !val.isShown;
                val.histories = value.data.histories;
              }
              return val;
            }),
          );
        }
      })
      .catch(() => {
        triggerError("Une erreur s'est produite");
      });
  };

  const handleChangeFile = (event: any) => {
    setFile(event.target.files[0]);
    const file: any = document.getElementById('file');
    if (file) {
      file.value = '';
    }
  };

  const handleClickUpload = () => {
    ref.current.click();
  };

  const data = [
    {
      text: 'Utilisateurs',
      img: ImgUser,
      url: '/pro/admin/users',
      select: '',
    },
    {
      text: 'Fichiers',
      img: ImgFiles,
      url: '/pro/admin/files',
      select: 'select',
    },
    // {
    //     text: "Règles générales",
    //     img: ImgReglesGenerales,
    //     url: "/pro/admin/general_rule",
    //     select :""
    // },
    {
      img: ImgParametres,
      text: 'Paramètres',
      url: '/pro/admin/settings',
      select: '',
    },
    {
      text: 'Correspondance',
      img: ImgReglesGenerales,
      url: '/pro/admin/corresp_table',
      select: '',
    },
    // {
    //     text: "Alertes",
    //     img: ImgNotif,
    //     url: "/pro/admin/alert",
    // 	select: ""
    // },
    // {
    //     text: "Règles spéciales",
    //     img: ImgReglesSepciales,
    //     url: "/pro/admin/special_rule",
    //     select :""
    // },
    {
      text: 'Services',
      img: ImgServices,
      url: '/pro/admin/admin_service',
      select: '',
    },
  ];

  const handleValidateFile = async () => {
    setIsLoading(true);
    if (selectFile.toLowerCase() === 'employee_contact') {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('date', state.state ? state.state.date : moment().format('YYYY-MM-DD'));
      await axios
        .post(`/api/upload/contact_employee`, formData, {})
        .then((value) => {
          if (value.status === 200) {
            setIsLoading(false);
            setIsUploaded(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (selectFile.toLowerCase() === 'dsn') {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('date', state.state ? state.state.date : moment().format('YYYY-MM-DD'));
      await axios
        .post(`/api/upload/dsn`, formData, {})
        .then((value) => {
          if (value.status === 200) {
            setIsLoading(false);
            setIsUploaded(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (selectFile.toLowerCase() === 'medical_visit') {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('date', state.state ? state.state.date : moment().format('YYYY-MM-DD'));
      await axios
        .post(`/api/upload/medical_visit`, formData, {})
        .then((value) => {
          if (value.status === 200) {
            setIsLoading(false);
            setIsUploaded(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [files, setFiles] = useState([
    {
      file_name: 'Matrice des contacts',
      file_url: 'employee_contact',
      isShown: false,
      histories: [],
    },
    {
      file_name: 'DSN',
      file_url: 'dsn',
      isShown: false,
      histories: [],
    },
    {
      file_name: 'Adresses visite médicale',
      file_url: 'medical_visit',
      isShown: false,
      histories: [],
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('access_token_rh')) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, [localStorage.getItem('access_token_rh')]);

  return (
    <div>
      <div className="row col-12">
        <div className="col-2">
          <Navbar expand="md" className="admin-navbar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="flex-column m-2">
                {data.map((item, index) => (
                  <Link key={index} className={`nav-link ${item.select === 'select' ? 'active' : ''}`} to={item.url}>
                    {item.text}
                  </Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        {isAdmin ? (
          <div className="col-10">
            <div className="container-fluid row mt-5">
              {/* <h1 className="titre-rh mb-2 col-lg-8 text-left ">Administration</h1>
							<div className="admin-nav text-center col-10 col-lg-10 justify-content-between mb-5">
								{data.map((value, index) => {
									return (
										<div
											className={
												value.select === "select"
													? "admin-menu-select"
													: "admin-menu"
											}
											key={index}
										>
											<Link to={value.url}>
												<div>
													<i>
														<img
															alt="kerij"
															className="img-admin"
															src={value.img}
														/>
													</i>
												</div>
												<div>{value.text}</div>
											</Link>
										</div>
									);
								})}
							</div> */}

              <div className="container-fluid col-lg-10 col-10">
                <div className="col-8 row">
                  <div className="gerer-user col-6">Gérer les fichiers de paramétrage</div>
                </div>
                <hr className="col-8" />
                {files.map((value, index) => {
                  return (
                    <div
                      className="mb-3"
                      key={index}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        paddingLeft: '50px',
                      }}
                    >
                      <div
                        className="col-8 row card-rh-header-masked-absence"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div className="col-4 prenom-nom">{value.file_name}</div>
                        <div className="col-4 poste text-start"></div>
                        <div
                          className="col-4 text-end"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            className="btn btn-kerij-rh btn-sm"
                            onClick={() => {
                              setModalIsOpenUpdateFile(true);
                              setSelectFile(value.file_url);
                            }}
                            style={{
                              padding: '10px',
                            }}
                          >
                            <img alt="reload" src={ImgUpdateFiles} /> &nbsp;Mettre à jour
                          </button>
                        </div>
                      </div>
                      <div className="col-8 row card-actions-rh-footer click-footer p-2">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            handleClickShow(index);
                          }}
                        >
                          <span
                            style={{
                              display: 'block',
                              alignSelf: 'center',
                              textAlign: 'center',
                              marginRight: '0.5vw',
                            }}
                          >
                            Dernières mise à jours
                          </span>
                          <FontAwesomeIcon
                            icon={value.isShown ? ('fa-solid fa-caret-up' as any) : ('fa-solid fa-caret-down' as any)}
                          />
                        </div>
                        {value.isShown &&
                          value.histories.length > 0 &&
                          value.histories.map((val: any, index: number) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '40%',
                                    marginRight: '10px',
                                  }}
                                >
                                  {val.name}
                                </div>
                                <div>- {moment(val.date_upload).format('DD/MM/YYYY')}</div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <NotConnectedRH />
        )}

        <Modal
          // style={customStyles}
          className="Modal modal-style"
          // overlayclassName="Overlay"
          // className="modal-document"
          closeTimeoutMS={200}
          isOpen={modalIsOpenUpdateFile}
          onRequestClose={() => {
            setModalIsOpenUpdateFile(!modalIsOpenUpdateFile);
            setFile(null);
            setIsUploaded(false);
          }}
          ariaHideApp={false}
        >
          <div className="container-fluid col-12">
            <div className="text-center">
              <span className="modal-titre">ADMINISTRATION</span>
            </div>
            <br />
            <div className="text-center mb-5">
              <span className="modal-sous-titre">
                {`Mettre à jour l'interface :`} {selectFile.toLocaleLowerCase()}
              </span>
            </div>

            <div
              className="col-12 text-center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button type="button" className="btn btn-kerij-rh col-10" onClick={handleClickUpload}>
                <img alt="selectfiles" src={ImgUpdateFiles} />
                &nbsp;&nbsp;&nbsp;Sélectionner un fichier
              </button>
              <input
                type="file"
                id="file"
                className="btn btn-kerij-rh col-10"
                style={{ display: 'none' }}
                ref={ref}
                multiple={false}
                onChange={handleChangeFile}
              />
            </div>
            <div className="col-12 text-center mt-3">
              <span>Taille des fichiers : 5 Mo maximum</span>
            </div>
            {/* <div className="col-10 text-center mt-4">
                        <div className="wait-files text-start col-12">
                            <img alt="selectfiles" src={ImgFileUploaded} />&nbsp;&nbsp;&nbsp;
                        </div>
                    </div> */}
            {file && (
              <div className="col-10 row files-uploaded text-center mt-4">
                {isUploaded && (
                  <div
                    className="col-1 text-center btn-lg"
                    style={{
                      color: '#40C9C9',
                      position: 'absolute',
                      left: 20,
                    }}
                  >
                    <FontAwesomeIcon icon={'fa-solid fa-circle-check' as any} style={{ color: '#79D9D9' }} />
                  </div>
                )}
                <div
                  className="text-start col-10"
                  onClick={() => {
                    handleOpenFile(file);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <img alt="selectfiles" src={ImgFileUploaded} />
                  &nbsp;&nbsp;&nbsp; {file.name}
                </div>
                <div className="text-end col-2" onClick={() => setFile(null)}>
                  <img alt="selectfiles" style={{ cursor: 'pointer' }} src={ImgTrash} />
                </div>
              </div>
            )}
            <div className="col-12 mt-5">
              <div className="text-center">
                <button
                  className={file ? 'btn-kerij mx-auto' : 'btn-kerij mx-auto disabled'}
                  style={{ width: '30%' }}
                  onClick={handleValidateFile}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {isLoading && (
          <Modal
            // style={customStyles}
            className="Modal"
            // overlayClassName="Overlay"
            // className="modal-document"
            closeTimeoutMS={200}
            isOpen={isLoading}
            ariaHideApp={false}
          >
            <div className="container-fluid container-loading text-center">
              <h3 className="question-form mt-5 mb-5 text-center">Traitement de votre document</h3>
              <div className="container">
                <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
              </div>
              <p className="mt-5">Veuillez patienter, KERij traite votre document...</p>
              <p>{`L'opération peut prendre quelques minutes.`}</p>
              <p>Merci.</p>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default AdminFichiers;
