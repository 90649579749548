/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { ParcoursScriptPresence, ParcoursScriptWhen } from '@/types';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { DurationInput } from '@/components/DurationInput';
import { parcoursPresence } from '@/components/ParcoursDetail';

/**
 * Display the delay
 */
export function parcoursWhenDelay(delayInDays: number) {
  return delayInDays === 0 ? 'J' : delayInDays < 0 ? `J-${Math.abs(delayInDays)}` : `J+${delayInDays}`;
}

type InputValue = ParcoursScriptWhen;

export interface ParcoursWhenInputProps {
  value?: InputValue;
  onChange?: (value: InputValue) => void;
}

export function ParcoursWhenInput(props: ParcoursWhenInputProps) {
  const { value = { reference_date: 'parcoursTriggeredAt', delay: 0, employee_presence: 'any' }, onChange } = props;
  const useField = <K extends keyof InputValue>(fieldName: K) => ({
    value: value[fieldName],
    onChange: (fieldValue: InputValue[K]) =>
      onChange?.({
        ...value,
        [fieldName]: fieldValue,
      }),
  });
  const referenceDateController = useField('reference_date');
  const delayController = useField('delay');
  const presenceController = useField('employee_presence');

  return (
    <Stack direction="column" spacing={4}>
      <FormControl>
        {/*
         * Date de référence
         */}
        <FormLabel>Date de réference</FormLabel>
        <RadioGroup
          {...referenceDateController}
          onChange={(event) =>
            referenceDateController.onChange(
              // @ts-ignore Data are ok
              event.target.value,
            )
          }
        >
          {[
            { value: 'parcoursTriggeredAt' as const, label: "Date d'éligibilité" },
            { value: 'absenceLastDateEnd' as const, label: "Date de fin d'absence connue" },
          ].map((option) => {
            return (
              <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            );
          })}
        </RadioGroup>
      </FormControl>

      {/*
       * Retardateur
       */}
      <Box sx={(theme) => ({ width: theme.spacing(45) })}>
        <DurationInput label={'Retardateur'} {...delayController} />
      </Box>

      {/*
       * Condition de présence
       */}
      <FormControl>
        <FormLabel>Condition de présence</FormLabel>
        <RadioGroup
          defaultValue={ParcoursScriptPresence.Values.any}
          {...presenceController}
          onChange={(event) =>
            presenceController.onChange(
              // @ts-ignore Data are ok
              event.target.value,
            )
          }
        >
          {[
            { value: ParcoursScriptPresence.Values.any },
            {
              value: ParcoursScriptPresence.Values.present,
            },
            {
              value: ParcoursScriptPresence.Values.absent,
            },
          ].map((option) => {
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={parcoursPresence(option.value)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
