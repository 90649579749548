import React from 'react';
import { QUESTIONNAIRE_SUIVI_CHOICES } from './data';
import { Absence } from '@/types/absences.type';
import { AppSelect } from '../AppSelect';

interface QuestionnaireSuiviFormProperties {
  value: string;
  absence?: Absence;
  onChange: (value: string) => void;
}

export function QuestionnaireSuiviForm({ value, absence, onChange }: QuestionnaireSuiviFormProperties) {
  return (
    <AppSelect
      value={value}
      items={[
        { label: 'Je suis revenu(e) à la date indiquée', value: QUESTIONNAIRE_SUIVI_CHOICES.BACK_CORRECT_DATE },
        ...(absence && absence.date_start
          ? [{ label: 'Je suis revenu(e) avant la date indiquée', value: QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE }]
          : []),
        { label: 'Mon absence est prolongée', value: QUESTIONNAIRE_SUIVI_CHOICES.BACK_PROLONGATION },
      ]}
      dense
      placeholder="Veuillez sélectionner"
      onChange={(_, value) => {
        onChange(value);
      }}
    />
  );
}
