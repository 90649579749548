import React, { useEffect, useState } from 'react';
import imgActions from '@/assets/img-actions-dashboard.svg';
import { useNavigate } from 'react-router-dom';
import { getDataActions } from '@/shared/services/getDataEvents.service';
import { useError } from '@/shared/useError';
import { PRO_URL } from '@/constants/urls.constants';
import { getFullName } from '@/shared/utils/getFullName.service';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  ListItem,
  ListItemButton,
  styled,
  Typography,
  typographyClasses,
} from '@mui/material';
import { useAppDispatch, useAppSelector, RHNotification } from '@/store';
import { RHNotificationAssociationType, type RHNotification as RHNotificationType } from '@/types';
import { IfPermission } from '@/components/IfPermission';
import { ListCardActions } from '../ListCard';
import { ActionButton } from '@/components/ActionButton';
import { Badge } from '@/components/Badge';
import { PageSectionList } from '@/components/Page';

const ToDoAction = () => {
  const dispatch = useAppDispatch();

  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);
  const { triggerError, clearError } = useError();
  const [data, setData] = useState<RHNotificationType[]>([]);

  const total = useAppSelector(RHNotification.getTotal);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchActions = async () => {
      setLoading(true);
      clearError();
      try {
        const data = await getDataActions({
          dateDebut: '',
          dateFin: '',
          search: '',
          listSelectBusiness: listSelectBusiness,
          selectedIdBusiness: selectedIdBusiness,
          offset: 0,
          limit: 4,
        });
        setData(data.actions);
        dispatch(RHNotification.actions.setTotal(data?.length || 0));
      } catch {
        triggerError();
      }
      setLoading(false);
    };
    fetchActions();
  }, [listSelectBusiness, selectedIdBusiness]);

  const noActions = () => {
    return (
      <Box minHeight={231} display={'flex'}>
        <Typography m={'auto'} variant="h6">
          {`Il n'y a aucune actions à réaliser`}
        </Typography>
      </Box>
    );
  };

  const renderLoading = () => {
    return (
      <Box minHeight={231} display={'flex'}>
        <Box m={'auto'}>
          {' '}
          <CircularProgress />
        </Box>
      </Box>
    );
  };

  const previewActions = () => (
    <PageSectionList PaperProps={{ elevation: 0, style: { borderRadius: 0 } }}>
      {data.slice(0, 4).map((value, index) => (
        <ListItem key={`Action__${index}`} disablePadding>
          <ListItemButton onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS)} disableRipple>
            <Grid container spacing={2.5} minHeight={69}>
              <Grid item xs={4} display={'flex'}>
                <Typography fontSize={14} fontWeight={600} mt={'auto'} mb={'auto'}>
                  {value.employee_information ? getFullName(value.employee_information) : ''}
                </Typography>
              </Grid>

              <Grid item xs={1} display={'flex'}>
                {value.employee_information && value.max_level && value.current_level ? (
                  <Pastille>
                    <Typography fontSize={12} fontWeight={700}>
                      {`${Number.parseInt(String(value.current_level))} / ${value.max_level}`}
                    </Typography>
                  </Pastille>
                ) : null}
              </Grid>

              <Grid item xs={7} display={'flex'}>
                <Typography fontSize={12} fontWeight={600} mt={'auto'} mb={'auto'} ml={'auto'} textAlign={'right'}>
                  {value.rh_notification_histories &&
                    value.rh_notification_histories.length > 0 &&
                    value.rh_notification_histories
                      .sort((a, b) => new Date(b.date_notification).getTime() - new Date(a.date_notification).getTime())
                      .at(0)?.action}

                  {value.association_type === RHNotificationAssociationType.Parcours && <>Déclenchement de parcours</>}
                </Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        </ListItem>
      ))}
    </PageSectionList>
  );

  const allActionsButton = (
    <IfPermission scope="absence-management" action="edit">
      <ActionButton
        color="secondary"
        actionName="next"
        label={'Toutes les actions à réaliser'}
        onClick={() => {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS);
        }}
      />
    </IfPermission>
  );

  return (
    <Card elevation={3} sx={(theme) => ({ border: '1px solid', borderColor: theme.shape.borderColorVariant })}>
      <CardHeader
        avatar={<img src={imgActions} />}
        sx={(theme) => ({
          backgroundColor: theme.md3.sys.color.primary,
          color: theme.md3.sys.color.onPrimary,
          paddingTop: theme.spacing(1.75),
          paddingBottom: theme.spacing(1.75),
        })}
        title={
          <Box display={'flex'}>
            <Typography flexGrow={1} fontSize={14} fontWeight={600}>
              Actions à réaliser
            </Typography>
            {total > 0 && <Badge label={total} />}
          </Box>
        }
      />

      <CardContent sx={{ p: 0 }}>
        {loading && <>{renderLoading()}</>}
        {!loading && <>{data.length > 0 ? previewActions() : noActions()}</>}
      </CardContent>

      <ListCardActions>{allActionsButton}</ListCardActions>
    </Card>
  );
};

export default ToDoAction;

const Pastille = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(3),
  width: 'auto',
  borderRadius: theme.shape.borderRadius * 2,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  backgroundColor: theme.md3.sys.color.primary,
  border: 0,
  margin: 'auto',
  [`& .${typographyClasses.root}`]: {
    color: theme.md3.sys.color.onSurface,
  },
}));
