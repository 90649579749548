import React, { useState, useContext, useEffect } from 'react';
import { NavbarMobile } from '@/components/Navbar';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import axios from '../../../lib/axios';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import './FicheEntretienRetour.css';
import moment from 'moment';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { Document, Page } from 'react-pdf';
import TextAreaAutoResize from '../FicheEntretienLiaison/TextAreaAutoResize';
import ReactLoading from 'react-loading';
import { customStyles } from '../../../shared/models/custom_modal.models';
import { KerijBox, KerijBoxType } from '../../base';
import { PRO_URL } from '../../../constants/urls.constants';
import { StatusCodes } from 'http-status-codes';
import useDecodeToken from '../../../hooks/useDecodeToken';
import { useError } from '@/shared/useError';
import { getNotif } from '../../../services/rh';
const { Buffer } = require('buffer');

function FicheEntretienLiaison() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [access, setAccess] = useState(false);
  const [notif, setNotif] = useState();
  const [arretEncours, setArretEncours] = useState();
  const [historique, setHistorique] = useState([]);
  const [idNotif, setIdNotif] = useState('');
  const [idSalarie, setIdSalarie] = useState('');
  const [type, setType] = useState('');
  const [src, setSrc] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { decodedToken, tokenFetchError } = useDecodeToken(token);
  const { error, triggerError, clearError } = useError();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [modalIsConfirmFiche, setModalIsConfirmFiche] = useState(false);
  const [decode, setDecode] = useState({
    idRhNotification: '',
    idBusiness: '',
    typeContact: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      if (decodedToken && decodedToken.idRhNotification && !tokenFetchError) {
        try {
          const response = await getNotif(token, decodedToken);
          if (response.status === StatusCodes.OK) {
            clearError();
            if (response.data.isExisted) {
              setAccess(false);
            } else {
              setIdSalarie(response.data.idEmployeeInformation);
              setArretEncours(response.data.progressAbsence);
              setHistorique(response.data.historicAbsence);
              setNotif(response.data.notif);
              setIdNotif(response.data.notif.id);
              setType(response.data.type);
              setAccess(true);
            }
          }
        } catch {
          setAccess(false);
          triggerError();
        }
      }
    };
    fetchData();
  }, [decodedToken, tokenFetchError]);

  const previewDocument = async () => {
    const printPDF = document.querySelector('#print-pdf');
    drawDOM(printPDF, {
      scale: 0.8,
      paperSize: 'C1',
    })
      .then((group) => {
        return exportPDF(group, {
          scale: 0.8,
          paperSize: 'C1',
        });
      })
      .then((dataUri) => {
        try {
          const buffer = dataUri.split(';base64,')[1];
          const binary = Buffer.from(buffer.replaceAll(/\s/g, ''), 'base64');
          // var byteArray = new Uint8Array(binary.length)
          var blob = new Blob([binary], { type: 'application/pdf' });
          const src = URL.createObjectURL(blob);
          window.open(src, '_blank');
        } catch (err) {
          console.log(err);
        }
      });
  };

  const valideDocument = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setModalIsConfirmFiche(false);
    const now = moment();
    var formData = new FormData();
    formData.set('dateAction', moment(now).format('YYYY-MM-DD'));
    // formData.set("idEmployeeInformation", idSalarie)
    // formData.set("idRhNotification", idNotif)
    formData.set('type', type);
    const printPDF = document.querySelector('#print-pdf');
    const blob = await drawDOM(printPDF, {
      scale: 0.8,
      paperSize: 'C1',
    })
      .then((group) => {
        return exportPDF(group, {
          scale: 0.8,
          paperSize: 'C1',
        });
      })
      .then((dataUri) => {
        try {
          const buffer = dataUri.split(';base64,')[1];
          const binary = Buffer.from(buffer.replaceAll(/\s/g, ''), 'base64');
          return new Blob([binary], { type: 'application/pdf' });
        } catch (err) {
          console.log(err);
        }
      });
    formData.append('documents', blob, `${moment().format('YYYYMMDD_HHmmss')}_${idSalarie}_Entretien_de_retour`);
    axios
      .post('/api/token/encode', {
        idEmployeeInformation: idSalarie,
        idRhNotification: idNotif,
        idBusiness: notif.id_business,
      })
      .then((val) => {
        if (val.status === StatusCodes.OK) {
          const tk = val.data.token;
          axios
            .post(`/api/rh/upload_interview_document`, formData, {
              headers: {
                Authorization: `Bearer ${tk}`,
              },
            })
            .then((value) => {
              if (value.status === StatusCodes.OK) {
                setIsLoading(false);
                navigate('/entretienfichefin');
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      });
  };

  return (
    <div>
      <div className="px-4 my-3">
        <Link className="text-link mt-4 pt-5" to={PRO_URL.PRO} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <img src={logo} className="App-logo" alt="logo" width="73" />
        </Link>
      </div>
      {isLoading ? (
        <div className="container-fluid container-loading text-center">
          <h3 className="question-form mt-5 mb-5 text-center">Envoie du document</h3>
          <div className="container">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
          <p className="mt-5">Veuillez patienter, KERij envoie votre document...</p>
          <p>L'opération peut prendre quelques instants.</p>
          <p>Merci.</p>
        </div>
      ) : (
        <div>
          {access && notif ? (
            <div>
              <div className="container-fluid mt-5">
                <h1 className="question-form mb-3 mt-10">
                  Vous allez réaliser un{' '}
                  {((type === 'Entretien de liaison' || type === 'Planifier entretien de liaison') &&
                    'entretien de liaison') ||
                    "entretien de retour à l'emploi"}
                </h1>

                {/* Intro */}
                <div className="col-8 mb-5">
                  <p className="text-center">
                    <i>Comment se déroule l’entretien de retour ?</i>
                  </p>
                  <button
                    type="button"
                    className="btn btn-kerij-bleu-infodoc offset-4 col-4"
                    onClick={() => {
                      if (decode.typeContact === 'Manager') {
                        axios
                          .get('/api/rh/visualize_list_absence', {
                            params: {
                              url: `src/data/Guide d'entretien de retour à l'emploi - Guide d'accompagnement manager n+1.pdf`,
                              nom: ``,
                            },
                            responseType: 'arraybuffer',
                          })
                          .then((val) => {
                            var file = new Blob([val.data]);
                            if (file !== null) {
                              setSrc(URL.createObjectURL(file));
                              setModalIsOpen(true);
                            }
                          });
                      } else {
                        axios
                          .get('/api/rh/visualize_list_absence', {
                            params: {
                              url: `src/data/Guide d'entretien de retour à l'emploi - Guide d'accompagnement RH.pdf`,
                              nom: ``,
                            },
                            responseType: 'arraybuffer',
                          })
                          .then((val) => {
                            var file = new Blob([val.data]);
                            if (file !== null) {
                              setSrc(URL.createObjectURL(file));
                              setModalIsOpen(true);
                            }
                          });
                      }
                    }}
                  >
                    <div className="col-12">
                      <FontAwesomeIcon className="fa-1x" icon={faCircleInfo} /> &nbsp;
                      <font>Comment se déroule l'entretien de retour ? </font>
                    </div>
                  </button>
                </div>

                <div className="col-8">
                  <KerijBox
                    message={
                      <span>
                        Cette fiche d’entretien contient des zones de commentaires vous permettant de renseigner
                        librement des informations sur le collaborateur concerné. Conformément à la réglementation, vous
                        devez veiller impérativement à ne pas faire de commentaires excessifs ou insultants envers tout
                        individu et dont le collaborateur concerné par l’entretien.
                      </span>
                    }
                    type={KerijBoxType.Warning}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  id="print-pdf"
                >
                  <form className="form-legacy col-lg-8">
                    {/* Informations Salarié */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Informations du salarié</h3>
                      <div className="row align-items-center mb-3">
                        <div className="col-2">Nom : </div>
                        <strong className="col-4">{notif.employee_information.lastname}</strong>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-2">Prénom : </div>
                        <strong className="col-4">
                          {notif.employee_information.firstname.at(0).toUpperCase() +
                            notif.employee_information.firstname.slice(1).toLowerCase()}
                        </strong>
                      </div>

                      <div className="row align-items-center">
                        <span className="col-2">
                          <label>Poste : </label>
                        </span>
                        <span className="col-4">
                          <input
                            name="poste-salarie"
                            type="text"
                            // value=""
                            // onChange=""
                            required
                          />
                        </span>
                      </div>

                      <div className="row align-items-center">
                        <span className="col-2">
                          <label>Service : </label>
                        </span>
                        <span className="col-4">
                          <input
                            name="service-salarie"
                            type="text"
                            // value=""
                            // onChange=""
                            required
                          />
                        </span>
                      </div>
                    </div>

                    {/* Qui réalise l'entretien ? */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Qui réalise l'entretien ?</h3>

                      <div className="row align-items-center">
                        <span className="col-2">
                          <label>Nom : </label>
                        </span>
                        <span className="col-4">
                          <input
                            name="nom-repsonsable"
                            type="text"
                            // value=""
                            // onChange=""
                            required
                          />
                        </span>
                      </div>
                      <div className="row align-items-center">
                        <span className="col-2">
                          <label>Prénom : </label>
                        </span>
                        <span className="col-4">
                          <input
                            name="prenom-repsonsable"
                            type="text"
                            // value=""
                            // onChange=""
                            required
                          />
                        </span>
                      </div>
                      <div className="row align-items-center">
                        <span className="col-2">
                          <label>Fonction: </label>
                        </span>
                        <span className="col-4">
                          <input
                            name="fonction-repsonsable"
                            type="text"
                            // value=""
                            // onChange=""
                            required
                          />
                        </span>
                      </div>
                    </div>

                    {/* Les arrêts du salarié */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Les arrêts du salarié</h3>

                      <h5 className="mb-3">
                        <strong> Arrêt en cours</strong>{' '}
                      </h5>

                      {arretEncours ? (
                        <div>
                          {moment(arretEncours.date_start).format('DD/MM/YYYY')} -{' '}
                          {arretEncours.date_end ? moment(arretEncours.date_end).format('DD/MM/YYYY') : '--'} -{' '}
                          {arretEncours.date_end
                            ? moment(arretEncours.date_end).diff(moment(arretEncours.date_start), 'd') + 1
                            : moment().diff(moment(arretEncours.date_start), 'd') + 1}{' '}
                          {(arretEncours.date_end
                            ? moment(arretEncours.date_end).diff(moment(arretEncours.date_start), 'd') + 1
                            : moment().diff(moment(arretEncours.date_start), 'd') + 1) > 1
                            ? 'jours'
                            : 'jour'}{' '}
                          - {arretEncours.type_absence.description_label}
                        </div>
                      ) : (
                        <p>Aucun arrêt</p>
                      )}
                      {/* <div>15/09/2022 - 30/09/2022 - 16 jours - Arrêt de travail</div> */}

                      <h5 className="mb-3 mt-3">
                        {' '}
                        <strong>Historique des arrêts (12 mois) </strong>
                      </h5>

                      {historique && historique.length > 0 ? (
                        historique.map((value, index) => {
                          return (
                            <div key={index}>
                              {moment(value.date_start).format('DD/MM/YYYY')} -{' '}
                              {value.date_end ? moment(value.date_end).format('DD/MM/YYYY') : '--'} -{' '}
                              {value.date_end
                                ? moment(value.date_end).diff(moment(value.date_start), 'd') + 1
                                : moment().diff(moment(value.date_start), 'd') + 1}{' '}
                              {(value.date_end
                                ? moment(value.date_end).diff(moment(value.date_start), 'd') + 1
                                : moment().diff(moment(value.date_start), 'd') + 1) > 1
                                ? 'jours'
                                : 'jour'}{' '}
                              - {value.type_absence.description_label}
                            </div>
                          );
                        })
                      ) : (
                        <p>Aucun arrêts</p>
                      )}
                    </div>

                    {/* Suivi et accompagnement de l'arrêt du salarié */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Suivi et accompagnement de l'arrêt du salarié</h3>

                      <div className="row align-items-center">
                        <span className="col-6">
                          <label>Date du dernier entretien professionnel : </label>
                        </span>
                        <span className="col-4">
                          <input
                            id="date1"
                            className="form-control form-date champ-date"
                            type="date"
                            name="date1"
                          ></input>
                        </span>
                      </div>
                      <div className="row align-items-center">
                        <span className="col-6">
                          <label>Date de l’entretien de liaison (à indiquer si il a eu lieu): </label>
                        </span>
                        <span className="col-4">
                          <input
                            id="date2"
                            className="form-control form-date champ-date"
                            type="date"
                            name="date2"
                          ></input>
                        </span>
                      </div>
                      <div className="row align-items-center">
                        <span className="col-6">
                          <label>Date de la visite de pré-reprise (à indiquer si elle a eu lieu) : </label>
                        </span>
                        <span className="col-4">
                          <input
                            id="date3"
                            className="form-control form-date champ-date"
                            type="date"
                            name="date3"
                          ></input>
                        </span>
                      </div>

                      <div className="row align-items-center">
                        <span className="col-6">
                          <label>Date de la visite médicale de reprise : </label>
                        </span>
                        <span className="col-4">
                          <input
                            id="date4"
                            className="form-control form-date champ-date"
                            type="date"
                            name="date4"
                          ></input>
                        </span>
                      </div>
                    </div>

                    {/* Actions évoquées lors de l’entretien de liaison (si il a eu lieu) : */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Actions évoquées lors de l’entretien de liaison (si il a eu lieu) :</h3>

                      <div>
                        <label>
                          <input type="checkbox" />
                          Actions de formation (formation et formation par apprentissage, bilan de compétence,
                          validation des acquis d’expérience)
                        </label>
                      </div>

                      <div>
                        <label>
                          <input type="checkbox" />
                          Actions d’évaluation
                        </label>
                      </div>

                      <div>
                        <label>
                          <input type="checkbox" />
                          Actions d’accompagnement et conseil (telles que l’essai encadré et la convention de
                          rééducation professionnelle) à envisager avec le soutien des acteurs de la compensation du
                          handicap et les acteurs de la réadaptation selon les territoires
                        </label>
                      </div>

                      <div>
                        <label>
                          <input type="checkbox" />
                          Organisation d’un entretien de pré-reprise
                        </label>
                      </div>

                      <div>
                        <label>
                          <input type="checkbox" />
                          Mise en place de mesures individuelles (d’aménagement, d’adaptation ou de transformation du
                          poste de travail, d’aménagement du temps de travail ), selon proposition par la médecine du
                          travail
                        </label>
                      </div>
                    </div>

                    {/* Votre état de santé physique et mentale  */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Votre état de santé physique et mentale</h3>

                      <div>
                        <strong>Prise de connaissance de votre état de santé physique et mentale. </strong>
                        <br />
                        <em>
                          <i>
                            <small>Comment allez-vous ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Comment vous sentez-vous physiquement ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Comment vous sentez-vous moralement ?</small>
                          </i>
                        </em>
                      </div>

                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      <div className="mt-3 mb-3">
                        <strong>
                          Comment évalueriez-vous votre état de santé{' '}
                          <u>
                            <i>physique</i>
                          </u>{' '}
                          sur une échelle de 1 à 10 allant de 1 Très mauvais à 10 Très bon ?{' '}
                        </strong>
                      </div>

                      <div className="form-check form-check-inline">
                        <input type="radio" value="1" name="physique" />
                        <label className="form-check-label">1</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="2" name="physique" />
                        <label className="form-check-label">2</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="3" name="physique" />
                        <label className="form-check-label">3</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="4" name="physique" />
                        <label className="form-check-label">4</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="5" name="physique" />
                        <label className="form-check-label">5</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="6" name="physique" />
                        <label className="form-check-label">6</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="7" name="physique" />
                        <label className="form-check-label">7</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="8" name="physique" />
                        <label className="form-check-label">8</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="9" name="physique" />
                        <label className="form-check-label">9</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="10" name="physique" />
                        <label className="form-check-label">10</label>
                      </div>

                      <div className="mt-3">
                        <strong>
                          Comment qualifieriez-vous votre état de santé{' '}
                          <u>
                            <i>mentale</i>
                          </u>{' '}
                          sur une échelle de 1 à 10 allant de 1 Très mauvais à 10 Très bon ?{' '}
                        </strong>
                      </div>

                      <div className="form-check form-check-inline">
                        <input type="radio" value="1" name="sante" />
                        <label className="form-check-label">1</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="2" name="sante" />
                        <label className="form-check-label">2</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="3" name="sante" />
                        <label className="form-check-label">3</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="4" name="sante" />
                        <label className="form-check-label">4</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="5" name="sante" />
                        <label className="form-check-label">5</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="6" name="sante" />
                        <label className="form-check-label">6</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="7" name="sante" />
                        <label className="form-check-label">7</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="8" name="sante" />
                        <label className="form-check-label">8</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="9" name="sante" />
                        <label className="form-check-label">9</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" value="10" name="sante" />
                        <label className="form-check-label">10</label>
                      </div>

                      <div className="mt-3">
                        <strong>
                          Comment abordez-vous votre reprise au travail ? Comment vous sentez-vous quant à cette reprise
                          ?
                        </strong>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* La qualité de vos relations professionnelles */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">La qualité de vos relations professionnelles</h3>

                      {/* Condition : Ne pas afficher si c'est le manager */}

                      <div>
                        <strong>
                          Prise de connaissance de la relation du salarié avec le/la supérieur(e) immédiat(e)
                        </strong>
                        <br />
                        <em>
                          <i>
                            <small>
                              Quelle perception avez-vous du soutien apporté par votre supérieure immédiate dans le
                              cadre de votre arrêt et de votre retour au travail ?
                            </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      <div className="mt-3">
                        <strong>
                          Questionnez la perception de la considération de sa/son supérieur(e) immédiat(e) pour sa
                          situation
                        </strong>
                        <br />
                        <em>
                          <i>
                            <small>
                              Comment qualifiez-vous la considération de votre supérieure immédiate quant à la mise en
                              place de conditions favorables à votre retour ?
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Comment qualifiez-vous la disponibilité de votre supérieure immédiate pour vous
                              accompagner dans le cadre de votre retour ?
                            </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      {/* Fin de condition */}

                      <div className="mt-3">
                        <strong>Prise de connaissance de la relation avec les collègues</strong>
                        <br />
                        <em>
                          <i>
                            <small>Avez-vous maintenu les échanges avec vos collègues durant votre arrêt ? </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Appréhendez-vous l’absence de soutien de vos collègues lors de votre retour au travail ?
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Présentez-vous des craintes à reprendre contact avec vos collègues de travail ?
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Appréhendez-vous de vous sentir obligé de révéler les motifs de votre absence à vos
                              collègues de travail ?{' '}
                            </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      <div className="mt-3">
                        <strong>Prise de connaissance du soutien apporté par l’entreprise</strong>
                        <br />
                        <em>
                          <i>
                            <small>Vous êtes-vous senti(e) et vous sentez-vous soutenu(e) par l’entreprise ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Vous êtes-vous senti(e) et vous sentez-vous accompagné(e) par l’entreprise ? </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Vous êtes-vous senti(e) et vous sentez-vous écouté(e) par l’entreprise ? </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Que pensez-vous des actions mises en place par l’entreprise pour votre retour ?{' '}
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Estimez-vous que l’entreprise à mis en place les conditions et les solutions que vous
                              attendiez pour favoriser votre retour ?{' '}
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Que pourrait-on faire pour améliorer davantage vos conditions de retour ?</small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* Exigences du poste */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Exigences du poste</h3>

                      <div>
                        <strong>Questionnez l’appréhension à reprendre le travail</strong>
                        <br />
                        <em>
                          <i>
                            <small>Vous sentez-vous de faire face aux exigences de votre poste ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Craignez-vous d’être surchargé(e) dès les premiers jours de votre retour au travail ?
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Craignez-vous le manque de reconnaissance quant aux efforts fournis pour reprendre votre
                              travail ?
                            </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      <div className="mt-3">
                        <strong>Questionnez la motivation à reprendre le travail</strong>
                        <br />
                        <em>
                          <i>
                            <small>
                              Quels sont les moments qui vous motivent au cours de votre journée de travail ?
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Ressentez-vous avoir perdu l’intérêt de travailler ?</small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* L’organisation interne mise en place durant votre arrêt  */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">L’organisation interne mise en place durant votre arrêt </h3>

                      <div>
                        <strong>
                          Informations sur les changements, la réorganisation de l’organisation et/ou du poste.
                        </strong>
                        <br />
                        <em>
                          <i>
                            <small>
                              Remplacement, évolution du poste, changements (de l’organisation et/ou du poste);
                              évolutions vouées à être pérennisées …
                            </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>
                              Présenter le poste si celui-ci est nouveau. Préciser les attentes du poste si celles-ci
                              sont nouvelles ou ont évoluées.
                            </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* Votre avenir professionnel */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Votre avenir professionnel</h3>

                      <div>
                        <strong>Prenez connaissance de sa vision quant à son avenir </strong>
                        <br />
                        <em>
                          <i>
                            <small>Comment voyez-vous votre avenir professionnel ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Comment vous projetez-vous quant à cette reprise de votre poste ? </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Envisagez-vous des changements suite à votre arrêt ? Quels sont-ils ? </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      <div className="mt-3">
                        <strong>(Ré)informez le/la salarié(e) des actions dont il peut bénéficier</strong>
                        <br />
                        <em>
                          <i>
                            <small>Comment voyez-vous votre avenir professionnel ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Comment vous projetez-vous quant à cette reprise de votre poste ? </small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Envisagez-vous des changements suite à votre arrêt ? Quels sont-ils ? </small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* Nous continuons de vous accompagner après votre reprise */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Nous continuons de vous accompagner après votre reprise</h3>

                      <div>
                        <strong>
                          Durant votre reprise vous n’êtes pas seul(e). (Re)Présentez l’équipe accompagnatrice et mettez
                          à disposition du salarié leurs informations de contact.
                        </strong>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>

                      <div className="mt-3">
                        <strong>(Ré)Informez le salarié des actions dont il peut bénéficier.</strong>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* Conclusion */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Conclusion</h3>

                      <div>
                        <strong>Clôture</strong>
                        <br />
                        <em>
                          <i>
                            <small>Avez-vous des questions ?</small>
                          </i>
                        </em>
                        <br />
                        <em>
                          <i>
                            <small>Souhaiteriez-vous aborder d’autres points que nous n’avons pas abordés ?</small>
                          </i>
                        </em>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* Synthèse */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Synthèse</h3>

                      <div>
                        <strong>Quels sont vos besoins d’accompagnement ?</strong>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />
                          Psychologue du travail de l’entreprise
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />
                          Assistante sociale
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />
                          Référente handicap
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />
                          Médecin du travail
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />
                          Service paie
                        </label>
                      </div>

                      <div>
                        <label>
                          <input type="checkbox" />
                          Formation
                        </label>
                      </div>

                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" placeholder="Autre ..." /> */}
                        <TextAreaAutoResize placeholder="Autre ..."></TextAreaAutoResize>
                      </div>

                      <div className="mt-3">
                        <strong>Effectuez un état des lieux du suivi de l’évolution professionnelle du salarié</strong>
                      </div>
                      <br />
                      <em>
                        <i>
                          <small>Au cours des 6 dernières années le/la salarié(e) ...</small>
                        </i>
                      </em>
                      <div>
                        <label>
                          <input type="checkbox" />A bénéficié des entretiens professionnels (prévus tous les deux ans
                          minimum)
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />A suivi au moins une action de formation
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />A acquis des éléments de certification par la formation ou par une
                          validation des acquis de son expérience
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="checkbox" />A bénéficié d'une progression salariale ou professionnelle.
                        </label>
                      </div>

                      <div className="mt-3">
                        <strong>Suivi du retour à l’emploi</strong>
                      </div>
                      <br />
                      <em>
                        <i>
                          <small>Prenez date du prochain rendez-vous.</small>
                        </i>
                      </em>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>

                    {/* Commentaire */}
                    <div className="entretien-cadre">
                      <h3 className="mb-3">Commentaire libre</h3>

                      <div>
                        <strong>Souhaitez-vous ajouter quelque chose ? </strong>
                      </div>
                      <div>
                        {/* <textarea rows="5" cols="50" className="container-fluid" /> */}
                        <TextAreaAutoResize></TextAreaAutoResize>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-2 d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-kerij"
                    onClick={previewDocument}
                    style={{ width: 'calc(50% - 10px)' }}
                  >
                    Voir ma fiche
                  </button>
                  <button
                    type="button"
                    className="btn btn-kerij"
                    onClick={() => {
                      setModalIsConfirmFiche(true);
                    }}
                    style={{ width: 'calc(50% - 10px)' }}
                  >
                    Valider ma fiche
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
            </div>
          )}
        </div>
      )}

      <Modal
        style={customStyles}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(!modalIsOpen);
        }}
        ariaHideApp={false}
        contentLabel="Guide de l'entretien de retour à l'emploi"
      >
        <button
          onClick={() => {
            setModalIsOpen(!modalIsOpen);
          }}
          className="btn btn-lg"
        >
          <FontAwesomeIcon className="fa-3x" icon={faXmark} />
        </button>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page scale={2} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </Modal>

      <Modal
        style={customStyles}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsConfirmFiche}
        onRequestClose={() => {
          setModalIsConfirmFiche(!modalIsConfirmFiche);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsConfirmFiche(!modalIsConfirmFiche);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">Attention</h3>
        <div className="text-center text-modal">
          Vous êtes sur le point de valider la fiche d’entretien. Une fois validée celle-ci ne pourra plus être modifiée
        </div>
        <div className="text-center mb-4 text-modal">Confirmez-vous l'envoi de la fiche ?</div>
        <div className="text-center">
          <button className="btn btn-kerij-bleu btn-modal col-6 mb-3" onClick={valideDocument}>
            Oui je valide ma fiche
          </button>
        </div>
        <div className="text-center">
          <button
            className="btn btn-kerij-bleu btn-modal col-6"
            onClick={() => {
              setModalIsConfirmFiche(false);
            }}
          >
            Non je modifie ma fiche
          </button>
        </div>
      </Modal>

      <NavbarMobile />
    </div>
  );
}

export default FicheEntretienLiaison;
