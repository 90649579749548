import { goldenHarvest } from '../../color';
import aquaSerenity from '../../color/aquaSerenity';
import infernoRed from '../../color/infernoRed';
import royalLavender from '../../color/royalLavender';
import silverVeil from '../../color/silverVeil';

// https://material-web.dev/theming/color/
// Utiliser les tokens de couleurs et non les couleurs directement
export const color = {
  // Primary
  primary: aquaSerenity[400],
  onPrimary: royalLavender[950],
  primaryContainer: aquaSerenity[100],
  onPrimaryContainer: aquaSerenity[950],
  // Secondary
  secondary: undefined, // TODO:
  onSecondary: undefined, // TODO:
  secondaryContainer: undefined, // TODO:
  onSecondaryContainer: undefined, // TODO:
  // Tertiary
  tertiary: royalLavender[600],
  onTertiary: '#FFFFFF',
  tertiaryContainer: goldenHarvest[200],
  onTertiaryContainer: royalLavender[950],
  // Error
  error: infernoRed[700],
  onError: '#FFFFFF',
  errorContainer: undefined, // TODO:
  onErrorContainer: undefined, // TODO:

  surface: royalLavender[50],
  onSurface: royalLavender[950],

  outlineVariant: silverVeil[300],
  onSurfaceVariant: royalLavender[700],

  outline: silverVeil[400],
  white: '#FFFFFF',
};
export type ColorScheme = typeof color;
