import React from 'react';
import { AppTextField } from '../AppTextField';

export interface AbsenceDateRangeInputProps {
  label: string;
  name: string;
  min?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (newValue?: string) => void;
}

export function AbsenceDateRangeInput(props: AbsenceDateRangeInputProps) {
  const { label, disabled, onChange, ...othersPros } = props;

  return (
    <AppTextField
      label={label}
      InputProps={{
        inputProps: { type: 'date' },
      }}
      readOnly={disabled}
      onChange={(_, value) => onChange?.(value)}
      onBlur={(event) => onChange?.(event.target.value)}
      {...othersPros}
    />
  );
}
