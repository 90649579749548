import { useQuery } from 'react-query';

import * as ActionsServices from '../../../services/rh';

export const useFetchRhGraphDataTrending = (
  data: any,
  options?: {
    onSuccess?: () => void;
    enabled?: boolean;
    onError?: () => void;
  },
) => {
  return useQuery(['fetchRhGraphDataTrending', data], () => ActionsServices.fetchRhGraphDataTrending({ ...data }), {
    onSuccess: options?.onSuccess,
    enabled: options?.enabled,
    onError: options?.onError,
  });
};
