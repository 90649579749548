import { Autocomplete, autocompleteClasses, TextField } from '@mui/material';
import React, { ReactNode, CSSProperties } from 'react';

interface IOption {
  id: string | number;
  label: string;
  labelShow?: string;
}

export interface ISelectSearchProps {
  name: string;
  title?: string | JSX.Element;
  rules: any;
  options: IOption[];
  error: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText: ReactNode;
  placeholder?: string;
  style?: CSSProperties;
  renderOption?: any;
  onSelected?: any;
  onChangeInput?: any;
  fullWidth?: boolean;
}

export default function AppSelectSearch(props: ISelectSearchProps) {
  const {
    error,
    required,
    disabled,
    helperText,
    name,
    title,
    options,
    renderOption,
    onSelected,
    onChangeInput,
    fullWidth,
    placeholder,
    ...other
  } = props;
  const [value, setValue] = React.useState<IOption | null>(null);
  return (
    <Autocomplete
      fullWidth={fullWidth || false}
      value={value}
      options={options}
      disabled={disabled}
      getOptionLabel={(item) => item.labelShow || item.label}
      onChange={(event, item) => {
        onSelected && onSelected(item);
        setValue(item);
      }}
      onInputChange={(e, newInputValue) => onChangeInput && onChangeInput(newInputValue)}
      renderOption={renderOption}
      renderInput={(_) => <TextField required={required} placeholder={placeholder} error={error} {..._} />}
      {...other}
    />
  );
}
