import React from 'react';
import type { Location } from 'react-router-dom';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { DeclarationTitle } from '@/features/declaration/components/DeclarationTitle';

export interface DeclarationDocumentHeaderProps {
  location: Location;
  absenceType: any;
}

export const DeclarationDocumentHeader = ({ location, absenceType }: DeclarationDocumentHeaderProps) => {
  return (
    <>
      <StepProgressBar type={absenceType} />
      {location.state?.document?.ocr_result?.data?.documentStatus === 'rejected' ? (
        <DeclarationTitle color={'error'} title="Votre document a été refusé, que souhaitez-vous faire ?" />
      ) : (
        <DeclarationTitle
          title="Vous allez maintenant joindre votre justificatif."
          subtitle="De quel document disposez-vous ?"
        />
      )}
    </>
  );
};
