import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import ForgetPasswordUI from './components/ForgetPasswordUI';

export const ForgetPasswordPage: React.FC = () => {
  return (
    <AppLayout navbar={false}>
      <ForgetPasswordUI />
    </AppLayout>
  );
};
