import React, { useState, useEffect } from 'react';
import { Navbar, NavbarMobile } from '@/components/Navbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../../lib/axios';
import moment from 'moment';
import { employeeVerifyAbsence } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { RequireLogin } from '@/components/RequireLogin';

function QuestionnaireAcceuille2() {
  const [isReturn, setIsReturn] = useState('1');
  const [isInterview, setIsInterview] = useState('1');
  const [searchParams] = useSearchParams();
  const [absence, setAbsence] = useState();
  const [access, setAccess] = useState(true);
  const token = searchParams.get('token_decode');
  const navigate = useNavigate();

  const handleClick = () => {
    axios
      .post(
        '/api/rh/business/reponse_welcome_question',
        {
          isInterview: isInterview,
          isReturn: isReturn,
          type: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((value) => {
        if (value.status === StatusCodes.OK) {
          navigate('/questionnaireretourfin');
        }
      });
  };

  useEffect(() => {
    try {
      if (token) {
        employeeVerifyAbsence()
          .then((value) => {
            if (value.status === StatusCodes.OK) {
              if (
                value.data.absence.is_welcome ||
                Math.ceil(moment(value.data.absence.date_end).diff(moment(value.data.absence.date_start), 'd', true)) <
                  60
              ) {
                return setAccess(false);
              }
              setAbsence(value.data.absence);
            }
          })
          .catch((err) => {
            setAccess(false);
          });
      }
    } catch {
      setAccess(false);
    }
  }, [token]);

  return (
    <RequireLogin>
      <div>
        <Navbar />
        {access ? (
          <div className="container-fluid mt-5">
            <h1 className="question-form mb-5">Questionnaire de réacceuille</h1>
            <h4 className="subtitle-services text-center" style={{ width: '60%' }}>
              Bonjour, nous espérons que vous allez mieux. Votre retour de {absence.type_absence.description_label} en
              théorique est prévu dans {Math.ceil(moment(absence.date_end).diff(moment(), 'd', true))} jours. A ce
              titre, vous bénéficierez
            </h4>
            <ol className="subtitle-services text-center" style={{ alignSelf: 'center', width: '60%' }}>
              <li>
                Un entretien de retour à l’emploi avec votre manager afin de créer un cadre favorable à votre reprise
                d’activité
              </li>
              <li>Une visite de reprise avec le médecin du travail</li>
              <li>Un programme de suivi personnalisé dans les premières semaines de votre retour</li>
            </ol>
            <h3 className="subtitle-services text-center">Pensez revenir au travail à l’issue de votre arrêt :</h3>
            <form className="form-legacy form-declaration form-declaration col-lg-4">
              <label>
                <select
                  className="form-select"
                  name="retour"
                  value={isReturn}
                  onChange={(e) => {
                    setIsReturn(e.target.value);
                  }}
                  required
                >
                  <option value={1}>Je pense que mon arrêt ne sera pas prolongé</option>
                  <option value={2}>Je pense que mon arrêt va être prolongé</option>
                  <option value={3}>Je n’en sais rien.</option>
                </select>
              </label>
            </form>
            {isReturn === '2' ? null : (
              <div className="container-fluid">
                <h3 className="subtitle-services text-center">
                  Souhaitez vous bénéficier d’une visite médicale de pré-reprise qui vous permettra de faire un point
                  d’étape avec le médecin du travail sur les conditions et modalités de votre reprise d’emploi ?
                </h3>
                <form className="form-legacy form-declaration form-declaration col-lg-4">
                  <label>
                    <select
                      className="form-select"
                      name="retour"
                      value={isInterview}
                      onChange={(e) => {
                        setIsInterview(e.target.value);
                      }}
                      required
                    >
                      <option value={1}>Non</option>
                      <option value={2}>Oui</option>
                    </select>
                  </label>
                </form>
              </div>
            )}
            <button className="btn btn-kerij" onClick={handleClick}>
              Valider
            </button>
          </div>
        ) : (
          <div className="container-fluid mt-5">
            <h1 className="question-form mb-5">Ce lien n'est plus disponible</h1>
          </div>
        )}
        <NavbarMobile />:
      </div>
    </RequireLogin>
  );
}

export default QuestionnaireAcceuille2;
