import React from 'react';

import { ReactComponent as LoadingAnalyseSVG } from '../../../assets/loading-analyse.svg';
import './kerijLoading.scss';

export const enum KerijLoadingType {
  ANALYSE = 'analyse',
  VALIDATION = 'validation',
}

const getTitle = (type: KerijLoadingType) => {
  switch (type) {
    case KerijLoadingType.ANALYSE: {
      return 'Patience, nous avons besoin de quelques secondes supplémentaires pour analyser votre déclaration';
    }
    case KerijLoadingType.VALIDATION: {
      return 'Veuillez patienter...';
    }
    default: {
      return '';
    }
  }
};

const getContent = (type: KerijLoadingType) => {
  switch (type) {
    case KerijLoadingType.ANALYSE: {
      return '';
    }
    case KerijLoadingType.VALIDATION: {
      return 'Cela peut prendre quelques secondes.';
    }
    default: {
      return '';
    }
  }
};

interface KerijLoadingProperties {
  type: KerijLoadingType;
}

export const KerijLoading: React.FC<KerijLoadingProperties> = ({
  type = KerijLoadingType.ANALYSE,
}: KerijLoadingProperties) => {
  const title = getTitle(type);
  const content = getContent(type);
  return (
    <div className="loading_analyse__container">
      <span className="loading_analyse__title_text">{title}</span>
      <span className="loading_analyse__content_text">{content}</span>
      <div className="loading_analyse__content">
        <div className="loading_analyse__loading"></div>
        <div className="loading_analyse__icon">
          <LoadingAnalyseSVG />
        </div>
      </div>
    </div>
  );
};
