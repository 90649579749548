import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IfFeature } from '@/components/IfFeature';
import { BrandIcon } from '@/components/BrandIcon';
import { NavbarMenuButton } from './NavbarMenuButton';
import {
  AppBar as AppBarDefault,
  type AppBarProps,
  Box,
  Stack,
  styled,
  Toolbar,
  typographyClasses,
} from '@mui/material';
import { Button } from '@/components/Button';
import classNames from 'clsx';
import { useNavbarMobile } from './useNavbarMobile';
import { useAppVariant } from '@/hooks/useAppVariant';
import { useAuthentication } from '@/hooks/useAuthentication';
import { NavbarNotificationButton } from './NavbarNotificationButton';
import { Notification, useAppSelector } from '@/store';

export const DESKTOP_NAV_HEIGHT = 64;

const componentName = 'Navbar';

export interface NavbarProps extends AppBarProps {}

export function Navbar(props: NavbarProps) {
  const { pathname } = useLocation();
  const navbarMobile = useNavbarMobile();
  const appVariant = useAppVariant();
  const { className, ...otherProps } = props;
  const { isAuthenticated } = useAuthentication();
  const hasUnread = useAppSelector(Notification.hasUnread);

  const renderNavButton = (label: React.ReactNode, to: string, selected?: boolean) => {
    return (
      <Button
        component={Link}
        className={`${componentName}__NavButton`}
        sx={{ pl: 2.5, pr: 2.5 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        to={to}
      >
        <Box fontSize={16} fontWeight={selected ? 600 : 400} lineHeight={'normal'}>
          {label}
        </Box>
      </Button>
    );
  };

  return navbarMobile ? null : (
    <AppBar
      elevation={3}
      className={classNames(
        componentName,
        `${componentName}--variant-${appVariant.large ? 'lg' : appVariant.medium ? 'md' : appVariant.small ? 'sm' : 'xs'}`,
        className,
      )}
      position="static"
      color="default"
      style={{ padding: 0, border: 0 }}
      {...otherProps}
    >
      <Toolbar className={`${componentName}__Toolbar`}>
        <Link
          className={`${componentName}__Brand`}
          to="/"
          style={{ color: 'inherit', textDecoration: 'inherit', display: 'block' }}
        >
          <BrandIcon className={`${componentName}__BrandIcon`} />
        </Link>

        {isAuthenticated && (
          <Stack direction={'row'} alignItems={'center'} ml={5}>
            {renderNavButton('Démarches', '/demarches', pathname === '/demarches')}
            <IfFeature name="ServiceManagement">
              {renderNavButton('Services', '/services', pathname === '/services')}
            </IfFeature>
            {renderNavButton('Événements', '/evenements', pathname === '/evenements')}
          </Stack>
        )}

        <Box className={`${componentName}__Spacer`} sx={{ flexGrow: 1 }} />

        {isAuthenticated && (
          <NavbarNotificationButton className={`${componentName}__MenuButton`} hasUnread={hasUnread} />
        )}
        <NavbarMenuButton isAuthenticated={isAuthenticated} className={`${componentName}__MenuButton`} />
      </Toolbar>
    </AppBar>
  );
}

export const AppBar = styled(AppBarDefault, {
  name: componentName,
})(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.white,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  [`& .${componentName}__BrandIcon`]: {
    height: theme.spacing(6),
  },
  [`& .${componentName}__NavButton .${typographyClasses.root}`]: {
    ...theme.md3.sys.typescale.body.medium,
  },
  [`& .${componentName}__NavButton .${typographyClasses.root}`]: {
    ...theme.md3.sys.typescale.body.large,
  },
  [`& .${componentName}__MenuButton`]: {
    marginLeft: theme.spacing(5),
  },
  [`&.${componentName}--variant-lg .${componentName}__Toolbar`]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}));
