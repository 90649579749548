import React from 'react';
import { AppLayout } from '../../components/AppLayout';
import { NotificationsContent } from './NotificationsContent';

export const Notifications: React.FC = () => {
  return (
    <AppLayout>
      <NotificationsContent />
    </AppLayout>
  );
};
