import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { PRO_URL } from '../../../constants/urls.constants';
import React from 'react';
import { ActionButton } from '@/components/ActionButton';

export const DeclarationEndPage = () => {
  return (
    <div className="row col-12">
      <h3 className="question-form mt-5 mb-5 text-center">Déclaration Terminée</h3>

      <div className="form-declaration text-center col-12 col-md-12">
        <div className="declaration-check mb-5">
          <FontAwesomeIcon className="fa-5x" icon={faCheck} />
        </div>

        <div className="col-12">
          <p>
            Merci. <br /> Votre déclaration a bien été transmise. <br />
            <br />
          </p>
        </div>
        <div className="d-flex justify-content-evenly align-items-center row col-9 mt-5 mx-auto">
          <Link to={`${PRO_URL.PREFIXE_PRO}${PRO_URL.ACCUEIL}`}>
            <ActionButton color="primary" size="large" label={"Retour à l'accueil"} sx={{ pl: 10, pr: 10 }} />
          </Link>

          {/*<div className="col-12 col-lg-3 mt-5">*/}
          {/*    <KerijButton label="Créer une nouvelle absence" onClick={handleButtonClick}/>*/}
          {/*</div>*/}

          <button type="button" className="btn btn-kerij col-6 col-lg-3">
            <Link to={`${PRO_URL.PREFIXE_PRO + PRO_URL.CREATE_HR_LEAVE}`}>Créer une nouvelle absence</Link>
          </button>
        </div>
      </div>
    </div>
  );
};
