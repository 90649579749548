import { InputBase, styled } from '@mui/material';

export const AppInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.25),
  },
  color: theme.palette.text.primary,
  position: 'relative',
  border: `1px solid`,
  borderColor: theme.md3.sys.color.outlineVariant,
  backgroundColor: theme.palette.common.white,
  fontSize: theme.spacing(1.5),
  minHeight: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  boxShadow: theme.shadows[1],
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  '&:focus-within': {
    boxShadow: theme.shadows[2],
  },
  '& .MuiInputBase-input': {
    outline: 'none',
  },
}));
