import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext, Notification, useAppDispatch, useAppFeature, useAppSelector } from '@/store';
import { BottomNavigation, Paper, PaperProps, styled } from '@mui/material';
import classNames from 'clsx';
import { NavbarMobileTab } from './NavbarMobileTab';
import { defineMenu } from './defineMenu';

const NavbarPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  borderTop: '1px solid',
  borderColor: theme.md3.sys.color.outlineVariant,
  paddingBottom: theme.spacing(1),
}));

const componentName = 'NavbarMobile';

export interface NavbarMobileProps extends PaperProps {}

export function NavbarMobile(props: NavbarMobileProps) {
  const { className, ...rootProps } = props;
  const user = useAppSelector(AccountContext.getUser);
  const loading = useAppSelector(AccountContext.isLoading);
  const hasUnread = useAppSelector(Notification.hasUnread);
  const dispatch = useAppDispatch();
  const appFeature = useAppFeature();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem('access_token_employee')) {
      localStorage.removeItem('access_token_employee');
      dispatch(AccountContext.actions.setUser(null));
      switch (location.pathname) {
        case '/activites': {
          navigate('/activiteslogin');
          break;
        }
        case '/services': {
          navigate('/serviceslogin');
          break;
        }
        case '/rdv': {
          navigate('/rdvlogin');
          break;
        }
        case '/profil': {
          navigate('/login');
          break;
        }
        // No default
      }
    }
  }, [user, loading, location]);

  const entries = useMemo(() => defineMenu(user, { hasUnread }), [hasUnread, user, appFeature]);

  return (
    <NavbarPaper
      elevation={3}
      sx={{ bottom: 0, left: 0, right: 0, position: 'fixed' }}
      {...rootProps}
      className={classNames(componentName, className)}
    >
      <BottomNavigation>
        {entries.map((entry, index) => {
          return (
            <NavbarMobileTab key={index} to={entry.to} label={entry.label} icon={entry.icon} feature={entry.feature} />
          );
        })}
      </BottomNavigation>
    </NavbarPaper>
  );
}
