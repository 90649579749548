import React, { ForwardedRef, useMemo } from 'react';
import classNames from 'clsx';
import { Icon, Switch as MUISwitch, SwitchProps as MUISwitchProps, styled, useTheme } from '@mui/material';
import { CheckRounded, CloseRounded } from '@mui/icons-material';

const componentName = 'KRJSwitch';
const transitionDuration = 300;

export interface SwitchProps extends MUISwitchProps {
  CheckIcon?: React.ReactNode;
  NotCheckIcon?: React.ReactNode;
}

export const Switch = React.forwardRef(function MUISwitch(props: SwitchProps, ref: ForwardedRef<HTMLButtonElement>) {
  const { spacing } = useTheme();
  const { checked, className, CheckIcon, NotCheckIcon, ...otherProps } = props;

  const icon = useMemo(() => {
    if (checked) return CheckIcon ?? <CheckRounded color="primary" />;
    return NotCheckIcon ?? <CloseRounded sx={(theme) => ({ color: theme.palette.grey[900] })} />;
  }, [checked]);

  return (
    <SwitchContainer>
      <StyledSwitch ref={ref} checked={checked} className={classNames(componentName, className)} {...otherProps} />
      <SwitchIcon
        sx={{
          left: spacing(checked ? 3.5 : 1.5),
        }}
      >
        {icon}
      </SwitchIcon>
    </SwitchContainer>
  );
});

const SwitchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  width: theme.spacing(5),
  height: theme.spacing(3),
}));

const SwitchIcon = styled(Icon)(({ theme }) => ({
  transitionDuration: `${transitionDuration}ms`,
  overflow: 'visible',
  position: 'absolute',
  top: theme.spacing(1.75),
  transform: 'translate(-50%, -50%)',
  fontSize: theme.spacing(2),
  pointerEvents: 'none',
}));

const StyledSwitch = styled(MUISwitch)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(3),
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: theme.spacing(0.25),
    transitionDuration: `${transitionDuration}ms`,
    '& + .MuiSwitch-track, &.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
      border: 0,
      borderRadius: theme.spacing(12.5),
    },
    '& + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[400],
    },
    '&.Mui-checked': {
      transform: `translateX(${theme.spacing(2)})`,
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));
