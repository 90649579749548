import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { AppLayoutContent } from '@/components/AppLayout';
import { NotificationFilterItems, NotificationFilterType } from '@/components/FilterNotification';
import { NotificationList, NotificationListContent } from '@/components/NotificationList';
import { NotificationQuery } from '@/query';
import { Notification, useAppDispatch, useAppSelector } from '@/store';
import { Notification as NotificationType } from '@/types';
import { PageTab } from '@/components/PageTab';
import { useMUITabController } from '@/hooks/useMUITabController';

export function NotificationsContent() {
  const filterController = useMUITabController({
    initialValue: NotificationFilterType.All,
    items: NotificationFilterItems,
  });
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(Notification.isLoading);

  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const lastElementRef = React.useRef<HTMLDivElement | null>(null);

  const [page, setPage] = useState(1);
  const queryNotification = NotificationQuery.useIndex({ page, per_page: 5, from: 31 });
  const [history, setHistory] = useState<Array<NotificationType>>([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = useCallback(
    () => (hasMore && queryNotification.isFetched ? setPage((prevPage) => prevPage + 1) : undefined),
    [hasMore, queryNotification.isFetched],
  );

  React.useEffect(() => {
    if (queryNotification.data) {
      setHistory((prevItems) => [...prevItems, ...queryNotification.data]);
      if (queryNotification.data.length === 0) setHasMore(false);
    }
  }, [queryNotification.data]);

  React.useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(
      (entries) => (entries[0].isIntersecting && hasMore ? loadMore() : undefined),
      { threshold: 1 },
    );
    if (lastElementRef.current) observerRef.current.observe(lastElementRef.current);
    return () => (observerRef.current ? observerRef.current.disconnect() : undefined);
  }, [loadMore, hasMore]);

  React.useEffect(() => {
    if (!isLoading) dispatch(Notification.load());
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <AppLayoutContent requireAuthentication contentTitle={'Notifications'}>
      <Box width={'100%'}>
        <PageTab {...filterController.getTabsProps()} items={filterController.control.items} />
      </Box>

      <NotificationList variant="list">
        <NotificationListContent variant="list" filter={filterController.control.value} additionalData={history} />
        <Box ref={lastElementRef} />
      </NotificationList>
    </AppLayoutContent>
  );
}
