import { statisticGetDashboardURL } from '@/services/rh';
import { useQuery } from 'react-query';

export const StatisticQuery = (() => {
  const useDefault = () =>
    useQuery('statisticDefaultDashboard', async () => {
      return statisticGetDashboardURL({ key: 'default' });
    });

  const useAbsence = () =>
    useQuery('statisticAbsenceDashboard', async () => {
      return statisticGetDashboardURL({ key: 'absence' });
    });

  return {
    useDefault,
    useAbsence,
  };
})();
