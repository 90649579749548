export const NotificationFilterType = {
  All: 'all',
  News: 'news',
} as const;
export type NotificationFilterType = (typeof NotificationFilterType)[keyof typeof NotificationFilterType];

export const NotificationFilterItems = [
  { value: NotificationFilterType.All, label: 'Toutes' },
  { value: NotificationFilterType.News, label: 'Non lues' },
];
