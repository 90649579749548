import React, { useEffect } from 'react';
// import { BrowserRouter, Route, Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { ActionButton } from '../ActionButton';

function Verify(props) {
  const state = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.state) {
      navigate('/signup');
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center col-lg-4">Vérification des informations</h3>

        <div className="mb-5 text-center">
          <div>Nous avons trouvé une correspondance dans notre base de données :</div>
          <br />

          <div>
            Nom Prénom :{' '}
            <strong>
              {state.state.salarie.lastname} {state.state.salarie.firstname}
            </strong>
          </div>
          <div>
            Date de naissance : <strong>{state.state.salarie.birthday}</strong>
          </div>
          <div>
            Adresse : <strong>{state.state.salarie.address}</strong>
          </div>
          <div>
            Localité : <strong>{state.state.salarie.locality}</strong>
          </div>
          <div>
            Code Postal : <strong>{state.state.salarie.zipcode}</strong>
          </div>
          <div>
            Societé : <strong>{state.state.salarie.business.name}</strong>
          </div>

          {state.state.hasAccount ? (
            <div>
              <div className="information-document col-12 row mt-5 mb-5">
                <div>
                  {' '}
                  <FontAwesomeIcon className="fa-1x" icon={faCircleExclamation} /> &nbsp; Nous avons trouvé un compte
                  déjà présent dans notre base de données. Veuillez l'utiliser pour vous connecter
                </div>
                <div>
                  {' '}
                  L'identifiant est le suivant : <strong>{state.state.salarie.employee_login.email}</strong>
                </div>
              </div>
              <div className="col-12 d-flex" style={{ justifyContent: 'center' }}>
                <Box width={350}>
                  <ActionButton
                    color="primary"
                    actionName="validate"
                    label={'Retour à la page de connexion'}
                    startIcon={null}
                    onClick={() => navigate('/login')}
                    fullWidth
                  />
                  <ActionButton
                    actionName="previous"
                    label={"Ce n'est pas moi"}
                    startIcon={null}
                    onClick={() => navigate(-1)}
                    fullWidth
                  />
                </Box>
              </div>
            </div>
          ) : (
            <div>
              <div className="mt-5 mb-5">Confirmez-vous ces informations ?</div>
              <div className="col-12 d-flex" style={{ justifyContent: 'center' }}>
                <Box width={350}>
                  <ActionButton
                    color="primary"
                    actionName="validate"
                    label={'Je confirme'}
                    startIcon={null}
                    onClick={() =>
                      navigate('/createpassword', {
                        state: state.state,
                      })
                    }
                    fullWidth
                  />
                  <ActionButton
                    actionName="previous"
                    label={"Ce n'est pas moi"}
                    startIcon={null}
                    onClick={() => navigate(-1)}
                    fullWidth
                  />
                </Box>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verify;
