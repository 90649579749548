import React from 'react';
import { AppLayout } from '@/components/AppLayout';
import { Profil } from './Profil';

export const ProfilPage: React.FC = () => {
  return (
    <AppLayout mobileBackButton>
      <Profil />
    </AppLayout>
  );
};
