import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import ChangePasswordUI from './components/ChangePasswordUI';

export const ChangePasswordPage: React.FC = () => {
  return (
    <AppLayout mobileBackButton>
      <ChangePasswordUI />
    </AppLayout>
  );
};
