import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, Divider, styled, Typography } from '@mui/material';
import { Absence } from '@/types';
import { PageTabsValue } from '@/components/PageTab';
import { EvenementsList } from './EvenementsList';
import { EmployeeAbsenceCardSkeleton } from '@/components/EmployeeAbsenceCard';

const componentName = 'EvenenementsTabContent';

export interface EvenenementsTabContentProps extends Omit<BoxProps, 'children'> {
  value: { pending: Array<Absence>; finished: Array<Absence>; deleted: Array<Absence> };
  type: PageTabsValue;
  loading?: boolean;
}

export function EvenementsTabContent(props: EvenenementsTabContentProps) {
  const { value, type, loading, className, ...boxProps } = props;

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      {type === 'pending' && (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {!loading && value.pending.length === 0 && <EmptyList>Aucune absence n’est en cours</EmptyList>}
          {loading && <EmployeeAbsenceCardSkeleton color="success" />}
          <EvenementsList absences={value.pending} type="pending" />
        </Box>
      )}

      {type === 'finished' && (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {!loading && value.finished.length === 0 && value.deleted.length === 0 && (
            <EmptyList>Vous n’avez aucune absence</EmptyList>
          )}
          {loading && <EmployeeAbsenceCardSkeleton color="variant" />}
          <EvenementsList absences={value.finished} type="finished" />
          {value.deleted.length > 0 && value.finished.length > 0 && <Divider sx={{ marginTop: 1, marginBottom: 1 }} />}
          <EvenementsList absences={value.deleted} type="deleted" />
        </Box>
      )}
    </Box>
  );
}

const EmptyList = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 'normal',
  textAlign: 'center',
}));
