import { Tab, type TabProps, type TabsProps } from '@mui/material';
import * as React from 'react';

export interface UseMUITabControllerParameters<T> {
  initialValue: T;
  items: Array<UseMUITabControllerItem<T>>;
}

export interface UseMUITabControllerItem<T> extends Omit<TabProps, 'value'> {
  value: T;
}

export interface UseMUITabController<T extends string | number> {
  control: {
    /**
     * Initial tab value
     */
    initialValue: T;
    /**
     * Current tab value
     */
    value: T;
    /**
     * Sets the current tab value
     *
     * @param value
     */
    setValue: (value: T) => void;
    /**
     * Tab items
     */
    items: Array<UseMUITabControllerItem<T>>;
  };
  /**
   * Returns all injectable Tabs props
   */
  getTabsProps(): {
    value: TabsProps['value'];
    onChange: TabsProps['onChange'];
    children: TabsProps['children'];
  };
}

/**
 * @example
 * const tabController = useMUITabController({
 *   initialValue: 'first',
 *   items: [
 *     { value: 'first', label: 'First' },
 *     { value: 'second', label: 'Second' }
 *   ]
 * });
 *
 * <Tabs {...tabController.getTabsProps()}></Tabs>
 *
 * @param parameters
 */
export function useMUITabController<T extends string | number>(
  parameters: UseMUITabControllerParameters<T>,
): UseMUITabController<T> {
  const { initialValue, items } = parameters;
  const [value, setValue] = React.useState(initialValue);

  function getTabsProps() {
    return {
      value,
      onChange: ((_: any, value: T) => setValue(value)) as TabsProps['onChange'],
      children: (
        <>
          {items.map((item) => (
            <Tab key={item.value} {...item} />
          ))}
        </>
      ),
    };
  }

  return {
    control: {
      initialValue,
      value,
      setValue,
      items,
    },
    getTabsProps,
  };
}
