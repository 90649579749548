import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import axios from '@/lib/axios';
import Modal from 'react-modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import jwtDecode from 'jwt-decode';
import { RHInformation, RHPermissions, RHRole, Business, ParentCompany } from '@/types';
import { fetchRhRole } from '@/services/rh';
import { RHAccountContext, useAppSelector } from '@/store';
import { AppSelect } from '@/components/AppSelect';

interface ListBusinessPermissionsModalProperties {
  isOpen: boolean;
  onClosed?: (permissions: RHPermissions | void) => void;
  user?: RHInformation;
}

const PermissionEditorModal = ({ isOpen, onClosed, user }: ListBusinessPermissionsModalProperties) => {
  return (
    <Modal
      // style={customStyles}
      className="Modal modal-style-child"
      // overlayclassName="Overlay"
      closeTimeoutMS={200}
      isOpen={isOpen}
      onRequestClose={() => {
        onClosed && onClosed();
      }}
      ariaHideApp={false}
    >
      <div className="text-center mb-2">
        <span className="modal-sous-titre">Liste des Permissions</span>
      </div>
      <div className="container-fluid col-12">
        <div className="col-12 d-flex flex-row-reverse">
          <span className="close text-right" style={{ cursor: 'pointer' }} onClick={() => onClosed && onClosed()}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        <PermissionEditor onValidateClicked={(permissions) => onClosed && onClosed(permissions)} user={user as any} />
      </div>
    </Modal>
  );
};

interface ListRolesProperties {
  rhPermissions: RHPermissions;
  onChange?: (roleId: string) => void;
  roleId?: string;
}

export const ListRoles = ({ roleId, onChange }: ListRolesProperties) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRhRole().then((response) => {
      setRoles(response.data);
    });
  }, []);

  return (
    <AppSelect
      value={roleId}
      items={roles.map((value: RHRole) => ({ value: value.id, label: value.role_name }))}
      label="Profil"
      onChange={(_, value) => onChange?.(value)}
    />
  );
};

interface ListBusinessesProperties {
  rhPermissions: RHPermissions;
  onChange?: (businesses: Array<string>) => void;
}

const ListBusinesses = ({ rhPermissions, onChange }: ListBusinessesProperties) => {
  const rh = useAppSelector(RHAccountContext.getProfile);

  const [listBusiness, setListBusiness] = useState<Array<Business>>([]);
  const [listSelectedBusinessesId, setListSelectedBusinessesId] = useState(
    rhPermissions?.map((permission) => permission.id_business),
  );
  useEffect(() => {
    onChange && onChange(listSelectedBusinessesId);
  }, [listSelectedBusinessesId]);

  useEffect(() => {
    if (rh) {
      const token = localStorage.getItem('access_token_rh');
      const decodedToken: any = jwtDecode(token || '');
      axios
        .get('/api/rh/get_list_company_business', {
          params: {
            idBusinesses: JSON.stringify(decodedToken?.idBusinesses || ''),
          },
        })
        .then((value) => {
          value.data.companies.map((company: ParentCompany) => {
            setListBusiness((businesses) => [...businesses, ...company.businesses]);
          });
        });
    }
  }, [rh]);

  return (
    <>
      <label className="label-add-user col-12">
        Etablissements autorisés
        {listBusiness.map((business) => {
          return (
            <div className="align-items-center" key={business.id}>
              <div className="ml-5 mx-5 d-flex form-check text-start" key={business.id}>
                <input
                  className="form-check-input checkbox-business my-2"
                  type="checkbox"
                  value=""
                  id={business.id}
                  onChange={() => {
                    if (listSelectedBusinessesId.includes(business.id)) {
                      setListSelectedBusinessesId((selectedBusinesses) =>
                        selectedBusinesses.filter((businessId) => businessId !== business.id),
                      );
                    } else setListSelectedBusinessesId((selectedBusinesses) => [...selectedBusinesses, business.id]);
                  }}
                  checked={!!listSelectedBusinessesId.includes(business.id)}
                ></input>
                <label className="form-check-label align-name-checkbox" htmlFor={business.id}>
                  {business.name}
                </label>
              </div>
            </div>
          );
        })}
      </label>
    </>
  );
};

interface ListBusinessProperties {
  onValidateClicked?: (listPermissions: RHPermissions) => void;
  user: RHInformation;
}

const PermissionEditor = ({ onValidateClicked, user }: ListBusinessProperties) => {
  const [selectedBusinessesId, setSelectedBusinessesId] = useState<Array<string>>([]);
  const [roleId, setRoleId] = useState(user?.rh_secures?.[0]?.id_role);
  const [listPermissions, setListPermissions] = useState<RHPermissions>([]);

  useEffect(() => {
    setListPermissions(
      selectedBusinessesId.map((businessId: string) => ({
        id_business: businessId,
        id_role: roleId,
        id_rh_information: user?.id,
      })),
    );
  }, [roleId, selectedBusinessesId?.length]);

  return (
    <div>
      <div className="col-12 text-start">
        <div className="text-start mb-3">
          <ListRoles rhPermissions={user?.rh_secures || []} onChange={(roleId) => setRoleId(roleId)} />
          <ListBusinesses
            rhPermissions={user?.rh_secures || []}
            onChange={(businesses: Array<string>) => setSelectedBusinessesId(businesses)}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="text-center">
          <button className="btn-kerij" onClick={() => onValidateClicked && onValidateClicked(listPermissions)}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};
export default PermissionEditorModal;
