import React, { useState } from 'react';
import { AppSelect } from '../AppSelect';

export function QuestionnaireSuiviContact() {
  const [entretien, setEntretien] = useState('3');

  return (
    <AppSelect
      value={entretien ?? undefined}
      items={[
        { label: 'Oui avec mon manager', value: '1' },
        { label: 'Oui avec une personne des RH', value: '2' },
        { label: 'Non merci', value: '3' },
      ]}
      dense
      placeholder="Veuillez sélectionner"
      onChange={(_, value) => {
        setEntretien(value);
      }}
    />
  );
}
