import React from 'react';
import { Service, ServiceCategoryType } from '@/types';
import { Box, BoxProps, styled, Typography } from '@mui/material';

export interface ServiceCardInfosProps extends Pick<BoxProps, 'sx'> {
  value: Service;
}

export function ServiceCardInfos(props: ServiceCardInfosProps) {
  const { value, ...boxProps } = props;

  const categories = value.categories?.filter((category) => category.type === ServiceCategoryType.Category) ?? [];
  const subcategories = value.categories?.filter((category) => category.type === ServiceCategoryType.Subcategory) ?? [];

  return (
    <Root {...boxProps}>
      <Typography textTransform={'uppercase'} sx={({ md3 }) => md3.sys.typescale.title.medium}>
        {value.title}
      </Typography>
      <CategoryText sx={({ md3 }) => md3.sys.typescale.body.large}>
        {categories.map(({ reference }) => reference.label).join(' / ')}
      </CategoryText>
      <CategoryText sx={({ md3 }) => md3.sys.typescale.body.large}>
        {subcategories.map(({ reference }) => reference.label).join(' / ')}
      </CategoryText>
    </Root>
  );
}

const Root = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}));

const CategoryText = styled(Typography)(({ theme }) => ({
  lineHeight: 'normal',
  color: theme.md3.sys.color.onSurfaceVariant,
}));
