import { notificationFindAllArchive } from '@/services/employee/notification';
import { useQuery } from 'react-query';

export const NotificationQuery = (() => {
  const useIndex = (parameters: notificationFindAllArchive.Parameters) =>
    useQuery(
      ['notificationHistoryIndex', parameters],
      async () => {
        return notificationFindAllArchive(parameters);
      },
      { keepPreviousData: true },
    );

  return {
    useIndex,
  };
})();
