import React from 'react';
import classNames from 'clsx';
import {
  FormControl,
  FormControlLabel,
  type FormControlProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { InputProps, useInput } from '@/hooks/useInput';

const componentName = 'QuestionYesNoInput' as const;

export interface QuestionYesNoInputProps
  extends Omit<FormControlProps, keyof InputProps<Element, boolean>>,
    InputProps<Element, boolean> {
  helperText?: React.ReactNode;
  // textAlign?: 'center';
}

export const QuestionYesNoInput = React.forwardRef(function QuestionYesNoInput(
  props: QuestionYesNoInputProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const input = useInput({ componentName: 'QuestionYesNoInput', props, ref: forwardedRef });
  const { helperText, ...rootProps } = input.useRootProps(props);
  const inputSlotProps = input.useSlotProps();

  return (
    <FormControl {...rootProps} className={classNames(componentName, rootProps.className)}>
      <FormLabel>{props.title}</FormLabel>
      <RadioGroup
        row
        value={input.value ? 'true' : 'false'}
        onChange={(event) => inputSlotProps.onChange(event, event.target.value === 'true')}
      >
        <FormControlLabel value={'true'} control={<Radio />} label="Oui" />
        <FormControlLabel value={'false'} control={<Radio />} label="Non" />
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});
