import React from 'react';
import { Box } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { Spacer } from '@/components/Spacer';
import { PageTab, PageTabProps, PageTabsValue } from '@/components/PageTab';
import { AbsenceQuery } from '@/query/AbsenceQuery';
import { Absence, AbsenceStatus } from '@/types';
import { EvenementsTabContent } from './EvenementsTabContent';
import { useToast } from '@/components/Toast';
import { AppLayoutContent } from '@/components/AppLayout';

export function EvenementsContent() {
  const appVariant = useAppVariant();
  const toast = useToast();

  const queryAbsence = AbsenceQuery.useIndex({ include_deleted: true });

  const [tabValue, setTabValue] = React.useState<PageTabsValue>('pending');

  const absences = React.useMemo(() => {
    if (!queryAbsence.data) return { pending: [], finished: [], deleted: [] };
    // eslint-disable-next-line unicorn/no-array-reduce
    return queryAbsence.data.reduce(
      (acc: { pending: Absence[]; finished: Absence[]; deleted: Absence[] }, absence) => {
        if (isPendingAbsence(absence)) acc.pending.push(absence);
        else if (isFinishedAbsence(absence)) acc.finished.push(absence);
        else if (isDeletedAbsence(absence)) acc.deleted.push(absence);
        return acc;
      },
      { pending: [], finished: [], deleted: [] },
    );
  }, [queryAbsence.data]);

  const onTabChange: PageTabProps['onChange'] = (_, tab) => {
    setTabValue(tab);
  };

  React.useEffect(() => {
    if (queryAbsence.isError)
      toast.present({
        message: 'Une erreur est survenue durant la récupération des absences',
        severity: 'error',
      });
  }, [queryAbsence.isError]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <AppLayoutContent
      requireAuthentication
      contentTitle={'Événements'}
      contentTitleAfter={
        <Box>
          <PageTab
            value={tabValue}
            items={[
              { label: 'En cours', value: 'pending' },
              { label: 'Terminés', value: 'finished' },
            ]}
            onChange={onTabChange}
          />
        </Box>
      }
    >
      <Spacer spacing={appVariant.small ? 4 : 2} />

      <EvenementsTabContent value={absences} type={tabValue} loading={queryAbsence.isLoading} />
    </AppLayoutContent>
  );
}

const isPendingAbsence = (absence: Absence) =>
  absence.status !== AbsenceStatus.DELETED && absence.status !== AbsenceStatus.FINISHED;
const isFinishedAbsence = (absence: Absence) => absence.status === AbsenceStatus.FINISHED;
const isDeletedAbsence = (absence: Absence) => absence.status === AbsenceStatus.DELETED;
