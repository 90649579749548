import React from 'react';
import { Service, ServiceFinanceLabel } from '@/types';
import { Chip, styled } from '@mui/material';
import { IconButton } from '../IconButton';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { IconButtonProps } from '../IconButton/IconButton';

export interface ServiceCardActionsProps extends Omit<IconButtonProps, 'action' | 'value' | 'icon'> {
  value: Service;
  action?: IconButtonProps;
}

export function ServiceCardActions(props: ServiceCardActionsProps) {
  const { value, action, ...buttonProps } = props;

  return (
    <>
      {value.funding && <FoundingChip label={ServiceFinanceLabel[`${value.funding}`]} size="medium" />}

      {action && (
        <ActionButton
          size="small"
          className="ActionButton"
          style={{ borderRadius: '0 0 0.25rem 0', left: '0' }}
          {...action}
          {...buttonProps}
        />
      )}

      <ActionButton
        icon={faHeart}
        size="small"
        className="ActionButton"
        sx={{ borderRadius: '999px', top: ({ spacing }) => spacing(1), right: ({ spacing }) => spacing(1) }}
        {...buttonProps}
      />
    </>
  );
}

const ActionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.tertiary,
  color: theme.md3.sys.color.onTertiary,
  height: theme.spacing(4),
  width: theme.spacing(4),
  position: 'absolute',
  top: '0',
  ':hover': {
    backgroundColor: theme.md3.sys.color.onTertiary,
    color: theme.md3.sys.color.tertiary,
  },
}));

const FoundingChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.tertiary,
  color: theme.md3.sys.color.onTertiary,
  position: 'absolute',
  left: theme.spacing(1),
  top: theme.spacing(1),
}));
