import * as React from 'react';
import { Box } from '@mui/material';
import { AppTextField, AppTextFieldProps } from '../AppTextField';
import { Spacer } from '../Spacer';

export interface AppCollapsibleFieldInputProps extends Pick<AppTextFieldProps, 'InputProps' | 'value' | 'onChange'> {}

export const AppCollapsibleFieldInput = React.forwardRef(function AppCollapsibleFieldInput(
  props: AppCollapsibleFieldInputProps,
  ref: React.ForwardedRef<Element>,
) {
  const { ...inputProps } = props;

  return (
    <Box pl={1} pr={1}>
      <AppTextField dense ref={ref} {...inputProps} noHelper />
      <Spacer spacing={3} />
    </Box>
  );
});
