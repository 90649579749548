import React from 'react';
import { Card, CardActionArea, CardContent, Typography, type CardProps } from '@mui/material';
import classNames from 'clsx';
import { Spacer } from '@/components/Spacer';
import { Link, type LinkProps } from 'react-router-dom';

const componentName = 'NavMenuCardButton';

export interface NavMenuCardButtonProps extends CardProps, Partial<Pick<LinkProps, 'to' | 'state'>> {
  icon: React.ReactNode;
  /**
   * Primary text
   */
  primary: React.ReactNode;
  /**
   * Secondary text
   */
  secondary: React.ReactNode;
}

export const NavMenuCardButton = (props: NavMenuCardButtonProps) => {
  const { className, icon, primary, secondary, to, state, onClick, ...otherProps } = props;
  const cardActionDefaultProps = {
    onClick,
  };
  const cardActionProps =
    to == null && state == null
      ? cardActionDefaultProps
      : {
          component: Link,
          to,
          state,
          ...cardActionDefaultProps,
        };
  return (
    <Card
      className={classNames(componentName, className)}
      color="surface"
      sx={{ height: '100%', display: 'flex' }}
      {...otherProps}
    >
      <CardActionArea {...(cardActionProps as any)}>
        <CardContent sx={{ height: '100%' }}>
          <Typography fontSize={32} lineHeight={0.5}>
            {icon}
          </Typography>
          <Spacer spacing={4} />
          <Typography fontSize={16} fontWeight={600} lineHeight={1.25}>
            {primary}
          </Typography>
          <Spacer spacing={0.5} />
          <Typography fontSize={14} lineHeight={1.4}>
            {secondary}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
