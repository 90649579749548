import React from 'react';
import { Box, ListItemButton, MenuItem, Typography, TypographyProps, useTheme } from '@mui/material';
import { NotificationListItemIcon } from './NotificationListItemIcon';
import { Spacer } from '../Spacer';
import moment from 'moment';
import { NotificationVariant } from './type';

export interface NotificationListItemProps extends Omit<TypographyProps, 'variant'> {
  variant: NotificationVariant;
  title: string;
  titleProps?: TypographyProps;
  date: string;
  hasRead: boolean;
  onClick?: () => void;
  bodyProps?: TypographyProps;
}

export function NotificationListItem(props: NotificationListItemProps) {
  const { children, variant, hasRead, onClick, date, title, titleProps, bodyProps } = props;

  const theme = useTheme();

  const renderItem = () => (
    <Box display={'flex'} gap={2} p={2.5} pt={2} pb={2} width={'100%'}>
      <NotificationListItemIcon badgeProps={{ invisible: hasRead }} />

      <Box whiteSpace={'break-spaces'} sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 1, gap: 1 }}>
        <Typography sx={({ md3 }) => md3.sys.typescale.title.medium} {...titleProps}>
          {title}
        </Typography>
        <Typography sx={({ md3 }) => ({ ...md3.sys.typescale.body.small, color: md3.sys.color.onSurfaceVariant })}>
          {moment(date).format('ll')}
        </Typography>
        <Typography sx={({ md3 }) => ({ ...md3.sys.typescale.body.medium })} {...bodyProps}>
          {children}
        </Typography>
      </Box>
    </Box>
  );

  return variant === NotificationVariant.List ? (
    <ListItemButton
      onClick={onClick}
      sx={{
        padding: 0,
        backgroundColor: hasRead ? '#fff' : theme.palette.aquaSerenity[50],
      }}
    >
      {renderItem()}
    </ListItemButton>
  ) : (
    <MenuItem
      onClick={onClick}
      sx={{
        padding: 0,
        maxWidth: theme.spacing(49),
        backgroundColor: hasRead ? '#fff' : theme.palette.aquaSerenity[50],
      }}
    >
      {renderItem()}
    </MenuItem>
  );
}
