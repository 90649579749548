import { absenceFindAll } from '@/services/employee/absence';
import { useQuery } from 'react-query';

export const AbsenceQuery = (() => {
  const useIndex = (parameters?: absenceFindAll.Parameters) =>
    useQuery(
      ['absenceIndex', parameters],
      async () => {
        const response = await absenceFindAll(parameters);
        return response.data;
      },
      { keepPreviousData: true },
    );

  const usePendingAction = () => {
    return useQuery(['absencePendingAction'], async () => {
      const response = await absenceFindAll({
        has_pending_action: true,
        per_page: 0,
      });
      return response.meta.total;
    });
  };

  return {
    useIndex,
    usePendingAction,
  };
})();
