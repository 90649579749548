import React from 'react';
import classNames from 'clsx';
import { Avatar, AvatarProps, styled, Typography } from '@mui/material';

const componentName = 'KRJBadge';

export interface BadgeProps extends AvatarProps {
  label?: string | number;
}

export const Badge = (props: BadgeProps) => {
  const { className, label, ...avatarProps } = props;

  return (
    <CustomBadge className={classNames(componentName, className)} {...avatarProps}>
      <Typography fontSize={10} fontWeight={600} color="primary">
        {String(label)}
      </Typography>
    </CustomBadge>
  );
};

const CustomBadge = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(2.5),
  width: 'auto',
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  backgroundColor: theme.palette.common.white,
  border: 0,
}));
