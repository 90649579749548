import React from 'react';
import { Absence } from '@/types';
import { AbsencesInputSelectItem } from './AbsencesInputSelectItem';
import { Card, CardHeader, Grid, Typography, styled } from '@mui/material';
import { ListCardContent } from '../ListCard';

export interface AbsencesInputSelectProperties {
  items: Absence[];
  value?: Absence[];
  onChange: (event: { checked: boolean }, items: Absence[]) => void;
}

export const AbsencesInputSelect = (props: AbsencesInputSelectProperties) => {
  const { items, value, onChange } = props;

  const InputCardHeader = styled(CardHeader)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    maxHeight: 40,
  }));
  const InputCardTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '1rem',
  });

  return (
    <Grid container>
      <Grid item xs={8}>
        <Card>
          <InputCardHeader color="primary" title={<InputCardTitle>Liste des absences</InputCardTitle>} />
          <ListCardContent>
            {items.map((absence: Absence) => (
              <AbsencesInputSelectItem key={absence.id} item={absence} value={value} onChange={onChange} />
            ))}
            {items.length === 0 && <div>Aucune absence</div>}
          </ListCardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
