import 'dynamsoft-document-viewer/dist/ddv.css';
import React from 'react';
import { IconButton, Box, Stack, Card, styled, CardActionArea } from '@mui/material';
import classNames from 'clsx';
import { InputProps } from '@/hooks/useInput';
import { PDFPreview } from '../PDFPreview';
import fillCancel from './assets/fill_cancel.svg';

const componentName = 'InputFileList';

const getSource = (file: File) => {
  return URL.createObjectURL(file);
};

const showFile = (file: File) => {
  window.open(getSource(file), '_blank');
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputFileResultProps extends Pick<InputProps<HTMLButtonElement, Array<File>>, 'value' | 'onChange'> {}

export const InputFileList = (props: InputFileResultProps) => {
  const { value, onChange } = props;

  const deleteFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    event.stopPropagation();
    if (value) {
      onChange?.(
        event,
        value.filter((_, ind) => index !== ind),
      );
    }
  };

  return (
    <Stack className={classNames(componentName)} display={'flex'} flexDirection={'row'} gap={4}>
      {value?.map((file, fileIndex) => (
        <PreviewCard key={fileIndex} elevation={1}>
          <Box textAlign={'right'} position={'absolute'} width={116}>
            <ButtonDelete onClick={(event) => deleteFile(event, fileIndex)}>
              <img src={fillCancel} />
            </ButtonDelete>
          </Box>

          <CardActionArea sx={{ height: '100%' }} onClick={() => showFile(file)}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'} overflow={'hidden'}>
              {file.type === 'application/pdf' && <PDFPreview value={{ name: file.name, url: getSource(file) }} />}

              {file.type.startsWith('image/') && (
                <img
                  style={{ width: 'auto', height: 'auto', maxHeight: '100%', maxWidth: '100%', margin: 'auto' }}
                  src={getSource(file)}
                  alt={file.name}
                />
              )}
            </Box>
          </CardActionArea>
        </PreviewCard>
      ))}
    </Stack>
  );
};

const PreviewCard = styled(Card)(({ theme }) => ({
  width: 116,
  height: 116,
  border: '1px solid',
  borderColor: theme.shape.borderColorVariant,
}));

const ButtonDelete = styled(IconButton)(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
}));
