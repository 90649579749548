import React from 'react';
import { Absence } from '@/types';
import { Badge, Box, Checkbox, Chip, FormControlLabel } from '@mui/material';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { isAccident, isMaladie } from '@/services/rh/absences/data';

export interface AbsencesInputSelectItemGroupProperties {
  value: Absence[];
  onChange: (event: { checked: boolean }, parent: Absence) => void;

  checked?: boolean;
  indeterminate?: boolean;
}

export const AbsencesInputSelectItemGroup = ({
  value,
  checked,
  indeterminate,
  onChange,
}: AbsencesInputSelectItemGroupProperties) => {
  const typesCounts = value.reduce(
    (acc, absence) => {
      const isArret = isMaladie(absence) || isAccident(absence);
      const label = isArret ? absence.type_absence.description_1_label : absence.type_absence.description_label;
      if (!acc[`${label}`]) acc[`${label}`] = 0;
      acc[`${label}`]++;
      return acc;
    },
    {} as Record<string, number>,
  );

  return (
    <Box width={'100%'}>
      <FormControlLabel
        label={
          <>
            <strong>{'Plusieurs absences '}</strong>
            <Chip
              label={
                <AbsenceDate
                  value={{
                    date_start: value[0].date_start,
                    date_end: value[value.length - 1].date_end,
                    date_end_hospi_theory: value[value.length - 1].date_end_hospi_theory,
                  }}
                />
              }
              variant="outlined"
              size="small"
              sx={{ background: 'inherit' }}
            />

            <div style={{ marginTop: '0.75rem' }}>
              {Object.entries(typesCounts).map(([label, count]) => (
                <Badge key={label} badgeContent={count} color="primary" sx={{ marginRight: '1rem' }}>
                  <Chip label={label} variant="outlined" size="small" sx={{ background: 'inherit' }} />
                </Badge>
              ))}
            </div>
          </>
        }
        control={
          <Checkbox
            style={{ marginRight: '0.5rem' }}
            indeterminate={!checked && indeterminate}
            checked={checked}
            onChange={(_, isCheck) => onChange({ checked: isCheck }, value[0])}
          />
        }
      />
    </Box>
  );
};
