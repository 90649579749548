import axios from '@/lib/axios';
import { Absence } from '@/types';

export const absenceFindAll = async (params: absenceFindAll.Parameters = {}) => {
  const response = await axios.get<absenceFindAll.Return>(`/api/employee/absences`, { params });
  return response.data;
};

export namespace absenceFindAll {
  export type Parameters = {
    include_deleted?: boolean;
    has_pending_action?: boolean;
    per_page?: number;
    page?: number;
  };
  export type Return = {
    data: Array<Absence>;
    meta: {
      total: number;
    };
  };
}
