import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchEmployeeProfile } from '../../../services/employee';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { AxiosResponse } from 'axios';
import { useToast } from '@/components/Toast';
import { Box, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { ProfilFormUIFormContent } from './ProfilFormUIFormContent';
import { ProfilFormUIDesktopHelper } from './ProfilFormUIDesktopHelper';
import { ProfilFormUIDesktopBreadcrumbs } from './ProfilFormUIDesktopBreadcrumbs';
import { ProfilFormUISkeleton } from './ProfilFormUISkeleton';
import { useAppVariant } from '@/hooks/useAppVariant';
import { AppLayoutContent } from '@/components/AppLayout';

export function ProfilFormUI() {
  const accountContext = useAppSelector((state) => state.accountContext);

  const appVariant = useAppVariant();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onLogout = (message: string, severity: 'success' | 'error' = 'error') => {
    dispatch(AccountContext.logout());
    toast.present({ message, severity });
    return navigate('/login', { state: { fromPath: '/profil' } });
  };

  const onUpdated = async () => {
    setIsSubmitting(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await findEmployee();
    setIsSubmitting(false);
  };

  async function findEmployee() {
    let profilValue: AxiosResponse;

    try {
      profilValue = await fetchEmployeeProfile();
      if (profilValue?.data?.last_change_password) return dispatch(AccountContext.actions.setUser(profilValue.data));
      else onLogout('Veuillez vous reconnectez');
    } catch {
      onLogout('Erreur de connexion au serveur');
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <AppLayoutContent requireAuthentication contentRight={<ProfilFormUIDesktopHelper />}>
      {appVariant.medium && (
        <Box>
          <Spacer spacing={2} />
          <ProfilFormUIDesktopBreadcrumbs />
          <Spacer spacing={2} />
        </Box>
      )}

      <Typography fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
        Informations
        {appVariant.medium ? ' ' : <br />}
        personnelles
      </Typography>

      <Spacer spacing={appVariant.medium ? 5 : 3} />
      {!isSubmitting && !accountContext.loading && <ProfilFormUIFormContent onUpdated={onUpdated} />}
      {isSubmitting || (accountContext.loading && <ProfilFormUISkeleton />)}
    </AppLayoutContent>
  );
}
