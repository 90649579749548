import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ResetPassword.css';
import ReactLoading from 'react-loading';
import { employeeSendCodeNoAuth } from '../../../../../services/employee';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ActionButton } from '@/components/ActionButton';

function ResetPasswordUI() {
  // const cookies = Cookies();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const state = useLocation();
  const [error, setError] = useState<string>('');
  const [messagePaste, setMessagePaste] = useState<string>('');

  useEffect(() => {
    if (!state.state) {
      navigate('/forgotPassword');
    }
  }, []);

  const handleClick = (event: any) => {
    event.preventDefault();
    try {
      if (!newPassword || !confirmPassword) {
        setError('Les champs doivent être renseignés');
        return;
      } else if (newPassword === confirmPassword) {
        setIsLoading(true);
        employeeSendCodeNoAuth({
          idEmployeeLogin: state.state.id_salarie_login,
          email: state.state.mail,
          phone: state.state.telephone,
          idEmployeeInformation: state.state.idEmployeeInformation,
        })
          .then((value) => {
            setIsLoading(false);
            navigate('/checkCode', {
              state: {
                id_salarie_login: state.state.id_salarie_login,
                mot_de_passe: newPassword,
                document: state.state.document,
                idBusiness: state.state.idBusiness,
                email: state.state.mail,
              },
            });
          })
          .catch((error_) => {
            setIsLoading(false);
            navigate('/checkCode', {
              state: {
                id_salarie_login: state.state.id_salarie_login,
                mot_de_passe: newPassword,
                document: state.state.document,
                idBusiness: state.state.idBusiness,
                email: state.state.mail,
              },
            });
          });
      } else {
        setError('Attention, les mots de passe ne correspondent pas.');
        return;
      }
    } catch {
      alert('Erreur');
    }
  };

  const handleOnPaste = (event: any) => {
    event.preventDefault();
    setMessagePaste('Impossible de copier-coller');
  };

  return (
    <div>
      {isLoading ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container-load">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <Spacer size="large" />

          <form onSubmit={handleClick} style={{ padding: 0, width: 350, textAlign: 'center' }}>
            <h3 className="question-form mb-5">Mettre à jour votre mot de passe</h3>

            <AppTextField
              label={'Nouveau mot de passe'}
              type={'password'}
              value={newPassword}
              InputProps={{
                type: showNewPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: (theme) => ({ paddingRight: theme.spacing(1) }),
              }}
              onChange={(_, value) => {
                setMessagePaste('');
                setNewPassword(value);
              }}
              onPaste={(e) => e.preventDefault()}
            />

            <div className="msg-pass">
              <em>
                Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins
                une lettre minuscule, et une valeur numérique.
              </em>
            </div>

            <AppTextField
              label={'Confirmation du mot de passe'}
              type={'password'}
              value={confirmPassword}
              placeholder={messagePaste}
              InputProps={{
                type: showConfirmPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: (theme) => ({ paddingRight: theme.spacing(1) }),
              }}
              onChange={(_, value) => {
                setMessagePaste('');
                setConfirmPassword(value);
              }}
              onPaste={handleOnPaste}
              onCopy={false as any}
              error={error != null && error.trim() != ''}
              helperText={error}
            />

            <Spacer />
            <ActionButton color="primary" type="submit" actionName="validate" startIcon={null} fullWidth />
          </form>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordUI;
