import React from 'react';
import classNames from 'clsx';
import { Box, Card, CardContent, type CardProps, Skeleton, Typography } from '@mui/material';
import { Spacer } from '../Spacer';

const componentName = 'EmployeeAbsenceCardSkeleton';

export type EmployeeAbsenceCardSkeletonProps = Omit<CardProps, 'children'>;

export function EmployeeAbsenceCardSkeleton(props: EmployeeAbsenceCardSkeletonProps) {
  const { className, ...cardProps } = props;

  return (
    <Card className={classNames(componentName, className)} color="surface" {...cardProps}>
      <CardContent>
        <Box display={'flex'} justifyContent={'space-between'} minHeight={28}>
          <Typography marginTop={'auto'} marginBottom={'auto'}>
            <Skeleton width={106} height={20} />
          </Typography>
        </Box>

        <Spacer spacing={2} />
        <Box display={'flex'} justifyContent={'space-between'} minHeight={20}>
          <Typography>
            <Skeleton width={80} height={32} />
          </Typography>
        </Box>

        <Spacer spacing={1} />

        <Box display={'flex'} justifyContent={'space-between'} minHeight={18}>
          <Typography>
            <Skeleton width={200} height={16} />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
