import * as React from 'react';
import { RenderLeafProps } from 'slate-react';
import { Code } from '../Code';
import { Theme } from '@mui/material';
export interface LeafProps extends RenderLeafProps {}

export function Leaf(props: LeafProps) {
  const { leaf, children, attributes } = props;

  let decorateChildren = children;

  if (leaf.bold) {
    decorateChildren = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    decorateChildren = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    decorateChildren = <s>{children}</s>;
  }
  if (leaf.code) {
    decorateChildren = <code>{children}</code>;
  }
  if (leaf.underline) {
    decorateChildren = <u>{children}</u>;
  }
  if (leaf.variable) {
    const background = (theme: Theme) => (leaf.error === true ? theme.md3.sys.color.errorContainer : undefined);
    const color = (theme: Theme) => (leaf.error === true ? theme.md3.sys.color.error : undefined);

    decorateChildren = (
      <Code
        sx={(theme) => ({
          background: background(theme),
          color: color(theme),
          zIndex: 10_000,
        })}
      >
        {children}
      </Code>
    );
  }

  return <span {...attributes}>{decorateChildren}</span>;
}
