import React from 'react';
import { BadgeProps, Box, Badge, useTheme, badgeClasses, BoxProps } from '@mui/material';
import { KERij } from '@/components/AppIcon';

export interface NotificationListItemIconProps extends Omit<BoxProps, 'children'> {
  badgeProps: Pick<BadgeProps, 'invisible'>;
}

export function NotificationListItemIcon(props: NotificationListItemIconProps) {
  const { badgeProps, ...boxProps } = props;

  const theme = useTheme();
  const icon = (
    <KERij
      sx={{
        height: theme.spacing(6),
        width: theme.spacing(6),
        color: theme.md3.sys.color.onSurfaceVariant,
      }}
    />
  );
  const iconContainer = badgeProps.invisible ? (
    icon
  ) : (
    <Badge
      variant="dot"
      invisible={false}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={(theme) => ({
        [`& .${badgeClasses.badge}`]: {
          height: theme.spacing(1.5),
          width: theme.spacing(1.5),
          borderRadius: '100%',
          left: theme.spacing(-1),
          top: theme.spacing(3),
          backgroundColor: theme.palette.aquaSerenity[600],
        },
      })}
    >
      {icon}
    </Badge>
  );

  return <Box {...boxProps}>{iconContainer}</Box>;
}
