import { fetchEmployeeProfile } from '@/services/employee';
import { AccountContext, AppDispatch } from '@/store';

export async function findEmployeeAccount(dispatch: AppDispatch) {
  const token = localStorage.getItem('access_token_employee');

  if (token) {
    try {
      const employeeProfile = await fetchEmployeeProfile();

      if (employeeProfile.data) {
        dispatch(AccountContext.actions.setUser(employeeProfile.data));
      } else {
        dispatch(AccountContext.logout());
      }
    } catch {
      dispatch(AccountContext.logout());
    } finally {
      dispatch(AccountContext.actions.setLoading(false));
    }
  } else {
    dispatch(AccountContext.logout());
    dispatch(AccountContext.actions.setLoading(false));
  }
}
