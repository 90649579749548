/* eslint-disable security/detect-object-injection */
const DeclarationFormUIStepsTitle = {
  AbsenceType: 'Que souhaitez vous déclarer ?',
  AbsenceLink: 'Votre maladie est-elle en lien avec une des situations suivantes ?',
  AccidentType: "Quel est le type d'accident ?",
  AbsenceDuration: 'Quelle est la durée de votre arrêt de travail ?',
  AbsenceDurationHospi: 'Quelle est la durée de votre hospitalisation ?',
  AbsenceDocument: 'Veuillez joindre votre justificatif',
  AbsenceContact: 'Acceptez-vous que votre entreprise prenne de vos nouvelles pendant votre absence ?',
  AbsenceMessage: 'Je souhaite adresser un message à mon manager',
  AbsenceValidation: 'Validation de votre déclaration',
  AbsenceEnd: 'Merci !',
} as const;
export type DeclarationFormUIStepsTitle =
  (typeof DeclarationFormUIStepsTitle)[keyof typeof DeclarationFormUIStepsTitle];

type StepMapping = {
  [type: string]: { [step: number]: DeclarationFormUIStepsTitle | null };
};

const titleMapping: StepMapping = {
  Edition: {
    1: DeclarationFormUIStepsTitle.AbsenceDocument,
    2: DeclarationFormUIStepsTitle.AbsenceContact,
    3: DeclarationFormUIStepsTitle.AbsenceMessage,
    4: DeclarationFormUIStepsTitle.AbsenceValidation,
    5: DeclarationFormUIStepsTitle.AbsenceEnd,
  },
  MA: {
    1: DeclarationFormUIStepsTitle.AbsenceType,
    2: DeclarationFormUIStepsTitle.AbsenceLink,
    3: DeclarationFormUIStepsTitle.AbsenceDuration,
    4: DeclarationFormUIStepsTitle.AbsenceDocument,
    5: DeclarationFormUIStepsTitle.AbsenceContact,
    6: DeclarationFormUIStepsTitle.AbsenceMessage,
    7: DeclarationFormUIStepsTitle.AbsenceValidation,
    8: DeclarationFormUIStepsTitle.AbsenceEnd,
  },
  AT: {
    1: DeclarationFormUIStepsTitle.AbsenceType,
    2: DeclarationFormUIStepsTitle.AccidentType,
    3: DeclarationFormUIStepsTitle.AbsenceDuration,
    4: DeclarationFormUIStepsTitle.AbsenceDocument,
    5: DeclarationFormUIStepsTitle.AbsenceContact,
    6: DeclarationFormUIStepsTitle.AbsenceMessage,
    7: DeclarationFormUIStepsTitle.AbsenceValidation,
    8: DeclarationFormUIStepsTitle.AbsenceEnd,
  },
  HO: {
    1: DeclarationFormUIStepsTitle.AbsenceDurationHospi,
    2: DeclarationFormUIStepsTitle.AbsenceDocument,
    3: DeclarationFormUIStepsTitle.AbsenceContact,
    4: DeclarationFormUIStepsTitle.AbsenceMessage,
    5: DeclarationFormUIStepsTitle.AbsenceValidation,
    6: DeclarationFormUIStepsTitle.AbsenceEnd,
  },
};

export function defineTitle(type: string | null, step: number) {
  const title = type == null ? null : titleMapping[type];
  if (title == null) return null;
  return title[step] ?? null;
}
