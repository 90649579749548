import * as React from 'react';
import classNames from 'clsx';
import { Avatar, Box, Typography, styled } from '@mui/material';
import { ParcoursRule as ParcoursRuleType } from '@/types';
import { extractRule } from './extractRule';
import { ParcoursRuleDetailText } from './ParcoursRuleDetailText';

const componentName = 'ParcoursRule';

export interface ParcoursRuleProps extends React.ComponentProps<'div'> {
  value: ParcoursRuleType;
  total: number;
  index?: number;
}

export function ParcoursRule(props: ParcoursRuleProps) {
  const { total, value, index, ...divProps } = props;

  const extractedRule = extractRule(value);

  const AvatarRule = styled(Avatar)(({ theme }) => ({
    height: 24,
    width: 24,
    fontSize: 16,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primaryBackground.contrastText,
  }));

  return (
    <div className={classNames(componentName)} {...divProps}>
      {extractedRule.success === false && <Typography color="error">{extractedRule.error}</Typography>}

      {extractedRule.success === true && (
        <Box fontWeight="bold" display="flex" mb={index === total - 1 ? 0 : 1.25}>
          {index != null && <AvatarRule>{index + 1}</AvatarRule>}
          <Typography color="primary" ml={1}>
            <b>{extractedRule.rule.key} </b>
            <ParcoursRuleDetailText parcoursRule={value} />
          </Typography>
        </Box>
      )}
    </div>
  );
}
