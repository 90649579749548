import React from 'react';
import { AccountContext, RHAccountContext, useAppSelector } from '@/store';
import { useAppWorkspace } from '@/hooks/useAppWorkspace';
import moment from 'moment';
import ModalAcceptNewCGU from '@/components/ModalAcceptNewCGU/ModalAcceptNewCGU';
import ModalResetPassword from '@/components/ModalResetPasswordRH/ModalResetPasswordRH';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line unicorn/prefer-set-has
const employeeRoute = ['/', '/profil', '/demarches', '/services', '/activites'];
const rhRoute = ['/pro', '/pro/absences', '/pro/actions', '/pro/stats', '/pro/admin', '/pro/tools'];

interface AccountProviderModalProps {
  children: React.ReactNode;
}

export const AccountProviderModal = ({ children }: AccountProviderModalProps) => {
  const appWorkspace = useAppWorkspace();
  const user = useAppSelector(AccountContext.getUser);
  const rh = useAppSelector(RHAccountContext.getProfile);
  const isRoutePro = appWorkspace.match('pro');
  const location = useLocation();

  return (
    <>
      {children}
      {user &&
      moment().diff(moment(user.last_change_password), 'months') >= 6 &&
      employeeRoute.some((route) => location.pathname.startsWith(route)) ? (
        <ModalResetPassword type={'employee'} />
      ) : (
        <></>
      )}
      {rh &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore FIXME: wrong typing
      moment().diff(moment(rh.last_change_password), 'months') >= 6 &&
      rhRoute.some((route) => location.pathname.startsWith(route)) ? (
        <ModalResetPassword type={'rh'} />
      ) : (
        <></>
      )}
      {user && !user.cgu_checked && !isRoutePro ? <ModalAcceptNewCGU /> : <></>}
    </>
  );
};
