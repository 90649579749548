import * as React from 'react';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withShortcuts } from './withShortcut';
import { withInlineTypes } from './withInlineTypes';
import { withHistory } from 'slate-history';
import { withVariables } from './withVariables';

export const EditorType = {
  Rich: 'rich',
  Text: 'text',
} as const;
export type EditorType = (typeof EditorType)[keyof typeof EditorType];

export function useEditor(type: EditorType) {
  return React.useMemo(
    () =>
      type === EditorType.Text
        ? withVariables(withReact(withHistory(createEditor())))
        : withShortcuts(withInlineTypes(withVariables(withReact(withHistory(createEditor()))))),
    [type],
  );
}
