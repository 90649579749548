import { emailRegex, phoneRegex } from './regex';

export const isPhoneNumber = (require?: boolean) => {
  return (value: string | null | undefined) => {
    if (!value && require) return false;
    if ((!value || value.trim() === '') && !require) return true;

    return phoneRegex.test(value ?? '');
  };
};

export const isEmail = (require?: boolean) => {
  return (value: string | null | undefined) => {
    if (!value && require) return false;
    if ((!value || value.trim() === '') && !require) return true;

    return emailRegex.test(value ?? '');
  };
};
