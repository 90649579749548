import React from 'react';
import { Service } from '@/types';
import { ServiceCard, ServiceCardLoader } from '../ServiceCard';
import { IconButtonProps } from '../IconButton/IconButton';
import { Grid } from '@mui/material';

export interface ServiceListProps {
  values: Service[];
  isLoading?: boolean;
  action?: (value: Service) => IconButtonProps;
  onClick?: (value: Service) => void;
}

export function ServiceList(props: ServiceListProps) {
  const { values, action, isLoading, onClick } = props;

  return (
    <Grid container spacing={3}>
      {values.length > 0 &&
        values.map((value, index) => {
          return (
            <Grid item xs={12} sm={4} lg={3} key={`ServiceCard__${index}`}>
              <ServiceCard
                value={value}
                onClick={() => onClick?.(value)}
                action={action?.(value)}
                sx={(theme) => ({
                  marginLeft: { xs: theme.spacing(1), sm: 0 },
                  marginRight: { xs: theme.spacing(1), sm: 0 },
                })}
              />
            </Grid>
          );
        })}

      {isLoading && (
        <Grid item xs={12} sm={4} lg={3} key={`ServiceCard__loading`}>
          <ServiceCardLoader />
        </Grid>
      )}

      {values.length === 0 && !isLoading && (
        <Grid item xs={12} sm={4} lg={3} key={`ServiceCard__loading`}>
          <ServiceCardLoader failedText="Aucun service disponible dans cette catégorie" />
        </Grid>
      )}
    </Grid>
  );
}
