// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandIcon {
  position: relative;
  aspect-ratio: 753/361;
}
.BrandIcon--dark {
  color: #fff;
}
.BrandIcon__Image {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.BrandIcon__Caption {
  height: 25%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(30%);
  margin-left: 2%;
}
.BrandIcon__Caption text {
  fill: currentColor;
  font-size: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/components/BrandIcon/BrandIcon.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,0BAA0B;EAC1B,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".BrandIcon {\n  position: relative;\n  aspect-ratio: 753/361;\n}\n.BrandIcon--dark {\n  color: #fff;\n}\n.BrandIcon__Image {\n  max-height: 100%;\n  max-width: 100%;\n  width: 100%;\n  height: 100%;\n}\n.BrandIcon__Caption {\n  height: 25%;\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  transform: translateY(30%);\n  margin-left: 2%;\n}\n.BrandIcon__Caption text {\n  fill: currentColor;\n  font-size: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
