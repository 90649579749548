/* eslint-disable unicorn/prefer-regexp-test */
// ** React Imports
import React, { ReactNode } from 'react';

// ** MUI Imports
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from './config/appTheme';
import { rhTheme } from './config/rhTheme';
import { AppWorkspace, useAppWorkspace } from '@/hooks/useAppWorkspace';

interface Props {
  children: ReactNode;
}

const ThemeComponent = (props: Props) => {
  const { children } = props;
  const appWorkspace = useAppWorkspace();
  const theme = appWorkspace.match(AppWorkspace.Pro) ? rhTheme : appTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Button variant="contained">Contained</Button> */}
      {/* <GlobalStyles styles={() => GlobalStyling(theme) as any} /> */}
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
