import React from 'react';
import { Box, BoxProps, Breadcrumbs, Link, Typography } from '@mui/material';
import { NavigateNextRounded } from '@mui/icons-material';

export interface ChangePasswordUIBreadcrumbsProps extends BoxProps {}

export function ChangePasswordUIBreadcrumbs(props: ChangePasswordUIBreadcrumbsProps) {
  const { ...boxProps } = props;

  const breadcrumbs = [
    <Link underline="hover" key="1" href={'/profil'} height={20}>
      <Typography fontSize={14} fontWeight={600} sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}>
        Profil
      </Typography>
    </Link>,
    <Typography
      key="2"
      fontSize={14}
      fontWeight={600}
      sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}
    >
      Modifier mon mot de passe
    </Typography>,
  ];

  return (
    <Box {...boxProps}>
      <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />}>{breadcrumbs}</Breadcrumbs>
    </Box>
  );
}
