import React, { useMemo } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemProps, ListItemText, Skeleton, Typography } from '@mui/material';
import { UserInterface } from '@/types';
import { capitalizeFirst } from '@/utils/string.utils';
import { useAppVariant } from '@/hooks/useAppVariant';

export interface ProfilResumeProps extends ListItemProps {
  user: UserInterface | null;
}

export function ProfilResume(props: ProfilResumeProps) {
  const { user } = props;

  const firstname = capitalizeFirst(user?.employee_information.firstname);
  const lastname = capitalizeFirst(user?.employee_information.lastname);

  const userShortIdentity = `${firstname[0]}${lastname[0]}`;
  const userLongIdentity = `${firstname} ${lastname}`;

  const emptyUser = useMemo(() => user == null, [user]);

  return (
    <ItemResume
      primary={emptyUser ? <Skeleton width={150} /> : userLongIdentity}
      secondary={emptyUser ? <Skeleton width={100} /> : (user?.employee_information.business.name ?? '')}
      avatar={emptyUser ? <Skeleton width={0} /> : userShortIdentity}
    />
  );
}

const ItemResume = (props: { primary?: React.ReactNode; secondary?: React.ReactNode; avatar?: React.ReactNode }) => {
  const { primary, secondary, avatar } = props;
  const appVariant = useAppVariant();

  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemAvatar>
        <Avatar
          sx={(theme) => ({
            height: appVariant.small ? theme.spacing(8) : theme.spacing(6),
            width: appVariant.small ? theme.spacing(8) : theme.spacing(6),
            backgroundColor: theme.md3.sys.color.tertiaryContainer,
            color: theme.md3.sys.color.onTertiaryContainer,
            border: 0,
          })}
        >
          <Typography fontSize={20} fontWeight={600}>
            {avatar}
          </Typography>
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={primary}
        secondary={secondary}
        sx={{ m: 0, ml: 2 }}
        primaryTypographyProps={{
          sx: {
            fontSize: appVariant.small ? 20 : 16,
            lineHeight: appVariant.small ? 1.2 : 1.25,
            fontWeight: 600,
          },
        }}
        secondaryTypographyProps={{
          sx(theme) {
            return {
              fontSize: appVariant.small ? 16 : 14,
              lineHeight: appVariant.small ? 1.5 : 1.4,
              color: theme.md3.sys.color.onSurface,
            };
          },
        }}
      />
    </ListItem>
  );
};
