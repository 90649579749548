import React from 'react';
import { NavbarMobileTabProps } from './NavbarMobileTab';
import { KERij } from '@/components/AppIcon';
import { UserAvatar } from './UserAvatar';
import { UserInterface } from '@/types';
import { Badge, Box } from '@mui/material';
import { AccountCircleOutlined, DashboardOutlined, Event, SearchOutlined } from '@mui/icons-material';

/**
 * Défini le contenu du menu Mobile salarié
 */
export function defineMenu(user: UserInterface | null, options: { hasUnread: boolean }): NavbarMobileTabProps[] {
  return [
    user
      ? {
          label: 'Profil',
          to: '/profil',
          icon: (
            <Box sx={(theme) => ({ marginTop: theme.spacing(1) })}>
              <UserAvatar user={user} />
            </Box>
          ),
        }
      : {
          label: 'Connexion',
          to: '/login',
          icon: <AccountCircleOutlined />,
        },
    {
      label: 'Services',
      feature: 'ServiceManagement',
      to: '/services',
      icon: <SearchOutlined />,
    },
    {
      label: 'Déclarer',
      to: '/declaration',
      icon: <KERij sx={{ padding: '2px' }} />, // FIXME
    },
    {
      label: 'Rdv',
      feature: 'InterviewManagement',
      to: '/rdv',
      icon: <Event />,
    },
    {
      label: 'Activités',
      to: '/activites',
      icon: (
        <Badge
          overlap="circular"
          color="warning"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          invisible={!options.hasUnread}
        >
          <DashboardOutlined />
        </Badge>
      ),
    },
  ];
}
