import React from 'react';
import { NavbarMobileTabProps } from './NavbarMobileTab';
import { KERij, withActivityBadge } from '@/components/AppIcon';
import { Feature, UserInterface } from '@/types';
import FolderOpenOutlined from '@mui/icons-material/FolderOpenOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';

const FolderOpen = withActivityBadge(FolderOpenOutlined);
const Notifications = withActivityBadge(NotificationsOutlined);

/**
 * Défini le contenu du menu Mobile salarié
 */
export function defineMenu(
  user: UserInterface | null,
  options: { hasUnread: boolean; hasEvent: boolean; isLogin: boolean },
): NavbarMobileTabProps[] {
  return [
    ...(options.isLogin
      ? [
          {
            label: 'Services',
            feature: 'ServiceManagement' as keyof Feature,
            to: options.isLogin ? '/services' : '/login',
            icon: <SearchOutlined />,
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Événements',
            to: options.isLogin ? '/evenements' : '/login',
            icon: <FolderOpen badgeProps={{ invisible: !options.hasEvent }} />,
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Démarches',
            to: options.isLogin ? '/demarches' : '/login',
            icon: <KERij />, // FIXME
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Notifications',
            to: '/notifications',
            icon: <Notifications badgeProps={{ invisible: !options.hasUnread }} />,
          },
        ]
      : []),
  ];
}
