import * as React from 'react';
import classNames from 'clsx';
import { ParcoursStateListItem } from './ParcoursStateListItem';
import { PageSectionList, type PageSectionListProps } from '@/components/Page';
import { ParcoursState } from '@/types';
import { Box, Grid, Typography } from '@mui/material';
import { Spacer } from '../Spacer';

const componentName = 'ParcoursStateList';

export interface ParcoursStateListProps extends PageSectionListProps {
  items?: Array<ParcoursState>;
}

export function ParcoursStateList(props: ParcoursStateListProps) {
  const { className, items, ...otherProps } = props;

  return items == null ? (
    <></>
  ) : (
    <Box>
      <Grid container>
        <Grid item xs={2.5}>
          <HeadingText>Collaborateur</HeadingText>
        </Grid>
        <Grid item xs={2.5}>
          <HeadingText>Date et heure</HeadingText>
        </Grid>
        <Grid item xs={7}>
          <HeadingText>Parcours</HeadingText>
        </Grid>
      </Grid>

      <Spacer size="small" />

      <PageSectionList className={classNames(componentName, className)} {...otherProps}>
        {items.map((item) => (
          <ParcoursStateListItem key={item.id} state={item} />
        ))}
      </PageSectionList>
    </Box>
  );
}

const HeadingText = (props: { children?: string }) => (
  <Typography p={1.5} pt={0} pb={0} lineHeight={'normal'} fontSize={14} fontWeight={600}>
    {props.children}
  </Typography>
);
