/**
 * @see https://material-web.dev/theming/material-theming/#shape
 */
export const shape = {
  corner: {
    none: 0,
    extraSmall: 6,
    // small: 8,
    medium: 10,

    // large:
    // extraLarge:
    full: 1000,
  },
};
