import { Absence } from '@/types/absences.type';
import { API_ROUTES } from '@/config/routes.config';
import { varToStringParams } from '@/shared/utils/paths';
import { Service, ServiceTheme } from '@/types';
import axios from '@/lib/axios';
import { AxiosError } from 'axios';

export interface EmployeeAbsenceCodeParams {
  description: string;
  description1?: string;
  description2?: string;
  idBusiness?: string;
}

export const getAbsenceCode = async (params: EmployeeAbsenceCodeParams) => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'description',
        value: params.description,
      },
      {
        key: 'description1',
        value: params.description1,
      },
      {
        key: 'description2',
        value: params.description2,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });

  return await axios.get<{ code: string }>(`/api/employee/absence_code${query}`);
};

export const employeeFetchAbsences = async (idAbsence: string, offset: number): Promise<any> => {
  return await axios.get('/api/protected/employee/absences/all_absences', {
    params: {
      idAbsence,
      limit: offset,
    },
  });
};
// TODO IMPROVE WITH SAME DATA AND INTERFACE (SIGNUP && VERIFYSELECT)
// IN VERIFYSELECT THERE IS LASTNAME : OTP BUT NOT IN SIGNUP
export const verifyEmployee = async (form: any) => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'firstname',
        value: form.prenom || form.firstname,
      },
      {
        key: 'birthday',
        value: form.dateNaissance || form.birthday,
      },
      {
        key: 'birthCommun',
        value: form.dptNaissance || form.birthCommun,
      },
      {
        key: 'zipcode',
        value: form.codePostal || form.zipcode,
      },
      {
        key: 'lastname',
        value: form.lastname,
      },
    ],
  });
  try {
    return await axios.get(`/api/public/employee/verify_employee${query}`);
  } catch (e: unknown) {
    return (e as unknown as AxiosError).response;
  }
};

export const viewDocumentEmployee = async (data: any, user: any, type: string): Promise<any> => {
  return await axios.get('/api/file_s3', {
    responseType: 'arraybuffer',
    params: {
      url: data.url,
      type: type,
      idBusiness: user.employee_information.id_business,
    },
  });
};

export const employeeUpdatePassword = async (data: any): Promise<any> => {
  return await axios.put('/api/public/employee/update_password', data);
};

export const employeeUpdateAbsence = async (data: any): Promise<any> => {
  return await axios.put(`/api/employee/update_absence`, data);
};

export const employeeDeclareAbsence = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/declare_absence`, data);
};

export const fetchEmployeePoliciesClient = async (): Promise<any> => {
  return await axios.get(`/api/employee/policies/client`);
};

export const fetchEmployeeProfile = async (config?: any) => {
  return await axios.get(`/api/employee/profile`, { ...config });
};

export const employeeViewNotif = async (data: { page?: number; per_page?: number; since: number }) => {
  const query = varToStringParams({
    variablesArray: [
      ...(data?.page ? [{ key: 'page', value: data?.page }] : []),
      ...(data?.per_page ? [{ key: 'per_page', value: data?.per_page }] : []),
      ...(data?.since ? [{ key: 'since', value: data?.since }] : []),
    ],
  });

  return await axios.get(`/api/employee/notifications${query}`);
};

export const employeeCheckNotif = async (data: any) => {
  return await axios.put(`/api/employee/check_notif`, data);
};
export const getInterviewDocumentEmployee = async (): Promise<any> => {
  return await axios.get(`/api/employee/get_interview_document_employee`);
};

export const medicalVisit = async (): Promise<any> => {
  return await axios.get(`/api/employee/medical_visit`);
};
export const employeeGetCpam = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'zipcode',
        value: params.zipcode,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  return await axios.get(`/api/employee/cpam/${query}`);
};
export const employeeVerifIsHospi = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/verify_is_hospi`, data);
};

export const employeeVerifyAbsenceMat = async () => {
  return await axios.post<{
    isHaving: boolean;
    absence: Absence;
  }>(`/api/employee/verify_absence_mat`);
};

export const employeeSendCodeNoAuth = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/send_code_no_auth`, data);
};

export const employeeSendCode = async (data: { phone?: string; email?: string }) => {
  return await axios.post(`/api/employee/send_code`, data);
};

export const employeeAcceptNewCgu = async (data: any): Promise<any> => {
  return await axios.put(`/api/employee/accept_new_cgu`, data);
};

export const employeeKeepPassword = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/keep_password`, data);
};

export const employeeVerifyPassword = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/verify_password`, data);
};
export const employeeChangePassword = async (data: any): Promise<any> => {
  return await axios.put(`/api/employee/change_password`, data);
};
export const employeeVerifyAbsence = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/verify_absence`, data);
};
export const employeeAddQuestionReturn = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/add_question_return`, data);
};
export const employeeVerifyQuestionReturn = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/verify_question_return`, data);
};

interface UpdateDateReturnBody {
  idAbsence: string;
  dateReturn: string;
  log?: string;
  typeNotification?: string;
  idEmployeeInformation?: string;
  updateEnd?: boolean;
}

export const employeeUpdateDateReturn = async (body: UpdateDateReturnBody): Promise<any> => {
  return await axios.post(`/api/employee/update_date_return`, body);
};

export const employeeExtendAbsence = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/extend_absence`, data);
};
export const employeeVerifyQuestionMedical = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/verify_question_medical`, data);
};

export const employeeUpdateQuestionMedical = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/update_question_medical`, data);
};

export const employeeRequestInterview = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/request_interview`, data);
};

export interface fetchServiceParameters {
  theme?: ServiceTheme;
  page?: number;
  per_page?: number;
}

export const fetchService = async (params: fetchServiceParameters) => {
  const query = varToStringParams({
    variablesArray: [
      { key: 'page', value: params.page },
      { key: 'per_page', value: params.per_page },
      { key: 'theme', value: params.theme },
    ],
  });

  return await axios.get<{ services: Service[]; theme: ServiceTheme | undefined }>(`/api/employee/get_service${query}`);
};
export const fetchSelectedService = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idService',
        value: params.idService,
      },
    ],
  });

  return await axios.get(`/api/employee/get_selected_service${query}`);
};

export const employeeConfirmCode = async (data: any): Promise<any> => {
  return await axios.post(`/api/employee/confirm_code`, data);
};

export const employeeAuthSignup = async (data: any) => {
  return await axios.post<employeeAuthSignup.Return>(`/api/employee/auth/signup`, data);
};
export namespace employeeAuthSignup {
  export type Return = any;
}

export const employeeUpdatePassWord = async (data: any): Promise<any> => {
  return await axios.put(API_ROUTES.EMPLOYEE_UPDATE_PASSWORD, data);
};

export const employeeVerifyContact = async (data: {
  phone?: string;
  email?: string;
  idEmployeeInformation?: string;
  idEmployeeLogin?: string;
}) => {
  return await axios.post(API_ROUTES.EMPLOYEE_VERIFY_CONTACT, data);
};

export const employeeDecodeToken = async (data: any): Promise<any> => {
  return await axios.post(`/api/token/decode`, data);
};

export const employeeCsv = async (searchValue: string, portfolio?: 'true'): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'firstname',
        value: searchValue,
      },
      {
        key: 'lastname',
        value: searchValue,
      },
      {
        key: 'description',
        value: searchValue,
      },
      {
        key: 'portfolio',
        value: portfolio,
      },
    ],
  });
  return await axios.get(`/api/rh/extract/employees${query}`);
};
