import * as React from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { AppTextField } from '@/components/AppTextField';
import { AppSelect, AppSelectMultiple } from '@/components/AppSelect';
import {
  isParcoursRuleComparableKey,
  isParcoursRuleIdentifiableKey,
  isParcoursRuleOperator,
  ParcoursRuleKey,
  ParcoursRuleKeyLabel,
  ParcoursRuleOperator,
  ParcoursRuleOperatorComparable,
  ParcoursRuleOperatorIdentifiable,
  ParcoursRuleOperatorLabel,
  ParcoursRuleType,
} from '@/types';
import { IconButton } from '@/components/IconButton';
import { ParcoursRuleValue } from '@/types/ParcoursRuleValue';
import { Check, Delete } from '@/components/AppIcon';

export interface ParcoursInputRule {
  order: number;
  key: ParcoursRuleKey;
  operator?: ParcoursRuleOperator;
  value?: ParcoursRuleValue;
}

export interface ParcoursInputProps {
  value: ParcoursInputRule;
  adding?: boolean;
  scriptKeys: Array<ParcoursRuleKey | null>;
  onConfirm?: (value: ParcoursInputRule, options?: { duration: number }) => void;
  onChange?: (value: ParcoursInputRule) => void;
  onDelete?: (value: ParcoursInputRule) => void;
}

export function ParcoursInput(props: ParcoursInputProps) {
  const { value, adding, scriptKeys, onChange, onConfirm, onDelete } = props;

  const [ruleValue, setRuleValue] = React.useState<ParcoursRuleKey | undefined>(value.key);
  const [operatorValue, setOperatorValue] = React.useState<ParcoursRuleOperator | undefined>(value.operator);
  const [customValue, setCustomValue] = React.useState<string | undefined>();
  const [customMultipleValue, setCustomMultipleValue] = React.useState<string[]>();
  const [durationValue, setDurationValue] = React.useState<string | undefined>();

  const operatorItems = React.useMemo(() => {
    if (isParcoursRuleComparableKey(ruleValue)) {
      return Object.entries(ParcoursRuleOperatorComparable).map(([key, value]) => ({
        label: ParcoursRuleOperatorLabel[value],
        value: value,
      }));
    } else if (isParcoursRuleIdentifiableKey(ruleValue)) {
      return Object.entries(ParcoursRuleOperatorIdentifiable).map(([key, value]) => ({
        label: ParcoursRuleOperatorLabel[value],
        value: value,
      }));
    } else {
      return [];
    }
  }, [ruleValue]);

  function initValue() {
    setRuleValue(value.key);
    setOperatorValue(value.operator);
    setCustomValue(
      typeof value?.value === 'string' || typeof value?.value === 'number'
        ? `${value.value}`
        : typeof (value?.value as any)?.value === 'number' || typeof (value?.value as any)?.value === 'string'
          ? `${(value?.value as any)?.value}`
          : undefined,
    );
    setCustomMultipleValue(Array.isArray(value?.value) ? value.value.map((v) => `${v}`) : []);
    setDurationValue(
      (value?.value as any)?.since
        ? (value?.value as any)?.sinceUnit === 'days'
          ? `${(value?.value as any).since}`
          : `${(value?.value as any)?.since * 365}`
        : undefined,
    );
  }

  React.useEffect(() => initValue(), [value]);
  return (
    <>
      <Grid container style={{ marginTop: 12 }}>
        {/* OPERATEUR */}
        <Grid item xs={4}>
          <AppSelect
            value={operatorValue}
            label={ruleValue ? ParcoursRuleKeyLabel[ruleValue] : ruleValue}
            placeholder="Sélectionnez un opérateur"
            items={operatorItems}
            disabled={ruleValue == null}
            readOnly={ruleValue && scriptKeys.includes(ruleValue)}
            onChange={(_, _value) => {
              const newValue = isParcoursRuleOperator(_value) ? _value : undefined;
              setOperatorValue(newValue);
              onChange?.({ ...value, operator: newValue });
            }}
            LabelProps={{
              sx: { overflow: 'visible' },
            }}
          />
        </Grid>

        {/* INPUT NUMBER */}
        {ruleValue &&
          (ParcoursRuleType[ruleValue].type === 'number' || ParcoursRuleType[ruleValue].type === 'duration') && (
            <Grid item xs={1.5} paddingLeft={1.5}>
              <AppTextField
                value={customValue}
                InputProps={{
                  type: 'number',
                  componentsProps: {
                    input: {
                      max: 999,
                      maxLength: 3,
                      style: { textAlign: 'center' },
                    },
                  },
                }}
                label={''}
                disabled={operatorValue == null}
                readOnly={ruleValue && scriptKeys.includes(ruleValue)}
                onChange={(_, _value) => {
                  const newValue = _value == null || _value.trim() === '' ? undefined : _value;
                  setCustomValue(newValue);
                  onChange?.({ ...value, value: { value: Number.parseInt(_value) } });
                }}
              />
            </Grid>
          )}

        {/* DATE */}
        {ruleValue && ParcoursRuleType[ruleValue].type === 'date' && (
          <Grid item xs={4} paddingLeft={1.5}>
            <AppTextField
              label=""
              value={customValue}
              InputProps={{ inputProps: { type: 'date' } }}
              readOnly={ruleValue && scriptKeys.includes(ruleValue)}
              onChange={(_, _value) => {
                setCustomValue(_value);
                onChange?.({ ...value, value: _value });
              }}
            />
          </Grid>
        )}

        {/* ENUM SIMPLE */}
        {ruleValue && ParcoursRuleType[ruleValue].type === 'enum' && operatorValue !== ParcoursRuleOperator.in && (
          <Grid item xs={4.5} paddingLeft={1.5}>
            {scriptKeys.includes(ruleValue) ? (
              <AppTextField
                label={''}
                value={
                  (ParcoursRuleType[ruleValue] as any).items.find(
                    (item: { value: string }) => item.value === customValue,
                  )?.label
                }
                readOnly
              />
            ) : (
              <AppSelect
                value={customValue}
                label={''}
                items={(ParcoursRuleType[ruleValue] as any).items}
                disabled={operatorValue == null}
                readOnly={scriptKeys.includes(ruleValue)}
                onChange={(_, _value) => {
                  const newValue = _value == null || _value.trim() === '' ? undefined : _value;
                  setCustomValue(newValue);
                  onChange?.({ ...value, value: newValue });
                }}
              />
            )}
          </Grid>
        )}

        {/* ENUM MULTIPLE */}
        {ruleValue && ParcoursRuleType[ruleValue].type === 'enum' && operatorValue === ParcoursRuleOperator.in && (
          <Grid item xs={4.5} paddingLeft={1.5}>
            <AppSelectMultiple
              value={customMultipleValue ?? []}
              label={''}
              items={(ParcoursRuleType[ruleValue] as any).items}
              disabled={operatorValue == null}
              readOnly={ruleValue && scriptKeys.includes(ruleValue)}
              onChange={(_, _value) => {
                setCustomMultipleValue(_value ?? []);
                onChange?.({ ...value, value: _value ?? [] });
              }}
            />
          </Grid>
        )}

        {/* DEPUIS */}
        {ruleValue && ParcoursRuleType[ruleValue].type === 'duration' && (
          <Grid item xs={3} paddingLeft={1.5}>
            <AppTextField
              label={'Période d’observation en J'}
              value={durationValue}
              InputProps={{
                type: 'number',
                componentsProps: {
                  input: {
                    max: 999,
                    maxLength: 3,
                    style: { textAlign: 'right' },
                  },
                },
                className: 'align-items-center',
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography fontSize={12}>jour(s)</Typography>
                  </InputAdornment>
                ),
              }}
              LabelProps={{ sx: { overflow: 'visible' } }}
              readOnly={ruleValue && scriptKeys.includes(ruleValue)}
              onChange={(_, _value) => {
                setDurationValue(_value);
                onChange?.(value);
              }}
            />
          </Grid>
        )}

        {/* ACTIONS */}
        <Grid display="flex" sx={{ margin: 'auto' }} item xs>
          <Box textAlign="right" width={'100%'}>
            {/* VALIDATION */}
            {ruleValue && !scriptKeys.includes(ruleValue) && (
              <IconButton
                icon={<Check />}
                disabled={
                  (customValue == null && operatorValue !== ParcoursRuleOperator.in) ||
                  (customMultipleValue &&
                    customMultipleValue.length === 0 &&
                    operatorValue === ParcoursRuleOperator.in) ||
                  (ParcoursRuleType[ruleValue].type === 'duration' && durationValue == null)
                }
                onClick={() => {
                  onConfirm?.(value, durationValue ? { duration: Number.parseInt(durationValue) } : undefined);
                }}
                color="primary"
                sx={(theme) => ({
                  fontSize: 16,
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                  backgroundColor: theme.palette.secondary.main,
                })}
              />
            )}

            {/* SUPPRESSION */}
            <IconButton
              size="medium"
              icon={<Delete />}
              disabled={ruleValue && scriptKeys.includes(ruleValue) && adding}
              onClick={() => onDelete?.(value)}
              sx={(theme) => ({ color: theme.palette.primary.dark })}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
