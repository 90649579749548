import React from 'react';
import { ListCardHeader } from '@/components/DashboardRH/ListCard';
import { Typography } from '@mui/material';
interface EntretiensLegalCardHeaderProps {
  title: string;
}

const EntretiensLegalCardHeader = ({ title }: EntretiensLegalCardHeaderProps) => {
  return (
    <ListCardHeader>
      <Typography fontSize={14} fontWeight={600} ml={2.5}>
        {title}
      </Typography>
    </ListCardHeader>
  );
};

export default EntretiensLegalCardHeader;
