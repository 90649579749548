import * as React from 'react';
import { RHNotification, useAppDispatch, useAppSelector } from '@/store';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import isAuthorized from '@/hooks/isAuthorized';
import { Box } from '@mui/material';
import { ListAltRounded } from '@mui/icons-material';
import { Badge } from '@/components/Badge';
import { ActionButton } from '@/components/ActionButton';

export function LayoutActions() {
  const location = window.location.pathname;
  const pathToActions = PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notificationTotal = useAppSelector(RHNotification.getTotal);
  const isActionsPage = React.useMemo(() => location === pathToActions, [location, pathToActions]);

  React.useEffect(() => {
    if (isAuthorized(window.location.pathname)) dispatch(RHNotification.updateTotal());
  }, []);

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(1.5),
        paddingTop: theme.spacing(5.5),
        paddingBottom: 0,
      })}
    >
      <ActionButton
        fullWidth
        color={'primary'}
        // FIXME: Ce style de bouton n'est pas dans le design system
        sx={
          isActionsPage
            ? (theme) => ({
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              })
            : undefined
        }
        label={
          <Box fontSize={12} fontWeight={600} textAlign={'left'} ml={1}>
            Actions à réaliser
          </Box>
        }
        startIcon={<ListAltRounded />}
        endIcon={notificationTotal > 0 ? <Badge label={notificationTotal} /> : undefined}
        onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS)}
      />
    </Box>
  );
}
