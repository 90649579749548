import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import RdvFinUI from './components/RdvFinUI';

export const RdvFinPage: React.FC = () => {
  return (
    <AppLayout>
      <RdvFinUI />
    </AppLayout>
  );
};
