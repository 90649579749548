import React from 'react';
import { Service } from '@/types';
import { Card, CardActionArea, CardProps, styled } from '@mui/material';
import { IconButtonProps } from '../IconButton/IconButton';
import { ServiceCardLogo } from './ServiceCardLogo';
import { ServiceCardActions } from './ServiceCardActions';
import { ServiceCardInfos } from './ServiceCardInfos';

export interface ServiceCardProps extends CardProps {
  value: Service;
  action?: IconButtonProps;
  onClick?: () => void;
}

export function ServiceCard(props: ServiceCardProps) {
  const { value, action, onClick, ...cardProps } = props;

  return (
    <>
      <StyledCard elevation={1} {...cardProps}>
        <StyledCardArea onClick={onClick}>
          <ServiceCardLogo value={value} />
          <ServiceCardActions value={value} action={action} />
        </StyledCardArea>
      </StyledCard>
      <ServiceCardInfos value={value} sx={cardProps.sx} />
    </>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  border: '1px solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.shape.borderColorVariant,
  backgroundColor: theme.palette.common.white,
}));

const StyledCardArea = styled(CardActionArea)(({ theme }) => ({
  height: theme.spacing(24.5),
}));
