/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps } from '@mui/material';
import { Absence } from '@/types';
import { Spacer } from '../Spacer';
import { ActionButton } from '../ActionButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const componentName = 'EmployeeAbsenceCardActions';

export interface EmployeeAbsenceCardActionsProps extends Omit<BoxProps, 'children'> {
  value: Absence;
  hasDocument?: boolean;
}

export function EmployeeAbsenceCardActions(props: EmployeeAbsenceCardActionsProps) {
  const { value, hasDocument, className, ...boxProps } = props;

  const navigate = useNavigate();

  const action = hasDocument
    ? value.notifications?.find((notification) => notification.type_notification === "Notification de suivi de l'arret")
    : undefined;

  const updateDocument = () => {
    const documentType =
      value.type_absence.description_1 == null ? 'HO' : String(value.type_absence.description_1) === '1' ? 'MA' : 'AT';
    const documentMaladieType =
      value.type_absence.description_2 == null ? '' : `&maladie-type=${value.type_absence.description_2}`;

    localStorage.setItem('declaration_date_start', moment(value.date_start).format('YYYY-MM-DD'));
    if (value.date_end != null)
      localStorage.setItem('declaration_date_end', moment(value.date_end).format('YYYY-MM-DD'));
    localStorage.setItem('declaration_last_day_work', moment(value.date_start).format('YYYY-MM-DD'));

    navigate(`/declaration-form?edit=${value.id}&step=1&type=${documentType}${documentMaladieType}`);
  };

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      {!hasDocument && (
        <Box>
          <Spacer spacing={4} />
          <ActionButton color="error" label={'Déposer un justificatif'} onClick={updateDocument} />
        </Box>
      )}

      {action && (
        <Box>
          <Spacer spacing={2.5} />
          <ActionButton href={action.url} color="secondary" label={'Mettre à jour mon absence'} />
        </Box>
      )}
    </Box>
  );
}
