import axios from '@/lib/axios';
import { DocumentAbsence } from '@/types';
import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';

export interface VerifyDocumentParameters {
  file: File;
}

export interface VerifyDocumentResultParameters {
  transactionId: string;
  id_employee_information: string;
  id_type_absence: string;
  date_start: string;
  date_end: string;
}

export const verifyAbsenceDocument = async (
  parameters: VerifyDocumentParameters,
): Promise<{ success: true; data: { transactionId: string } } | { success: false; error: string }> => {
  const { file } = parameters;
  const formData = new FormData();
  formData.set('file', file, file.name);

  try {
    const { data } = await axios.post<{ data: { transactionId: string } }>(
      '/api/employee/declare_absence/verify_document',
      formData,
      {},
    );
    return { success: true, data: data.data };
  } catch {
    return { success: false, error: 'Une erreur est survenue durant la validation' };
  }
};

export const verifyAbsenceDocumentResult = async (
  parameters: VerifyDocumentResultParameters,
): Promise<{ success: true; data: { data: DocumentAbsence['ocr_result'] } } | { success: false; error: string }> => {
  const { transactionId, id_employee_information, date_start, date_end, id_type_absence } = parameters;
  const formData = new FormData();
  formData.set('id_employee_information', id_employee_information);
  formData.set('date_start', date_start);
  formData.set('date_end', date_end);
  formData.set('id_type_absence', id_type_absence);

  const data = await retry(
    () => axios.post(`/api/employee/declare_absence/verify_document/result`, parameters, { params: { transactionId } }),
    {
      delay: 400,
      factor: 1.5,
      maxAttempts,
    },
  );

  if (data == 'timeout') return { success: false, error: "La validation de votre document n'est pas terminée" };
  if (data == 'error')
    return { success: false, error: 'Une erreur est survenue durant la validation de votre document' };

  return { success: true, data };
};

const maxAttempts = 9;
const minDelay = 7000;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
type CallbackResponse = object & { data: DocumentAbsence['ocr_result'] };

async function retry(
  asyncFunction: () => Promise<AxiosResponse<CallbackResponse>>,
  options: { delay: number; factor: number; maxAttempts: number },
): Promise<CallbackResponse | 'error' | 'timeout'> {
  let attempt = 0;
  await delay(minDelay);
  while (attempt <= options.maxAttempts) {
    attempt++;
    const delayTime = options.delay * Math.pow(options.factor, attempt - 1);
    try {
      const { data, status } = await asyncFunction();
      if (status === StatusCodes.NO_CONTENT) await delay(delayTime);
      else if (status === StatusCodes.OK) return data;
      else return 'error';
    } catch {
      return 'error';
    }
  }
  return 'timeout';
}
