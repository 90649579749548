import Verify from '../../components/Signup/Verify';
import { AppLayout } from '../../components/AppLayout';
import React from 'react';

export const VerifyPage: React.FC = () => {
  return (
    <AppLayout>
      <Verify />
    </AppLayout>
  );
};
