import React from 'react';
import { DeclarationPage } from '@/pages/Declaration';
// DashboardRH
import {
  CreateAbsencePage,
  EditAbsencesPage,
  DeleteAbsencesPage,
  DeclarationEndPage,
  EditProfilPage,
  HomePage,
  ParcoursPage,
  ParcoursStatePage,
} from '@/pages/DashboardRH';
// import ChoixFin from '@/components/EntretienLiaison/ChoixDateSalarie/ChoixFin';
import { SignupPage } from '@/pages/signup/signup.page';
import { VerifyPage } from '@/pages/signup/verify.page';
import { VerifySelectPage } from '@/pages/signup/verifyselect.page';
import { CreatePasswordPage } from '@/pages/signup/create-password.page';
import { CreateContactPage } from '@/pages/signup/create-contact.page';
import { ConfirmationPage } from '@/pages/signup/confirmation.page';
import { ServiceDetails } from '@/components/ServiceDetail';
import { QuestionnaireSuiviFin, QuestionnaireSuivi } from '@/components/QuestionnaireSuivi';
import QuestionnaireRetour from '@/components/QuestionnaireRetour/QuestionnaireRetour';
import QuestionnaireRetourFin from '@/components/QuestionnaireRetour/QuestionnaireRetourFin';
import { DepotDocumentHistoriquePage } from '@/pages/depot-document-historique.page';
import QuestionnaireAcceuille1 from '@/components/QuestionnaireAcceuille/QuestionnaireAcceuille1';
import QuestionnaireAcceuille2 from '@/components/QuestionnaireAcceuille/QuestionnaireAcceuille2';
import FicheEntretienLiaison from '@/components/EntretienLiaison/FicheEntretienLiaison/FicheEntretienLiaison';
import FicheEntretienRetour from '@/components/EntretienLiaison/FicheEntretienRetour/FicheEntretienRetour';
import FicheEntretienFin from '@/components/EntretienLiaison/FicheEntretienFin/FicheEntretienFin';
import QuestionnaireVisite from '@/components/QuestionnaireVisite/QuestionnaireVisite';
import DepotDocument from '@/components/DepotDocument/DepotDocument';
import DepotDocumentVisite from '@/components/DepotDocument/DepotDocumentVisite';
import DepotDocumentFin from '@/components/DepotDocument/DepotDocumentFin';
import MeetingRooms from '@/components/MeetingRooms/MeetingRooms';
import { ADMIN_URL, PRO_URL } from '../constants/urls.constants';
import AccueilRH from '@/components/DashboardRH/AccueilRH/AccueilRH';
import Absences from '@/components/DashboardRH/EnCours/components/Absences/Absences';
import Actions from '@/components/DashboardRH/EnCours/Actions';
import { ExtractionAbsences } from '@/components/DashboardRH/ExtractionsAbsences/ExtractionsAbsences';
// import AdminCorrespTable from '@/components/DashboardRH/Administration/CorrespTable';
import { Stats } from '@/pages/DashboardRH/Stats';
// import ListLiaisonInterview from '@/components/DashboardRH/ListEntretien/components/ListLiaisonInterview';
import ListReturnInterview from '@/components/DashboardRH/ListEntretien/components/ListReturnInterview';
// import Admin from '@/components/DashboardRH/Administration/Admin';
// import AdminUtilisateurs from '@/components/DashboardRH/Administration/Utilisateurs';
// import AddService from '@/components/DashboardRH/Administration/AddService';
import Tools from '@/components/DashboardRH/Tools/Tools';
import { LoginRH } from '@/components/LoginRH';
import PasswordRH from '@/components/PasswordRH/PasswordRH';
import ConfirmationRH from '@/components/PasswordRH/ConfirmationRH';
import LineChartTotal from '@/components/Charts/LineChartEncours/Total';
import LineChartArret from '@/components/Charts/LineChartEncours/ArretTravail';
import LineChartHospitalisation from '@/components/Charts/LineChartEncours/Hospitalisation';
import LineChartParentalite from '@/components/Charts/LineChartEncours/Parentalite';
import RadarChartPrevalence from '@/components/Charts/RadarChartAbsentisme/Prevalence';
import RadarChartTaux from '@/components/Charts/RadarChartAbsentisme/Taux';
import RadarChartOccurrence from '@/components/Charts/RadarChartAbsentisme/Occurrence';
import BarChartGravite from '@/components/Charts/RadarChartAbsentisme/Gravite';
import ProfilValidationRH from '@/components/PasswordRH/ValidateRH';
import DetailsAbsence from '@/components/DashboardRH/DetailsAbsence/DetailsAbsence';
import PreferencesEmployee from '@/components/EntretienLiaison/PreferencesEmployee/PreferencesEmployee';
// import ChoixDateManager from '@/components/EntretienLiaison/ChoixDateManager/ChoixDateManager';
// import ChoixDateManagerFin from '@/components/EntretienLiaison/ChoixDateManager/ChoixDateManagerFin';
// import ChoixDateSalarie from '@/components/EntretienLiaison/ChoixDateSalarie/ChoixDateSalarie';
// import ChoixSST from '@/components/EntretienLiaison/ChoixSST/ChoixSST';
// import ChoixDateSalarieFin from '@/components/EntretienLiaison/ChoixDateSalarie/ChoixDateSalarieFin';
import QuestionSalarieVisite from '@/components/VisiteMedicale/QuestionSalarieVisite';
import { PrivacyPolicyClient } from '@/pages/PrivacyPolicyClient';
import { CookiePolicy } from '@/pages/CookiePolicy';
import { Terms } from '@/pages/Terms';
import { PrivacyPolicyKERij } from '@/pages/PrivacyPolicyKERij';
import { LegalMentionsInformation } from '@/pages/LegalMentionsInformation';
import QuestionnaireVisiteFin from '@/components/QuestionnaireVisite/QuestionnaireVisiteFin';
// import AdminFichiers from '@/components/DashboardRH/Administration/Fichiers';
import Setting from '@/components/DashboardRH/Administration/Setting';
import { AdminService } from '@/components/DashboardRH/Administration/AdminService';
import { ProgressAbsencePage } from '@/components/DashboardRH/EnCours/components/Absences/ProgressAbsencePage';
import { AddService } from '@/pages/administration/AddService';
import Admin from '@/pages/administration/Admin';
import AdminCorrespTable from '@/pages/administration/AdminCorrespTable';
import AdminFichiers from '@/pages/administration/AdminFichiers';
import ChoixDateManager from '@/pages/choix/ChoixDateManager';
import ChoixDateManagerFin from '@/pages/choix/ChoixDateManagerFin';
import ChoixDateSalarie from '@/pages/choix/ChoixDateSalarie';
import ChoixDateSalarieFin from '@/pages/choix/ChoixDateSalarieFin';
import ChoixFin from '@/pages/choix/ChoixFin';
import ChoixSST from '@/pages/choix/ChoixSST/ChoixSST';
import ListLiaisonInterview from '@/pages/listEntretien/ListLiaisonInterview';
import { AdminUtilisateurs } from '@/components/DashboardRH/Administration/AdminUtilisateurs';
import { AdminRoles } from '@/components/DashboardRH/Administration/AdminRoles';
import { ChangePasswordPage } from '@/pages/employee/ChangePassword';
import { CheckCodePage } from '@/pages/employee/CheckCode';
import { ForgetPasswordPage } from '@/pages/employee/ForgetPassword';
import { LoginInformationPage } from '@/pages/employee/LoginInformation';
import { Login } from '@/pages/employee/Login';
import { ProfilValidationPage } from '@/pages/employee/ProfilValidation';
import { ProfilPage } from '@/pages/employee/Profil';
import { RdvFinPage } from '@/pages/employee/RdvFinPage';
import { ResetPassword } from '@/pages/employee/ResetPassword';
import { Services } from '@/pages/employee/Services';
import EntretiensLegal from '@/pages/EntretiensLegal';
import Collaborateurs from '@/pages/Collaborateurs';
import { Report } from '@/pages/Report';
import { Navigate, type RouteProps } from 'react-router-dom';
import { ComingSoonPage } from '@/pages/ComingSoonPage';
import { ProfilFormPage } from '@/pages/employee/ProfilFormPage';
import { Evenements } from '@/pages/employee/Evenements';
import { DeclarationFormPage } from '@/pages/DeclarationForm';
import { LegalMentions } from '@/pages/LegalMentions';
import { Notifications } from '@/pages/Notifications';
import { LegalDataController } from '@/pages/LegalDataController';

export type IPage = RouteProps;

export const RouterConfig = {
  // showBlogDetailPage: (params) => ({
  //     path: `/show_detail_blog`,
  //     component: ShowBlogDetailPage,
  // }),
  rootPage: () => ({
    path: '/',
    element: <Navigate to="/login" replace />,
  }),
  profilPage: () => ({
    path: '/profil',
    element: <ProfilPage />,
  }),
  profilFormPage: () => ({
    path: '/profil/edit',
    element: <ProfilFormPage />,
  }),
  profilvalidationPage: () => ({
    path: '/profilvalidation',
    element: <ProfilValidationPage />,
  }),
  loginPage: () => ({
    path: '/login',
    element: <Login />,
  }),
  checkCodePage: () => ({
    path: '/checkCode',
    element: <CheckCodePage />,
  }),
  informationPage: () => ({
    path: '/information',
    element: <LoginInformationPage />,
  }),
  changepasswordPage: () => ({
    path: '/changepassword',
    element: <ChangePasswordPage />,
  }),
  signupPage: () => ({
    path: '/signup',
    element: <SignupPage />,
  }),
  verifyPage: () => ({
    path: '/verify',
    element: <VerifyPage />,
  }),
  verifyselectPage: () => ({
    path: '/verifyselect',
    element: <VerifySelectPage />,
  }),
  forgotPasswordPage: () => ({
    path: '/forgotPassword',
    element: <ForgetPasswordPage />,
  }),
  resetPasswordPage: () => ({
    path: '/resetPassword',
    element: <ResetPassword />,
  }),
  createpasswordPage: () => ({
    path: '/createpassword',
    element: <CreatePasswordPage />,
  }),
  createcontactPage: () => ({
    path: '/createcontact',
    element: <CreateContactPage />,
  }),
  confirmationPage: () => ({
    path: '/confirmation',
    element: <ConfirmationPage />,
  }),
  demarchesPage: () => ({
    path: '/demarches',
    element: <DeclarationPage />,
  }),
  declarationFormPage: () => ({
    path: '/declaration-form',
    element: <DeclarationFormPage />,
  }),
  choixfinPage: () => ({
    path: '/choixfin',
    element: <ChoixFin />,
  }),
  servicesPage: () => ({
    path: '/services',
    element: <Services />,
  }),
  servicedetailsPage: () => ({
    path: '/services/:id',
    Component: ServiceDetails,
  }),
  questionnairesuiviPage: () => ({
    path: '/questionnairesuivi',
    element: <QuestionnaireSuivi />,
  }),
  questionnairesuivifinPage: () => ({
    path: '/questionnairesuivifin',
    element: <QuestionnaireSuiviFin />,
  }),
  questionnaireretourPage: () => ({
    path: '/questionnaireretour',
    element: <QuestionnaireRetour />,
  }),
  questionnaireretourfinPage: () => ({
    path: '/questionnaireretourfin',
    element: <QuestionnaireRetourFin />,
  }),
  depotdocumenthistoriquefinPage: () => ({
    path: '/depotdocumenthistoriquefin',
    element: <DepotDocumentHistoriquePage />,
  }),
  questionnaireacceuille1Page: () => ({
    path: '/questionnaireacceuille1',
    element: <QuestionnaireAcceuille1 />,
  }),
  questionnaireaccueil2Page: () => ({
    path: '/questionnaireaccueil2',
    element: <QuestionnaireAcceuille2 />,
  }),

  activitesPage: () => ({
    path: '/activites',
    element: <Navigate to="/notifications" />,
  }),
  historiquesalariePage: () => ({
    path: '/historiquesalarie',
    element: <Navigate to="/notifications" />,
  }),
  notificationsPage: () => ({
    path: '/notifications',
    element: <Notifications />,
  }),

  evenementsPage: () => ({
    path: '/evenements',
    element: <Evenements />,
  }),
  entretienliaisonfichePage: () => ({
    path: '/entretienliaisonfiche',
    element: <FicheEntretienLiaison />,
  }),
  entretienfichefinPage: () => ({
    path: '/entretienfichefin',
    element: <FicheEntretienFin />,
  }),
  entretienretourfichePage: () => ({
    path: '/entretienretourfiche',
    element: <FicheEntretienRetour />,
  }),
  questionnairevisitefinPage: () => ({
    path: '/questionnairevisitefin',
    element: <QuestionnaireVisiteFin />,
  }),
  questionnairevisitePage: () => ({
    path: '/questionnairevisite',
    element: <QuestionnaireVisite />,
  }),
  validatedemanderdvPage: () => ({
    path: '/validatedemanderdv',
    element: <RdvFinPage />,
  }),
  depotdocumentPage: () => ({
    path: '/depotdocument',
    element: <DepotDocument />,
  }),
  depotdocumentvisitePage: () => ({
    path: '/depotdocumentvisite',
    element: <DepotDocumentVisite />,
  }),
  depotdocumentfinPage: () => ({
    path: '/depotdocumentfin',
    element: <DepotDocumentFin />,
  }),
  meetingroomsPage: () => ({
    path: '/meetingrooms',
    element: <MeetingRooms />,
  }),
  // for pro url

  homeHRPage: () => ({
    path: PRO_URL.PRO,
    element: <HomePage />,
  }),
  accueilHRPage: () => ({
    path: PRO_URL.ACCUEIL,
    element: <AccueilRH />,
  }),
  absencesHRPage: () => ({
    path: PRO_URL.ABSENCE_HISTORY,
    element: <Absences />,
  }),
  progressAbsenceHRPage: () => ({
    path: PRO_URL.PROGRESS_ABSENCE,
    element: <ProgressAbsencePage />,
  }),
  actionsHRPage: () => ({
    path: PRO_URL.ACTIONS,
    element: <Actions />,
  }),
  absencesExtractHRPage: () => ({
    path: PRO_URL.EXTRACTED_ABSENCES,
    element: <ExtractionAbsences />,
  }),
  correspTableAdminPage: () => ({
    path: ADMIN_URL.CORRESP_TABLE,
    element: <AdminCorrespTable />,
  }),
  statsHRPage: () => ({
    path: PRO_URL.STATS,
    element: <Stats />,
  }),
  listentretienliaisonHRPage: () => ({
    path: PRO_URL.LIST_LIAISON_INTERVIEW,
    element: <ListLiaisonInterview />,
  }),
  listentretienreturnHRPage: () => ({
    path: PRO_URL.LIST_RETURN_INTERVIEW,
    element: <ListReturnInterview />,
  }),
  adminHRPage: () => ({
    path: ADMIN_URL.ADMIN,
    element: <Admin />,
  }),
  usersAdminPage: () => ({
    path: ADMIN_URL.USERS,
    element: <AdminUtilisateurs />,
  }),
  rolesAdminPage: () => ({
    path: ADMIN_URL.ROLES,
    element: <AdminRoles />,
  }),
  addserviceAdminPage: () => ({
    path: ADMIN_URL.ADD_SERVICE,
    element: <AddService />,
  }),
  fileAdminPage: () => ({
    path: ADMIN_URL.FILES,
    element: <AdminFichiers />,
  }),
  settingAdminPage: () => ({
    path: ADMIN_URL.SETTINGS,
    element: <Setting />,
  }),
  serviceAdminPage: () => ({
    path: ADMIN_URL.SERVICES,
    element: <AdminService />,
  }),

  toolsRHPage: () => ({
    path: PRO_URL.TOOLS,
    element: <Tools />,
  }),
  loginRHPage: () => ({
    path: PRO_URL.LOGIN,
    element: <LoginRH />,
  }),
  passwordRHPage: () => ({
    path: PRO_URL.PASSWORD,
    element: <PasswordRH />,
  }),
  confirmationRHPage: () => ({
    path: PRO_URL.CONFIRMATION,
    element: <ConfirmationRH />,
  }),
  tendancegeneraleRHPage: () => ({
    path: PRO_URL.STATS_GENERAL_TREND,
    element: <LineChartTotal />,
  }),
  linechartarretRHPage: () => ({
    path: PRO_URL.STATS_CHART_ARRET,
    element: <LineChartArret />,
  }),
  linecharthospitalisationRHPage: () => ({
    path: PRO_URL.CHART_HOSPITALIZATION,
    element: <LineChartHospitalisation />,
  }),
  linechartparentaliteRHPage: () => ({
    path: PRO_URL.CHART_PARENTALITY,
    element: <LineChartParentalite />,
  }),
  radarcharttauxRHPage: () => ({
    path: PRO_URL.CHART_TAUX,
    element: <RadarChartTaux forPage={undefined} />,
  }),
  radarchartprevalenceRHPage: () => ({
    path: PRO_URL.CHART_PREVALENCE,
    element: <RadarChartPrevalence />,
  }),
  radarchartoccurrenceRHPage: () => ({
    path: PRO_URL.CHART_OCCURENCE,
    element: <RadarChartOccurrence />,
  }),
  barchartgraviteRHPage: () => ({
    path: PRO_URL.CHART_GRAVITE,
    element: <BarChartGravite />,
  }),
  validationprofilRHPage: () => ({
    path: PRO_URL.VALIDATION_PROFIL,
    element: <ProfilValidationRH />,
  }),
  detailsabsenceRHPage: () => ({
    path: PRO_URL.DETAILS_ABSENCE,
    element: <DetailsAbsence />,
  }),
  preferencesemployeeRHPage: () => ({
    path: PRO_URL.PREFERENCES_EMPLOYEE,
    element: <PreferencesEmployee />,
  }),
  choixdatemanagerRHPage: () => ({
    path: PRO_URL.DATE_CHOICE_MANAGER,
    element: <ChoixDateManager />,
  }),
  choixdatemanagerfinRHPage: () => ({
    path: PRO_URL.DATE_CHOICE_MANAGER_END,
    element: <ChoixDateManagerFin />,
  }),
  choixdatesalarieRHPage: () => ({
    path: PRO_URL.DATE_CHOICE_EMPLOYEE,
    element: <ChoixDateSalarie />,
  }),
  choixdatesstRHPage: () => ({
    path: PRO_URL.DATE_CHOICE_SST,
    element: <ChoixSST />,
  }),
  creerarretRHPage: () => ({
    path: PRO_URL.CREATE_HR_LEAVE,
    element: <CreateAbsencePage />,
  }),
  editarretRHPage: () => ({
    path: PRO_URL.EDIT_HR_LEAVE,
    element: <EditAbsencesPage />,
  }),
  deletearretRHPage: () => ({
    path: PRO_URL.DELETE_HR_LEAVE,
    element: <DeleteAbsencesPage />,
  }),
  declarationEndRHPage: () => ({
    path: PRO_URL.DECLARATION_END_HR,
    element: <DeclarationEndPage />,
  }),
  choixdatesalariefinRHPage: () => ({
    path: PRO_URL.DATE_CHOICE_EMPLOYEE_END,
    element: <ChoixDateSalarieFin />,
  }),
  editprofilHRPage: () => ({
    path: PRO_URL.PROFIL,
    element: <EditProfilPage />,
  }),
  questionsalarievisitePage: () => ({
    path: '/questionsalarievisite',
    element: <QuestionSalarieVisite />,
  }),
  politiqueDeConfidentialiteClientPage: () => ({
    path: '/politique-de-confidentialite-client',
    element: <PrivacyPolicyClient />,
  }),
  mentionsLegalesPage: () => ({
    path: '/mentions-legales',
    element: <LegalMentions />,
  }),
  politiqueDeCookiesPage: () => ({
    path: '/politique-de-cookies',
    element: <CookiePolicy />,
  }),
  conditionsPage: () => ({
    path: '/conditions',
    element: <Terms />,
  }),
  politiqueDeConfidentialitePage: () => ({
    path: '/politique-de-confidentialite',
    element: <PrivacyPolicyKERij />,
  }),
  mentionsLegalesInformationPage: () => ({
    path: '/mentions-legales-information',
    element: <LegalMentionsInformation />,
  }),
  responsableDeTraitementPage: () => ({
    path: '/responsable-de-traitement',
    element: <LegalDataController />,
  }),
  entretiensLegalPage: () => ({
    path: PRO_URL.ENTRETIEN_LEGAL,
    element: <EntretiensLegal />,
  }),
  collaborateursPage: () => ({
    path: PRO_URL.COLLABORATEURS,
    element: <Collaborateurs />,
  }),
  reportPage: () => ({
    path: '/report',
    element: <Report />,
  }),
  serviceAnimationPage: () => ({
    path: PRO_URL.SERVICE_ANIMATION,
    Component: ComingSoonPage,
  }),
  parcoursStateListPage: () => ({
    path: PRO_URL.PARCOURS_STATE,
    Component: ParcoursStatePage,
  }),
  parcoursListPage: () => ({
    path: PRO_URL.PARCOURS,
    Component: ParcoursPage,
  }),
  parcoursEditPage: () => ({
    path: PRO_URL.PARCOURS_EDIT,
    Component: ParcoursPage,
  }),
  parcoursCreatePage: () => ({
    path: PRO_URL.PARCOURS_CREATE,
    Component: ParcoursPage,
  }),
  parcoursDetailPage: () => ({
    path: PRO_URL.PARCOURS_DETAIL,
    Component: ParcoursPage,
  }),
} satisfies { [key: string]: (...args: any[]) => IPage };
