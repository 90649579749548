import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useError } from '@/shared/useError';
import isAuthorized from '../../../../hooks/isAuthorized';
import { PRO_URL } from '../../../../constants/urls.constants';
import { NotConnectedRH } from '../../shared/components/NotConnectedRH';
import DataTable from './components/DataTable';
import TitleContent from './components/TitleContent';
import OptionContent from './components/OptionContent';
import EditAbsenceModal from './components/EditAbsenceModal';
import AdditionalWeekModal from './components/AdditionalWeekModal';
import WorkingTimeModal from './components/WorkingTimeModal';
import {
  getAbsenceOptionsParams as getAbsenceOptionsParameters,
  getNoteAbsenceOptionsParams as getNoteAbsenceOptionsParameters,
} from './api';
import { StatusCodes } from 'http-status-codes';
import { RHAccountContext, useAppDispatch, useAppSelector } from '@/store';

const Setting = () => {
  const rh = useAppSelector(RHAccountContext.getProfile);
  const isAdmin = useAppSelector(RHAccountContext.isAdmin);

  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);

  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  const listSelectCompany = useAppSelector((state) => state.rhNavbarContext?.listSelectCompany);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [absenceOptions, setAbsenceOptions] = useState<any>([]);
  const [modalIsOpenEditAbsenceOptions, setModalIsOpenEditAbsenceOptions] = useState(false);
  const [select, setSelect] = useState<any>();

  const location = useLocation();
  const pathname = location.pathname;
  const [viewCloseAbsenceOptions, setViewCloseAbsenceOptions] = useState(false);
  const [modalIsOpenNotePromo, setModalIsOpenNotePromo] = useState(false);
  const [modalIsOpenNoteReduction, setModalIsOpenNoteReduction] = useState(false);

  const [noteAbsenceOptions, setNoteAbsenceOptions] = useState({
    notePromo: '',
    noteReduction: '',
  });

  const { error, triggerError, clearError } = useError();
  const getAbsenceOptions = async (listSelectBusiness: any[], listSelectCompany: any[], rh: any) => {
    if (rh) {
      const idBusinesses = rh.rh_information.id_business
        ? JSON.stringify([rh.rh_information.id_business])
        : listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id).length > 0
          ? JSON.stringify(listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id))
          : '[]';
      const idCompanies = rh.rh_information.id_parent_company
        ? JSON.stringify([rh.rh_information.id_parent_company])
        : listSelectCompany?.filter((value) => value.isChecked).length > 0
          ? JSON.stringify(listSelectCompany.filter((value) => value.isChecked).map((value) => value.id))
          : '[]';
      if (
        rh.rh_information.id_parent_company ||
        rh.rh_information.id_business ||
        (JSON.parse(idBusinesses).length > 0 && JSON.parse(idCompanies).length > 0)
      ) {
        const absenceOptionsParamsRes = await getAbsenceOptionsParameters(
          {
            idBusiness:
              listSelectBusiness?.filter((value) => value.isChecked).length > 0
                ? listSelectBusiness.filter((value) => value.isChecked).at(0).id
                : selectedIdBusiness,
          },
          triggerError("Une erreur s'est produite"),
        );

        if (absenceOptionsParamsRes?.status === StatusCodes.OK) {
          setAbsenceOptions(absenceOptionsParamsRes?.data?.absenceOptions);
        }

        const noteAbsenceOptionsParamsRes = await getNoteAbsenceOptionsParameters(
          {
            idBusiness:
              listSelectBusiness?.filter((value) => value.isChecked).length > 0
                ? listSelectBusiness.filter((value) => value.isChecked).at(0).id
                : selectedIdBusiness,
          },
          triggerError("Une erreur s'est produite"),
        );

        if (noteAbsenceOptionsParamsRes?.status === StatusCodes.OK) {
          setNoteAbsenceOptions({
            notePromo: noteAbsenceOptionsParamsRes?.data?.noteAbsenceOption
              ? noteAbsenceOptionsParamsRes?.data?.noteAbsenceOption?.note_promo
              : '',
            noteReduction: noteAbsenceOptionsParamsRes?.data?.noteAbsenceOption
              ? noteAbsenceOptionsParamsRes?.data?.noteAbsenceOption?.note_reduction
              : '',
          });
        }
      } else {
        setAbsenceOptions([]);
      }
    }
  };

  useEffect(() => {
    if (isAuthorized(pathname)) {
      getAbsenceOptions(listSelectBusiness, listSelectCompany, rh);
    } else {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, [rh, listSelectBusiness, listSelectCompany]);

  useEffect(() => {
    if (isAuthorized(pathname)) {
      dispatch(RHAccountContext.load());
    } else {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, []);

  return (
    <Box>
      <Box className="row col-12">
        {isAdmin ? (
          <Box className="container-fluid col-12">
            <Box className="container-fluid row mt-5">
              <Box className="container-fluid col-lg-12 col-12">
                <Box className="col-10 row">
                  <Box className="gerer-user col-6">
                    <Typography component="strong">Paramètres</Typography>
                  </Box>
                </Box>

                {absenceOptions.length > 0 ? (
                  <Box
                    className="col-10 row"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <TitleContent
                      setViewCloseAbsenceOptions={setViewCloseAbsenceOptions}
                      viewCloseAbsenceOptions={viewCloseAbsenceOptions}
                    />
                    {viewCloseAbsenceOptions ? (
                      <DataTable
                        absenceOptions={absenceOptions}
                        setSelect={setSelect}
                        setModalIsOpenEditAbsenceOptions={setModalIsOpenEditAbsenceOptions}
                      />
                    ) : null}
                  </Box>
                ) : null}
                {viewCloseAbsenceOptions && (
                  <OptionContent
                    setModalIsOpenNotePromo={setModalIsOpenNotePromo}
                    noteAbsenceOptions={noteAbsenceOptions}
                    setModalIsOpenNoteReduction={setModalIsOpenNoteReduction}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <NotConnectedRH />
        )}

        {absenceOptions?.length && select !== undefined && modalIsOpenEditAbsenceOptions ? (
          <EditAbsenceModal
            absenceOptions={absenceOptions}
            setAbsenceOptions={setAbsenceOptions}
            select={select}
            listSelectBusiness={listSelectBusiness}
            selectedIdBusiness={selectedIdBusiness}
            getAbsenceOptions={getAbsenceOptions}
            setModalIsOpenEditAbsenceOptions={setModalIsOpenEditAbsenceOptions}
            listSelectCompany={listSelectCompany}
            modalIsOpenEditAbsenceOptions={modalIsOpenEditAbsenceOptions}
          />
        ) : null}

        <AdditionalWeekModal
          noteAbsenceOptions={noteAbsenceOptions}
          setNoteAbsenceOptions={setNoteAbsenceOptions}
          listSelectBusiness={listSelectBusiness}
          selectedIdBusiness={selectedIdBusiness}
          getAbsenceOptions={getAbsenceOptions}
          setModalIsOpenNotePromo={setModalIsOpenNotePromo}
          listSelectCompany={listSelectCompany}
          modalIsOpenNotePromo={modalIsOpenNotePromo}
        />

        <WorkingTimeModal
          noteAbsenceOptions={noteAbsenceOptions}
          setNoteAbsenceOptions={setNoteAbsenceOptions}
          listSelectBusiness={listSelectBusiness}
          selectedIdBusiness={selectedIdBusiness}
          getAbsenceOptions={getAbsenceOptions}
          setModalIsOpenNoteReduction={setModalIsOpenNoteReduction}
          listSelectCompany={listSelectCompany}
          modalIsOpenNoteReduction={modalIsOpenNoteReduction}
        />
      </Box>
    </Box>
  );
};

export default Setting;
