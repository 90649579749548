import React, { useMemo } from 'react';
import { useAppFeature } from '@/store';
import { BottomNavigationAction, bottomNavigationActionClasses, Box, styled } from '@mui/material';
import { Feature } from '@/types';
import { useLocation, useNavigate } from 'react-router-dom';

const componentName = 'NavbarMobileTab';
const defaultPath = '/declaration';

export interface NavbarMobileTabProps {
  to: string;
  label?: string;
  icon?: React.ReactNode;
  feature?: keyof Feature;
}

export function NavbarMobileTab(props: NavbarMobileTabProps) {
  const { to, label, feature, icon } = props;
  const appFeature = useAppFeature();

  const location = useLocation();
  const navigate = useNavigate();

  const isActive = useMemo(
    () => (location.pathname === '/' ? to === defaultPath : location.pathname.startsWith(to)),
    [location.pathname, to],
  );

  const action = (
    <ButtonNavigation
      label={label}
      icon={icon}
      sx={(theme) => ({
        color: isActive ? theme.md3.sys.color.onSurface : theme.md3.sys.color.onSurfaceVariant,
        backgroundColor: isActive ? theme.md3.sys.color.primaryContainer : theme.md3.sys.color.white,
      })}
      onClick={() => navigate(to)}
      showLabel
      disableRipple
      disableTouchRipple
    />
  );

  return feature == null || appFeature[feature] ? React.cloneElement(action, { className: componentName }) : null;
}

const ButtonNavigation = styled(BottomNavigationAction)(({ theme }) => ({
  height: 'fit-content',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  minWidth: 0,
  borderRadius: theme.shape.borderRadius,
  padding: 0,
  [`& .${bottomNavigationActionClasses.label}`]: {
    fontWeight: 600,
    fontSize: 10,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    lineHeight: 'normal',
  },

  [`& svg`]: {
    marginTop: theme.spacing(1),
    fontSize: 24,
  },
}));
