import React from 'react';
import './Login.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { useError } from '@/shared/useError';
import { fetchEmployeeProfile } from '@/services/employee';
import { redirectToStoredPath, storeAuthData } from '@/utils/auth.utils';
import { RouterConfig } from '@/config/RouterConfig';
import { UserTypes } from '@/types/userTypes.enum';
import { KerijBox, KerijBoxType } from '@/components/base';
import InputEmailValidate from '@/components/base/input/InputEmailValidate';
import InputPassword from '@/components/base/input/InputPassword';
import { FieldValues, useForm } from 'react-hook-form';
import { AccountContext, useAppDispatch } from '@/store';
import { isLoginResponse, login } from '@/services/employee/auth/login';
import { Spacer } from '@/components/Spacer';
import { Button } from '@/components/Button';
import { ActionButton } from '@/components/ActionButton';

function LoginUI() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParameters] = useSearchParams();

  const { error, triggerError, clearError } = useError();
  const token = searchParameters.get('token_decode') || null;

  const { register, formState, handleSubmit } = useForm();
  const { errors, isValid, isSubmitting } = formState;

  const handleNavigatePreviousPage = () => {
    const previousLocation = location.state?.previousLocation;
    if (previousLocation) navigate(`${previousLocation?.pathname}${previousLocation?.search || ''}`);
  };

  const redirectToSignUp = () => {
    navigate('/signup', {
      state: {
        document: location.state ? location.state.document : null,
        page: location.state ? location.state.page : null,
      },
    });
  };

  const redirectToForgotPassword = () => {
    navigate('/forgotPassword', {
      state: {
        document: location.state ? location.state.document : null,
      },
    });
  };

  const redirectToConfirmation = (user: any) => {
    const stateValue = {
      hasAccount: false,
      password: null,
      originalPhone: user.infoEmployee?.employee_login?.phone,
      originalEmail: user.infoEmployee?.employee_login?.email,

      salarie: {
        id: user.infoEmployee?.id,
        email: user.infoEmployee?.employee_login?.email,
        description: user.infoEmployee?.description,
        id_business: user.infoEmployee?.business?.id,
        business: user.infoEmployee?.business,
        lastname: user.infoEmployee?.lastname,
      },
    };

    navigate('/createpassword', { state: stateValue });
  };

  const redirectAfterConnect = () => {
    if (location.state?.previousLocation) return handleNavigatePreviousPage();

    if (token) {
      if (location.state?.previousPage && location.state?.previousPage === 'historique')
        return navigate(`/historiquesalarie?token_decode=${token}`);
      else if (location.state?.previousPage && location.state?.previousPage === 'service')
        return navigate(`/services?token_decode=${token}`);
    }

    if (location.state) {
      const { document, page } = location.state;
      if (document && !document.page) redirectToStoredPath(navigate);
      else if (document?.page || page) navigate(page ?? document.page);
      else navigate(RouterConfig.profilPage().path);
    } else {
      if (location.state?.previousLocation) {
        handleNavigatePreviousPage();
        return;
      }
      redirectToStoredPath(navigate);
    }
  };

  const onSubmit = async (event: FieldValues) => {
    clearError();
    const { email, password } = event;
    const { data, status: responseStatus } = await login({ email, password });

    if (isLoginResponse(data)) {
      const { infoEmployee, status, token: newToken, exp, refreshToken } = data;
      if (status && status === 'pending') return redirectToConfirmation(data);
      storeAuthData({ userType: UserTypes.EMPLOYEE, token: newToken, exp, refreshToken });

      if (infoEmployee.employee_login?.last_change_password) {
        const { data } = await fetchEmployeeProfile();
        dispatch(AccountContext.actions.setUser(data));
        return redirectAfterConnect();
      } else navigate(RouterConfig.changepasswordPage().path);
    } else if (responseStatus === StatusCodes.UNAUTHORIZED) triggerError('Mot de passe ou email incorrect');
    else if (responseStatus === StatusCodes.TOO_MANY_REQUESTS)
      triggerError('Vous avez envoyé beaucoup de requêtes. Vous devez attendre 15 minutes pour reessayer');
    else triggerError();
  };

  return (
    <div>
      <Spacer size="large" />
      <h1 className="KerijHeader m-auto text-center col-12 col-lg-8 col-xl-6">
        Pour aller plus loin, vous devez vous authentifier
      </h1>

      <form className="m-auto" onSubmit={handleSubmit(onSubmit)} style={{ padding: 0, width: 350 }}>
        {error && <KerijBox message={error} type={KerijBoxType.Error} />}

        <Spacer />

        <InputEmailValidate label="E-Mail" register={register} error={errors?.['email']?.message} />
        <InputPassword label="Mot de passe" register={register} error={errors?.['password']?.message} />

        <Spacer />

        <ActionButton
          type="submit"
          color="primary"
          variant="contained"
          actionName="validate"
          disabled={!isValid || isSubmitting}
          startIcon={null}
          fullWidth
        />
        <Spacer size="small" />
        <Button color="tertiary" onClick={redirectToForgotPassword} fullWidth>
          {'Mot de passe oublié ?'}
        </Button>
        <Spacer size="small" />
        <Button color="secondary" onClick={redirectToSignUp} fullWidth>
          {"Je n'ai pas encore d'identifiant"}
        </Button>
      </form>
    </div>
  );
}

export default LoginUI;
