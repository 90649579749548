import React from 'react';
import { RHPage } from '@/components/RHPage';
import { PermissionBox } from '@/components/PermissionBox';
import { StatisticQuery } from '@/query';

export function Stats() {
  return (
    <PermissionBox scope="management" action="access">
      <StatsProtectedPage />
    </PermissionBox>
  );
}

export function StatsProtectedPage() {
  const defaultDashboard = StatisticQuery.useDefault();
  const dashboardURL = defaultDashboard.data?.attributes?.url;

  return (
    <RHPage title={'Statistiques'}>
      {dashboardURL == null ? null : (
        <iframe
          src={dashboardURL}
          frameBorder="0"
          style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            minHeight: '48vw',
          }}
          height="100%"
          width="100%"
        ></iframe>
      )}
    </RHPage>
  );
}
