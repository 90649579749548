import React from 'react';
import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, type BoxProps } from '@mui/material';
import classNames from 'clsx';
import { Link } from 'react-router-dom';
import type { NavMenuItem } from './NavMenuItem';
import { useAppVariant } from '@/hooks/useAppVariant';
import { NavMenuCardButton } from './NavMenuCardButton';
import { ChevronRight } from '@/components/AppIcon';
import { IconButton } from '@/components/IconButton';

const componentName = 'NavMenu';

export interface NavMenuProps extends BoxProps {
  items: Array<NavMenuItem>;
}

export const NavMenu = (props: NavMenuProps) => {
  const { className, items, ...otherProps } = props;
  const appVariant = useAppVariant();
  const expand = true; // Opposite of dense
  return (
    <Box className={classNames(componentName, className)} {...otherProps}>
      {appVariant.small ? (
        <Grid container spacing={4}>
          {items.map(({ primary, secondary, to, icon }) => (
            <Grid item xs={12} sm={6} md={4} key={primary}>
              <NavMenuCardButton to={to} icon={icon} primary={primary} secondary={secondary} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <List sx={expand ? { display: 'flex', flexDirection: 'column', gap: 2 } : undefined}>
          {items.map(({ primary, to, icon }) => (
            <ListItem
              key={primary}
              secondaryAction={<IconButton edge="end" icon={<ChevronRight />} color={'primary'} />}
              disablePadding
            >
              <ListItemButton component={Link as any} onClick={undefined} to={to}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} secondary={undefined} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
