import { autocompleteClasses, selectClasses, type ThemeOptions } from '@mui/material';
import { elevations } from './elevations';
import { themePaletteLight } from './themePalette';
import * as md3 from './token';
import { ThemeMode } from './ThemeMode';

const spacing = (amount: number) => {
  return amount * 8;
};

export function createThemeOption(dense: boolean) {
  const typographyTitle = {
    fontWeight: 'bolder',
  };
  const themePalette = themePaletteLight;

  const themeShape = {
    borderRadius: 10,
    borderColor: md3.sys.color.outline,
    borderColorVariant: md3.sys.color.outlineVariant,
  } satisfies ThemeOptions['shape'];

  const themeValue: ThemeOptions = {
    typography: {
      ...md3.ref.typeface.brand,
      button: {
        textTransform: 'none',
      },
      h1: typographyTitle,
      h2: typographyTitle,
      h3: typographyTitle,
      h4: typographyTitle,
      h5: typographyTitle,
      h6: typographyTitle,
      // Added by Stéphane in the theme, it would be better to merge with an existing variant
      headline: {
        ...typographyTitle,
        fontSize: '1rem',
      },
      // Also custom typography used for inline code
      code: {
        ...md3.ref.typeface.monospace,
      },
    },
    components: {
      MuiChip: {
        defaultProps: {
          sx: {
            backgroundColor: 'rgba(15, 7, 91, 0.5)',
            color: 'white',
            fontWeight: 'bolder',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            opacity: 1, // Fix global style on _reboot.scss hr { opacity: 0.25 }
            backgroundColor: md3.sys.color.outline,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          sx: {
            padding: '10px 16px',
          },
        },
        // variants: [
        //   {
        //     props: { variant: 'outlined' },
        //     style: {
        //       backgroundColor: 'white',
        //       boxShadow: elevations[0].value,
        //       borderColor: royalLavender[200],
        //     },
        //   },
        // ]
      },
      // FIXME: Trop d'impact sur les TextField
      // MuiInputLabel: {
      //   defaultProps: {
      //     sx: {
      //       fontSize: 20,
      //       fontWeight: 'bolder',
      //     }
      //   }
      // },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            // Styles spécifiques pour l'état "default"
            backgroundColor: themePalette.grey?.['100'],
            color: (themePalette.primary as any).dark,
            // Attention : on rajoute un border par rapport au style de base
            // Si cela crée des conflits sur les styles de base alors il faut créer une autre variant "outline" à la place
            borderColor: themeShape.borderColorVariant,
            borderWidth: '1px',
            borderStyle: 'solid',
          },
        },
      },
      MuiListItemText: {
        defaultProps: {
          primaryTypographyProps: {
            color: 'primary',
            fontWeight: 'h6.fontWeight',
          },
          secondaryTypographyProps: {
            variant: 'caption' as const,
            color: (theme: any) => theme.palette.primaryBackground.contrastText,
          },
        },
      },
      MuiList: {
        defaultProps: {
          dense: dense,
        },
        styleOverrides: {
          dense: (() => {
            const avatarMinWidth = spacing(4 + 1.5);
            return {
              ['& .MuiListItemAvatar-root']: {
                width: spacing(4),
                height: spacing(4),
                minWidth: avatarMinWidth,
              },
              ['& .MuiListItemAvatar-root .MuiAvatar-root']: {
                width: spacing(4),
                height: spacing(4),
                fontSize: spacing(2),
              },
              ['& .MuiDivider-inset']: {
                marginLeft: avatarMinWidth + spacing(/* card marginLeft */ 2),
              },
              ['& .MuiListItemText-inset']: {
                paddingLeft: avatarMinWidth,
              },
            };
          })(),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...md3.sys.typescale.label.medium,
            color: md3.sys.color.onSurface,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            ...(dense ? md3.sys.typescale.body.small : undefined),
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          size: dense ? 'small' : 'medium',
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'inherit', // Bug de mui ? (par défaut met à md => c'est un problème dans beaucoup de composants)
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            headline: 'p',
          },
        },
      },

      MuiSelect: {
        defaultProps: {
          sx: (theme) => ({
            padding: 0,
            [`& .${selectClasses.select}`]: { paddingBottom: 0 },
            ['& .MuiInputBase-input']: {
              display: 'flex',
              // Important: la zone cliquable ne doit pas être inférieur
              minHeight: `${theme.spacing(4.5)} !important`,
              ['& .MuiBox-root']: {
                marginTop: 'auto',
                marginBottom: 'auto',
                paddingLeft: theme.spacing(2.5),
              },
            },
          }),
          MenuProps: {
            PaperProps: {
              sx: (theme) => ({
                boxShadow: theme.shadows[10],
                border: '1px solid',
                borderColor: theme.shape.borderColorVariant,
                maxHeight: 40 * 7,
                ['& .MuiMenuItem-root']: {
                  maxHeight: 40,
                  '&:hover': {
                    background: theme.palette.secondary.light,
                  },
                },
                ['& .MuiMenuItem-root .Mui-selected']: {
                  background: theme.palette.primary.light,
                },
              }),
            },
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          sx(theme) {
            return {
              margin: 'auto',
              height: theme.spacing(5),
              border: '0',
              [`& .${autocompleteClasses.inputRoot}`]: {
                paddingTop: '0',
                paddingBottom: '0',
                backgroundColor: theme.palette.common.white,
                'label + &': {
                  marginTop: theme.spacing(2.25),
                },
                color: theme.palette.text.primary,
                position: 'relative',
                border: '0',

                fontSize: theme.spacing(1.5),
                minHeight: theme.spacing(5),
                borderRadius: '10px',
                width: '100%',
                boxShadow: theme.shadows[1],
                paddingLeft: theme.spacing(2.5),
                paddingRight: theme.spacing(2.5),
                transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),

                [`& .MuiOutlinedInput-notchedOutline`]: {
                  border: `1px solid !important`,
                  borderColor: `${theme.md3.sys.color.outlineVariant} !important`,
                },
              },
            };
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: md3.sys.color.onSurfaceVariant,
            // fontSize: md3.sys.typescale.body.small,
            borderRadius: md3.sys.shape.corner.extraSmall,
            minHeight: spacing(3),
          },
        },
      },
    },
    palette: themePalette,
    shape: themeShape,
    shadows: ['none', ...defineValues('0px 4px 8px 0px rgba(0, 0, 0, 0.12)', elevations, 24)],
    md3,
  };
  return {
    [ThemeMode.Dark]: themeValue,
    [ThemeMode.Light]: themeValue,
  } satisfies { [mode: string]: ThemeOptions };
}

// Helpers : aide en attendant de finir le style
function defineValues<Length extends number>(
  defaultValue: string,
  values: Array<{ index: number; value: string }>,
  length: Length,
): ThemeValue<Length> {
  const result = Array.from({ length }, () => defaultValue);

  // Remplace les valeurs à des index spécifiques avec les valeurs fournies
  for (const { index, value } of values) {
    if (index >= 0 && index < length) {
      result[index] = value;
    }
  }

  return result as ThemeValue<Length>;
}

type ThemeValue<Length extends number, R extends string[] = []> = R['length'] extends Length
  ? R
  : ThemeValue<Length, [...R, string]>;
