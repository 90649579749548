/* eslint-disable unicorn/prefer-query-selector */
import React, { createRef, useEffect, useMemo, useState } from 'react';
import '../../../../DashboardRH.css';
import useCheckRhAuthentication from '@/shared/hooks/chechRhAuthentication.hooks';
import { CircularProgress } from '@mui/material';
import CardCurrentAbsence from '../CardCurrentAbsence';
import { Absence, AbsenceStatus } from '@/types';
import { PermissionBox } from '@/components/PermissionBox';
import { AbsencesFilters, fetchRhAbsences } from '@/services/rh';
import { ListCard } from '@/components/DashboardRH/ListCard';
import { RHPage } from '@/components/RHPage';
import { AbsenceSearchFilter, CurrentAbsenceSearchBar } from './CurrentAbsenceSearchBar';
import { AbsenceCode } from '@/types/AbsenceCode';
import { ActionButton } from '@/components/ActionButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useToast } from '@/components/Toast';
import { absencesExtract } from '@/services/rh/absences/absencesExtract';

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: 'Extractions dossiers en cours',
  fieldSeparator: ';',
});

const CurrentAbsence = () => {
  const toast = useToast();

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
  const filters: Omit<AbsencesFilters, 'page' | 'per_page'> = {
    status: [AbsenceStatus.DRAFT, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
    detail: true,
  };

  const [submitting, setSubmitting] = useState(false);
  const [isPortfolioFilter, setIsPortfolioFilter] = useState(false);
  const [typeFilters, setTypeFilters] = useState<{ [K in AbsenceCode]?: boolean }>({
    [AbsenceCode.Maladie]: false,
    [AbsenceCode.MaladiePro]: false,
    [AbsenceCode.AccidentTravail]: false,
    [AbsenceCode.AccidentTrajet]: false,
    [AbsenceCode.Hospitalisation]: false,
    [AbsenceCode.PathologieGrossesse]: false,
  });
  const internalCodeAbsence = useMemo(
    () => Object.entries(typeFilters).flatMap(([key, isCheck]) => (isCheck ? [key] : [])) as AbsenceCode[],
    [typeFilters],
  );

  const [currentAbsences, setCurrentAbsences] = useState<Array<Absence>>([]);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadMoreEl = createRef<HTMLDivElement>();

  async function fetchAbsencesByFilters(refresh?: true) {
    const apiPagination = refresh ? { page: 1, per_page: 10 } : pagination;
    setPagination(apiPagination);
    setLoading(true);
    const { data } = await fetchRhAbsences({
      ...filters,
      ...apiPagination,
      search,
      internal_code_absence: internalCodeAbsence,
      portfolio: isPortfolioFilter ? 'true' : undefined,
    });
    setCurrentAbsences((initialValue) => (apiPagination.page === 1 ? data : [...initialValue, ...data]));
    setCanLoadMore(data.length === apiPagination.per_page);
    setLoading(false);
    setPagination((initialValue) => ({ page: initialValue.page + 1, per_page: initialValue.per_page }));
  }

  async function extractAbsences() {
    setSubmitting(true);
    try {
      const { data } = await absencesExtract({
        type: 'current',
        search,
        status: filters['status'],
        internal_code_absence: internalCodeAbsence,
        portfolio: isPortfolioFilter ? 'true' : undefined,
      });

      if (data.length === 0) {
        setSubmitting(false);
        return toast.present({ message: 'Aucune ligne à exporter', severity: 'error' });
      }

      download(csvConfig)(generateCsv(csvConfig)(data));
      toast.present({ message: `${data.length} ligne(s) exportée(s)`, severity: 'success' });
    } catch {
      toast.present({ message: "Une erreur est survenue durant l'export", severity: 'error' });
    }
    setSubmitting(false);
  }

  useEffect(() => {
    if (loadMore) {
      setLoadMore(false);
      fetchAbsencesByFilters();
    }
  }, [loadMore]);

  useEffect(() => {
    setCurrentAbsences([]);
    setCanLoadMore(true);
    fetchAbsencesByFilters(true);
  }, [search, typeFilters, isPortfolioFilter]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setLoadMore(entry.isIntersecting);
      },
      {
        root: null, // Observe vis-à-vis du viewport
        rootMargin: '0px',
        threshold: 0.5, // 50% de l'élément doit être visible pour déclencher l'observation
      },
    );

    if (loadMoreEl.current) observer.observe(loadMoreEl.current);

    return () => {
      if (loadMoreEl.current) observer.unobserve(loadMoreEl.current);
    };
  }, [loadMoreEl]);

  useCheckRhAuthentication();

  return (
    <PermissionBox scope="absence-management" action="access">
      <RHPage
        title="Dossiers ouverts"
        actions={
          <ActionButton
            label="Export Excel"
            endIcon={<FileDownloadIcon />}
            disabled={submitting}
            onClick={extractAbsences}
          />
        }
        topElement={
          <CurrentAbsenceSearchBar
            onSubmit={(filters: AbsenceSearchFilter) => {
              setSearch(filters.content);
              setIsPortfolioFilter(filters.portfolio ?? false);
              setTypeFilters({
                [AbsenceCode.Maladie]: filters.type[AbsenceCode.Maladie].checked,
                [AbsenceCode.MaladiePro]: filters.type[AbsenceCode.MaladiePro].checked,
                [AbsenceCode.AccidentTravail]: filters.type[AbsenceCode.AccidentTravail].checked,
                [AbsenceCode.AccidentTrajet]: filters.type[AbsenceCode.AccidentTrajet].checked,
                [AbsenceCode.Hospitalisation]: filters.type[AbsenceCode.Hospitalisation].checked,
                [AbsenceCode.PathologieGrossesse]: filters.type[AbsenceCode.PathologieGrossesse].checked,
              });
            }}
            onCancel={() => {
              setIsPortfolioFilter(false);
              setTypeFilters({
                [AbsenceCode.Maladie]: false,
                [AbsenceCode.MaladiePro]: false,
                [AbsenceCode.AccidentTravail]: false,
                [AbsenceCode.AccidentTrajet]: false,
                [AbsenceCode.Hospitalisation]: false,
                [AbsenceCode.PathologieGrossesse]: false,
              });
            }}
          />
        }
      >
        <div>
          {currentAbsences?.map((absences: Absence, index) => (
            <ListCard key={index}>
              <CardCurrentAbsence absence={absences} />
            </ListCard>
          ))}

          {loading && (
            <div className={'text-center'}>
              <CircularProgress />
            </div>
          )}

          {!loading && canLoadMore && <div ref={loadMoreEl}></div>}

          {!loading && currentAbsences?.length === 0 && (
            <div className="text-center mt-5 mb-3 col-12 col-sm-10">
              <b>Aucune absence</b>
            </div>
          )}
        </div>
      </RHPage>
    </PermissionBox>
  );
};

export default CurrentAbsence;
