import { useMediaQuery, useTheme } from '@mui/material';

/**
 * @example
 *
 * const appVariant = useAppVariant();// appVariant.isMedium = true when breakpoint sm or more
 */
export function useAppVariant() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme.breakpoints.up('md'));
  const large = useMediaQuery(theme.breakpoints.up('lg'));
  return {
    /**
     * Small screen size (larger than sm)
     */
    small,
    /**
     * Medium screen size (larger than md)
     */
    medium,
    /**
     * Large screen size (larger than lg)
     */
    large,
  };
}
