import { AbsenceCode } from '@/types/AbsenceCode';

export interface AbsenceSearchFilterTypeItem {
  label: string;
  icon: React.ReactElement;
  checked: boolean;
}

export interface AbsenceSearchFilter {
  content: string;
  portfolio: boolean;
  type: {
    [AbsenceCode.Maladie]: AbsenceSearchFilterTypeItem;
    [AbsenceCode.MaladiePro]: AbsenceSearchFilterTypeItem;
    [AbsenceCode.AccidentTravail]: AbsenceSearchFilterTypeItem;
    [AbsenceCode.AccidentTrajet]: AbsenceSearchFilterTypeItem;
    [AbsenceCode.Hospitalisation]: AbsenceSearchFilterTypeItem;
    [AbsenceCode.PathologieGrossesse]: AbsenceSearchFilterTypeItem;
  };
}
