import { displayDate } from '@/utils/date.utils';
import { WarningColumnsTypes, TypeAbsence } from './data';
import { Absence, DocumentAbsence, DocumentOCR } from '@/types';

export const ItemTypes = {
  TypeAbsence: 'Type',
  DateStart: 'DateStart',
  DateEnd: 'DateEnd',
  Pregnancy: 'Pregnancy',
  RelatedWork: 'RelatedWork',
} as const;
export type ItemTypes = (typeof ItemTypes)[keyof typeof ItemTypes];

/**
 * Liste des champs
 */
export type WarningItems = {
  [ItemTypes.DateStart]: WarningItem;
  [ItemTypes.DateEnd]: WarningItem;
  [ItemTypes.RelatedWork]?: WarningItem;
  [ItemTypes.TypeAbsence]?: WarningItem;
  [ItemTypes.Pregnancy]?: WarningItem;
};

export interface OCRValueUndefined {
  value: string;
  undefinedValue: true;
}
export interface OCRValueDefined {
  value: string;
  undefinedValue: false;
}
export type OCRValue = OCRValueUndefined | OCRValueDefined;
export const isUndefinedOCR = (value: OCRValue): value is OCRValueUndefined => value.undefinedValue;

export interface WarningItem {
  label: string;
  isValid: boolean;
  isDisabled: boolean;
  /**
   * Valeur confirmée
   */
  confirmed?: string;
  /**
   * Type de champs
   */
  type: WarningColumnsTypes;
  /**
   * Valeur initiales
   */
  data?: {
    declared: string | boolean | null;
    ocr?: string | boolean | null;
  };
  updatedTo?: string;
}

/**
 * Définition des champs du formulaires d'ocr
 * @param ocrResult Contenu de l'OCR
 * @param absence Absence en base de donnée
 * @returns Les champs du formulaire de modification de l'ocr
 */
export function defineFields(ocrResult: DocumentAbsence['ocr_result'], absence: Absence): WarningItems {
  const isArretMaladie = absence.type_absence.description === 1;
  const prolongationValue = absence.id_absence == null ? TypeAbsence.Initial : TypeAbsence.Prolongation;

  const declareValue = (key: keyof Pick<DocumentOCR, 'date_end' | 'date_start' | 'work' | 'work' | 'pregnancy'>) =>
    ocrResult?.[key]?.value.declare ?? null;
  const detectValue = (key: keyof Pick<DocumentOCR, 'date_end' | 'date_start' | 'work' | 'work' | 'pregnancy'>) =>
    ocrResult?.[key]?.value.detect ?? null;
  const detectStatus = (
    key: keyof Pick<DocumentOCR, 'date_end' | 'date_start' | 'work' | 'work' | 'pregnancy'>,
    allowNull = false,
  ) => ocrResult?.[key]?.status ?? (allowNull ? 'skip' : 'invalid');

  return {
    [ItemTypes.TypeAbsence]: isArretMaladie
      ? {
          label: 'Type d’arrêt',
          type: WarningColumnsTypes.Button,
          isValid: true,
          isDisabled: true,
          data: { declared: prolongationValue, ocr: prolongationValue },
        }
      : undefined,
    [ItemTypes.DateStart]: {
      label: 'Date de début',
      type: WarningColumnsTypes.Date,
      isValid: detectStatus('date_start') !== 'invalid',
      isDisabled: detectStatus('date_start') === 'skip',
      data: {
        declared: absence.date_start ?? declareValue('date_start'),
        ocr: ocrResult?.date_start?.value.detect ?? null,
      },
      updatedTo:
        declareValue('date_start') == null || absence.date_start == null
          ? undefined
          : declareValue('date_start') == absence.date_start
            ? undefined
            : displayDate(String(declareValue('date_start') ?? absence.date_start)),
    },
    [ItemTypes.DateEnd]: {
      type: WarningColumnsTypes.Date,
      label: 'Date de fin',
      isValid: detectStatus('date_end') !== 'invalid' || absence.date_end_hospi_theory != null,
      isDisabled: detectStatus('date_end') === 'skip' || absence.date_end_hospi_theory != null,
      data: {
        declared: absence.date_end ?? absence.date_end_hospi_theory ?? declareValue('date_end'),
        ocr: detectValue('date_end'),
      },
    },
    [ItemTypes.Pregnancy]:
      detectStatus('pregnancy', true) === 'invalid'
        ? {
            label: 'Congé Patho.',
            type: WarningColumnsTypes.Button,
            isValid: false,
            isDisabled: false,
            data: {
              declared: declareValue('pregnancy'),
              ocr: detectValue('pregnancy'),
            },
          }
        : undefined,
    [ItemTypes.RelatedWork]:
      detectStatus('work', true) === 'invalid'
        ? {
            type: WarningColumnsTypes.Button,
            label: 'AT / MP',
            isValid: false,
            isDisabled: false,
            data: {
              declared: declareValue('work'),
              ocr: detectValue('work'),
            },
          }
        : undefined,
  };
}
