import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types/Parcours';
import { ListItem, ListItemAvatar, ListItemButton, type ListItemProps, ListItemText } from '@mui/material';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { ParcoursAvatar } from '@/components/ParcoursAvatar';
import { ParcoursSwitchActive } from '@/components/ParcoursSwitchActive';
import { ParcoursOwner } from '@/types';

const componentName = 'ParcoursListItem';

export interface ParcoursListItemProps extends ListItemProps {
  parcours: Parcours;
  onAction?: (type: 'edit' | 'delete' | 'detail', parcours: Parcours) => void;
}

export function ParcoursListItem(props: ParcoursListItemProps) {
  const { parcours, onAction, ...otherProps } = props;

  const isUpdated = parcours.edited_by != null;
  const createdBy =
    parcours.owner === ParcoursOwner.Admin ? `l'équipe KERij` : parcours.created_by == null ? '' : parcours.created_by;

  const actionDate = isUpdated ? parcours.updated_at : parcours.created_at;
  const action = isUpdated ? 'modifié' : 'créé';
  const actionAt = moment(actionDate).format(`[le ]${FormatDate.FRENCH_DATE}`);
  const actionAuthor = isUpdated ? parcours.edited_by : createdBy;
  const actionAuthorFormat = actionAuthor ? `par ${actionAuthor}` : '';

  const parcoursHistoryInfos = `${action} ${actionAt} ${actionAuthorFormat}`;

  return (
    <ListItem
      secondaryAction={<ParcoursSwitchActive value={parcours} />}
      className={classNames(componentName, componentName)}
      disablePadding
      {...otherProps}
    >
      <ListItemButton disableRipple>
        <ListItemAvatar>
          <ParcoursAvatar parcours={parcours} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 600,
            onClick: () => onAction?.('detail', parcours),
            sx: (theme) => ({
              ':hover': { textDecoration: 'underline' },
              cursor: 'pointer',
            }),
          }}
          primary={parcours.name}
          secondary={parcoursHistoryInfos}
          sx={(theme) => ({ paddingRight: theme.spacing(2.5), cursor: 'default' })}
        />
      </ListItemButton>
    </ListItem>
  );
}
