import React from 'react';
import { Absence } from '../../../types/absences.type';
import { EmployeeInformation } from '../../../types/user.interface';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { Box, Button, Typography } from '@mui/material';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { AbsenceLabel } from '../Absences/AbsenceLabel';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';

export interface AbsencesDeleteValidationProps {
  employee: EmployeeInformation;
  cause?: string | null;
  absences?: Absence[];
  isSubmit?: boolean;
  onReset: () => void;
}

export const AbsencesDeleteValidation = ({
  employee,
  cause,
  absences,
  isSubmit,
  onReset,
}: AbsencesDeleteValidationProps) => {
  return (
    <>
      {!isSubmit && (
        <Box>
          <Typography fontWeight={600}>
            Vous êtes sur le point de supprimer les arrêts suivants pour le collaborateur{' '}
            <em>{employee && getFullName(employee)}</em>
          </Typography>

          <Spacer spacing={2.5} />

          {absences?.map((absence: Absence) => (
            <Box key={absence.id}>
              <AbsenceLabel absence={absence} />
              {' - '}
              <AbsenceDate value={absence} />
            </Box>
          ))}

          <Spacer spacing={2.5} />

          <Typography>Motif : {cause}</Typography>
        </Box>
      )}
      {isSubmit && (
        <Box>
          <Typography fontWeight={600}>
            Votre suppression a bien été transmise et le manager informé de ce changement
          </Typography>
          <Typography fontSize={12} fontWeight={600}>
            Le collaborateur va recevoir une notification
          </Typography>

          <Spacer spacing={1.25} />

          <ActionButton
            variant="outlined"
            label={'Supprimer un nouvel arrêt'}
            onClick={onReset}
            sx={{ mt: 1, mr: 1 }}
          />
        </Box>
      )}
    </>
  );
};
