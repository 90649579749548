import React from 'react';
import { Absence } from '../../../types/absences.type';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { Box, Typography } from '@mui/material';
import { AbsenceLabel } from '../Absences/AbsenceLabel';
import { AbsenceTypeConversion } from '../Absences/AbsenceTypeConversion';
import { Spacer } from '@/components/Spacer';

interface ChangeTypeAbsenceResultProperties {
  absences?: Absence[];
  excluded?: Absence[];
}

export const ChangeTypeAbsenceResult = ({ absences, excluded }: ChangeTypeAbsenceResultProperties) => {
  return (
    <>
      {absences &&
        absences.map((absence: Absence) => (
          <>
            <div className="mt-2">
              <Box>
                <AbsenceLabel absence={absence} through />
                {' ➜ '}
                <AbsenceTypeConversion absence={absence} />
              </Box>
              <AbsenceDate value={absence} />
            </div>
          </>
        ))}
      {excluded && excluded.length > 0 && (
        <Box>
          <Spacer />
          <Typography sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant, fontSize: 14 })} fontWeight={600}>
            Vous ne pouvez pas modifier la nature des arrêts suivants :
          </Typography>
          {excluded.map((absence: Absence) => (
            <>
              <div className="mt-2">
                <Box sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant, fontSize: 12 })}>
                  <AbsenceLabel absence={absence} /> <AbsenceDate value={absence} />
                </Box>
              </div>
            </>
          ))}
        </Box>
      )}
    </>
  );
};
