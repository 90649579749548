import type { ThemeOptions } from '@mui/material';
import { aquaSerenity, royalLavender, sageMeadow, silverVeil, sunsetEmber, infernoRed, goldenHarvest } from './color';
import * as md3 from './token';

export const themePaletteLight = {
  mode: 'light' as const,
  primary: {
    main: royalLavender[950],
    dark: md3.sys.color.onSurfaceVariant,
    light: royalLavender[100],
    contrastText: md3.sys.color.white,
  },
  secondary: {
    light: md3.sys.color.primary,
    main: md3.sys.color.primary,
    dark: aquaSerenity[700],
    contrastText: royalLavender[950],
  },
  error: {
    main: md3.sys.color.error,
  },
  warning: {
    main: sunsetEmber[500],
  },
  success: {
    main: sageMeadow[500],
  },
  info: {
    main: aquaSerenity[700],
  },
  primaryBackground: {
    main: royalLavender[50],
    contrastText: md3.sys.color.onPrimary,
    dark: md3.sys.color.onSurfaceVariant,
  },
  grey: {
    ...silverVeil,
  },
  text: {
    primary: md3.sys.color.onSurface,
  },
  aquaSerenity: {
    ...aquaSerenity,
  },
  goldenHarvest: {
    ...goldenHarvest,
  },
  royalLavender: {
    ...royalLavender,
  },
  sageMeadow: {
    ...sageMeadow,
  },
  silverVeil: {
    ...silverVeil,
  },
  sunsetEmber: {
    ...sunsetEmber,
  },
  infernoRed: {
    ...infernoRed,
  },
  divider: md3.sys.color.outlineVariant,
  action: {
    hover: md3.sys.color.primaryContainer,
    focus: md3.sys.color.primaryContainer,
  },
} satisfies ThemeOptions['palette'];
