import React from 'react';
import { AppLayout } from '../../../components/AppLayout';
import { LoginContent } from './LoginContent';
import { FooterLinks } from '@/components/FooterLinks';

export const Login: React.FC = () => {
  return (
    <AppLayout
      navbar={false}
      footer={<FooterLinks sx={{ position: 'fixed', left: 0, right: 0, bottom: 0, paddingTop: 1, paddingBottom: 4 }} />}
    >
      <LoginContent />
    </AppLayout>
  );
};
