import React, { useState } from 'react';
import { ParcoursPublicQuery } from '@/query';
import { RDVModal } from './RDVModal';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import { ParcoursPublic } from '@/types';
import { Grid } from '@mui/material';
import { useToast } from '@/components/Toast';
import { StatusCodes } from 'http-status-codes';
import { RDVItem } from './RDVItem';

export function RDVTabContent() {
  const toast = useToast();

  const query = ParcoursPublicQuery.useIndex();
  const parcoursPublicTriggerMutation = ParcoursPublicQuery.useTrigger();
  const confirmDialogController = useMUIDialogController();

  const [submitting, setSubmitting] = useState(false);
  const [parcours, setParcours] = useState<ParcoursPublic>();

  async function triggerParcours() {
    setSubmitting(true);
    if (parcours?.parcours_id)
      try {
        const { status } = await parcoursPublicTriggerMutation.mutateAsync({ parcours_id: parcours.parcours_id });

        if (status === StatusCodes.OK)
          toast.present({ message: 'Votre demande a déjà été prise en compte pour ce rendez-vous', severity: 'error' });
        else if (status === StatusCodes.CREATED)
          toast.present({ message: 'Votre demande a bien été prise en compte', severity: 'success' });
        else toast.present({ message: 'Une erreur est survenue, veuillez nous excusez', severity: 'error' });
      } catch {
        toast.present({ message: 'Une erreur est survenue, veuillez nous excusez', severity: 'error' });
      }
    confirmDialogController.control.setOpen(false);
    setSubmitting(false);
  }

  return (
    <Grid container spacing={3} paddingTop={2} mb={3}>
      {query.data?.map((value, index) => (
        <Grid key={index} item xs={12} sx={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
          <RDVItem
            value={value}
            onTrigger={() => {
              setParcours(value);
              confirmDialogController.control.setOpen(true);
            }}
          />
        </Grid>
      ))}
      <RDVModal
        value={parcours}
        onSubmit={triggerParcours}
        {...confirmDialogController.getRootProps()}
        disabled={submitting}
      />
    </Grid>
  );
}
