import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, Typography } from '@mui/material';
import { Absence, AbsenceStatus, DocumentAbsence, DocumentStatus } from '@/types';

const componentName = 'EmployeeAbsenceCardState';

export interface EmployeeAbsenceCardStateProps extends Omit<BoxProps, 'children'> {
  value: Partial<Pick<Absence, 'date_end' | 'date_end_hospi_theory' | 'status'>>;
  absenceDocument?: DocumentAbsence;
}

export function EmployeeAbsenceCardState(props: EmployeeAbsenceCardStateProps) {
  const { value, absenceDocument, className, ...boxProps } = props;

  const isEnded = value.status === AbsenceStatus.FINISHED;
  const isDeleted = value.status === AbsenceStatus.DELETED;
  const isValidDocument = absenceDocument?.status === DocumentStatus.APPROVED;
  const isPendingDocument = absenceDocument?.status === DocumentStatus.PENDING;

  const label = () => {
    if (isDeleted) return 'Absence supprimée';
    if (isEnded) return 'Absence terminée';

    if (isValidDocument) return 'Déclaration validée';
    if (isPendingDocument) return 'Déclaration en cours d’analyse';

    return 'Déclaration en attente de document';
  };

  return (
    <Box className={classNames(componentName, className)} display={'flex'} flexDirection={'column'} {...boxProps}>
      <Typography lineHeight={1} margin={'auto'} fontSize={12}>
        {label()}
      </Typography>
    </Box>
  );
}
