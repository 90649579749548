import React, { useState } from 'react';
import { Absence, AbsenceStatus } from '../../../../../../types/absences.type';
import { AbsenceDate } from '../../../../Absences/AbsenceDate';
import { AbsenceLabel } from '../../../../Absences/AbsenceLabel';
import { CardAbsenceHistoryModal } from './CardAbsenceHistoryModal';
import { Box } from '@mui/material';
import { ListCardContentLine } from '@/components/DashboardRH/ListCard';

interface CardAbsenceHistoryItemProperties {
  absence: Absence;
}

export const CardAbsenceHistoryItem = ({ absence }: CardAbsenceHistoryItemProperties) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <ListCardContentLine
        label={
          <>
            <AbsenceLabel absence={absence} />
            {' - '}
            <AbsenceDate value={absence} />
          </>
        }
        deleted={absence.status === AbsenceStatus.DELETED}
        onClick={() => setIsOpen(true)}
      />

      <CardAbsenceHistoryModal absence={absence} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  );
};
