import React, { ForwardedRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';

interface DeclarationDesktopWarningProps {
  open: boolean;
  text?: React.ReactElement | string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const DeclarationDesktopWarning = React.forwardRef(function DeclarationDesktopWarning(
  props: DeclarationDesktopWarningProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { open, onConfirm, onCancel, onClose } = props;

  const confirm = () => {
    onClose?.();
    onConfirm?.();
  };

  const cancel = () => {
    onClose?.();
    onCancel?.();
  };

  return (
    <React.Fragment>
      <Dialog ref={ref} open={open} onClose={onClose}>
        <DialogTitle color="primary">ATTENTION !</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">
            <Typography>Vous êtes sur le point de réaliser une déclaration d’absence.</Typography>
            <Spacer />

            <Typography fontWeight="bolder">
              Si vous disposez d’un arrêt de travail au format papier, nous vous recommandons de saisir votre absence
              sur votre SMARTPHONE.
            </Typography>
            <Spacer />

            <Typography fontWeight="bolder">
              Notre assistant de prise de photo facilitera votre déclaration et la bonne prise en compte de votre arrêt
              de travail.
            </Typography>
            <Spacer />

            <Typography>Nous vous remercions pour votre collaboration.</Typography>

            <Typography>L’équipe KERij</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ActionButton actionName="cancel" onClick={cancel} />
          <ActionButton actionName="next" label={'Continuer'} onClick={confirm} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});
