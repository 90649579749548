import { Absence, AbsenceStatus, DocumentAbsence, DocumentStatus } from '@/types';
import { varToStringParams } from '../../shared/utils/paths';
import { RHInformation } from '../../types/rh.interface';
import axios from '@/lib/axios';
import { NotificationsType } from '@/components/DashboardRH/EnCours/components/Notifications/NotificationSearchBar';
import { AbsenceCode } from '@/types/AbsenceCode';

export * from './statistic';

export interface FetchRhActionParameters {
  idBusinesses?: string;
  idBusiness?: string;
  limit?: number;
  offset?: number;
  search?: string;
  portfolio?: 'true';
  type?: NotificationsType[];
}

export const fetchRhAction = async (params: FetchRhActionParameters): Promise<any> => {
  if (!params.idBusiness) return null;
  return await axios.get(`/api/rh/actions`, { params: params });
};

export const getNotif = async (token: string, decodedToken: any): Promise<any> => {
  return await axios.get('/api/rh/get_notif', {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      idRhNotification: decodedToken.idRhNotification,
    },
  });
};

export const uploadMedicalNotif = async (token: string, data: any): Promise<any> => {
  return await axios.post('/api/rh/upload_medical_notif', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const verifyAfterVisitInfo = async (token: string, data: any): Promise<any> => {
  return await axios.post('/api/rh/verify_after_visit_info', data);
};

export const updateRHRole = async (role: any, data: any): Promise<any> => {
  return await axios.put('/api/rh/roles/' + role?.id, data);
};

export const createRHRole = async (data: any): Promise<any> => {
  return await axios.post('/api/rh/roles/', data);
};

export const chooseSSTRH = async (token: string, data: any): Promise<any> => {
  return await axios.put('/api/rh/choix_sst', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export interface AbsencesFilters {
  id_employee_information?: string;
  status?: AbsenceStatus[];
  type_absence?: string[];
  detail?: true;
  search?: string;
  page?: number;
  per_page?: number;
  internal_code_absence?: AbsenceCode[];
  portfolio?: 'true';
}
export const fetchRhAbsences = async (params: AbsencesFilters) => {
  return await axios.get<Absence[]>(`/api/rh/absences`, { params });
};

export const fetchRhAbsencesCount = async () => {
  const filters = { status: [AbsenceStatus.DRAFT, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED] };
  return await axios.get<number>(`/api/rh/count/absences`, { params: filters });
};

export const fetchRhAbsencesHistories = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'search',
        value: params.search,
      },
      {
        key: 'offset',
        value: params.offset,
      },
      {
        key: 'limit',
        value: params.limit,
      },
    ],
  });

  return await axios.get(`/api/rh/employee_histories${query}`);
};
interface Pagination {
  offset?: number;
  limit?: number;
}
interface SearchFilter extends Pagination {
  search?: string;
}
export const fetchCurrentAbsences = async (params?: SearchFilter) => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'offset',
        value: params?.offset,
      },
      {
        key: 'limit',
        value: params?.limit,
      },
      {
        key: 'search',
        value: params?.search,
      },
    ],
  });
  return await axios.get(`/api/rh/current_absences${query}`);
};

export const fetchRhGraphDataTrending = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'currentDay',
        value: params.currentDay,
      },
      {
        key: 'day',
        value: params.day,
      },
      {
        key: 'type',
        value: params.type,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
      {
        key: 'idBusinesses',
        value: params.idBusinesses,
      },
    ],
  });
  if (!params.idBusiness && !params?.isAuthorized) {
    return null;
  }

  return await axios.get(`/api/rh/graph/data_trending${query}`);
};

export const fetchRhMedicalAddresses = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'matricule',
        value: params.matricule,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }

  return await axios.get(`/api/rh/medical_addresses${query}`);
};

export const fetchRhBusinessAbsenceOption = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idTypeAbsence',
        value: params.idTypeAbsence,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }
  return await axios.get(`/api/rh/business/absence_option${query}`);
};

export const fetchRhExtendedAbsence = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idAbsence',
        value: params.idAbsence,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
      {
        key: 'idEmployeeInformation',
        value: params.idEmployeeInformation,
      },
      {
        key: 'isLinkHospitalisation',
        value: params.isLinkHospitalisation,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }

  return await axios.get(`/api/rh/get_extended_absence${query}`);
};

export const updateNotificationEmployee = async (payload: any): Promise<any> => {
  return await axios.put(`/api/rh/update_notification_employee`, payload);
};

export const fetchRhGraphDataAbsenteeism = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'month',
        value: params.month,
      },
      {
        key: 'year',
        value: params.year,
      },
      {
        key: 'type',
        value: params.type,
      },
      {
        key: 'monthDiff',
        value: params.monthDiff,
      },
    ],
  });

  return await axios.get(`/api/rh/graph/data_absenteeism${query}`);
};

export const verifySelectedDateEmployee = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/verify_selected_date_employee`, payload);
};

export const fetchRhDateInterview = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idRhNotification',
        value: params.idRhNotification,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }

  return await axios.get(`/api/rh/date_interview${query}`);
};

export const fetchRhProfile = async (): Promise<any> => {
  return await axios.get(`/api/rh/profile`);
};

export const updateRhProfile = async (profile: RHInformation): Promise<any> => {
  return await axios.put(`/api/rh/profile`, { ...profile });
};

export const fetchRhRole = async (): Promise<any> => {
  return await axios.get(`/api/rh/roles`);
};
export const fetchRhRoleById = async (id: string): Promise<any> => {
  return await axios.get(`/api/rh/roles/${id}`);
};

export const rhAclSecurity = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/acl_security`, payload);
};

export const fetchRhListCompanyBusiness = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idParentCompanies',
        value: params.idParentCompanies,
      },
      {
        key: 'idParentCompany',
        value: params.idParentCompany,
      },
      {
        key: 'idBusinesses',
        value: params.idBusinesses,
      },
    ],
  });

  return await axios.get(`/api/rh/get_list_company_business${query}`);
};

export const rhFinishNotif = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/finish_notif`, payload);
};

export const fetchFileS3 = async (params: any, responseType?: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'url',
        value: params.url,
      },
      {
        key: 'type',
        value: params.type,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
      {
        key: 'idRhInformation',
        value: params.idRhInformation,
      },
    ],
  });

  return await axios.get(`/api/file_s3${query}`, { responseType });
};

export const updateRhReportDocument = async (payload: any): Promise<any> => {
  return await axios.put(`api/rh/update_report_document`, payload);
};

export const updateActiveEmployeeContact = async (payload: any): Promise<any> => {
  return await axios.put(`/api/rh/update_active_employee_contact`, payload);
};

export const updateInfoEmployeeContact = async (payload: any): Promise<any> => {
  return await axios.put(`/api/rh/update_info_employee_contact`, payload);
};

export const deleteEmployeeContact = async (id: string): Promise<any> => {
  return await axios.delete(`/api/rh/employee_contact/${id}`);
};

export const notifyManagerDeclarationAbsence = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/notify_manager_declaration_absence`, payload);
};

export const notifyManagerInterviewLiaison = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/notify_manager_interview_liaison`, payload);
};

export const sendEmaiDeclareReturnManager = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/send_email_declare_return_manager`, payload);
};

export const updateRHAction = async (payload: any): Promise<any> => {
  return await axios.put(`/api/rh/update_action`, payload);
};

export const sendEmailVisitMedical = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/send_email_visit_medical`, payload);
};

export const sendEmailVisitPrereprise = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/send_email_visit_prereprise`, payload);
};

export const sendEmailVisitHealthMedical = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/send_email_visit_health_medical`, payload);
};

export const notifyManagerInterviewReturn = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/notify_manager_interview_return`, payload);
};

export const notifySst = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/notify_sst`, payload);
};

export const rhDeleteNotif = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/delete_notif`, payload);
};

export const notifyEmployeeInterviewLiaison = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/notify_employee_interview_liaison`, payload);
};

export const notifyEmployeeInterviewRetour = async (payload: any): Promise<any> => {
  return await axios.post(`/api/rh/notify_employee_interview_retour`, payload);
};

export interface RhUploadFileAbsenceRequest {
  params: {
    idBusiness: string;
    idEmployeeInformation: string;
  };
  body: FormData;
}

export interface RHUploadDocumentApiResponse {
  url?: string;
  created_at?: string;
  updated_at?: string;
  id?: string;
  name?: string;
  id_business?: string;
  bucket_key?: string;
  type?: string;
  id_absence?: string;
  ocr_result?: DocumentAbsence['ocr_result'];
  validation_source?: string | null;
  status: DocumentStatus;
}

export const rhUploadFileAbsence = async ({
  params,
  body,
}: RhUploadFileAbsenceRequest): Promise<RHUploadDocumentApiResponse[] | undefined> => {
  const { data } = await axios.post<RHUploadDocumentApiResponse[] | undefined>(
    `/api/rh/upload_file_absence/${params.idBusiness}/${params.idEmployeeInformation}`,
    body,
  );
  return data;
};

export const rhKeepPassword = async (data: any): Promise<any> => {
  return await axios.post('/api/rh/keep_password', data);
};

export const rhVerifyPassword = async (data: any): Promise<any> => {
  return await axios.post(`/api/rh/verify_password`, data);
};
export const rhChangePassword = async (data: any): Promise<any> => {
  return await axios.put(`/api/rh/change_password`, data);
};
