import * as React from 'react';
import { ListSubheader, List, Typography, Box } from '@mui/material';
import { RHMenuItem, isGroupItemsProps } from './data';
import { LayoutMenuItem } from './LayoutMenuItem';
import { LayoutMenuGroupItems } from './LayoutMenuGroupItems';
import { IfPermission } from '../../IfPermission';
import isAuthorized from '@/hooks/isAuthorized';
import { useAppFeature } from '@/store';

export function LayoutMenu(props: { menu: RHMenuItem[] }) {
  const { menu } = props;
  const appFeature = useAppFeature();
  return (
    <>
      {menu.map((element, elementKey) =>
        element.feature && !appFeature[element.feature] ? null : (
          <Box key={elementKey}>
            {element.permission == null ? (
              <PageContent element={element} key={elementKey} />
            ) : (
              <IfPermission {...element.permission}>
                <PageContent element={element} key={elementKey} />
              </IfPermission>
            )}
          </Box>
        ),
      )}
    </>
  );
}

function PageContent(props: { element: RHMenuItem }) {
  const { element } = props;

  return (
    <>
      {isAuthorized(window.location.pathname) && (
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" sx={{ padding: 0 }}>
              <Typography
                className="section-title"
                sx={(theme) => ({
                  mt: theme.spacing(2.5),
                  paddingLeft: theme.spacing(2.5),
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                  paddingRight: theme.spacing(2.5),
                  fontWeight: 600,
                  fontSize: 10,
                  color: theme.palette.primary.dark,
                  [`&::after`]: {
                    backgroundColor: theme.shape.borderColorVariant,
                    marginLeft: theme.spacing(1.25),
                  },
                })}
              >
                {element.title}
              </Typography>
            </ListSubheader>
          }
        >
          {element.items.map((item, index) =>
            isGroupItemsProps(item) ? (
              <LayoutMenuGroupItems key={index} {...item} />
            ) : (
              <LayoutMenuItem key={index} {...item} />
            ),
          )}
        </List>
      )}
    </>
  );
}
